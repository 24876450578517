import { Backdrop, Box, Button, CircularProgress, FormControlLabel, Grid, IconButton, MenuItem, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import ViewerDisplay from "../../compornents/viewer/viewerDIsplay";
import { ViewerDisplayRefs } from "../../interfaces/viewer/viewerDIsplayInterface";
import "./StudentViewer.css";
import { useNavigate, useParams } from "react-router-dom";
import { AddExamStudentActionLog, CreateFirstDetectionAnswer, CreateFirstIdentificationAnswer, CreateSecondDetectionAnswer, CreateSecondIdentificationAnswer, GetExamQuestion } from "../../repositories/studentExamQuestionRepo";
import { Stack } from "@mui/system";
import { Check, Delete, Edit } from "@mui/icons-material";
import { DrawingData } from "../../objects/viewer";
import { VideoDialogKeyType, VideoDialogRefs } from "../../interfaces/viewer/videoDialogInterface";
import VideoDialog from "../../compornents/viewer/videoDialog";
import { DiagnosisData } from "../../objects/diagnosis";
import { StudentQuestionData, StudentDetectionAnswerData, StudentIdentificationAnswerData } from "../../objects/student";
import { ExamStudentActionLogCreateData, ExamStudentActionLogKind } from "../../objects/exam";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import CommonAlert from "../../compornents/commonAlert";


const StudentViewer: FC = () => {
  const navigate = useNavigate();

  const { examUid } = useParams();

  const [question, setQuestion] = useState<StudentQuestionData | null>(null);
  const [questionTitle, setQuestionTItle] = useState('');
  const [questionCount, setQuestionCount] = useState<{total: number, current: number} | null>(null);
  const [isSecond, setIsSecond] = useState(false);
  const [drawingDataList, setDrawingDataList] = useState<{isConfirm: boolean, data: DrawingData}[]>([]);
  const [identificationAnswerList, setIdentificationAnswerList] = useState<{caseImageUid: number, category: string, data: StudentIdentificationAnswerData}[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const viewerDisplayRef = useRef<ViewerDisplayRefs>(null);
  const videoDialogRef = useRef<VideoDialogRefs>(null);
  const commonAlertRef = useRef<CommonAlertRefs>(null);

  // initialize.
  useEffect( () => {
    (async () => {
      if (!examUid) navigate('/notfound');

      setIsLoading(true);

      const questionData = await GetExamQuestion(Number(examUid));

      if (!questionData) navigate(`/students/${examUid}/finished`);

      setQuestion(questionData);

      setIsLoading(false);
      
      if (questionData && !questionData.answered_first) {
      } else {
        setIsSecond(true);
      };
    })()
  }, []);

  useEffect(() => {
    (async () => {
      if (isSecond) {
        initializeAnswers();
        if (viewerDisplayRef) {
          viewerDisplayRef.current?.changeCurrentIndex(1);
          viewerDisplayRef.current?.deleteCurrentDrawing();
          viewerDisplayRef.current?.showAnnotationImage(null);
        };
        setIsLoading(false);
        await addActionLog(true, ExamStudentActionLogKind.finished_loading, '', null);
        addActionLog(true, ExamStudentActionLogKind.first_time_show_ai_video, '', null);
        await openVideoDialog();
      };
    })()
  }, [isSecond]);

  useEffect(() => {
    if (question) {
      initializeAnswers();
      addActionLog(question.answered_first, ExamStudentActionLogKind.finished_loading, '', null);
    };
  }, [question]);

  useEffect(() => {
    const list = drawingDataList.filter((x) => x.isConfirm);
    viewerDisplayRef.current?.updateDrawingDataList(0 < list.length ? list.map((x) => x.data) : []);
  }, [drawingDataList]);

  const initializeAnswers = () => {
    console.log('Initialize Answers');
    try {
      if (!question) throw new Error('Question is empty');

      setQuestionCount({total: question.total_question_count, current: question.done_question_count + 1});

      switch (question.questions_type) {
        case 'detection':
          setQuestionTItle("検出問題");
          setDrawingDataList([]);
          break;
        case 'identification':
          setQuestionTItle("鑑別問題");

          const answerList: {caseImageUid: number, category: string, data: StudentIdentificationAnswerData}[] = [];
          question.annotation_list.forEach((x) => {
            const data = new StudentIdentificationAnswerData(
              1,
              x.image_index,
              x.uid
            );
            answerList.push({caseImageUid: x.case_image_uid, category: '', data: data});
          });
          setIdentificationAnswerList(answerList);

          break;
      };
    } catch (error) {
      console.error(error);
    };
  };

  const finishDrawing = (data: DrawingData) => {
    if (!question || question.questions_type !== 'detection') return;
    console.log('Finished drawing');
    try {
      if (question.detection_answer_maximum_limit <= drawingDataList.length) {
        viewerDisplayRef.current?.deleteCurrentDrawing();
        commonAlertRef.current?.error('解答上限数に達しています。\n新たに追加する場合は既存の解答を削除してください。');
        return;
      };
      if (!data.shapeData) throw new Error('Shape data is empty');

      if (drawingDataList.filter((x) => !x.isConfirm).length < 1) {
        setDrawingDataList([...drawingDataList, {isConfirm: false, data: data}]);
      } else {
        setDrawingDataList(
          drawingDataList.map((x) => (!x.isConfirm ? {isConfirm: false, data: data} : x))
        );
      };
    } catch (error) {
      console.error(error);
    };
  };

  const changeImageIndex = (imageIndex: number) => {
    if (drawingDataList.filter((x) => !x.isConfirm).length < 1) return;
    
    setDrawingDataList(
      drawingDataList.map((x) => (!x.isConfirm 
        ? {isConfirm: false, data: new DrawingData(x.data.uid, imageIndex, x.data.data, x.data.shapeData, x.data.fileName)} 
        : x
      ))
    );
  };

  const deleteDetectionAnswer = (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement, MouseEvent>, selectedIndex: number) => {
    console.log(`Delete detection answer -> index: ${selectedIndex}`);
    try {
      if (!viewerDisplayRef.current) throw new Error('Viewer display ref does not exist');

      if (viewerDisplayRef.current.checkIsPlaying()) {
        viewerDisplayRef.current.handleIsPlaying(false);
        return;
      };
      
      setDrawingDataList(
        drawingDataList.filter((x, index) => index !== selectedIndex)
      );

      viewerDisplayRef.current.deleteCurrentDrawing();
    } catch (error) {
      console.error(error);
    } finally {
      event.stopPropagation();
    };
  };

  const confirmDetectionAnswer = (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    console.log(`Confirm detection answer`);
    try {
      if (!viewerDisplayRef.current) throw new Error('Viewer display ref does not exist');

      if (viewerDisplayRef.current.checkIsPlaying()) {
        viewerDisplayRef.current.handleIsPlaying(false);
        return;
      };

      setDrawingDataList(
        drawingDataList.map((x) => ({
          isConfirm: true,
          data: x.data,
        }))
      );

      viewerDisplayRef.current.deleteCurrentDrawing();
    } catch (error) {
      console.error(error);
    } finally {
      event.stopPropagation();
    };
  };

  const EditDetectionAnswer = (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement, MouseEvent>, selectedIndex: number) => {
    console.log(`Edit detection answer -> index: ${selectedIndex}`);
    try {
      if (!viewerDisplayRef.current) throw new Error('Viewer display ref does not exist');

      if (viewerDisplayRef.current.checkIsPlaying()) {
        viewerDisplayRef.current.handleIsPlaying(false);
      };

      const targetData = drawingDataList[selectedIndex];

      setDrawingDataList(
        drawingDataList.map((x, index) => (
          index === selectedIndex
            ? {
                isConfirm: false,
                data: x.data
            }
            : {
                isConfirm: true,
                data: x.data
            }))
      );

      viewerDisplayRef.current.addCurrentDrawing(targetData.data);
    } catch (error) {
      console.error(error);
    } finally {
      event.stopPropagation();
    };
  };

  const selectDetectionAnswerRow = (index: number) => {
    console.log(`Select detection answer row -> index: ${index}`);
    try {
      if (!viewerDisplayRef.current) throw new Error('Viewer display ref does not exist');

      const answerData = drawingDataList[index];
      if (!answerData) throw new Error(`Could not find the answerData => index: ${index}`);
      if (!answerData.data) throw new Error(`Could not find the drawingData => index: ${index}`);

      viewerDisplayRef.current.showTargetDrawnData(answerData.data.uid);
    } catch (error) {
      console.error(error);
    };
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>, selectedIndex: number) => {
    console.log(`Handle category change -> index: ${selectedIndex}`);
    try {
      setIdentificationAnswerList(
        identificationAnswerList.map((x, index) => (
          index === selectedIndex
            ? {
                caseImageUid: x.caseImageUid,
                category: event.target.value,
                data: new StudentIdentificationAnswerData(
                  1,
                  x.data.image_index,
                  x.data.template_answer_uid
                )
            }
            : {
                caseImageUid: x.caseImageUid,
                category: x.category,
                data: x.data
            }
          )
        )
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    };
  };

  const handleDiagnosisChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, selectedIndex: number) => {
    console.log(`Handle change diagnosis. value -> ${event.target.value}`);
    try {
      setIdentificationAnswerList(
        identificationAnswerList.map((x, index) => (
          index === selectedIndex
            ? {
                caseImageUid: x.caseImageUid,
                category: x.category,
                data: new StudentIdentificationAnswerData(
                  Number(event.target.value),
                  x.data.image_index,
                  x.data.template_answer_uid
                )
            }
            : {
                caseImageUid: x.caseImageUid,
                category: x.category,
                data: x.data
            }
          )
        )
      );
    } catch (error) {
      console.error(error);
    } finally {
      event.stopPropagation();
    }
  };

  const showAnnotationImage = async (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement, MouseEvent>, caseImageUid: number, templateAnswerUid: number) => {
    console.log(`Show annotation image -> caseImageUid: ${caseImageUid}`);
    try {
      if (!viewerDisplayRef.current) throw new Error('Viewer display ref does not exist');
      event.stopPropagation();

      const showed = await viewerDisplayRef.current.showAnnotationImage(caseImageUid);

      if (showed) {
        addActionLog(isSecond, ExamStudentActionLogKind.showed_image, '', templateAnswerUid);
      };
    } catch (error) {
      console.error(error);
      addActionLog(isSecond, ExamStudentActionLogKind.error, '画像表示でエラー検知', templateAnswerUid);
    } finally {
      event.stopPropagation();
    };
  };

  const getDiagnosisSelectList = (category: string): DiagnosisData[] => {
    if (!question) return [];

    switch (category) {
      case 'malignant':
        const maligrantList = question.diagnosis_list.filter((x) => x.is_malignant);
        if (!maligrantList.find((x) => x.uid === 1)) {
          maligrantList.push(new DiagnosisData(1, false, ''));
        };
        return maligrantList;
      case 'benign':
        const benignList = question.diagnosis_list.filter((x) => !x.is_malignant);
        if (!benignList.find((x) => x.uid === 1)) {
          benignList.push(new DiagnosisData(1, false, ''));
        };
        return benignList;
      default:
        return [];
    };
  };

  const answerQuestion = async (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('Answer question');

    try {
      if (!question) throw new Error('Question data is empty');

      setIsLoading(true);
      viewerDisplayRef.current?.handleIsPlaying(false);

      await addActionLog(isSecond, ExamStudentActionLogKind.clicked_confirm_button, '', null);

      switch (question.questions_type) {
        case 'detection':
          const detectionAnswerList: StudentDetectionAnswerData[] = [];
          const imageBlobList: {fileName: string, data: Blob}[] = [];

          drawingDataList.forEach((x) => {
            const shapeData = x.data.shapeData;

            detectionAnswerList.push(new StudentDetectionAnswerData(
              x.data.imageIndex,
              shapeData.startX,
              shapeData.startY,
              shapeData.endX,
              shapeData.endY,
              shapeData.imageWidth,
              shapeData.imageHeight,
              shapeData.shapeType,
              shapeData.borderColor,
              shapeData.borderWidth,
              x.data.fileName
            ));

            imageBlobList.push({fileName: x.data.fileName, data: x.data.data});
          });

          if (!isSecond) {
            const detectionFirstResult = await CreateFirstDetectionAnswer(
              question.question_uid, 
              detectionAnswerList, 
              imageBlobList
            );

            if (detectionFirstResult.needSecond) setIsSecond(true);
            else window.location.reload();
          } else {
            await CreateSecondDetectionAnswer(
              question.question_uid,
              detectionAnswerList,
              imageBlobList
            );

            window.location.reload();
          };

          break;
        case 'identification':
          const studentIdentificationAnswerList = identificationAnswerList.map((x) => x.data);
          if (!isSecond) {
            const identificationFirstResult = await CreateFirstIdentificationAnswer(
              question.question_uid,
              studentIdentificationAnswerList
            );

            if (identificationFirstResult.needSecond) setIsSecond(true);
            else window.location.reload();
          } else {
            await CreateSecondIdentificationAnswer(
              question.question_uid,
              studentIdentificationAnswerList
            );

            window.location.reload();
          };

          break;
        default:
          throw new Error('Question type is not allowed');
      };
    } catch (error) {
      console.error(error);
      await addActionLog(isSecond, ExamStudentActionLogKind.error, '解答処理でエラー', null);
      commonAlertRef.current?.error('解答処理に失敗しました。');
    } finally {
      setIsLoading(false);
    };
  };

  const addActionLog = async (second: boolean, actionUid: ExamStudentActionLogKind, description: string, targetUid: number | null) => {
    console.log('Add action log');
    try {
      if (!question) throw new Error('Question data is empty');

      await AddExamStudentActionLog(
        question.question_uid,
        second,
        new ExamStudentActionLogCreateData(
          actionUid,
          description,
          targetUid,
          new Date()
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const clickedShowAiVideoButton = async () => {
    console.log('Clicked show ai video button');
    try {
      await addActionLog(isSecond, ExamStudentActionLogKind.clicked_show_ai_video_button, '', null);
      await openVideoDialog();
    } catch (error) {
      console.error(error);
    };
  };

  const openVideoDialog = async () => {
    if (!videoDialogRef || !isSecond || !question) return;
    videoDialogRef.current?.openDialog(VideoDialogKeyType.ExamQuestionUid, question.question_uid, false);
    
    if (viewerDisplayRef.current?.checkIsPlaying()) {
      viewerDisplayRef.current?.handleIsPlaying(false);
    };
  };

  const closedVideoDialog = () => {
    addActionLog(isSecond, ExamStudentActionLogKind.closed_video_dialog_button, '', null);
  };

  const videoDialogCoughtError = (message: string) => {
    addActionLog(isSecond, ExamStudentActionLogKind.error, message, null);
  };

  return (
    <Grid container className="StudentViewer-root-grid">
      <Grid item xs={9}>
        { question !== null
          ? <ViewerDisplay 
              ref={viewerDisplayRef}
              caseData={question.case_data}
              enableDrawing={question.questions_type === 'detection' ? true : false}
              enableShowDrawingData={true}
              finishDrawing={finishDrawing}
              changeImageIndex={question.questions_type === 'detection' ? changeImageIndex : undefined}
            />
          : <div>
            </div>
        }
      </Grid>
      <Grid 
        container 
        item 
        xs={3} 
        className="StudentViewer-panel-grid"
      >
        <Grid 
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          className="StudentViewer-list-grid"
        >
          <Grid 
            container  
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            style={{padding: 5}}
          >
            {question &&
              <>
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  spacing={1}
                  className='StudentViewer-title-stack'
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                      {`${question.question_number}.${questionTitle}`}
                    </Typography>
                    {questionCount &&
                      <Typography variant="body1">
                        {`${questionCount.current}/${questionCount.total}`}
                      </Typography> 
                    }
                  </Stack>
                  <Typography variant="body1" className='StudentViewer-description'>
                    {question.description}
                  </Typography> 
                </Stack>
                {question.questions_type === 'detection' &&
                  <TableContainer component={Paper} className='StudentViewer-detection-table'>
                    <Table size="small" stickyHeader style={{width: '100%'}}>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ minWidth: 30 }}>No.</TableCell>
                          <TableCell style={{ width: '100%', minWidth: 100 }}>枚目</TableCell>
                          <TableCell style={{ minWidth: 50 }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {drawingDataList.map((answer, index) => (
                          <TableRow 
                            hover 
                            tabIndex={-1} 
                            key={index} 
                            onClick={(event) => drawingDataList.filter((x) => !x.isConfirm).length < 1 && selectDetectionAnswerRow(index)}
                          >
                            <TableCell>{ index + 1 }</TableCell>
                            <TableCell>{ answer.data.imageIndex }</TableCell>
                            <TableCell align="right">
                              {answer.isConfirm === false 
                              ? <Stack direction='row' spacing={1}>
                                  <IconButton 
                                    onClick={(evt) => deleteDetectionAnswer(evt, index)}
                                    edge="end" size="small" color="error"
                                  >
                                    <Delete />
                                  </IconButton>
                                  <IconButton 
                                    onClick={(evt) => confirmDetectionAnswer(evt)}
                                    edge="end" size="small" color="success"
                                  >
                                    <Check />
                                  </IconButton>
                                </Stack>
                              : <IconButton
                                  onClick={(evt) => EditDetectionAnswer(evt, index)}
                                  edge="end" size="small" color="primary"
                                >
                                  <Edit />
                                </IconButton>
                              }
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                }
                {question.questions_type === 'identification' &&
                  <Paper 
                    elevation={3}
                    className='StudentViewer-identification-main-paper'
                  >
                    {identificationAnswerList.map((x, index) => (
                      <Paper
                        key={index}
                        onClick={async (evt) => await showAnnotationImage(evt, x.caseImageUid, x.data.template_answer_uid)}
                        variant="outlined"
                        style={{margin: 5, padding: 5}}
                      >
                        <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Button
                          onClick={async (evt) => await showAnnotationImage(evt, x.caseImageUid, x.data.template_answer_uid)}
                          fullWidth
                        >
                          {`画像表示(${x.data.image_index}枚目)`}
                        </Button>
                        <RadioGroup 
                          value={x.category}
                          onChange={(evt) => handleCategoryChange(evt, index)}
                          row
                        >
                          <FormControlLabel value="malignant" control={<Radio />} label="悪性" />
                          <FormControlLabel value="benign" control={<Radio />} label="良性" />
                        </RadioGroup>
                        <TextField
                          select
                          value={x.data.diagnosis_uid} 
                          onChange={(evt) => handleDiagnosisChange(evt, index)}
                          disabled={x.category === ''}
                          size="small"
                          fullWidth
                        >
                          {getDiagnosisSelectList(x.category).map((d) => (
                            <MenuItem key={d.uid} value={d.uid}>{d.display}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      </Paper>
                    ))}
                  </Paper>
                }
                <Button
                  onClick={async (event) => await answerQuestion(event)}
                  disabled={(
                    (question.questions_type === 'detection' && 
                      0 < drawingDataList.filter((x) => !x.isConfirm).length
                    ) ||
                    (question.questions_type === 'identification' &&
                      0 < identificationAnswerList.filter((x) => x.data.diagnosis_uid < 2).length
                    )
                  )}
                  variant="contained"
                  fullWidth
                >
                  {`解答する(${isSecond === false ? '1度目' : '2度目'})`}
                </Button>
                <Box component="div" sx={{ height: '140px', marginTop: '5px', backgroundColor: '#eeeeee', border: '1px dashed grey' }}>
                  {isSecond &&
                    <Button
                      onClick={async (event) => await clickedShowAiVideoButton()}
                      fullWidth
                      style={{height: '100%'}}
                    >
                      {`AI動画を表示する`}
                    </Button>
                  }
                </Box>
              </>
            }
          </Grid>
        </Grid>
      </Grid>
      {isSecond &&
        <VideoDialog
          ref={videoDialogRef}
          closeDialog={closedVideoDialog}
          errorDetected={videoDialogCoughtError}
        />
      }
      <CommonAlert
        ref={commonAlertRef}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default StudentViewer;