import { ExamTemplateCaseAnnotationData } from "./examTemplateCaseAnnotationData";

export class ExamTemplateAnswerAnnotationData {
  uid: number;
  case_image_uid: number;
  exam_template_case_annotation: ExamTemplateCaseAnnotationData | null;

  constructor(
    uid: number,
    case_image_uid: number,
    exam_template_case_annotation: ExamTemplateCaseAnnotationData | null
  ) {
    this.uid = uid;
    this.case_image_uid = case_image_uid;
    this.exam_template_case_annotation = exam_template_case_annotation;
  }
}
