import { Pause, PlayArrow } from "@mui/icons-material";
import { alpha, Backdrop, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Slider, Stack } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import { CreateRandomAnswerUid } from "../../fuctions/createRandomAnswerUid";
import drawingShapeService from "../../fuctions/drawingShapeService";
import { ViewerDisplayProps, ViewerDisplayRefs } from "../../interfaces/viewer/viewerDIsplayInterface";
import { ImageData } from "../../objects/viewer";
import { DrawingData, ShapeData } from "../../objects/viewer";
import { GetAnnotationImageData, GetCaseImageData } from "../../repositories/caseRepo";
import './viewerDisplay.css';


const ViewerDisplay = forwardRef<ViewerDisplayRefs, ViewerDisplayProps>((props, ref) => {
  const caseData = props.caseData;
  const enableDrawing = props.enableDrawing;
  const enableShowDrawingData = props.enableShowDrawingData;

  const [windowResizeTimeout, setWindowResizeTimeout] = useState(0);
  const [imageDataList, setImageDataList] = useState<ImageData[]>([]);
  const [originalImgWidth, setOriginalImgWidth] = useState(0);
  const [originalImgHeight, setOriginalImgHeight] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playingSpeed, setPlayingSpeed] = useState(33);
  const [startCie, setStartCie] = useState<{x: number, y: number}>({x: -1, y: -1});
  const [shapeType, setShapeType] = useState('circle');
  const [annotationImageBlob, setAnnotationImageBlob] = useState<{caseImageUid: number, data: Blob} | null>(null);
  const [annotationImageList, setAnnotationImageList] = useState<{caseImageUid: number, data: Blob}[]>([]);
  const [infoImageUrl, setInfoImageUrl] = useState('');
  const [currentDrawingData, setCurrentDrawingData] = useState<DrawingData | null>(null);
  const [drawingDataList, setDrawingDataList] = useState<DrawingData[]>([]);
  const [selectedDrawingBlob, setSelectedDrawingBlob] = useState<{index: number, blob: Blob} | null>(null);
  const [loading, setLoading] = useState(false);

  const cancelToken = axios.CancelToken.source();

  useImperativeHandle (ref, () => ({
    addCurrentDrawing(data: DrawingData) {
      console.log('Add current drawing');
      setCurrentIndex(data.imageIndex);
      setCurrentDrawingData(data);
    },
    deleteCurrentDrawing() {
      console.log('Delete current drawing');
      setIsPlaying(false);
      setCurrentDrawingData(null);
    },
    checkIsPlaying () {
      return isPlaying;
    },
    handleIsPlaying (play: boolean) {
      setIsPlaying(play);
    },
    async showTargetDrawnData(drawUid: string): Promise<void> {
      console.log(`Show target drawn data -> drawUid: ${drawUid}`);

      try {
        if (!drawUid) {
          console.log('set null')
          setSelectedDrawingBlob(null);
        } else {
          const drawingData = drawingDataList.find(d => d.uid === drawUid);
          if (! drawingData || !drawingData.shapeData) throw new Error('Could not find DrawingData.');

          if (isPlaying) setIsPlaying(false);
          const changedBlob = await changeImageBorderColor(drawingData.shapeData);
          setCurrentIndex(drawingData.imageIndex);
          setSelectedDrawingBlob({index: drawingData.imageIndex, blob: changedBlob});
        };
      } catch (error) {
        console.error(error);
      }
    },
    updateDrawingDataList(list: DrawingData[]) {
      try {
        setDrawingDataList(list);
        setSelectedDrawingBlob(null);
      } catch (error) {
        console.error(error);
      }
    },
    changeCurrentIndex(targetIndex: number) {
      setCurrentIndex(targetIndex);
      setAnnotationImageBlob(null);
    },
    async showAnnotationImage(caseImageUid: number | null) {
      console.log(`Show annotation data -> caseImageUid: ${caseImageUid}`);
      try {
        if (!caseImageUid) {
          setAnnotationImageBlob(null);
          return true;
        };

        if (annotationImageBlob?.caseImageUid === caseImageUid) return false;

        setLoading(true);

        const data = caseData.case_image_list.find((x) => x.id === caseImageUid);
        if (!data) throw new Error(`Could not find the case image data -> uid: ${caseImageUid}`);

        const tempData = annotationImageList.find((x) => x.caseImageUid === caseImageUid);
        if (tempData) {
          setIsPlaying(false);
          setCurrentIndex(data.image_index);
          setAnnotationImageBlob({caseImageUid: caseImageUid, data: tempData.data});
        } else {
          const image = await GetAnnotationImageData(data.id);
          setIsPlaying(false);
          setCurrentIndex(data.image_index);
          setAnnotationImageBlob({caseImageUid: caseImageUid, data: image});
          setAnnotationImageList([...annotationImageList, {caseImageUid: caseImageUid, data: image}]);
        };

        return true;
      } catch (error) {
        console.error(error);
        throw error;
      } finally{
        setLoading(false);
      };
    }
  }));

  // initialize.
  useEffect(() => {
    (async () => {
      await Promise.all(caseData.case_image_list.map(async (x) => {
        try {
          const image = await GetAnnotationImageData(x.id);
          setAnnotationImageList([...annotationImageList, {caseImageUid: x.id, data: image}]);
        } catch (error) {
          console.error(error);
        };
      }));

      const indexList = [...Array(caseData.image_count)].map((_, i) => (i));
      
      await Promise.all(indexList.map(async (x) => {
        const index = x + 1;
        const data = await getCaseImageData(caseData.id, index);

        if (!data) {
          setImageDataList((list) => [...list, new ImageData(index, null, true)]);
        } else {
          setImageDataList((list) => [...list, new ImageData(index, data, false)]);
        };

        if (index === 1) {
          setCurrentIndex(1);
          if (annotationImageBlob) setAnnotationImageBlob(null);
        };
      }));
    })();

    const handleWindowResize = () => {
      setWindowResizeTimeout((timeout) => {
        return timeout + 1;
      });
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      cancelToken.cancel();
    };
  }, []);

  const getCaseImageData = async (caseUid: number, imageIndex: number): Promise<Blob | null> => {
    try {
      return await GetCaseImageData(caseUid, imageIndex, cancelToken);
    } catch (error) {
      console.error(error);
      return null;
    };
  };

  const setImageCanvas = async (blob: Blob) => {
    const img = new Image();
    img.src = URL.createObjectURL(blob);

    img.onload = async () => {
      const imgWidth = img.width;
      const imgHeight = img.height;

      setOriginalImgWidth(imgWidth);
      setOriginalImgHeight(imgHeight);

      const display = document.getElementById('display') as HTMLDivElement | null;
      if (!display) throw new Error('Could not get display element');

      const imageCanvas = document.getElementById('imageCanvas') as HTMLCanvasElement | null;
      if (!imageCanvas) throw new Error('Could not get imageCanvas.');
      const imageCanvasCtx = imageCanvas.getContext('2d');
      if (!imageCanvasCtx) throw new Error('Could not get imageCanvas context.');

      const maxWidth = display.clientWidth / imgWidth;
      const maxHeight = display.clientHeight / imgHeight;

      const ratio = Math.min(maxWidth, maxHeight);
      const setWidth = imgWidth * ratio;
      const setHeight = imgHeight * ratio;

      imageCanvas.width = setWidth;
      imageCanvas.height = setHeight;

      imageCanvasCtx.drawImage(img, 0, 0, setWidth, setHeight);

      if (currentDrawingData) {
        setCurrentDrawingData(new DrawingData(
          currentDrawingData.uid,
          currentDrawingData.imageIndex,
          currentDrawingData.data,
          currentDrawingData.shapeData,
          currentDrawingData.fileName
        ));
      } else {
        const drawCanvas = document.getElementById('drawCanvas') as HTMLCanvasElement | null;
        if (!drawCanvas) return;

        drawCanvas.width = setWidth;
        drawCanvas.height = setHeight;
      };
    };
  }

  // Interval for playing the image video.
  useEffect(() => {
    let playingInterval: NodeJS.Timer | undefined = undefined;

    if (isPlaying) {
      if (annotationImageBlob) setAnnotationImageBlob(null);
      playingInterval = setInterval(() => {
        setCurrentIndex((index) => {
          if (caseData.image_count <= index) {
            return 1;
          } else {
            const nextIndex = index + 1;
            return nextIndex; 
          }
        });
      }, playingSpeed);
    } else {
      clearInterval(playingInterval);
    }

    return () => clearInterval(playingInterval);
  }, [isPlaying, playingSpeed]);


  // Timeout for resizing display.
  useEffect(() => {
    const timer = setTimeout(async () => {
      try {
        const imageData = imageDataList.find(x => x.index == currentIndex);
        if (imageData && imageData.data) {
          setImageCanvas(imageData.data);
        };
      } catch (error) {
        console.error(error);
      }
    }, 0.1 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [windowResizeTimeout]);

  // Timeout for changing current index, get image.
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        const imageData = imageDataList.find(x => x.index === currentIndex);
        if (imageData && imageData.data) {
          if (infoImageUrl) setInfoImageUrl('');
          setImageCanvas(imageData.data);
        } else {
          const publicPath = process.env.PUBLIC_URL;

          if (!imageData || !imageData.data) {
            setInfoImageUrl(`${publicPath}/image-loading.jpg`);
          } else if (imageData.isError) {
            setInfoImageUrl(`${publicPath}/image-error.jpg`);
          };
        };

        if (props.changeImageIndex) props.changeImageIndex(currentIndex);
      } catch (error) {
        console.error(error);
      }
    }, 0.01 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [currentIndex]);

  // Watching for showing drawing data.
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        console.log('Set current drawing data');
        
        const imageCanvas = document.getElementById('imageCanvas') as HTMLCanvasElement | null;
        if (!imageCanvas) throw new Error('Could not get imageCanvas.');
  
        const drawCanvas = document.getElementById('drawCanvas') as HTMLCanvasElement | null;
        if (!drawCanvas) throw new Error('Could not get drawCanvas.');
  
        drawCanvas.width = imageCanvas.width;
        drawCanvas.height = imageCanvas.height;
  
        if (!currentDrawingData) {
          console.log('Initiarize draw canvas');
          
          const ctx = drawCanvas.getContext('2d');
          if (!ctx) throw new Error('Could not get imageCanvas context.');
  
          ctx.clearRect(0, 0, drawCanvas.clientWidth, drawCanvas.clientHeight);
        } else {
          if (currentDrawingData.shapeData) {
            const shapeData = new ShapeData(
              currentDrawingData.shapeData.startX,
              currentDrawingData.shapeData.startY,
              currentDrawingData.shapeData.endX,
              currentDrawingData.shapeData.endY,
              currentDrawingData.shapeData.imageWidth,
              currentDrawingData.shapeData.imageHeight,
              currentDrawingData.shapeData.shapeType,
              "#76ff03",
              currentDrawingData.shapeData.borderWidth
            );
            drawingShapeService.drawShape(shapeData, drawCanvas);
          };
        };
      } catch (error) {
        console.error(error);
      };
    }, 0.0005 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [currentDrawingData]);

  const changeImageBorderColor = async (srcShapeData: ShapeData): Promise<Blob> => {
    console.log('Change image border color');

    const canvas = document.createElement('canvas');
    canvas.width = srcShapeData.imageWidth;
    canvas.height = srcShapeData.imageHeight;

    const shapeData = new ShapeData(
      srcShapeData.startX,
      srcShapeData.startY,
      srcShapeData.endX,
      srcShapeData.endY,
      srcShapeData.imageWidth,
      srcShapeData.imageHeight,
      srcShapeData.shapeType,
      "#76ff03",
      srcShapeData.borderWidth
    );

    drawingShapeService.drawShape(shapeData, canvas);
    const blobData: Blob | null = await new Promise(resolve => canvas.toBlob((blob) => blob ? resolve(blob) : null));

    if (!blobData) throw new Error('Could not create blob');
    else return blobData;
  };

  const wheelImages = (event: React.WheelEvent<HTMLDivElement>) => {
    setIsPlaying(false);

    if (0 < event.deltaY) {
      if (annotationImageBlob) setAnnotationImageBlob(null);
      
      if (currentIndex < caseData.image_count) {
        setCurrentIndex(currentIndex + 1);
      } else {
        setCurrentIndex(1);
      };
    } else if (event.deltaY < 0) {
      if (annotationImageBlob) setAnnotationImageBlob(null);

      if (1 < currentIndex) {
        setCurrentIndex(currentIndex - 1);
      } else {
        setCurrentIndex(caseData.image_count);
      };
    };
  };

  const handleIndexChange = async (event: Event | React.SyntheticEvent, newValue: number | number[]) => {
    console.log('Handle index change');

    setIsPlaying(false);
    
    const newIndex = newValue as number;
    if (newIndex  !== currentIndex) {
      if (annotationImageBlob) setAnnotationImageBlob(null);
      setCurrentIndex(newIndex);
    };
  };

  const handlePlaying = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      setIsPlaying(!isPlaying);
    } catch (error) {
      console.error(error);
    } finally {
      event.stopPropagation();
    }
  }

  const canvasMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!enableDrawing) {
      setIsPlaying(!isPlaying);
      return;
    } else {
      if (isPlaying) {
        setIsPlaying(false);
      };
    };

    const canvasTarget = e.target as HTMLCanvasElement;
    const rect = canvasTarget.getBoundingClientRect();

    setStartCie({x: e.clientX - rect.left, y: e.clientY - rect.top});
  }

  const canvasMouseOut = (e: React.MouseEvent<HTMLCanvasElement>) => {
    setStartCie({x: -1, y: -1});
  }

  const canvasMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (startCie.x < 0 || startCie.y < 0) return;
    try {
      setSelectedDrawingBlob(null);

      const canvasTarget = e.target as HTMLCanvasElement;
      const rect = canvasTarget.getBoundingClientRect();

      const endX = e.clientX - rect.left;
      const endY = e.clientY - rect.top;

      if (startCie.x - endX === 0 && startCie.y - endY === 0) return;

      const x1 = startCie.x;
      const y1 = startCie.y;
      const x2 = endX;
      const y2 = endY;

      const widthRatio = originalImgWidth / canvasTarget.width;
      const heightRatio = originalImgHeight / canvasTarget.height;

      const orgStartX = Math.round(x1 * widthRatio);
      const orgEndX = Math.round(x2 * widthRatio);
      const orgStartY = Math.round(y1 * heightRatio);
      const orgEndY = Math.round(y2 * heightRatio);

      const shapeData = new ShapeData(orgStartX, orgStartY, orgEndX, orgEndY, originalImgWidth, originalImgHeight, shapeType, '#ffea00', 2);

      const canvas = document.createElement('canvas');
      canvas.width = originalImgWidth;
      canvas.height = originalImgHeight;

      drawingShapeService.drawShape(shapeData, canvas);

      canvas.toBlob((blob) => {
        if (!blob) throw new Error('Blob is null');

        const id = CreateRandomAnswerUid();
        const drawingData = new DrawingData(
          id,
          currentIndex,
          blob,
          shapeData,
          `${id}.jpg`
        );
  
        setCurrentDrawingData(drawingData);
      });
    } catch (error) {
      console.error(error)
    };
  };

  const canvasMouseUp = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (startCie.x < 0 || startCie.y < 0) return;
    try {
      const canvasTarget = e.target as HTMLCanvasElement;
      const rect = canvasTarget.getBoundingClientRect();

      const endX = e.clientX - rect.left;
      const endY = e.clientY - rect.top;

      if (startCie.x - endX === 0 && startCie.y - endY === 0) {
        return;
      };

      const x1 = startCie.x;
      const y1 = startCie.y;
      const x2 = endX;
      const y2 = endY;

      const widthRatio = originalImgWidth / canvasTarget.width;
      const heightRatio = originalImgHeight / canvasTarget.height;

      const orgStartX = Math.round(x1 * widthRatio);
      const orgEndX = Math.round(x2 * widthRatio);
      const orgStartY = Math.round(y1 * heightRatio);
      const orgEndY = Math.round(y2 * heightRatio);

      const shapeData = new ShapeData(orgStartX, orgStartY, orgEndX, orgEndY, originalImgWidth, originalImgHeight, shapeType, '#ffea00', 2);

      const canvas = document.createElement('canvas');
      canvas.width = originalImgWidth;
      canvas.height = originalImgHeight;

      drawingShapeService.drawShape(shapeData, canvas);

      canvas.toBlob((blob) => {
        if (!blob) throw new Error('Blob is null');
        
        const id = CreateRandomAnswerUid();
        const drawingData = new DrawingData(
          id,
          currentIndex,
          blob,
          shapeData,
          `${id}.jpg`
        );
  
        setCurrentDrawingData(drawingData);
  
        if (props.finishDrawing) props.finishDrawing(drawingData);
      });
    } catch (error) {
      console.error(error)
    } finally {
      setStartCie({x: -1, y: -1});
    }
  }

  const getImageCanvasWidth = (): number => {
    return (document.getElementById('imageCanvas') as HTMLCanvasElement | null)?.width ?? 500;
  }

  const getImageCanvasHeight = (): number => {
    return (document.getElementById('imageCanvas') as HTMLCanvasElement | null)?.height ?? 300;
  }

  return (
    <div className="ViewerDisplay-root">
      <Grid container item direction="column" justifyContent="center" alignItems="center" style={{height: '100%'}}>
        <div id="display" className="display" onWheel={wheelImages}>
          <canvas id="imageCanvas" className="imageCanvas"/>
          {/* <img id="originalImage" className="originalImage"/> */}
          {enableShowDrawingData && 
            drawingDataList.filter((x) => x.imageIndex === currentIndex).map((x, index) => (
              <img 
                key={index} 
                src={URL.createObjectURL(x.data)} 
                width={getImageCanvasWidth()} 
                height={getImageCanvasHeight()}
                className="drawnImage"
              />
            ))
          }
          {(selectedDrawingBlob !== null && selectedDrawingBlob.index === currentIndex) &&
            <img
              src={URL.createObjectURL(selectedDrawingBlob.blob)} 
              width={getImageCanvasWidth()} 
              height={getImageCanvasHeight()}
              className="selectedDrawnImage"
            />
          }
          { infoImageUrl &&
            <img 
              id="infoImage"
              src={infoImageUrl}
              width={getImageCanvasWidth()} 
              height={getImageCanvasHeight()}
              className="annotationImage"
            />
          }
          { annotationImageBlob != null &&
            <img 
              id="annotationImage"
              src={URL.createObjectURL(annotationImageBlob.data)}
              width={getImageCanvasWidth()} 
              height={getImageCanvasHeight()}
              className="annotationImage"
            />
          }
          <canvas
            id="drawCanvas"
            className="drawCanvas"
            onMouseDown={canvasMouseDown}
            onMouseMove={enableDrawing ? canvasMouseMove : undefined}
            onMouseUp={enableDrawing ? canvasMouseUp : undefined}
            onMouseOut={canvasMouseOut}
          />
          <div className="ViewerDisplay-overlay-bottom-left">
            <div style={{width: '150px', textAlign: 'left', color: '#03a9f4'}}>{`Img: ${currentIndex}/${caseData.image_count}`}</div>
            <div style={{width: '150px', textAlign: 'left', color: '#03a9f4'}}>{`${originalImgWidth} * ${originalImgHeight}`}</div>
          </div>
          <div className="ViewerDisplay-ImageInfo">
            <Stack spacing={1} direction="row" alignItems="center" style={{width: '100%'}}>
              <FormControl sx={{ m: 1, minWidth: 100, margin: 0 }} size="small">
                <InputLabel style={{color: '#03a9f4'}}>Speed</InputLabel>
                <Select
                  value={playingSpeed}
                  onChange={(evt) => setPlayingSpeed(Number(evt.target.value))}
                  label="Speed"
                  sx={{
                    color: '#03a9f4',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#03a9f4'
                    },
                    '& .MuiSvgIcon-root': {
                        color: '#03a9f4'
                    }
                }}
                >
                  <MenuItem value={33}>{'x1.0'}</MenuItem>
                  <MenuItem value={49}>{'x0.75'}</MenuItem>
                  <MenuItem value={66}>{'x0.50'}</MenuItem>
                </Select>
              </FormControl>
              <IconButton 
                onClick={(event) => handlePlaying(event)}
                edge='start'
                size="small" 
                color='primary'
              >
                {isPlaying ?
                  <Pause/> :
                  <PlayArrow />
                }
              </IconButton>
              <Slider value={currentIndex} onChange={handleIndexChange} min={1} max={caseData.image_count} step={1} sx={{
                '& .MuiSlider-thumb': {
                  color: '#03a9f4',
                  borderRadius: '20px',
                  width: '20px',
                  height: '13px',
                  '&:hover, &.Mui-focusVisible': {
                    boxShadow: `0px 0px 0px 2px ${alpha('#03a9f4', 0.16)}`,
                  },
                  '&.Mui-active': {
                    boxShadow: `0px 0px 0px 4px ${alpha('#03a9f4', 0.16)}`,
                  },
                },
                '& .MuiSlider-track': {
                  color: 'transparent',
                },}}
              />
            </Stack>
          </div>
        </div>
      </Grid>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
});

export default ViewerDisplay;