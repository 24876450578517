import { axiosClient } from "../contexts/axiosClientProvider";

export const CopyExamTemplateCaseFiles = async (caseUid: number) => {
  let url = `/exam/template/cases/files/${caseUid}`;

  await axiosClient.put(url);
};

export const CopyExamTemplateCases = async () => {
  let url = `/exam/template/cases/copy/allfromcenter`;

  await axiosClient.put(url, null, {
    timeout: 300000,
  });
};

export const UpdateExamTemplateCaseDetectionManualCount = async (
  caseUid: number,
  countValue: number | null
): Promise<void> => {
  let url = `/exam/template/cases/count/${caseUid}`;

  await axiosClient.put(url, {
    new_count: countValue,
  });
};

export const UpdateExamTemplateCaseAnnotationSize = async (
  templateAnswerUid: number,
  size: number
): Promise<void> => {
  const body = {
    size: 0 < size ? size : null,
  };

  await axiosClient.put(
    `/exam/template/cases/annotations/${templateAnswerUid}/size`,
    body
  );
};
