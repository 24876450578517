import { axiosClient } from "../contexts/axiosClientProvider";
import { UserCreate, UserData } from "../objects/user";

export const AddUser = async (data: UserData): Promise<string> => {
  const response = await axiosClient.post<string>(`users`, data);
  return response.data;
};

export const GetUser = async (userId: string): Promise<UserData> => {
  const response = await axiosClient.get<UserData>(`users/${userId}`);
  return response.data;
};

export const GetUserList = async (userId: string, includeDeleted: boolean): Promise<UserData[]> => {
  const response = await axiosClient.get<UserData[]>(`users?user_id=${userId}&deleted=${includeDeleted}`);
  return response.data;
};

export const UpdateUser = async (userId: string, data: UserData): Promise<UserData> => {
  const response = await axiosClient.put<UserData>(`users/${userId}`, data);
  return response.data;
};

export const InitializeUserPassword = async (userId: string): Promise<string> => {
  const response = await axiosClient.put(`users/password/initialize/${userId}`);
  return response.data;
};

export const EnableUser = async (userId: string): Promise<string> => {
  const response = await axiosClient.put(`users/enable/${userId}`);
  return response.data;
};

export const DeleteUser = async (userId: string): Promise<void> => {
  await axiosClient.delete<UserData>(`users/${userId}`);
};