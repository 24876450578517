export class ShapeData {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  imageWidth: number;
  imageHeight: number;
  shapeType: string;
  borderColor: string;
  borderWidth: number;

  constructor (startX: number, startY: number, endX: number, endY: number, imageWidth: number, imageHeight: number, shapeType: string, borderColor: string, borderWidth: number) {
    this.startX = startX;
    this.startY = startY;
    this.endX = endX;
    this.endY = endY;
    this.imageWidth = imageWidth;
    this.imageHeight = imageHeight;
    this.shapeType = shapeType;
    this.borderColor = borderColor;
    this.borderWidth = borderWidth;
  }
}