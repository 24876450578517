import { Backdrop, Button, Chip, CircularProgress, FormControl, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import CommonHeader from "../../compornents/commonHeader";
import './ExamStudentResultPage.css';
import { Stack } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { GetExam } from "../../repositories/examRepo";
import { ExamData, ExamStudentData, ExamStudentResultQuestionData } from "../../objects/exam";
import { GetExamStudent, GetExamStudentResultList } from "../../repositories/examStudentRepo";
import ExamStudentResultRow from "../../compornents/exam/examStudentResultRow";
import { ExamTemplateQuestionsType, ExamTemplateQuestionsTypeDisplay, GetExamTemplateQuestionTypeList } from "../../objects/examTemplate";
import { ExamStudentResultDownloadDialog, ExamStudentResultDownloadDialogRefs } from "../../compornents/exam/ExamStudentResultDownloadDialog";


const ExamStudentResultPage: FC = () => {
  const navigate = useNavigate();

  const { examUid, examStudentUid } = useParams();

  const [exam, setExam] = useState<ExamData | null>(null);
  const [student, setStudent] = useState<ExamStudentData | null>(null);
  const [resultList, setResultList] = useState<ExamStudentResultQuestionData[]>([]);
  const [filterQuestionType, setFilterQuestionType] = useState<ExamTemplateQuestionsType | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const examStudentResultDownloadDialogRef = useRef<ExamStudentResultDownloadDialogRefs>(null);

  const questionTypeList = GetExamTemplateQuestionTypeList();

  // initialize.
  useEffect( () => {
    if (!examUid || !examStudentUid) navigate('/notfound');
    (async () => {
      try {
        setIsLoading(true);

        const targetExamUid = Number(examUid);

        const exam = await GetExam(targetExamUid);
        setExam(exam);

        const examStudent = await GetExamStudent(Number(examStudentUid));
        setStudent(examStudent);
        console.log(examStudent)
        
        await getStudentResultList();
      } catch (error) {
        console.error(error);

      } finally {
        setIsLoading(false);
      };
    })()
  }, []);

  useEffect(() => {
    if (!exam) return;

    (async () => {
      try {
        setIsLoading(true);
        await getStudentResultList();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      };
    })()
  }, [filterQuestionType]);

  const getStudentResultList = async () => {
    try {
      if (!examStudentUid) throw new Error('Student uid is empty');

      const list = await GetExamStudentResultList(Number(examStudentUid), filterQuestionType);
      setResultList(list);
    } catch (error) {
      console.error(error);
      setResultList([]);
    };
  };

  const handleTagList = (tagList: string) => {
    if (!tagList) return [];

    const tagJson = JSON.parse(tagList);

    const result: string[] = [];
    tagJson.forEach((x: any) => {
      if (x.value) result.push(x.value);
    });
    return result;
  };

  const handleClickOpenExamDownloadDialogButton = () => {
    examStudentResultDownloadDialogRef.current?.openDialog();
  };

  const handleSetQuestionType = (value: string | null) => {
    switch (value) {
      case ExamTemplateQuestionsType.Detection:
        setFilterQuestionType(value);
        break;
      case ExamTemplateQuestionsType.Identification:
        setFilterQuestionType(value);
        break;
      default:
        setFilterQuestionType(null);
        break;
    };
  };

  return (
    <Grid container>
      <CommonHeader headerTitle={(exam !== null && student !== null) ? `被験者評価: ${student.sign_in_id}(${exam.title})` : '' }/>
      <div className="ExamStudentResultPage-main-div">
        <Paper className="ExamStudentResultPage-filter-paper">
          <div className="ExamStudentResultPage-paper-stack-frame">
            <Stack 
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              style={{ marginBottom: '5px' }}
            >
              <Typography>{ `氏名: ${(student && student.name) ? student.name : ''}` }</Typography>
              <Stack 
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0.5}
              >
                {(student && student.tag_list) && 
                    handleTagList(student.tag_list).map((x, index) => (
                      <Chip key={index} label={x} variant="outlined" color='primary' size="small"/>
                    )
                )}
              </Stack>
            </Stack>
            <Stack 
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <FormControl style={{width: '300px'}} size="small">
                <Select
                  value={filterQuestionType}
                  onChange={(evt) => handleSetQuestionType(evt.target.value)}
                >
                  <MenuItem value={''}></MenuItem>
                  {questionTypeList.map((x, index) => (
                    <MenuItem value={x} key={index}>{ExamTemplateQuestionsTypeDisplay(x)}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                onClick={(evt) => handleClickOpenExamDownloadDialogButton()}
                variant="outlined"
              >
                {'ダウンロード'}
              </Button>
            </Stack>
          </div>
        </Paper>
        <TableContainer component={Paper} className='ExamStudentResultPage-exams-table'>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 100 }}>出題順</TableCell>
                <TableCell style={{ minWidth: 100 }}>問題No.</TableCell>
                <TableCell style={{ minWidth: 100 }}>症例番号</TableCell>
                <TableCell style={{ minWidth: 100 }}>出題形式</TableCell>
                <TableCell style={{ minWidth: 100 }}>{'点数(視聴前)'}</TableCell>
                <TableCell style={{ minWidth: 100 }}>{'点数(視聴後)'}</TableCell>
                <TableCell style={{ width: '100%', minWidth: 30 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {examUid &&
                resultList.map((x) => (
                  <ExamStudentResultRow 
                    key={x.question_uid}
                    examUid={Number(examUid)}
                    row={x} 
                  />
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      { (examUid !== undefined && student !== null) &&
        <ExamStudentResultDownloadDialog
          ref={examStudentResultDownloadDialogRef}
          examUid={Number(examUid)}
          examStudent={student}
        />
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default ExamStudentResultPage;