export class HealthCheckExamQuestionData {
  question_uid: number;
  question_exists: boolean;
  template_question_exists: boolean;
  template_answers_exists: boolean;
  case_exists: boolean;
  unknown_case_image_uid_list: number[];
  unknown_image_index_list: number[];
  forbidden_template_answer_uid_list: number[];
  ai_video_exists: boolean | null;
  messages: string[];

  constructor (
    question_uid: number,
    question_exists: boolean,
    template_question_exists: boolean,
    template_answers_exists: boolean,
    case_exists: boolean,
    unknown_case_image_uid_list: number[],
    unknown_image_index_list: number[],
    forbidden_template_answer_uid_list: number[],
    ai_video_exists: boolean | null,
    messages: string[]
  ) {
    this.question_uid = question_uid;
    this.question_exists = question_exists;
    this.template_question_exists = template_question_exists;
    this.template_answers_exists = template_answers_exists;
    this.case_exists = case_exists;
    this.unknown_case_image_uid_list = unknown_case_image_uid_list;
    this.unknown_image_index_list = unknown_image_index_list;
    this.forbidden_template_answer_uid_list = forbidden_template_answer_uid_list;
    this.ai_video_exists = ai_video_exists;
    this.messages = messages;
  };

  checkResult (): boolean {
    if (!this.question_exists) return false;
    else if (!this.template_question_exists) return false;
    else if (!this.template_answers_exists) return false;
    else if (!this.case_exists) return false;
    else if (0 < this.unknown_case_image_uid_list.length) return false;
    else if (0 < this.unknown_image_index_list.length) return false;
    else if (0 < this.forbidden_template_answer_uid_list.length) return false;
    else if (!this.ai_video_exists) return false;
    else return true;
  };
};