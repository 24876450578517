import { DialogTitle, Stack, Typography } from "@mui/material";
import { CloseButton } from "../button/CloseButton";

type CommonDialogTitleProps = {
  title: string;
  disabled: boolean;
  clickedClose?: () => void;
};

export const CommonDialogTitle: React.FC<CommonDialogTitleProps> = (props) => {
  const { title, disabled, clickedClose } = props;

  const handleClickedCloseButton = () => {
    if (clickedClose) clickedClose();
  };

  return (
    <DialogTitle>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          minWidth: 0,
        }}
      >
        <Typography variant="h6" noWrap>
          {title}
        </Typography>
        {clickedClose && (
          <CloseButton
            iconButton={true}
            disabled={disabled}
            close={handleClickedCloseButton}
          />
        )}
      </Stack>
    </DialogTitle>
  );
};
