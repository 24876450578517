import { Close } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";

export type CloseButtonProps = {
  iconButton: boolean;
  disabled: boolean;
  close: () => void;
};

export const CloseButton: React.FC<CloseButtonProps> = (props) => {
  const { iconButton, disabled, close } = props;

  const handleClickButton = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    close();
  };

  return (
    <>
      {iconButton ? (
        <IconButton
          onClick={(evt) => handleClickButton(evt)}
          disabled={disabled}
        >
          <Close />
        </IconButton>
      ) : (
        <Button onClick={(evt) => handleClickButton(evt)} disabled={disabled}>
          {`閉じる`}
        </Button>
      )}
    </>
  );
};
