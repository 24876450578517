import { createContext, useState } from "react";

type ImageAuthContextProps = {
  imageAuth: string | null;
  setImageAuth: (imageAuth: string | null) => void;
};

const ImageAuthContext = createContext<ImageAuthContextProps>({
  imageAuth: null,
  setImageAuth: (imageAuth) => {},
});

export const ImageAuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [imageAuth, setImageAuth] = useState<string | null>(null);

  return (
    <ImageAuthContext.Provider value={{ imageAuth, setImageAuth }}>
      {children}
    </ImageAuthContext.Provider>
  );
};

export default ImageAuthContext;
