export class HealthCheckExamData {
  exam_uid: number;
  student_count_is_correct: boolean;
  diff_exam_question_uid_list: number[];
  messages: string[];

  constructor (
    exam_uid: number,
    student_count_is_correct: boolean,
    diff_exam_question_uid_list: number[],
    messages: string[]
  ) {
    this.exam_uid = exam_uid;
    this.student_count_is_correct = student_count_is_correct;
    this.diff_exam_question_uid_list = diff_exam_question_uid_list;
    this.messages = messages;
  };

  checkResult (): boolean {
    if (!this.student_count_is_correct) return false;
    else if (0 < this.diff_exam_question_uid_list.length) return false;
    else return true;
  };

  handleValueColor (): string {
    if (this.checkResult()) return '#2196f3';
    else return '#f44336';
  };
};