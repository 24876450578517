import { axiosClient } from "../contexts/axiosClientProvider";
import { ExamQuestionData } from "../objects/exam";
import { ExamTemplateQuestionsType } from "../objects/examTemplate";



export const GetExamQuestion = async (examUid: number): Promise<{prev_uid: number | null, next_uid: number | null, data: ExamQuestionData}> => {
  let url = `exams/questions/${examUid}`;

  const response = await axiosClient.get<{prev_uid: number | null, next_uid: number | null, data: ExamQuestionData}>(url);
  return response.data;
};

export const GetExamQuestionList = async (examUid: number, questionsType: ExamTemplateQuestionsType | null): Promise<ExamQuestionData[]> => {
  let url = `exams/questions?exam_uid=${examUid}`;

  if (questionsType !== null) {
    url = url + `&questions_type=${questionsType}`;
  };

  const response = await axiosClient.get<ExamQuestionData[]>(url);
  return response.data;
};