import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import UseRefreshStudentToken from '../hooks/useRefreshStudentToken';
import UseStudentAuth from '../hooks/useStudentAuth';
import { axiosStudentClient } from '../contexts/axiosStudentClientProvider';
import { Backdrop, CircularProgress } from '@mui/material';


const PersistStudentSignin = () => {
  const [isLoading, setIsLoading] = useState(true);

  const refresh = UseRefreshStudentToken();

  const { studentAuth, setStudentAuth } = UseStudentAuth();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        const newToken = await refresh(axiosStudentClient);
        if (newToken) setStudentAuth(newToken);
        else throw new Error('Could not get new token');
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      };
    };

    !studentAuth ? verifyRefreshToken() : setIsLoading(false);
  });

  useEffect(() => {
    if (studentAuth) setIsLoading(false);
  }, [studentAuth]);

  return (
    <>
      {isLoading 
        ? <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        : <Outlet/>
      }
    </>
  );
};

export default PersistStudentSignin;