import { createContext, useState } from "react";


type StudentAuthContextProps = {
  studentAuth: string | null;
  setStudentAuth: (studentAuth: string | null) => void;
};

const StudentAuthContext = createContext<StudentAuthContextProps>({
  studentAuth: null,
  setStudentAuth: (studentAuth) => {}
});

export const StudentAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [studentAuth, setStudentAuth] = useState<string | null>(null);

  return (
    <StudentAuthContext.Provider value={{studentAuth, setStudentAuth}}>
      {children}
    </StudentAuthContext.Provider>
  );
};

export default StudentAuthContext;