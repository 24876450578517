import { IconButton, Button } from "@mui/material";
import React, { ReactNode } from "react";
import { CommonTooltip } from "../tooltip/CommonTooltip";
import { CommonDialog } from "./CommonDialog";

type CommonDialogButtonProps = {
  iconButton: boolean;
  buttonText: string;
  buttonIcon: ReactNode;
  dialogTitle: string;
  disabled: boolean;
  contentChildren: ReactNode;
  actionChildren: ReactNode;
  openDialog: boolean;
  setOpenDialog: (newValue: boolean) => void;
  buttonColor?:
    | "info"
    | "primary"
    | "inherit"
    | "secondary"
    | "error"
    | "success"
    | "warning";
  hideIcon?: boolean;
  buttonVariant?: "text" | "outlined" | "contained";
  tooltipMessage?: string;
  maxWidth?: "sm" | "md" | "lg";
  disableFullWidth?: boolean;
  isProgressing?: boolean;
  closeAction?: () => void;
};

export const CommonDialogButton: React.FC<CommonDialogButtonProps> = (
  props
) => {
  const {
    iconButton,
    buttonText,
    buttonIcon,
    dialogTitle,
    disabled,
    contentChildren,
    actionChildren,
    openDialog,
    setOpenDialog,
    buttonColor,
    hideIcon,
    buttonVariant,
    tooltipMessage,
    maxWidth,
    disableFullWidth,
    isProgressing,
    closeAction,
  } = props;

  const handleClickDialogOpenButton = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (disabled) return;
    setOpenDialog(true);
  };

  const handleShowOpenDialogButton = () => {
    switch (iconButton) {
      case true:
        return (
          <IconButton
            onClick={(evt) => handleClickDialogOpenButton(evt)}
            disabled={disabled}
            color={buttonColor}
            size="small"
          >
            {buttonIcon}
          </IconButton>
        );
      case false:
        return (
          <Button
            onClick={(evt) => handleClickDialogOpenButton(evt)}
            disabled={disabled}
            startIcon={hideIcon !== true ?? buttonIcon}
            color={buttonColor}
            variant={buttonVariant || "text"}
          >
            {buttonText}
          </Button>
        );
    }
  };

  const handleCloseDialog = () => {
    if (isProgressing === true) return;
    setOpenDialog(false);
    if (closeAction) closeAction();
  };

  return (
    <React.Fragment>
      <CommonTooltip title={tooltipMessage || ""}>
        {handleShowOpenDialogButton()}
      </CommonTooltip>
      <CommonDialog
        openDialog={openDialog}
        closeDialog={handleCloseDialog}
        dialogTitle={dialogTitle}
        disabled={disabled}
        contentChildren={contentChildren}
        actionChildren={actionChildren}
        maxWidth={maxWidth}
        disableFullWidth={disableFullWidth}
        isProgressing={isProgressing}
      />
    </React.Fragment>
  );
};
