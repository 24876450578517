import { AssignmentInd, CloudDownload, Delete, Edit, Grading, MonitorHeart } from "@mui/icons-material";
import { Backdrop, CircularProgress, IconButton, Stack, TableCell, TableRow, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { ExamDialogRefs } from "../../interfaces/exam/examDialogInterface";
import { ExamRowProps } from "../../interfaces/exam/examRowInterface";
import { ExamStudentDialogRefs } from "../../interfaces/exam/examStudentDialogInterface";
import { DownloadStandaloneZip } from "../../repositories/examRepo";
import CommonAlert from "../commonAlert";
import ExamDialog from "./examDialog";
import ExamStudentDialog from "./examStudentDialog";
import './examRow.css';
import HealthCheckExamDialog from "./healthCheckExamDialog";
import { HealthCheckExamDialogRefs } from "../../interfaces/exam/healthCheckExamDialogInterface";


const ExamRow: FC<ExamRowProps> = (props: ExamRowProps) => {
  const navigate = useNavigate();

  const { row, reloadExamData } = props;

  const examDialogRef = useRef<ExamDialogRefs>(null);
  const healthCheckExamDialog = useRef<HealthCheckExamDialogRefs>(null);
  const examStudentDialogRef = useRef<ExamStudentDialogRefs>(null);
  const commonAlertRef = useRef<CommonAlertRefs>(null);

  const [isLoading, setIsLoading] = useState(false);

  const moveToEditorPage = (event: React.MouseEvent<HTMLButtonElement>, templateUid: number) => {
    console.log('Move to editer page');
    try {
      navigate(`/exams/templates/${templateUid}`);
    } catch (error) {
      console.error(error);
    } finally {
      event.stopPropagation();
    };
  };

  const downloadStandaloneZip = async (event: React.MouseEvent<HTMLButtonElement>, examUid: number) => {
    console.log('Download standalone zip');

    try {
      setIsLoading(true);

      await DownloadStandaloneZip(examUid);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      event.stopPropagation();
    };
  };

  const moveToGradingPage = (event: React.MouseEvent<HTMLButtonElement>, examUid: number) => {
    console.log('Move to grading page');

    try {
      navigate(`/exams/grading/${examUid}`);
    } catch (error) {
      console.error(error);
    } finally {
      event.stopPropagation();
    };
  };

  const openExamDialog = (examUid: number, examTemplateUid: number) => {
    examDialogRef.current?.openDialog('update', examUid, examTemplateUid);
  };

  const openExamHealthCheckDialog = () => {
    healthCheckExamDialog.current?.openDialog();
  };

  const openExamStudentDialog = async () => {
    if (!examStudentDialogRef.current) return;
    try {
      setIsLoading(true);
      await examStudentDialogRef.current.openDialog();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatedExamData = async () => {
    await reloadExamData();
  };

  return (
    <>
      <TableRow hover tabIndex={-1} key={row.uid}>
        <TableCell align="left">
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            width='100%'
          >
            <IconButton 
              onClick={async (event) => openExamDialog(row.uid, row.template_uid)} 
              edge="end" size="small" color="primary"
            >
              <Edit />
            </IconButton>
            <IconButton 
              onClick={async (event) => openExamHealthCheckDialog()} 
              edge="end" size="small" color="primary"
            >
              <MonitorHeart />
            </IconButton>
            <IconButton 
              onClick={async (event) => moveToEditorPage(event, row.template_uid)} 
              edge="end" size="small" color="primary"
            >
              <MenuIcon />
            </IconButton>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0.5}
          >
            <Typography>{row.uid}</Typography>
            {!row.is_active &&
              <Delete color="error" fontSize="small" />
            }
          </Stack>
        </TableCell>
        <TableCell>{ row.template_title }</TableCell>
        <TableCell>{ row.title }</TableCell>
        <TableCell align="right">{ row.questions_count }</TableCell>
        <TableCell align="right">{ row.student_total }</TableCell>
        <TableCell align="right">{ row.detection_answer_maximum_limit }</TableCell>
        <TableCell align="right">
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            width='100%'
          >
            <IconButton 
              onClick={async (evt) => await downloadStandaloneZip(evt, row.uid)}
              disabled={isLoading || !row.is_active}
              edge="end" size="small" color="primary"
            >
              <CloudDownload />
            </IconButton>
            <IconButton 
              onClick={async (event) => await openExamStudentDialog()} 
              edge="end" size="small" color="primary"
            >
              <AssignmentInd />
            </IconButton>
            <IconButton 
              onClick={(evt) => moveToGradingPage(evt, row.uid)}
              edge="end" size="small" color="primary"
            >
              <Grading />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <ExamDialog
        ref={examDialogRef}
        examUid={row.uid}
        examTemplateUid={row.template_uid}
        updatedExamData={async () => await updatedExamData()}
      />
      <HealthCheckExamDialog
        ref={healthCheckExamDialog}
        examUid={row.uid}
      />
      <ExamStudentDialog
        ref={examStudentDialogRef}
        examUid={row.uid}
        title={row.title}
      />
      <CommonAlert
        ref={commonAlertRef}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
};

export default ExamRow;