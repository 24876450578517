import { Error } from "@mui/icons-material";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import './UnauthorizedPage.css';

const UnautholizedPage: FC = () => {
  const navigate = useNavigate();

  return (
    <Grid 
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className='UnautholizedPage-root-grid'
    >
      <Paper elevation={3} className='UnautholizedPage-main-paper'>
        <Typography
          variant="h6" 
          gutterBottom
          className="UnautholizedPage-title-typo"
        >
          <Error 
            fontSize="small"
            color="error"
            className="UnautholizedPage-title-icon"
          />
          Error
        </Typography>
        <div className="UnautholizedPage-body-div">
        <Typography
          variant="body1" 
          gutterBottom
        >
          {'権限がありません。'}
        </Typography>
        <Button
          onClick={(evt) => navigate('/')}
          fullWidth
          color="primary" 
          variant="outlined"
          className="UnautholizedPage-back-button"
        >
          トップへ戻る
        </Button>
        </div>
      </Paper>
    </Grid>
  )
};

export default UnautholizedPage;