import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { ExamStudentDialogProps, ExamStudentDialogRefs } from "../../interfaces/exam/examStudentDialogInterface";
import { ExamStudentData } from "../../objects/exam";
import { DisableExamStudentList, DownloadExamStudentCsv, EnableExamStudentList, GetExamStudentList, UploadExamStudentCsv } from "../../repositories/examStudentRepo";
import CommonAlert from "../commonAlert";
import './examStudentDialog.css'
import ExamStudentRow from "./examStudentRow";


const ExamStudentDialog = forwardRef<ExamStudentDialogRefs, ExamStudentDialogProps>((props, ref) => {
  const { examUid, title, closedExamStudentDialog } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [studentList, setStudentList] = useState<{isChecked: boolean, data: ExamStudentData}[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const commonAlertRef = useRef<CommonAlertRefs>(null);
  
  const { 
    getRootProps: getStudentListCsvRootProps, 
    getInputProps: getStudentListCsvInputProps, 
    isDragActive: isStudentListCsvDragActive 
  } = useDropzone({ 
    onDrop: async (acceptedFile: File[]) => {
      await uploadCsv(acceptedFile[0]);
    }, 
    accept: {
      "text/csv": []
    },
    multiple: false 
  });
  
  useImperativeHandle (ref, () => ({
    async openDialog (): Promise<void> {
      try {
        setIsLoading(true);

        await getStudentList(examUid);
        
        setOpenDialog(true);
      } catch (error) {
        console.error(error);
        commonAlertRef.current?.error('被験者リストダイアログが開けませんでした。');
      } finally {
        setIsLoading(false);
      };
    }
  }));

  useEffect(() => {
    if (!openDialog) {
      setStudentList([]);
    };
  }, [openDialog]);

  const getStudentList = async (examUid: number) => {
    console.log('Get student list');
    try {
      const list = await GetExamStudentList(examUid, null, null);

      const result: {isChecked: boolean, data: ExamStudentData}[] = [];
      list.forEach((x) => {
        result.push({
          isChecked: false,
          data: x
        })
      });

      setStudentList(result);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('被験者リストの取得に失敗しました。');
      setStudentList([]);
    };
  };

  const checkedStudentRow = (uid: number) => {
    setStudentList(studentList.map((x) => (
      x.data.uid === uid
      ? {isChecked: x.isChecked = !x.isChecked, data: x.data}
      : x
    )));
  };

  const updatedStudentRowData = async () => {
    try {
      setIsLoading(true);

      await getStudentList(examUid);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    };
  };

  const disableExamStudentList = async () => {
    try {
      setIsLoading(true);

      const list = studentList.filter((x) => x.isChecked).map((x) => x.data.uid);

      if (list.length < 1) return;

      await DisableExamStudentList(list);

      await getStudentList(examUid);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error("被験者の無効化に失敗しました。");
    } finally {
      setIsLoading(false);
    };
  };

  const enableExamStudentList = async () => {
    try {
      setIsLoading(true);
      
      const list = studentList.filter((x) => x.isChecked).map((x) => x.data.uid);

      if (list.length < 1) return;

      await EnableExamStudentList(list);

      await getStudentList(examUid);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error("被験者の無効化に失敗しました。");
    } finally {
      setIsLoading(false);
    };
  };

  const downloadStudentListCsv = async (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('Download student list csv');
    try {
      if (!examUid) throw new Error('Exam uid is empty');
      setIsLoading(true);
      await DownloadExamStudentCsv(examUid, `${examUid}_${title}_被験者リスト`);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('被験者リストのダウンロードに失敗しました。');
    } finally {
      setIsLoading(false);
    };
  };

  const uploadCsv = async (file: File) => {
    console.log('Upload csv');
    try {
      if (!examUid) throw new Error('Exam uid is empty');

      setIsLoading(true);
      await UploadExamStudentCsv(examUid, file);

      await getStudentList(examUid);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('被験者リストのアップデートに失敗しました。');
    } finally {
      setIsLoading(false);
    };
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='xl'
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>{title && `被験者リスト (${title})`}</DialogTitle>
        <DialogContent>
          <Grid 
            container 
            item
            direction="row" 
            justifyContent="center" 
            alignItems="center"
          >
            <TableContainer component={Paper} className='ExamStudentDialog-table'>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell style={{ minWidth: 80 }}>被験者ID</TableCell>
                    <TableCell style={{ minWidth: 200 }}>ユーザー氏名</TableCell>
                    <TableCell style={{ width: '100%', minWidth: 200 }}>タグ</TableCell>
                    <TableCell style={{ minWidth: 50 }}>受験済</TableCell>
                    <TableCell style={{ minWidth: 80 }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentList.map((student, index) => (
                    <ExamStudentRow
                      key={index}
                      examUid={examUid}
                      row={student}
                      checkedRow={checkedStudentRow}
                      updatedData={updatedStudentRowData}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            width="100%"
          >
            {0 < studentList.filter((x) => x.isChecked).length
              ? <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Button
                  onClick={(evt) => disableExamStudentList()}
                  disabled={isLoading}
                  color="error"
                  variant="contained"
                >
                  無効化
                </Button>
                <Button
                  onClick={(evt) => enableExamStudentList()}
                  disabled={isLoading}
                  color="primary"
                  variant="contained"
                >
                  有効化
                </Button>
              </Stack>
              : <div></div>
            }
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Button 
                onClick={ async (evt) => await downloadStudentListCsv(evt)} 
                variant="contained"
              >
                CSVダウンロード
              </Button>
              <div {...getStudentListCsvRootProps()} className='ExamStudentDialog-dropzone'>
                <input {...getStudentListCsvInputProps()} />
                {
                  isStudentListCsvDragActive ?
                    <p className="ExamStudentDialog-dropzone-text">ファイルをドロップしてください…</p> :
                    <p className="ExamStudentDialog-dropzone-text">CSVアップロード</p>
                }
              </div>
            </Stack>
          </Stack>
        </DialogActions>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      <CommonAlert
        ref={commonAlertRef}
      />
    </>
  )
});

export default ExamStudentDialog;