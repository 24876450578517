import { axiosClient } from "../contexts/axiosClientProvider";
import { ExamStudentQuestionScoreCreateData, ExamStudentQuestionScoreData } from "../objects/exam";


export const GetExamStudentQuestionScore = async (examStudentQuestionUid: number): Promise<ExamStudentQuestionScoreData> => {
  let url = `exam/student/question/scores/${examStudentQuestionUid}`;

  const response = await axiosClient.get<ExamStudentQuestionScoreData>(url);

  return response.data;
};

export const GetExamStudentQuestionScoreList = async (
  useIsSecond: boolean,
  useIsScored: boolean,
  examStudentUid: number | null,
  examQuestionUid: number | null,
  isSecond: boolean | null,
  isScored: boolean | null): Promise<ExamStudentQuestionScoreData[]> => {
  let url = `exam/student/question/scores?use_is_second=${useIsSecond}&use_is_scored=${useIsScored}`;

  if (examStudentUid !== null) {
    url = url + `&exam_student_uid=${examStudentUid}`;
  };

  if (examQuestionUid !== null) {
    url = url + `&exam_question_uid=${examQuestionUid}`;
  };

  if (useIsSecond && isSecond !== null) {
    url = url + `&is_second=${isSecond}`;
  };

  if (useIsScored && isScored !== null) {
    url = url + `&is_scored=${isScored}`;
  };

  const response = await axiosClient.get<ExamStudentQuestionScoreData[]>(url);
  return response.data;
};

export const UpdateExamStudentQuestionScore = async (
  examStudentQuestionUid: number, 
  data: ExamStudentQuestionScoreCreateData): Promise<void> => {
    let url = `exam/student/question/scores/${examStudentQuestionUid}`;

    await axiosClient.put(url, data);
};