export class StudentIdentificationAnswerData {
  diagnosis_uid: number;
  image_index: number;
  template_answer_uid: number;

  constructor (
    diagnosis_uid: number, 
    image_index: number, 
    template_answer_uid: number
  ) {
      this.diagnosis_uid = diagnosis_uid;
      this.image_index = image_index;
      this.template_answer_uid = template_answer_uid;
  };
}