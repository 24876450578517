import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Backdrop, Checkbox, CircularProgress, FormControl, FormControlLabel, IconButton, MenuItem, Select, Stack } from "@mui/material";
import axios from "axios";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { GradingAnswerViewerSearchPanelRefs, GradingAnswerViewerSearchPanelProps } from "../../interfaces/viewer/gradingAnswerViewerSearchPanelInterface";
import { ExamStudentQuestionScoreData } from "../../objects/exam";
import { GetExamStudentQuestionScoreList } from "../../repositories/examStudentQuestionScoreRepo";
import CommonAlert from "../commonAlert";
import './gradingAnswerViewerSearchPanel.css';


const GradingAnswerViewerSearchPanel = forwardRef<GradingAnswerViewerSearchPanelRefs, GradingAnswerViewerSearchPanelProps>((props, ref) => {

  const { examUid, examQuestionUid, initialScoreUid, changedExamStudentQuestionScore } = props;

  const onlyIsNotScoredStorageName = `grading-answer-viewer-search-panel-${examUid}-only-not-scored`;

  const onlyIsNotScoredStorage = sessionStorage.getItem(onlyIsNotScoredStorageName);
  let onlyIsNotScoredInitialValue = false;
  if (onlyIsNotScoredStorage) {
    if (onlyIsNotScoredStorage.toLowerCase() === 'true') onlyIsNotScoredInitialValue = true;
  };
  
  const [onlyIsNotScored, setOnlyIsNotScored] = useState(onlyIsNotScoredInitialValue);
  const [questionScoreList, setQuestionScoreList] = useState<ExamStudentQuestionScoreData[] | null>(null);
  const [selectedQuestionScoreUid, setSelectedQuestionScoreUid] = useState<number | null>(initialScoreUid);
  const [isLoading, setIsLoading] = useState(false);

  const commonAlertRef = useRef<CommonAlertRefs>(null);

  const cancelToken = axios.CancelToken.source();

  useImperativeHandle (ref, () => ({
    moveToNextScore(): void {
      if (questionScoreList === null) return;
      if (onlyIsNotScored) {
        setQuestionScoreList(questionScoreList.filter((x) => x.uid !== selectedQuestionScoreUid));
      } else {
        const currentIndex = questionScoreList.findIndex((x) => x.uid === selectedQuestionScoreUid);

        const nextScore = questionScoreList[currentIndex + 1];
        if (nextScore === undefined) {
          return;
        } else {
          setSelectedQuestionScoreUid(nextScore.uid);
        };
      };
    }
  }));

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await getExamStudentQuestionScoreList();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      };
    })()
  }, [onlyIsNotScored]);

  useEffect(() => {
    (async () => {
      if (questionScoreList === null) return;

      if (questionScoreList.length < 1) {
        commonAlertRef.current?.info('検索対象の被験者が見つかりません。');
        setSelectedQuestionScoreUid(null);
        return;
      };

      const find = questionScoreList.find((x) => x.uid === selectedQuestionScoreUid);
      if (!find) setSelectedQuestionScoreUid(null);
      await changedExamStudentQuestionScore(find ? find : null);
    })()
  }, [questionScoreList, selectedQuestionScoreUid]);

  const getExamStudentQuestionScoreList = async () => {
    console.log('Get exam student question score list');
    try {
      const useIsScored = onlyIsNotScored ? true : false;
      const isScored = onlyIsNotScored ? false : null;

      const result = await GetExamStudentQuestionScoreList(false, useIsScored, null, examQuestionUid, null, isScored);
      setQuestionScoreList(result);
      if (!selectedQuestionScoreUid && 0 < result.length) setSelectedQuestionScoreUid(result[0].uid);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('問題総合評価リストの取得に失敗しました。');
      setQuestionScoreList([]);
    };
  };

  const handleOnlyNotScoredButton = () => {
    sessionStorage.setItem(onlyIsNotScoredStorageName, `${!onlyIsNotScored}`);
    setOnlyIsNotScored(!onlyIsNotScored);
  };

  const handleClickedPrevStudentButton = () => {
    if (questionScoreList === null) return;
    if (questionScoreList.length < 1) return;

    if (!selectedQuestionScoreUid) {
      const data = questionScoreList[-1];
      setSelectedQuestionScoreUid(data.uid);
    };

    const currentIndex = questionScoreList.findIndex((x) => x.uid === selectedQuestionScoreUid);
    const prevIndex = currentIndex - 1;

    if (prevIndex < 0) return;

    const data = questionScoreList[prevIndex];
    setSelectedQuestionScoreUid(data.uid);
  };

  const handleClickedNextStudentButton = () => {
    if (questionScoreList === null) return;
    if (questionScoreList.length < 1) return;

    if (!selectedQuestionScoreUid) {
      const data = questionScoreList[0];
      setSelectedQuestionScoreUid(data.uid);
    };

    const currentIndex = questionScoreList.findIndex((x) => x.uid === selectedQuestionScoreUid);
    const nextIndex = currentIndex + 1;

    if (questionScoreList.length <= nextIndex) return;

    const data = questionScoreList[nextIndex];
    setSelectedQuestionScoreUid(data.uid);
  };

  const handlePrevStudentButtonDisabled = (studentUid: number | null): boolean => {
    if (questionScoreList === null) return true;
    if (questionScoreList.length < 1) return true;
    if (!studentUid) return false;

    const currentIndex = questionScoreList.findIndex((x) => x.uid === selectedQuestionScoreUid);
    const prevIndex = currentIndex - 1;

    if (prevIndex < 0) return true;

    return false;
  };

  const handleNextStudentButtonDisabled = (studentUid: number | null): boolean => {
    if (questionScoreList === null) return true;
    if (questionScoreList.length < 1) return true;
    if (!studentUid) return false;

    const currentIndex = questionScoreList.findIndex((x) => x.uid === studentUid);
    const nextIndex = currentIndex + 1;

    if (questionScoreList.length <= nextIndex) return true;

    return false;
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        className='GradingAnswerViewer-action-stack'
      >
        <FormControlLabel 
          disabled={isLoading} 
          control={
            <Checkbox
              checked={onlyIsNotScored}
              onChange={(evt) => handleOnlyNotScoredButton()}
              size='small'
            />
          } 
          label="問題未評価の被験者のみ"
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <IconButton 
          onClick={(evt) => handleClickedPrevStudentButton()}
          disabled={isLoading || handlePrevStudentButtonDisabled(selectedQuestionScoreUid)}
          edge="end" size="small" color="primary"
        >
          <NavigateBefore />
        </IconButton>
        {questionScoreList !== null &&
          <FormControl fullWidth size="small">
            <Select
              value={selectedQuestionScoreUid}
              onChange={(evt) => setSelectedQuestionScoreUid(evt.target.value ? Number(evt.target.value) : null)}
              disabled={isLoading || questionScoreList.length < 1}
            >
              {questionScoreList.map((x, index) => (
                <MenuItem value={x.uid} key={index}>{`${x.exam_student.sign_in_id}(${!x.is_second ? '視聴前' : '視聴後'})`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        <IconButton 
          onClick={(evt) => handleClickedNextStudentButton()}
          disabled={isLoading || handleNextStudentButtonDisabled(selectedQuestionScoreUid)}
          edge="end" size="small" color="primary"
        >
          <NavigateNext />
        </IconButton>
      </Stack>
      <CommonAlert
        ref={commonAlertRef}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
});

export default GradingAnswerViewerSearchPanel;