import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { HealthCheckExamDialogProps, HealthCheckExamDialogRefs } from "../../interfaces/exam/healthCheckExamDialogInterface";
import { ExamQuestionData, ExamStudentData } from "../../objects/exam";
import { HealthCheckExamData, HealthCheckExamQuestionData, HealthCheckExamStudentData } from "../../objects/healthcheck";
import { GetExamQuestionList } from "../../repositories/examQuestionRepo";
import CommonAlert from "../commonAlert";
import HealthCheckExamQuestionRow from "./healthCheckExamQuestionRow";
import './healthCheckExamDialog.css';
import { HealthCheckExam, HealthCheckExamQuestion, HealthCheckExamStudent } from "../../repositories/healthCheckRepo";
import { GetExamStudentList } from "../../repositories/examStudentRepo";
import HealthCheckExamStudentRow from "./healthCheckExamStudentRow";


const HealthCheckExamDialog = forwardRef<HealthCheckExamDialogRefs, HealthCheckExamDialogProps>((props, ref) => {
  const { examUid } = props;

  const commonAlertRef = useRef<CommonAlertRefs>(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [exam, setExam] = useState<{result: boolean, healthData: HealthCheckExamData | null} | null>(null);
  const [studentList, setStudentList] = useState<{progress: boolean, base: ExamStudentData, healthData: HealthCheckExamStudentData | null}[]>([]);
  const [questionList, setQuestionList] = useState<{progress: boolean, base: ExamQuestionData, healthData: HealthCheckExamQuestionData | null}[]>([]);
  const [isExecuting, setIsExecuting] = useState(false);
  const [healthCheckResult, setHealthCheckResult] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useImperativeHandle (ref, () => ({
    async openDialog (): Promise<void> {
      try {
        setIsLoading(true);

        const examStudentList = await GetExamStudentList(examUid, null, null);

        const sList: {progress: boolean, base: ExamStudentData, healthData: HealthCheckExamStudentData | null}[] = [];
        examStudentList.forEach((x) => {
          sList.push({progress: false, base: x, healthData: null});
        });
        setStudentList(sList);

        const examQuestionList = await GetExamQuestionList(examUid, null);

        const qList: {progress: boolean, base: ExamQuestionData, healthData: HealthCheckExamQuestionData | null}[] = [];
        examQuestionList.forEach((x) => {
          qList.push({progress: false, base: x, healthData: null});
        });
        setQuestionList(qList);

        setOpenDialog(true);
      } catch (error) {
        console.error(error);
        commonAlertRef.current?.error(`試験ヘルスチェックダイアログが開けませんでした。`);
      } finally {
        setIsLoading(false);
      };
    }
  }));

  useEffect(() => {
    if (!openDialog) {
      setQuestionList([]);
    };
  }, [openDialog]);

  useEffect(() => {
    if (!isExecuting) return;

    let keep = true;

    if (isExecuting && 0 < questionList.length) {
      (async () => {
        let errorExists = false;

        try {
          const examHealthCheckResult = await healthCheckExam(examUid);
          setExam(examHealthCheckResult
            ? {
              result: true,
              healthData: new HealthCheckExamData(
                examHealthCheckResult.exam_uid,
                examHealthCheckResult.student_count_is_correct,
                examHealthCheckResult.diff_exam_question_uid_list,
                examHealthCheckResult.messages
              )
            } : {
              result : false,
              healthData: null
            }
          );

          if (!examHealthCheckResult) errorExists = true;

          for (const student of studentList) {
            if (!keep) break;
            const healthCheck = await healthCheckExamStudent(student.base.uid);

            setStudentList((prev) => prev.map((x) => 
              student.base.uid === x.base.uid
              ? { progress: true, 
                  base: x.base, 
                  healthData: healthCheck
                    ? new HealthCheckExamStudentData(
                      healthCheck.student_uid,
                      healthCheck.is_active,
                      healthCheck.q_order_by_count_is_correct,
                      healthCheck.diff_q_uid_list,
                      healthCheck.duplicate_q_order_by_uid_list,
                      healthCheck.forbidden_q_order_by_uid_list,
                      healthCheck.messages
                    )
                    : null
                }
              : x
            ));

            if (!healthCheck) errorExists = true;
          };

          for (const question of questionList) {
            if (!keep) break;

            setQuestionList((prev) => prev.map((x) => 
              question.base.uid === x.base.uid
              ? {progress: false, base: x.base, healthData: x.healthData}
              : x
            ));

            const healthCheck = await healthCheckExamQuestion(question.base.uid);

            setQuestionList((prev) => prev.map((x) => 
              question.base.uid === x.base.uid
              ? { progress: true, 
                  base: x.base, 
                  healthData: healthCheck
                    ? new HealthCheckExamQuestionData(
                      healthCheck.question_uid,
                      healthCheck.question_exists,
                      healthCheck.template_question_exists,
                      healthCheck.template_answers_exists,
                      healthCheck.case_exists,
                      healthCheck.unknown_case_image_uid_list,
                      healthCheck.unknown_image_index_list,
                      healthCheck.forbidden_template_answer_uid_list,
                      healthCheck.ai_video_exists,
                      healthCheck.messages
                    )
                    : null
                }
              : x
            ));

            if (!healthCheck) errorExists = true;
          };
        } catch (error) {
          console.error(error);
          errorExists = true;
        } finally {
          setHealthCheckResult(errorExists ? false : true);
          setIsLoading(false);
          setIsExecuting(false);
        };
      })()
    };

    return () => {
      keep = false;
    };
  }, [isExecuting]);

  const healthCheckExam = async (examUid: number) : Promise<HealthCheckExamData | null> => {
    try {
      const result = await HealthCheckExam(examUid);
      return result;
    } catch (error) {
      console.error(error);
      return null;
    };
  };

  const healthCheckExamStudent = async (studentUid: number) : Promise<HealthCheckExamStudentData | null> => {
    try {
      const result = await HealthCheckExamStudent(studentUid);
      return result;
    } catch (error) {
      console.error(error);
      return null;
    };
  };

  const healthCheckExamQuestion = async (questionUid: number): Promise<HealthCheckExamQuestionData | null> => {
    try {
      const result = await HealthCheckExamQuestion(questionUid);
      return result;
    } catch (error) {
      console.error(error);
      return null;
    };
  };

  const handleBooleanDisplay = (value: boolean): string => {
    return value ? 'YES' : 'NO';
  };

  const handleNumberListDisplay = (value: number[]): string => {
    if (value.length < 1) return '-';
    else return value.join(',');
  };

  const handleHealthCheckResultMessage = (): string => {
    if (healthCheckResult === null) return "-";
    else if (healthCheckResult === true) return "正常";
    else return "エラーあり";
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='xl'
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>{`試験ヘルスチェック`}</DialogTitle>
        <DialogContent style={{padding: '5px 20px'}}>
          <Paper 
            className="HealthCheckExamDialog-exam-paper"
          >
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography color="inherit">
                    {`被験者数:`}
                  </Typography>
                  <Typography color={exam !== null && exam.healthData !== null ? exam.healthData.handleValueColor() : ''}>
                    {`${exam !== null && exam.healthData !== null ? handleBooleanDisplay(exam.healthData.student_count_is_correct) : '-'}`}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography color="inherit">
                    {`比較不一致リスト:`}
                  </Typography>
                  <Typography color={exam !== null && exam.healthData !== null ? exam.healthData.handleValueColor() : ''}>
                    {`${exam !== null && exam.healthData !== null ? handleNumberListDisplay(exam.healthData.diff_exam_question_uid_list) : '-'}`}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Typography color="inherit">
                    {`メッセージ:`}
                </Typography>
                <Typography color={exam !== null && exam.healthData !== null ? exam.healthData.handleValueColor() : ''}>
                  {`${exam !== null && exam.healthData !== null ? exam.healthData.messages.join(',\n') : '-'}`}
                </Typography>
              </Stack>
            </Stack>
          </Paper>
          <TableContainer component={Paper} className='HealthCheckExamDialog-student-table'>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 60 }}>被験者ID</TableCell>
                  <TableCell style={{ width: '100%', minWidth: 300 }}>メッセージ</TableCell>
                  <TableCell style={{ minWidth: 60 }}>有効</TableCell>
                  <TableCell style={{ minWidth: 120 }}>問題数</TableCell>
                  <TableCell style={{ minWidth: 120 }}>不一致問題リスト</TableCell>
                  <TableCell style={{ minWidth: 120 }}>重複問題リスト</TableCell>
                  <TableCell style={{ minWidth: 120 }}>不整問題リスト</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentList.map((x, index) => (
                  <HealthCheckExamStudentRow
                    key={index}
                    row={x}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} className='HealthCheckExamDialog-question-table'>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 60 }}>問題番号</TableCell>
                  <TableCell style={{ width: '100%', minWidth: 300 }}>メッセージ</TableCell>
                  <TableCell style={{ minWidth: 60 }}>問題</TableCell>
                  <TableCell style={{ minWidth: 120 }}>問題テンプレート</TableCell>
                  <TableCell style={{ minWidth: 120 }}>解答テンプレート</TableCell>
                  <TableCell style={{ minWidth: 120 }}>不整解答リスト</TableCell>
                  <TableCell style={{ minWidth: 60 }}>症例</TableCell>
                  <TableCell style={{ minWidth: 100 }}>不明アノテーションリスト</TableCell>
                  <TableCell style={{ minWidth: 100 }}>不明画像リスト</TableCell>
                  <TableCell style={{ minWidth: 80 }}>AI解析動画</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {questionList.map((x, index) => (
                  <HealthCheckExamQuestionRow
                    key={index}
                    row={x}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            style={{width: "100%"}}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <div>{"結果:"}</div>
              {healthCheckResult !== null &&
                <div style={{color: healthCheckResult === true ? "#2196f3" : "#f44336"}}>
                  {`${handleHealthCheckResultMessage()}`}
                </div>
              }
            </Stack>
            <Button 
              onClick={(evt) => setIsExecuting(true)}
              disabled={isLoading || isExecuting}
              variant="contained"
            >
              実行する
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <CommonAlert
        ref={commonAlertRef}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
});

export default HealthCheckExamDialog;