export class AuthedUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: number;
  access_token: string;

  constructor (id: string, first_name: string, last_name: string, email: string, role: number, access_token: string) {
    this.id = id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.role = role;
    this.access_token = access_token;
  };
};