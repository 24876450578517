import React, { createContext, useEffect } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import UseImageAuth from '../hooks/useImageAuth';

const BaseUrl = process.env.REACT_APP_IMAGE_API_URL;

export const axiosImageClient = axios.create({
  baseURL: BaseUrl,
  withCredentials: true,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  }
});

type AxiosImageClientProps = {
};

const AxiosImageClientContext = createContext<AxiosImageClientProps>({});

export const AxiosImageClientProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { imageAuth } = UseImageAuth();
  
  useEffect(() => {
    const requestInterceptors = axiosImageClient.interceptors.request.use((config: AxiosRequestConfig) => {
      if (config.headers) {
        const token = imageAuth;
        config.headers.Authorization = `Bearer ${token}`;
      };

      return config;
    });

    return () => {
      axiosImageClient.interceptors.request.eject(requestInterceptors);
    };
  }, [imageAuth]);

  return (
    <AxiosImageClientContext.Provider value={{}}>
      { children }
    </AxiosImageClientContext.Provider>
  );
};