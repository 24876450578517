import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { ExamTemplateDialogProps, ExamTemplateDialogRefs } from "../../interfaces/examTemplate/examTemplateDialogInterface";
import { ExamTemplateData, ExamTemplateStatus } from "../../objects/examTemplate";
import { AddExamTemplate, CopyExamTemplate, DeleteExamTemplate, GetExamTemplate, UpdateExamTemplate } from "../../repositories/examTemplateRepo";
import CommonAlert from "../commonAlert";


const ExamTemplateDialog = forwardRef<ExamTemplateDialogRefs, ExamTemplateDialogProps>((props, ref) => {
  const { updatedTemplateData } = props;

  const commonAlertRef = useRef<CommonAlertRefs>(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [examTemplateUid, setExamTemplateUid] = useState<number | null>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [deleteButtonEnable, setDeleteButtonEnable] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useImperativeHandle (ref, () => ({
    async openDialog (mode: string, examTemplateUid: number): Promise<void> {
      try {
        setIsLoading(true);

        switch (mode) {
          case 'add':
            setDialogTitle('追加');
            setExamTemplateUid(-1);
            initializeParam();
            break;
          case 'update':
            if (!examTemplateUid) throw new Error(`Exam template uid is empty`);
  
            const dataForUpdate = await GetExamTemplate(examTemplateUid);
  
            setDialogTitle('編集');
            setExamTemplateUid(examTemplateUid);
            setTitle(dataForUpdate.title);
            setDescription(dataForUpdate.description);
            console.log(dataForUpdate.status)
            if (dataForUpdate.status !== 'deleted') setDeleteButtonEnable(true);
            else setDeleteButtonEnable(false);
            break;
          case 'copy':
            if (!examTemplateUid) throw new Error(`Exam template uid is empty`);
  
            const dataForCopy = await GetExamTemplate(examTemplateUid);
  
            setDialogTitle('コピー');
            setExamTemplateUid(examTemplateUid);
            setTitle(dataForCopy.title);
            setDescription(dataForCopy.description);
            break;
          default:
            throw new Error(`Mode is not allowed. mode: ${mode}`);
        };
  
        setMode(mode);
      } catch (error) {
        console.error(error);
        commonAlertRef.current?.error(`試験テンプレートダイアログが開けませんでした。`);
      } finally {
        setIsLoading(false);
      };
    }
  }));

  useEffect(() => {
    if (mode) setOpenDialog(true);
  }, [mode]);

  useEffect(() => {
    if (!openDialog) {
      setMode('');
      setExamTemplateUid(null);
      setDeleteButtonEnable(false);
      initializeParam();
    };
  }, [openDialog]);

  const initializeParam = () => {
    setTitle('');
    setDescription('');
  };

  const inputTitle = (newValue: string) => {
    setTitle(newValue);
  };

  const inputDescription = (newValue: string) => {
    setDescription(newValue);
  };

  const confirm = async () => {
    console.log('Confirm exam template data action');
    
    try {
      if (!examTemplateUid) throw new Error('Exam template uid is empty');
      setIsLoading(true);

      const req = new ExamTemplateData(
        examTemplateUid,
        title,
        description,
        ExamTemplateStatus.Editing,
        []
      );

      switch (mode) {
        case 'add':
          await AddExamTemplate(req);
          break;
        case 'update':
          await UpdateExamTemplate(examTemplateUid, req);
          break;
        case 'copy':
          await CopyExamTemplate(examTemplateUid, req);
          break;
        default:
          throw new Error(`Mode is not allowed. mode: ${mode}`);
      };

      commonAlertRef.current?.success(`試験テンプレートを${dialogTitle}しました。`);
      setOpenDialog(false);
      if (updatedTemplateData) await updatedTemplateData();
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`試験テンプレート${dialogTitle}に失敗しました。`);
    } finally {
      setIsLoading(false);
    };
  };

  const deleteExamTemplate = async () => {
    console.log('Delete exam template');
    try {
      if (!examTemplateUid) throw new Error('Exam template uid is empty');
      setDeleteDialog(false);
      setIsLoading(true);

      await DeleteExamTemplate(examTemplateUid);
      
      commonAlertRef.current?.warn(`試験テンプレートを削除しました。`);
      setOpenDialog(false);
      if (updatedTemplateData) await updatedTemplateData();
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`試験テンプレート削除に失敗しました。`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='md'
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>{`試験テンプレート ${dialogTitle}`}</DialogTitle>
        <DialogContent style={{padding: 20}}>
          <Grid 
            container 
            direction="row" 
            justifyContent="space-between" 
            alignItems="center" 
            spacing={2}
          >
            <Grid container item>
              <TextField 
                value={title} 
                onChange={(event) => inputTitle(event.target.value)} 
                label="タイトル" 
                variant="outlined" 
                size="small"
                fullWidth
              />
            </Grid>
            <Grid container item>
              <TextField 
                value={description} 
                onChange={(event) => inputDescription(event.target.value)} 
                multiline 
                rows={10} 
                inputProps={{
                  maxLength: 250,
                }}
                label='概要'
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button 
              onClick={(event) => setDeleteDialog(true)} 
              disabled={(!deleteButtonEnable || isLoading)}
              variant="outlined" 
              color='error'
            >
              削除する
            </Button>
            <Button 
              onClick={ async (event) => await confirm()} 
              disabled={(!title || isLoading)}
              variant="contained"
            >
              実行する
            </Button>
          </Grid>
        </DialogActions>
        <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}>
          <DialogTitle>
            {"試験テンプレートを削除してよろしいですか?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              試験テンプレートを削除します。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button 
                onClick={async (event) => setDeleteDialog(false)} 
                disabled={(!deleteButtonEnable || isLoading)} 
                variant='outlined'
              >
                いいえ
              </Button>
              <Button 
                onClick={async (event) => await deleteExamTemplate()} 
                disabled={(!deleteButtonEnable || isLoading)} 
                variant="contained" color='error'
              >
                はい
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      <CommonAlert
        ref={commonAlertRef}
      />
    </>
  )
});

export default ExamTemplateDialog;