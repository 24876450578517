import { axiosClient } from "../contexts/axiosClientProvider";
import { FacilityData, VendorData } from "../objects/case";


export const GetExamTemplateFilter = async (templateTile: string): Promise<{title: string, uid: number}[]> => {
  const response = await axiosClient.get<{title: string, uid: number}[]>(`filter/exams/templates?title=${templateTile}`);
  return response.data;
};

export const GetFacilityList = async (filter: string): Promise<FacilityData[]> => {
  const response = await axiosClient.get<FacilityData[]>(`filter/facilities?filter=${filter}`);
  return response.data;
};

export const GetVendorList = async (filter: string): Promise<VendorData[]> => {
  const response = await axiosClient.get<VendorData[]>(`filter/vendors?filter=${filter}`);
  return response.data;
};

export const GetExamTemplateCaseVendorList = async (examTemplateUid: number): Promise<string[]> => {
  let url = `filter/vendor/template/case/${examTemplateUid}`;

  const response = await axiosClient.get(url);

  return response.data;
};

export const GetExamTemplateCaseVendorAndModelList = async (examTemplateUid: number): Promise<string[]> => {
  let url = `filter/vendorandmodel/template/case/${examTemplateUid}`;

  const response = await axiosClient.get(url);

  return response.data;
};