import { axiosClient } from "../contexts/axiosClientProvider";
import { DiagnosisData } from "../objects/diagnosis";

export const GetDiagnosisList = async (isMalignant: boolean | null, isSelectable: boolean | null, isActive: boolean | null): Promise<DiagnosisData[]> => {
  const queryList: string[] = [];

  if (isMalignant !== null) queryList.push(`is_malignant=${isMalignant}`);
  if (isSelectable !== null) queryList.push(`is_selectable=${isSelectable}`);
  if (isActive !== null) queryList.push(`is_active=${isActive}`);

  let url = '/diagnosis';
  if (0 < queryList.length) url = url + `?${queryList.join('&')}`;

  const response = await axiosClient.get<DiagnosisData[]>(url);
  return response.data;
};