import { CloudDownload, CloudUpload, Info, Monitor, VideoFile } from "@mui/icons-material";
import { Backdrop, CircularProgress, Grid, IconButton, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { FC, Fragment, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { ExamTemplateQuestionRowProps } from "../../interfaces/examTemplate/examTemplateQuestionRowInterface";
import { VideoDialogKeyType, VideoDialogRefs } from "../../interfaces/viewer/videoDialogInterface";
import { ExamTemplateQuestionData } from "../../objects/examTemplate";
import { GetCaseData, GetDicomFileUrl } from "../../repositories/caseRepo";
import { UploadExamTemplateQuestionAiVideo } from "../../repositories/examTemplateQuestionRepo";
import CommonAlert from "../commonAlert";
import CommonHtmlTooltip from "../commonHtmlTooltip";
import VideoDialog from "../viewer/videoDialog";
import './examTemplateQuestionRow.css';


const ExamTemplateQuestionRow: FC<ExamTemplateQuestionRowProps> = (props: ExamTemplateQuestionRowProps) => {
  const navigate = useNavigate();

  const { examTemplateUid, isEditable, row, diagnosisList, changedQuestion } = props;

  const [isLoading, setIsLoading] = useState(false);

  const videoDialogRef = useRef<VideoDialogRefs>(null);
  const commonAlertRef = useRef<CommonAlertRefs>(null);

  const handleDisplayList = (): string[] => {
    const diagnosisUidList: number[] = [];
    row.exam_template_answers.map((x) => x.diagnosis_uid).forEach((x) => {
      if (!diagnosisUidList.includes(x)) diagnosisUidList.push(x);
    });

    const resultList: string[] = [];
    diagnosisUidList.forEach((x) => {
      const diagnosis = diagnosisList.find((d) => d.diagnosisUid === x);
      const label =  diagnosis ? diagnosis.display : '???';
      const count = row.exam_template_answers.filter((y) => y.diagnosis_uid === x).length;
      resultList.push(`${label} (x${count})`);
    });

    return resultList;
  };

  const downloadDicomFile = async (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('Download dicom file');

    try {
      setIsLoading(true);
      const result = await GetDicomFileUrl(row.case_uid);
      setIsLoading(false);

      const element = document.createElement('a');
      element.href = result;
      element.target = '_blank';
      element.click();
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`DICOMファイルのダウンロードに失敗しました。`);
      setIsLoading(false);
    } finally {
      event.stopPropagation();
    };
  };

  const handleUploadFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(`Upload ai video -> questionUid: ${row.uid}`);
    try {
      if (!event.target.files) throw new Error('Files are empty');
      setIsLoading(true);
      
      const targetFile = event.target.files[0];
      await uploadAiVideo(true, targetFile);
      changedQuestion();
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('AI解析動画のアップロードに失敗しました。');
    } finally {
      setIsLoading(false);
    };
  };

  const uploadAiVideo = async (overWrite: boolean, file: File) => {
    await UploadExamTemplateQuestionAiVideo(row.uid, overWrite, null, file);
  };

  const openVideoDialog = async () => {
    if (!videoDialogRef.current) return;
    await videoDialogRef.current.openDialog(VideoDialogKeyType.ExamTemplateQuestionUid, row.uid, isEditable);
  };

  const deletedVideo = (data: ExamTemplateQuestionData) => {
    changedQuestion();
  };

  const openViewer = async (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('Open viewer');
    try {
      setIsLoading(true);
      const caseData = await GetCaseData(row.case_uid);
      setIsLoading(false);

      navigate(`/exams/templates/viewer/${examTemplateUid}/${caseData.id}`);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      event.stopPropagation();
    };
  };

  const handleVendorModelName = () => {
    const vendorName = row.exam_template_case.vendor_name;
    const modelName = row.exam_template_case.model_name;

    if (!vendorName && !modelName) return "";
    
    if (!modelName) return `${vendorName}`;

    return `${vendorName}(${modelName})`;
  };

  return (
    <>
      <TableRow hover key={row.uid}>
        <TableCell padding='none'>
        <CommonHtmlTooltip placement="left" title={
          <Fragment>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              width={150}
            >
              <Grid container item xs={4}>
                <Typography color="inherit">{`Q ID`}</Typography>
              </Grid>
              <Grid container item xs={8}>
                <Typography color="inherit">{`:${row.uid}`}</Typography>
              </Grid>
            </Grid>
          </Fragment>
        }>
          <IconButton edge="end" size="small" color="primary">
            <Info />
          </IconButton>
        </CommonHtmlTooltip>
        </TableCell>
        <TableCell>{row.case_uid}</TableCell>
        <TableCell>{row.exam_template_case.institution_name}</TableCell>
        <TableCell>{handleVendorModelName()}</TableCell>
        <TableCell>
          { 0 < row.exam_template_answers.length && handleDisplayList().map((x, index) => (
            <Typography key={index}>{ x }</Typography>
          ))}
        </TableCell>
        <TableCell>{row.exam_template_case.image_count}</TableCell>
        <TableCell>{row.ai_video_exists ? `あり` : 'なし'}</TableCell>
        <TableCell padding='none'>
          <Stack direction='row' spacing={0.5}>
            <IconButton 
              onClick={(evt) => downloadDicomFile(evt)} 
              disabled={!isEditable}
              edge='start' size="small" color="primary"
            >
              <CloudDownload />
            </IconButton>
            <IconButton 
              disabled={!isEditable}
              edge='end' size="small" color="primary" component="label"
            >
              <input 
                onChange={(evt) => {
                  handleUploadFileChange(evt);
                  evt.target.value = '';
                }}
                hidden 
                accept="video/*" 
                type="file"
              />
              <CloudUpload />
            </IconButton>
            <IconButton 
              onClick={(evt) => openVideoDialog()} 
              disabled={!row.ai_video_exists}
              edge='end' size="small" color="primary"
            >
              <VideoFile />
            </IconButton>
            <IconButton 
              onClick={(evt) => openViewer(evt)} 
              edge='end' size="small" color="primary"
            >
              <Monitor />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <VideoDialog
        ref={videoDialogRef}
        deletedVideo={deletedVideo}
      />
      <CommonAlert
        ref={commonAlertRef}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
};

export default ExamTemplateQuestionRow;