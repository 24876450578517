import { axiosClient } from "../contexts/axiosClientProvider";
import { ExamTemplateAnswerData } from "../objects/examTemplate";

export const GetExamTemplateAnswerList = async (
  examTemplateQuestionUid: number
): Promise<ExamTemplateAnswerData[]> => {
  let url = `exams/templates/answers?template_question_uid=${examTemplateQuestionUid}`;

  const response = await axiosClient.get<ExamTemplateAnswerData[]>(url);
  return response.data;
};
