import { Backdrop, CircularProgress, Grid } from "@mui/material";
import axios from "axios";
import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { GradingAnswerDrawingDisplayProps, GradingAnswerDrawingDisplayRefs } from "../../interfaces/viewer/gradingAnswerDrawingDisplayInterface";
import { ExamAnswerDrawingData } from "../../objects/exam";
import { GetCaseImageData } from "../../repositories/caseRepo";
import { GetExamAnswerDrawingImage } from "../../repositories/examAnswerDrawingRepo";
import CommonAlert from "../commonAlert";
import './gradingAnswerDrawingDisplay.css';


const GradingAnswerDrawingDisplay = forwardRef<GradingAnswerDrawingDisplayRefs, GradingAnswerDrawingDisplayProps>((props, ref) => {
  const { caseData } = props;

  const [drawingUidList, setDrawingUidList] = useState<number[]>([]);
  const [drawingBlobList, setDrawingBlobList] = useState<{uid: number, blob: Blob | null}[]>([]);
  const [drawingBlob, setDrawingBlob] = useState<{uid: number, imageIndex: number, blob: Blob} | null>(null);
  const [imageBlobList, setImageBlobList] = useState<{imageIndex: number, blob: Blob}[]>([]);
  const [imageBlob, setImageBlob] = useState<{imageIndex: number, blob: Blob} | null>(null);
  const [displaySize, setDisplaySize] = useState<{width: number, height: number}>({width: 300, height: 50});
  const [windowResizeTimeout, setWindowResizeTimeout] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const commonAlertRef = useRef<CommonAlertRefs>(null);

  const cancelToken = axios.CancelToken.source();

  useImperativeHandle (ref, () => ({
    updateDrawingUidList (list: number[]) {
      setDrawingUidList(list);
    },
    async showDrawingImage (drawingData: ExamAnswerDrawingData) {
      console.log('Show drawing image');
      try {
        setIsLoading(true);

        const imageIndex = drawingData.image_index;
        
        const targetImage = imageBlobList.find((x) => x.imageIndex === imageIndex);
        if (targetImage) {
          calcurateDisplaySize(targetImage.blob);
          setImageBlob({imageIndex: imageIndex, blob: targetImage.blob});
        } else {
          const newImageBlob = await GetCaseImageData(caseData.id, imageIndex, cancelToken);
          calcurateDisplaySize(newImageBlob);
          setImageBlob({imageIndex: imageIndex, blob: newImageBlob});
          setImageBlobList([...imageBlobList, {imageIndex: imageIndex, blob: newImageBlob}]);
        };

        const targetDrawing = drawingBlobList.find((x) => x.uid === drawingData.uid);
        if (!targetDrawing) throw new Error(`Could not find target drawing data -> drawingUid: ${drawingData.uid}`);

        const targetBlob = targetDrawing.blob;
        if (targetBlob) {
          setDrawingBlob({uid: targetDrawing.uid, imageIndex: imageIndex, blob: targetBlob});
        } else {
          const newDrawingBlob = await GetExamAnswerDrawingImage(drawingData.uid, cancelToken);
          setDrawingBlob({uid: drawingData.uid, imageIndex: imageIndex, blob: newDrawingBlob});
          setDrawingBlobList((x) => x.map((y) => y.uid === drawingData.uid ? {uid: y.uid, blob: newDrawingBlob} : y));
        };
      } catch (error) {
        console.error(error);
        commonAlertRef.current?.error('解答画像の表示に失敗しました。');
        setDrawingBlob(null);
        setImageBlob(null);
      } finally {
        setIsLoading(false);
      };
    },
  }));

  // initialize.
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowResizeTimeout((timeout) => {
        return timeout + 1;
      });
    };

    window.addEventListener('resize', handleWindowResize);
  }, []);

  // Timeout for resizing display.
  useEffect(() => {
    const timer = setTimeout(async () => {
      try {
        if (imageBlob) calcurateDisplaySize(imageBlob.blob);
      } catch (error) {
        console.error(error);
      }
    }, 0.1 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [windowResizeTimeout]);

  useEffect(() => {
   if (drawingBlob && !drawingUidList.find(x => x === drawingBlob.uid)) {
      setDrawingBlob(null);
    };

    if (drawingBlob && imageBlob && drawingBlob.imageIndex !== imageBlob.imageIndex) {
      setImageBlob(null);
    };

    setDrawingBlobList(drawingUidList.map((x) => { return { uid: x, blob: null } }));
  }, [drawingUidList]);

  const calcurateDisplaySize = (imageBlob: Blob) => {
    const img = new Image();
    img.src = URL.createObjectURL(imageBlob);

    img.onload = async () => {
      const imgWidth = img.width;
      const imgHeight = img.height;

      const display = document.getElementById('display') as HTMLDivElement | null;
      if (!display) throw new Error('Could not get display element');

      const maxWidth = display.clientWidth / imgWidth;
      const maxHeight = display.clientHeight / imgHeight;

      const ratio = Math.min(maxWidth, maxHeight);
      const setWidth = imgWidth * ratio;
      const setHeight = imgHeight * ratio;

      setDisplaySize({width: setWidth, height: setHeight});
    };
  };

  return (
    <>
      <Grid container item direction="column" justifyContent="center" alignItems="center" className="GradingAnswerDrawingDisplay-root-grid">
        <div id="display" className="GradingAnswerDrawingDisplay-display">
          {imageBlob &&
            <img 
              id="GradingAnswerDrawingDisplay-image"
              src={URL.createObjectURL(imageBlob.blob)}
              width={displaySize.width}
              height={displaySize.height}
              className="GradingAnswerDrawingDisplay-image"
            />
          }
          {drawingBlob &&
            <img 
              id="GradingAnswerDrawingDisplay-drawing"
              src={URL.createObjectURL(drawingBlob.blob)}
              width={displaySize.width}
              height={displaySize.height}
              className="GradingAnswerDrawingDisplay-drawing"
            />
          }
          {drawingBlob &&
            <div className="GradingAnswerDrawingDisplay-overlay-bottom-left">
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <div style={{color: '#03a9f4', textAlign: 'left'}}>{`Img: ${drawingBlob.imageIndex}`}</div>
                </Grid>
                <Grid item xs={12}>
                  <div style={{color: '#03a9f4', textAlign: 'left'}}>{`No.${drawingBlob.uid}`}</div>
                </Grid>
              </Grid>
            </div>
          }
        </div>
      </Grid>
      <CommonAlert
        ref={commonAlertRef}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
});

export default GradingAnswerDrawingDisplay;