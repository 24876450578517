export class ExamStudentActionLogBaseData {
  action_uid: ExamStudentActionLogKind;
  description: string;
  target_uid: number | null;
  created: Date;

  constructor (
    action_uid: ExamStudentActionLogKind,
    description: string,
    target_uid: number | null,
    created: Date) {
      this.action_uid = action_uid;
      this.description = description;
      this.target_uid = target_uid;
      this.created = created;
  };
};

export class ExamStudentActionLogCreateData extends ExamStudentActionLogBaseData {
  constructor (
    action_uid: ExamStudentActionLogKind,
    description: string,
    target_uid: number | null,
    created: Date) {
      super(
        action_uid,
        description,
        target_uid,
        created
      );
  };
};

export class ExamStudentActionLogData extends ExamStudentActionLogBaseData {
  uid: number;
  student_uid: number;
  question_uid: number;
  is_second: boolean;

  constructor (
    action_uid: ExamStudentActionLogKind,
    description: string,
    target_uid: number | null,
    created: Date,
    uid: number,
    student_uid: number,
    question_uid: number,
    is_second: boolean) {
      super(
        action_uid,
        description,
        target_uid,
        created
      );

      this.uid = uid;
      this.student_uid = student_uid;
      this.question_uid = question_uid;
      this.is_second = is_second;
  };
};

export const ExamStudentActionLogKind = {
  finished_loading: 1,
  drew_image: 100,
  clicked_show_image_button: 200,
  showed_image: 201,
  first_time_show_ai_video: 300,
  clicked_show_ai_video_button: 301,
  closed_video_dialog_button: 350,
  clicked_confirm_button: 999,
  reloaded: 1000,
  error: 99999
} as const;

export type ExamStudentActionLogKind = typeof ExamStudentActionLogKind[keyof typeof ExamStudentActionLogKind];