import { ExamTemplateAnswerData } from "./examTemplateAnswerData";
import { ExamTemplateCaseData } from "./ExamTemplateCaseData";


export class ExamTemplateQuestionBaseData {
  section_uid: number;
  case_uid: number;
  description: string;
  image_range_start: number;
  image_range_end: number;

  constructor (
    section_uid: number, 
    case_uid: number,
    description: string, 
    image_range_start: number, 
    image_range_end: number) {
      this.section_uid = section_uid;
      this.description = description;
      this.case_uid = case_uid;
      this.image_range_start = image_range_start;
      this.image_range_end = image_range_end;
  };
};

export class ExamTemplateQuestionCreate extends ExamTemplateQuestionBaseData {
  constructor(
    section_uid: number, 
    case_uid: number,
    description: string, 
    image_range_start: number, 
    image_range_end: number) {
      super(
        section_uid,  
        case_uid, 
        description,
        image_range_start, 
        image_range_end
      );
  };
};

export class ExamTemplateQuestionData extends ExamTemplateQuestionBaseData {
  uid: number;
  question_number: number;
  ai_video_exists: boolean;
  exam_template_answers: ExamTemplateAnswerData[];
  exam_template_case: ExamTemplateCaseData;

  constructor (
    uid: number, 
    section_uid: number, 
    case_uid: number,
    question_number: number, 
    description: string, 
    image_range_start: number, 
    image_range_end: number, 
    ai_video_exists: boolean,
    exam_template_answers: ExamTemplateAnswerData[],
    exam_template_case: ExamTemplateCaseData) {
      super(
        section_uid,  
        case_uid, 
        description,
        image_range_start, 
        image_range_end
      );

      this.uid = uid;
      this.question_number = question_number;
      this.ai_video_exists = ai_video_exists;
      this.exam_template_answers = exam_template_answers;
      this.exam_template_case = exam_template_case;
  };
};

export const ExamTemplateQuestionsType = {
  Detection: 'detection',
  Identification: 'identification'
} as const;

export type ExamTemplateQuestionsType = typeof ExamTemplateQuestionsType[keyof typeof ExamTemplateQuestionsType];

export const ExamTemplateQuestionsTypeDisplay = (name: ExamTemplateQuestionsType): string => {
  switch (name) {
    case 'detection':
      return '検出問題';
    case 'identification':
      return '鑑別問題';
  };
};

export const GetExamTemplateQuestionTypeList = () => {
  return [
    ExamTemplateQuestionsType.Detection,
    ExamTemplateQuestionsType.Identification
  ];
};

export function GetExamTemplateQuestionTypeValue (value: string): ExamTemplateQuestionsType | null {
  if (value === ExamTemplateQuestionsType.Detection) return ExamTemplateQuestionsType.Detection;
  else if (value === ExamTemplateQuestionsType.Identification) return ExamTemplateQuestionsType.Identification;
  else return null;
};