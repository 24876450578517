import { ShapeData } from "../objects/viewer";

const drawShape = (shapeData: ShapeData, canvas: HTMLCanvasElement) => {
  //console.log('Draw shape');
  //console.log(shapeData);

  switch (shapeData.shapeType) {
    case 'rectangle':
      drawRect(shapeData, canvas);
      break;
    case 'circle':
      drawCircle(shapeData, canvas);
      break;
    default:
      throw new Error(`Shape is not allowed. (${shapeData.shapeType})`);
  }
};

const drawRect = (shapeData: ShapeData, canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext("2d");

  if (!ctx) return;

  ctx.beginPath();
  ctx.strokeStyle = shapeData.borderColor;
  ctx.lineWidth = shapeData.borderWidth;
  ctx.rect(shapeData.startX, shapeData.startY, shapeData.endX - shapeData.startX, shapeData.endY - shapeData.startY);
  ctx.stroke();
};

const drawCircle = (shapeData: ShapeData, canvas: HTMLCanvasElement) => {
  const canvasOrgWidth = canvas.width;
  const canvasOrgHeight = canvas.height;
  
  const ctx = canvas.getContext("2d");

  if (!ctx) return;

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  const widthRatio = canvasOrgWidth / shapeData.imageWidth;
  const heightRatio = canvasOrgHeight / shapeData.imageHeight;

  const orgStartX = Math.round(shapeData.startX * widthRatio);
  const orgEndX = Math.round(shapeData.endX * widthRatio);
  const orgStartY = Math.round(shapeData.startY * heightRatio);
  const orgEndY = Math.round(shapeData.endY * heightRatio);

  // const orgRadiusX = (orgEndX - orgStartX) / 2;
  // const orgArcX = orgStartX + orgRadiusX;
  // const orgRadiusY = (orgEndY - orgStartY) / 2;
  // const orgArcY = orgStartY + orgRadiusY;

  // ctx.beginPath();
  // ctx.arc(orgArcX, orgArcY, orgRadiusX, 0, Math.PI * 2, true);
  // ctx.strokeStyle = shapeData.borderColor;
  // ctx.lineWidth = shapeData.borderWidth;
  // ctx.stroke();

  const x1 = orgStartX < orgEndX ? orgStartX : orgEndX;
  const x2 = orgStartX < orgEndX ? orgEndX : orgStartX;
  const y1 = orgStartY < orgEndY ? orgStartY : orgEndY;
  const y2 = orgStartY < orgEndY ? orgEndY : orgStartY;
  const radius = Math.sqrt(Math.pow(x2-x1, 2) + Math.pow(y2-y1, 2));

  ctx.beginPath();
  ctx.arc(orgStartX, orgStartY, radius, 0, Math.PI * 2, true);
  ctx.strokeStyle = shapeData.borderColor;
  ctx.lineWidth = shapeData.borderWidth;
  ctx.stroke();
};

export default { drawShape }