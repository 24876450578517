import { Pause, PlayArrow, Replay } from "@mui/icons-material";
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { VideoDialogKeyType, VideoDialogProps, VideoDialogRefs } from "../../interfaces/viewer/videoDialogInterface";
import { DeleteExamTemplateQuestionAiVideo } from "../../repositories/examTemplateQuestionRepo";
import { GetVideo, GetVideoForStudent, GetVideoInfo, GetVideoInfoForStudent } from "../../repositories/videoRepo";
import CommonAlert from "../commonAlert";
import './videoDialog.css';


const VideoDialog = forwardRef<VideoDialogRefs, VideoDialogProps>((props, ref) => {
  const { deletedVideo, errorDetected, closeDialog } = props;

  const videoRef = useRef<HTMLVideoElement>(null);
  const commonAlertRef = useRef<CommonAlertRefs>(null);

  const [isEditable, setIsEditable] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [keyType, setKeyType] = useState<VideoDialogKeyType | null>(null);
  const [questionUid, setQuestionUid] = useState<number | null>(null);
  const [videoSrc, setVideoSrc] = useState('');
  const [windowResizeTimeout, setWindowResizeTimeout] = useState(0);
  const [videoHeight, setVideoHeight] = useState(500);
  const [videoLoadedError, setVideoLoadedError] = useState(false);
  const [playingSpeed, setPlayingSpeed] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useImperativeHandle (ref, () => ({
    async openDialog (keyType: VideoDialogKeyType, uid: number, isEditable: boolean): Promise<void> {
      try {
        setOpenDialog(true);

        const videoInfo = keyType === VideoDialogKeyType.ExamTemplateQuestionUid ? await GetVideoInfo(uid) : await GetVideoInfoForStudent(uid);
  
        switch (videoInfo.type) {
          case 'local':
            const videoBlob = keyType === VideoDialogKeyType.ExamTemplateQuestionUid ? await GetVideo(uid) : await GetVideoForStudent(uid);
            setVideoSrc(`${window.URL.createObjectURL(videoBlob)}`);
            break;
          case 'gcs':
            setVideoSrc(videoInfo.url);
            break;
          default:
            throw new Error('Video type is not allowed.');
        };

        setIsEditable(isEditable);
        setKeyType(keyType);
        setQuestionUid(uid);
      } catch (error) {
        console.error(error);
        setVideoLoadedError(true);
        if (errorDetected) errorDetected('動画情報の取得に失敗しました。');
        commonAlertRef.current?.error(`動画情報の取得に失敗しました。`);
        setOpenDialog(false);
      };
    }
  }));

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowResizeTimeout((timeout) => {
        return timeout + 1;
      });
    };

    window.addEventListener('resize', handleWindowResize);
  }, []);

  useEffect(() => {
    if (keyType) {
      setOpenDialog(true);
    };
  }, [keyType]);

  useEffect(() => {
    if (!openDialog) {
      setKeyType(null);
      setIsPlaying(false);
      setVideoLoadedError(false);
      setQuestionUid(null);
      setVideoSrc('');
    };
  }, [openDialog]);

  useEffect(() => {
    if (!videoRef.current) return;

    videoRef.current.playbackRate = playingSpeed;
  }, [playingSpeed]);

  useEffect(() => {
    if (!videoRef.current) return;

    if (isPlaying) videoRef.current.play();
    else videoRef.current.pause();
  }, [isPlaying]);

  // Timeout for resizing display.
  useEffect(() => {
    const timer = setTimeout(async () => {
      try {
        const clientHeight = document.body.clientHeight;
        const ratioHeight = clientHeight * 0.7;
        const height = 500 < ratioHeight ? ratioHeight : 500;
        setVideoHeight(height);
      } catch (error) {
        console.error(error);
      }
    }, 0.1 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [windowResizeTimeout]);

  const videoOnPlay = () => {
    console.log('Playing')
    if (videoRef.current) videoRef.current.playbackRate = playingSpeed;
    setIsPlaying(true);
    setIsLoading(false);
  };

  const videoOnError = () => {
    setVideoLoadedError(true);
    setVideoSrc('');
    setIsPlaying(false);
    setIsLoading(false);
    if (errorDetected) errorDetected('動画の読み込みに失敗しました。');
    commonAlertRef.current?.error(`動画の読み込みに失敗しました。`);
  };

  const handlePlaying = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      setIsPlaying(!isPlaying);
    } catch (error) {
      console.error(error);
    } finally {
      event.stopPropagation();
    }
  };

  const deleteVideo = async () => {
    console.log('Delete video');
    try {
      if (!questionUid) throw new Error('Question uid is empty');
      setOpenDeleteDialog(false);
      setIsLoading(true);

      const result = await DeleteExamTemplateQuestionAiVideo(questionUid);

      if (deletedVideo) deletedVideo(result);
      setOpenDialog(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    };
  };

  const handleCloseDialog = () => {
    if (closeDialog) closeDialog();
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='lg'
        open={openDialog} 
        onClose={() => handleCloseDialog()}
        style={{backgroundColor: 'white'}}
      >
        <DialogContent className="VideoDialog-Dialog-Content">
          <Grid 
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {videoSrc
              ? <video 
                  id="VideoDialog-video"
                  ref={videoRef}
                  src={videoSrc} 
                  crossOrigin="anonymous" 
                  muted autoPlay loop
                  disablePictureInPicture
                  onContextMenu={(evt) => {evt.preventDefault()}}
                  onPlay={(evt) => videoOnPlay()}
                  onError={(evt) => videoOnError()}
                  onClick={(evt) => setIsPlaying(!isPlaying)}
                  height={videoHeight}
                />
              : <Grid container
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch" 
                  style={{height: videoHeight}}
                >
                  {!videoLoadedError
                    ? <Typography variant='h5' style={{color: 'white', textAlign: 'center'}}>読み込み中</Typography>
                    : <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        width='100%'
                      >
                        <Typography variant='h5' style={{color: 'white', textAlign: 'center'}}>動画が読み込めませんでした。</Typography>
                        {/* <Button 
                          onClick={(event) => handleCloseDialog()} 
                          variant="contained"
                        >
                          再読み込み
                        </Button> */}
                      </Stack>
                  }
                </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions style={{backgroundColor: '#424242'}}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {isEditable
              ? <Button 
                  onClick={(event) => setOpenDeleteDialog(true)} 
                  disabled={!isEditable || videoLoadedError}
                  variant="outlined" 
                  color='error'
                >
                  削除する
                </Button>
              : <div></div>
            }
            <Stack spacing={1} direction="row" alignItems="center">
              <FormControl sx={{ m: 1, minWidth: 100, margin: 0 }} size="small">
                <InputLabel style={{color: '#03a9f4'}}>Speed</InputLabel>
                <Select
                  value={playingSpeed}
                  onChange={(evt) => setPlayingSpeed(Number(evt.target.value))}
                  label="Speed"
                  sx={{
                    color: '#03a9f4',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#03a9f4'
                    },
                    '& .MuiSvgIcon-root': {
                        color: '#03a9f4'
                    }
                  }}
                  disabled={(!videoSrc || videoLoadedError)}
                >
                  <MenuItem value={1}>{'x1.0'}</MenuItem>
                  <MenuItem value={0.75}>{'x0.75'}</MenuItem>
                  <MenuItem value={0.5}>{'x0.50'}</MenuItem>
                </Select>
              </FormControl>
              <IconButton 
                onClick={(event) => handlePlaying(event)}
                disabled={(!videoSrc || videoLoadedError)}
                edge='start'
                size="small" 
                color='primary'
              >
                {isPlaying ?
                  <Pause/> :
                  <PlayArrow />
                }
              </IconButton>
            </Stack>
            <Button 
              onClick={(event) => handleCloseDialog()} 
              variant="contained"
            >
              閉じる
            </Button>
          </Grid>
        </DialogActions>
        <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
          <DialogTitle>
            {"動画を削除してよろしいですか?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              動画を削除します、削除された動画は復元できません。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button onClick={async (event) => setOpenDeleteDialog(false)} variant='outlined'>いいえ</Button>
              <Button onClick={async (event) => await deleteVideo()} variant="contained" color='error'>はい</Button>
            </Grid>
          </DialogActions>
        </Dialog>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      <CommonAlert
        ref={commonAlertRef}
      />
    </>
  )
});

export default VideoDialog;