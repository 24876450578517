import { Edit } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { CommonDialogButton } from "../../common/dialog/CommonDialogButton";
import { Box, Button, InputAdornment, Stack, TextField } from "@mui/material";
import { GetAnnotationImageData } from "../../../repositories/caseRepo";
import { UpdateExamTemplateCaseAnnotationSize } from "../../../repositories/ExamTemplateCasesRepo";
import CommonAlert from "../../commonAlert";
import { CommonAlertRefs } from "../../../interfaces/commonAlertInterface";

type CaseLesionScaleDialogButtonProps = {
  iconButton: boolean;
  disabled: boolean;
  getCaseImageUid: () => number;
  getTemplateCaseAnnotationUid: () => number;
  finishedAction: () => void;
  buttonText?: string;
  buttonVariant?: "text" | "outlined";
  hideIcon?: boolean;
};

export const CaseLesionScaleDialogButton: React.FC<
  CaseLesionScaleDialogButtonProps
> = (props) => {
  const {
    iconButton,
    disabled,
    getCaseImageUid,
    getTemplateCaseAnnotationUid,
    finishedAction,
    buttonText,
    buttonVariant,
    hideIcon,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [isPause, setIsPause] = useState(false);
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  const [size, setSize] = useState(0);

  const commonAlertRef = useRef<CommonAlertRefs>(null);

  useEffect(() => {
    (async () => {
      if (!openDialog) {
        setImageBlob(null);
        setSize(0);
        return;
      }

      try {
        setIsPause(true);
        const imageUid = getCaseImageUid();
        const imageData = await GetAnnotationImageData(imageUid);
        setImageBlob(imageData);
      } catch (error) {
        setOpenDialog(false);
        commonAlertRef.current?.error(
          "アノテーション画像の取得に失敗しました。"
        );
      } finally {
        setIsPause(false);
      }
    })();
  }, [openDialog]);

  const handleChangeSizeValue = (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    evt.stopPropagation();
    setSize(Number(evt.target.value));
  };

  const handleClickActionButton = async (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    evt.stopPropagation();
    try {
      setIsPause(true);

      const uid = getTemplateCaseAnnotationUid();
      await UpdateExamTemplateCaseAnnotationSize(uid, size);

      handleFinishedUpdate();
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error("腫瘍サイズの更新に失敗しました。");
    } finally {
      setIsPause(false);
    }
  };

  const handleFinishedUpdate = () => {
    setOpenDialog(false);
    finishedAction();
  };

  return (
    <>
      <CommonDialogButton
        iconButton={iconButton}
        buttonText={buttonText ?? "腫瘍サイズを更新"}
        buttonIcon={<Edit />}
        dialogTitle={`腫瘍サイズを更新する`}
        disabled={disabled}
        contentChildren={
          <Box>
            {imageBlob && (
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <img
                  src={imageBlob ? URL.createObjectURL(imageBlob) : undefined}
                  width={700}
                  height={"auto"}
                />
                <TextField
                  value={size}
                  onChange={(evt) => handleChangeSizeValue(evt)}
                  label="サイズ"
                  type="number"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{"cm"}</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    step: "0.1",
                    min: 0,
                    max: 100,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText="0で更新すると未設定になります。"
                />
              </Stack>
            )}
          </Box>
        }
        actionChildren={
          <Button
            onClick={(evt) => handleClickActionButton(evt)}
            disabled={isPause || !imageBlob}
            variant="contained"
          >
            {"更新する"}
          </Button>
        }
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        hideIcon={hideIcon}
        buttonVariant={buttonVariant}
        maxWidth="lg"
        tooltipMessage={""}
        isProgressing={isPause}
      />
      <CommonAlert ref={commonAlertRef} />
    </>
  );
};
