import { axiosClient } from "../contexts/axiosClientProvider";
import { ExamAnswerIdentificationAnalysisData } from "../objects/exam";

export const GetExamAnswerIdentificationAnalysis = async (
  examUid: number,
  useMalignant: boolean,
  diagnosisUid: number | null,
  studentTag: string,
  vendorModelName: string | null,
  studentUid?: number
) => {
  let url = `/exam/answer/identification/${examUid}`;

  const filterList: string[] = [];

  filterList.push(`use_malignant=${useMalignant}`);

  if (!useMalignant && diagnosisUid !== null) {
    filterList.push(`diagnosis_uid=${diagnosisUid}`);
  }

  if (studentTag) {
    filterList.push(`student_tag=${studentTag}`);
  }

  if (vendorModelName !== null && vendorModelName) {
    filterList.push(`vendor_model_name=${vendorModelName}`);
  }

  if (studentUid) {
    filterList.push(`student_uid=${studentUid}`);
  }

  if (0 < filterList.length) {
    url += `?${filterList.join("&")}`;
  }

  const response = await axiosClient.get<ExamAnswerIdentificationAnalysisData>(
    url
  );
  return response.data;
};
