import { Dialog, DialogTitle, DialogContent, DialogContentText, Stack, FormGroup, FormControlLabel, Checkbox, DialogActions, Button, Backdrop, CircularProgress } from "@mui/material";
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { ExamStudentData } from "../../objects/exam";
import { DownloadExamStudentAnswerData } from "../../repositories/examStudentRepo";
import CommonAlert from "../commonAlert";

export interface ExamStudentResultDownloadDialogRefs {
  openDialog: () => void;
};

export type ExamStudentResultDownloadDialogProps = {
  examUid: number;
  examStudent: ExamStudentData;
};

export const ExamStudentResultDownloadDialog = forwardRef<ExamStudentResultDownloadDialogRefs, ExamStudentResultDownloadDialogProps>((props, refs) => {
  const { examUid, examStudent } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [answerIsChecked, setAnswerIsChecked] = useState(false);
  const [imageIsChecked, setImageChecked] = useState(false);
  const [actionLogIsChecked, setActionLogIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const commonAlertRef = useRef<CommonAlertRefs>(null);

  useImperativeHandle(refs, () => ({
    openDialog: () => {
      if (examUid < 1) return;
      setAnswerIsChecked(true);
      setImageChecked(true);
      setActionLogIsChecked(true);
      setOpenDialog(true);
    }
  }));

  useEffect(() => {

  }, [openDialog]);

  const handleDownloadButtonDisabled = (): boolean => {
    if (!answerIsChecked && !imageIsChecked && !actionLogIsChecked) return true;
    else return false;
  };

  const handleClickDownloadButton = async () => {
    try {
      setIsLoading(true);
      
      await DownloadExamStudentAnswerData(
        examStudent.uid, 
        answerIsChecked,
        imageIsChecked,
        actionLogIsChecked,
        `${examUid}_${examStudent.uid}_${examStudent.sign_in_id}_解答データ`
      );

      setIsLoading(false);
      setOpenDialog(false);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('試験結果のダウンロードに失敗しました。');
    } finally {
      setIsLoading(false);
    };
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>{'試験結果ダウンロード'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`被験者ID: ${examStudent.sign_in_id}`}
            <br></br>
            {examStudent.name && `氏名: ${examStudent.name}`}
          </DialogContentText>
          <Stack
            direction='column'
            justifyContent='stretch'
            alignItems='flex-start'
            spacing={1}
            width='100%'
          >
            <FormGroup>
              <FormControlLabel 
                control={
                  <Checkbox 
                    checked={answerIsChecked}
                    onChange={() => setAnswerIsChecked(!answerIsChecked)}
                  />
                } 
                label="解答データ" 
              />
              <FormControlLabel 
                control={
                  <Checkbox 
                    checked={imageIsChecked}
                    onChange={() => setImageChecked(!imageIsChecked)}
                  />
                } 
                label="画像データ" 
              />
              <FormControlLabel 
                control={
                  <Checkbox 
                    checked={actionLogIsChecked}
                    onChange={() => setActionLogIsChecked(!actionLogIsChecked)}
                  />
                } 
                label="操作ログ" 
              />
            </FormGroup>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            spacing={1}
            width='100%'
          >
            <Button 
            onClick={handleCloseDialog}
            variant='outlined'
          >
            {'閉じる'}
          </Button>
          <Button 
            onClick={handleClickDownloadButton}
            disabled={handleDownloadButtonDisabled()}
            variant='contained'
          >
            {'ダウンロード'}
          </Button>
          </Stack>
        </DialogActions>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      <CommonAlert
        ref={commonAlertRef}
      />
    </>
  )
});
