import { Error } from "@mui/icons-material";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import './NotFoundPage.css';

const NotFoundPage: FC = () => {
  const navigate = useNavigate();

  return (
    <Grid 
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className='NotFoundPage-root-grid'
    >
      <Paper elevation={3} className='NotFoundPage-main-paper'>
        <Typography
          variant="h6" 
          gutterBottom
          className="NotFoundPage-title-typo"
        >
          <Error 
            fontSize="small"
            color="error"
            className="NotFoundPage-title-icon"
          />
          Error
        </Typography>
        <div className="NotFoundPage-body-div">
        <Typography
          variant="body1" 
          gutterBottom
        >
          {'ページが見つかりません。'}
        </Typography>
        <Button
          onClick={(evt) => navigate(-1)}
          fullWidth
          color="primary" 
          variant="outlined"
          className="NotFoundPage-back-button"
        >
          戻る
        </Button>
        </div>
      </Paper>
    </Grid>
  )
};

export default NotFoundPage;