import { Delete, DeleteOutline } from "@mui/icons-material";
import { Autocomplete, Backdrop, Button, Checkbox, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import CommonHeader from "../../compornents/commonHeader";
import './ExamListPage.css';
import { Stack } from "@mui/system";
import { GetExamList } from "../../repositories/examRepo";
import { ExamData } from "../../objects/exam";
import { GetExamTemplateFilter } from "../../repositories/filterRepo";
import ExamRow from "../../compornents/exam/examRow";
import CommonAlert from "../../compornents/commonAlert";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";


const ExamListPage: FC = () => {
  const commonAlertRef = useRef<CommonAlertRefs>(null);
  
  const [examsList, setExamsList] = useState<ExamData[]>([]);
  const [filterExamTemplateList, setFilterExamTemplateList] = useState<{title: string, uid: number}[]>([]);
  const [filterTemplateUid, setFilterTemplateUid] = useState<number | null>(null);
  const [filterExamTitle, setFilterExamTitle] = useState('');
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // initialize.
  useEffect( () => {
    (async () => {
      sessionStorage.clear();
      await getExamList();
    })()
  }, []);

  const getExamList = async () => {
    try {
      setIsLoading(true);
      const list = await GetExamList(filterTemplateUid, filterExamTitle, includeDeleted);
      setExamsList(list);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('試験リストの取得に失敗しました。');
      setExamsList([]);
    } finally {
      setIsLoading(false);
    }
  };

  const inputFilterExamTitle = (newValue: string) => {
    setFilterExamTitle(newValue);
  };

  const getTemplateFilter = async (inputValue: string) => {
    try {
      const result = await GetExamTemplateFilter(inputValue);
      setFilterExamTemplateList(result);
    } catch (error) {
      console.error(error);
    };
  };

  const handleChangeTemplateFilter = (templateUid: number | null) => {
    try {
      setFilterTemplateUid(templateUid);
    } catch (error) {
      console.error(error);
    };
  };

  return (
    <Grid container>
      <CommonHeader headerTitle="試験管理"/>
      <div className="ExamListPage-main-div">
        <Paper className="ExamListPage-filter-paper">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid 
              container 
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Stack direction="row" spacing={1} style={{width: '100%'}}>
                <Autocomplete
                  onChange={(event, value) => handleChangeTemplateFilter(value ? value.uid : null)}
                  disablePortal
                  options={filterExamTemplateList}
                  getOptionLabel={option => option.title}
                  sx={{width: '20%'}}
                  size='small'
                  renderInput={(params) => 
                    <TextField 
                      {...params} 
                      onChange={async (evt) => await getTemplateFilter(evt.target.value)}
                      label="試験テンプレート"
                    />
                  }
                />
                <TextField 
                  value={filterExamTitle} 
                  onChange={(event) => inputFilterExamTitle(event.target.value)} 
                  label="タイトル" variant="outlined" size="small" style={{width: '30%'}}
                />
                <Checkbox 
                  checked={includeDeleted} 
                  onChange={(evt) => setIncludeDeleted(includeDeleted ? false : true)}
                  icon={<DeleteOutline />} 
                  checkedIcon={<Delete color="error" />}
                  size='small' 
                />
                <Button 
                  onClick={async () => await getExamList()} 
                  color="primary" 
                  variant="contained"
                >
                  検索する
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
        <TableContainer component={Paper} className='ExamListPage-exams-table'>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 100 }}></TableCell>
                <TableCell style={{ minWidth: 70 }}>試験番号</TableCell>
                <TableCell style={{ minWidth: 350 }}>試験テンプレート</TableCell>
                <TableCell style={{ width: '100%', minWidth: 350 }}>タイトル</TableCell>
                <TableCell style={{ minWidth: 50 }}>問題数</TableCell>
                <TableCell style={{ minWidth: 60 }}>被験者数</TableCell>
                <TableCell style={{ minWidth: 60 }}>検知上限</TableCell>
                <TableCell style={{ minWidth: 100 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {examsList.map((x, index) => (
                <ExamRow
                  key={index}
                  row={x}
                  reloadExamData={getExamList}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <CommonAlert
        ref={commonAlertRef}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default ExamListPage;