export class HealthCheckExamStudentData {
  student_uid: number;
  is_active: boolean;
  q_order_by_count_is_correct: boolean;
  diff_q_uid_list: number[];
  duplicate_q_order_by_uid_list: number[];
  forbidden_q_order_by_uid_list: number[];
  messages: string[];

  constructor (
    student_uid: number,
    is_active: boolean,
    q_order_by_count_is_correct: boolean,
    diff_q_uid_list: number[],
    duplicate_q_order_by_uid_list: number[],
    forbidden_q_order_by_uid_list: number[],
    messages: string[]
  ) {
    this.student_uid = student_uid;
    this.is_active = is_active;
    this.q_order_by_count_is_correct = q_order_by_count_is_correct;
    this.diff_q_uid_list = diff_q_uid_list;
    this.duplicate_q_order_by_uid_list = duplicate_q_order_by_uid_list;
    this.forbidden_q_order_by_uid_list = forbidden_q_order_by_uid_list;
    this.messages = messages;
  };

  checkResult (): boolean {
    if (!this.q_order_by_count_is_correct) return false;
    else if (0 < this.diff_q_uid_list.length) return false;
    else if (0 < this.duplicate_q_order_by_uid_list.length) return false;
    else if (0 < this.forbidden_q_order_by_uid_list.length) return false;
    else return true;
  };

  handleValueColor (): string {
    if (this.checkResult()) return '#2196f3';
    else return '#f44336';
  };
};