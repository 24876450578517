import { Close, Check } from "@mui/icons-material";
import {
  Backdrop,
  CircularProgress,
  IconButton,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import { forwardRef, useRef, useState } from "react";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import {
  ExamAnswerRowProps,
  ExamAnswerRowRefs,
} from "../../interfaces/exam/examAnswerRowInterface";
import { UpdateExamAnswerMarupeke } from "../../repositories/examAnswerRepo";
import CommonAlert from "../commonAlert";
import { CaseLesionScaleDialogButton } from "../case/dialog/CaseLesionScaleDialogButton";

const ExamAnswerRow = forwardRef<ExamAnswerRowRefs, ExamAnswerRowProps>(
  (props, refs) => {
    const {
      row,
      selectedExamAnswerUid,
      getTemplateAnswer,
      getCaseImageUid,
      handleSelectExamAnswerData,
      handleUpdatedData,
      handleUpdateTemplateAnswer,
    } = props;

    const [isLoading, setIsLoading] = useState(false);

    const commonAlertRef = useRef<CommonAlertRefs>(null);

    const handleClickAnswerRow = () => {
      if (handleSelectExamAnswerData) handleSelectExamAnswerData(row);
    };

    const updateAnswerMarupeke = async (
      event:
        | React.MouseEvent<HTMLButtonElement>
        | React.MouseEvent<HTMLDivElement, MouseEvent>,
      newValue: boolean | null
    ) => {
      try {
        setIsLoading(true);

        event.stopPropagation();

        if (
          selectedExamAnswerUid === null ||
          selectedExamAnswerUid !== row.uid
        ) {
          if (handleSelectExamAnswerData) handleSelectExamAnswerData(row);
          return;
        }

        await UpdateExamAnswerMarupeke(
          row.uid,
          newValue !== row.is_correct ? newValue : null
        );

        setIsLoading(false);
        if (handleUpdatedData) await handleUpdatedData();
      } catch (error) {
        console.error(error);
        commonAlertRef.current?.error("被験者病名評価の更新に失敗しました。");
      } finally {
        setIsLoading(false);
      }
    };

    const handleGetSize = () => {
      const templateAnswer = getTemplateAnswer(row.template_answer_uid);

      const answerAnnotation = templateAnswer.exam_template_answer_annotation;
      if (answerAnnotation) {
        const annotation = answerAnnotation.exam_template_case_annotation;
        if (annotation)
          return annotation.size ? annotation.size.toString() : "未設定";
      }

      return "未設定";
    };

    const handleGetCaseImageUid = () => {
      return getCaseImageUid(row.template_answer_uid);
    };

    const handleGetAnnotationUid = () => {
      const templateAnswer = getTemplateAnswer(row.template_answer_uid);
      console.log(templateAnswer);
      const answerAnnotation = templateAnswer.exam_template_answer_annotation;
      if (!answerAnnotation)
        throw new Error("Could not get the answer annotation.");

      const annotation = answerAnnotation.exam_template_case_annotation;
      if (!annotation) throw new Error("Could not get the case annotation.");
      return annotation.uid;
    };

    const handleFinishedScaleLesion = async () => {
      if (handleUpdateTemplateAnswer) await handleUpdateTemplateAnswer();
    };

    return (
      <>
        <TableRow
          onClick={(evt) => handleClickAnswerRow()}
          hover
          style={{
            backgroundColor: selectedExamAnswerUid === row.uid ? "#e0e0e0" : "",
          }}
        >
          <TableCell style={{ maxWidth: "100px", minWidth: 100 }}>
            {row.diagnosis.display}
          </TableCell>
          <TableCell align="right">
            {0 < row.image_index ? row.image_index : "-"}
          </TableCell>
          <TableCell padding="none">
            <CaseLesionScaleDialogButton
              iconButton={false}
              disabled={isLoading || row.diagnosis_uid === 99999}
              getCaseImageUid={handleGetCaseImageUid}
              getTemplateCaseAnnotationUid={handleGetAnnotationUid}
              finishedAction={handleFinishedScaleLesion}
              buttonText={handleGetSize()}
              buttonVariant="text"
              hideIcon
            />
          </TableCell>
          <TableCell align="right" padding="none">
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={0.5}
            >
              <IconButton
                onClick={async (evt) => await updateAnswerMarupeke(evt, false)}
                disabled={isLoading}
                size="small"
                edge="end"
                color={row.is_correct === false ? "error" : "default"}
              >
                <Close fontSize="small" />
              </IconButton>
              <IconButton
                onClick={async (evt) => await updateAnswerMarupeke(evt, true)}
                disabled={isLoading}
                size="small"
                edge="end"
                style={{
                  color: row.is_correct === true ? "#00c853" : "#757575",
                }}
              >
                <Check fontSize="small" />
              </IconButton>
            </Stack>
          </TableCell>
        </TableRow>
        <CommonAlert ref={commonAlertRef} />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
);

export default ExamAnswerRow;
