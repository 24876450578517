import { Backdrop, Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import "./ExamFinishedPage.css";
import { useNavigate, useParams } from "react-router-dom";
import { CheckExamFinished } from "../repositories/studentExamQuestionRepo";
import axios from "axios";
import { StudentSignOut } from "../repositories/authRepo";
import UseStudentAuth from "../hooks/useStudentAuth";


const ExamFinishedPage: FC = () => {
  const navigate = useNavigate();

  const { examUid } = useParams();
  
  const { setStudentAuth } = UseStudentAuth();

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
      if (!examUid) return;
      const result = await CheckExamFinished(Number(examUid));

      if (result.finished) {
        setTitle(result.title);
        setBody('全ての問題が終了しました、お疲れさまでした。');
      } else {
        navigate(`/exams/${examUid}`);
      };
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      };
    })()
  }, []);

  const backToSigninPage = async () => {
    await StudentSignOut();
    setStudentAuth(null);
  };

  return (
    <Grid 
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className="ExamFinishedPage-root-grid"
    >
      <Paper
        elevation={3}
        className="ExamFinishedPage-main-paper"
      >
        <Grid 
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          className="ExamFinishedPage-main-title"
        >
          <Typography
            variant="h4" 
            gutterBottom
          >
            試験終了
          </Typography>
          <Typography
            variant="h5" 
            gutterBottom
          >
            {title}
          </Typography>
          <Typography
            variant="body1" 
            gutterBottom
          >
            {body}
          </Typography>
          {/* <Button
           onClick={async (evt) => await backToSigninPage()}
          >
            ログイン画面へ戻る
          </Button> */}
        </Grid>
      </Paper>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default ExamFinishedPage;