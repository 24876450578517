import { Backdrop, Box, Button, CircularProgress, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import './SigninPage.css';
import { useNavigate, useLocation } from "react-router-dom";
import CommonAlert from "../compornents/commonAlert";
import { CommonAlertRefs } from "../interfaces/commonAlertInterface";
import UseAuth from "../hooks/useAuth";
import { AuthedUser } from "../objects/auth";
import { axiosPublicClient } from "../contexts/axiosClientProvider";
import './SigninPage.css';
import UseImageAuth from "../hooks/useImageAuth";

const SigninPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  let from = '/';
  if (location.state && location.state.from && location.state.from.pathname) {
    if (location.state.from.pathname !== '/signin') from = location.state.from.pathname;
  };

  const { auth, setAuth } = UseAuth();
  const { setImageAuth } = UseImageAuth();

  const commonAlertRef = useRef<CommonAlertRefs>(null);

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // initialize.
  useEffect( () => {
    sessionStorage.clear();
    setAuth(null);
    setImageAuth(null);
  }, []);

  useEffect( () => {
    if (auth) navigate(from, {replace: true});
  }, [auth]);

  const inputId = (newValue: string) => {
    setId(newValue);
  };

  const inputPassword = (newValue: string) => {
    setPassword(newValue);
  };

  const signIn = async () => {
    try {
      if (!id) {
        commonAlertRef.current?.error('IDを入力してください。');
        return;
      };

      if (!password) {
        commonAlertRef.current?.error('パスワードを入力してください。');
        return;
      };

      setIsLoading(true);

      let formData = new FormData();

      formData.append('username', id);
      formData.append('password', password);

      await axiosPublicClient.post('signin', formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        }}).then((res) => {
          setAuth(new AuthedUser(
            res.data.id,
            res.data.first_name,
            res.data.last_name,
            res.data.email,
            res.data.role,
            res.data.access_token
          ));

          setImageAuth(res.data.image_token);
        }).catch((error) => {
          console.error(error);
          if (error.response.status === 401) {
            console.error('GET HTTP error 401');
          } else if (error.response.status === 404) {
            console.error('GET HTTP error 404');
          };
    
          throw new Error("HTTP POST failed.");
        }
      );
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('ログインに失敗しました。');
    } finally {
      setIsLoading(false);
    };
  };

  return (
    <>
      <Grid 
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="SigninPage-root-grid"
      >
        <Paper elevation={3} className='SigninPage-main-paper'>
        <Box className="SigninPage-title-box">
            <Typography variant="h6" color={'InfoBackground'}>
              革新がん出題システム
            </Typography>
          </Box>
          <Grid 
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            className="SigninPage-form-grid"
          >
            <TextField 
              value={id} 
              autoComplete='off'
              onChange={(event) => inputId(event.target.value)} 
              inputProps={{
                maxLength: 250,
              }}
              fullWidth 
              label="ID" 
              margin="dense"
              size="small"
            />
            <TextField 
              value={password} 
              onChange={(event) => inputPassword(event.target.value)}
              onKeyDown={async (evt) => {
                if (evt.key === 'Enter' && 0 < password.length) {
                  await signIn();
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
              fullWidth 
              label="PASSWORD" 
              margin="dense" 
              size="small" 
              type="password"
              autoComplete="current-password"
            />
            <Divider style={{margin: '10px 0'}}/>
            <Button 
              onClick={async (event) => await signIn()}
              fullWidth 
              variant="contained"
            >
              ログイン
            </Button>
          </Grid>
        </Paper>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
      <CommonAlert
        ref={commonAlertRef}
      />
    </>
  );
};

export default SigninPage;