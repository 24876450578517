import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import ViewerDisplay from "../../compornents/viewer/viewerDIsplay";
import { ViewerDisplayRefs } from "../../interfaces/viewer/viewerDIsplayInterface";
import { CaseData } from "../../objects/case";
import "./ExamTemplateViewer.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  ExamTemplateAnswerAnnotationData,
  ExamTemplateAnswerData,
  ExamTemplateAnswerType,
  ExamTemplateQuestionCreate,
  ExamTemplateQuestionData,
  ExamTemplateQuestionsType,
} from "../../objects/examTemplate";
import { GetExamTemplateSectionList } from "../../repositories/examTemplateSectionRepo";
import {
  AddExamTemplateDetectionQuestion,
  AddExamTemplateIdentificationQuestion,
  CheckExamTemplateQuestionExists,
  DeleteExamTemplateQuestion,
} from "../../repositories/examTemplateQuestionRepo";
import { Stack } from "@mui/system";
import { Delete, Download, Info, KeyboardReturn } from "@mui/icons-material";
import VideoDialog from "../../compornents/viewer/videoDialog";
import {
  VideoDialogKeyType,
  VideoDialogRefs,
} from "../../interfaces/viewer/videoDialogInterface";
import { GetExamTemplate } from "../../repositories/examTemplateRepo";
import ViewerErrorCompornent from "../../compornents/viewer/viewerErrorCompornent";
import { GetCaseData, GetDicomFileUrl } from "../../repositories/caseRepo";
import CommonAlert from "../../compornents/commonAlert";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { GetDiagnosisList } from "../../repositories/diagnosisRepo";
import { DiagnosisData } from "../../objects/diagnosis";
import CommonHtmlTooltip from "../../compornents/commonHtmlTooltip";
import { CopyExamTemplateCaseFiles } from "../../repositories/ExamTemplateCasesRepo";

const ExamTemplateViewer: FC = () => {
  const navigate = useNavigate();

  const { templateUid, caseUid } = useParams();

  const [isEditable, setIsEditable] = useState(false);
  const [caseData, setCaseData] = useState<CaseData | null>(null);
  const [switchDetection, setSwitchDetection] = useState(false);
  const [switchIdentification, setSwitchIdentification] = useState(false);
  const [detectionSectionUid, setDetectionSectionUid] = useState(-1);
  const [identificationSectionUid, setIdentificationSectionUid] = useState(-1);
  const [detectionQuestion, setDetectionQuestion] =
    useState<ExamTemplateQuestionData | null>(null);
  const [identificationQuestion, setIdentificationQuestion] =
    useState<ExamTemplateQuestionData | null>(null);
  const [answerDataList, setAnswerDataList] = useState<
    { isChecked: boolean; data: ExamTemplateAnswerData }[]
  >([]);
  const [detectionAiVideoFile, setDetectionAiVideoFile] = useState<File | null>(
    null
  );
  const [identificationAiVideoFile, setIdentificationAiVideoFile] =
    useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const diagnosisList = useRef<DiagnosisData[]>([]);

  const viewerDisplayRef = useRef<ViewerDisplayRefs>(null);
  const videoDialogRef = useRef<VideoDialogRefs>(null);
  const commonAlertRef = useRef<CommonAlertRefs>(null);

  const {
    getRootProps: getDetectionRootProps,
    getInputProps: getDetectionInputProps,
    isDragActive: isDetectionDragActive,
  } = useDropzone({
    onDrop: (acceptedFile: File[]) => {
      if (identificationAiVideoFile) {
        if (identificationAiVideoFile.name === acceptedFile[0].name) {
          return;
        }
      }

      setDetectionAiVideoFile(acceptedFile[0]);

      if (!switchDetection) setSwitchDetection(true);
    },
    accept: {
      "video/*": [],
    },
    disabled: !isEditable,
    multiple: false,
  });

  const {
    getRootProps: getIdentificationRootProps,
    getInputProps: getIdentificationInputProps,
    isDragActive: isIdentificationDragActive,
  } = useDropzone({
    onDrop: (acceptedFile: File[]) => {
      if (detectionAiVideoFile) {
        if (detectionAiVideoFile.name === acceptedFile[0].name) {
          return;
        }
      }

      setIdentificationAiVideoFile(acceptedFile[0]);
      if (!switchIdentification) setSwitchIdentification(true);
    },
    accept: {
      "video/*": [],
    },
    disabled: !isEditable || answerDataList.length < 1,
    multiple: false,
  });

  // initialize.
  useEffect(() => {
    (async () => {
      try {
        if (!templateUid || !caseUid)
          throw new Error("Template uid or case uid are empty");
        setIsLoading(true);

        diagnosisList.current = await GetDiagnosisList(null, true, true);

        const caseData = await GetCaseData(Number(caseUid));

        const template = await GetExamTemplate(Number(templateUid));

        if (template.status === "editing") setIsEditable(true);
        else setIsEditable(false);

        const sections = await GetExamTemplateSectionList(Number(templateUid));

        const answerList: {
          isChecked: boolean;
          data: ExamTemplateAnswerData;
        }[] = [];
        let uid = -1;

        caseData.case_image_list.forEach((x) => {
          const annotation = new ExamTemplateAnswerAnnotationData(
            uid,
            x.id,
            null
          );

          const diagnosis = diagnosisList.current.find(
            (y) => y.display === x.diagnosis
          );
          if (!diagnosis) throw new Error("Could not find diagnosis");

          answerList.push({
            isChecked: false,
            data: new ExamTemplateAnswerData(
              uid,
              x.image_index,
              diagnosis.uid,
              ExamTemplateAnswerType.Annotation,
              diagnosis,
              annotation,
              null
            ),
          });
          uid = uid - 1;
        });

        answerList.sort((a, b) => {
          if (a.data.image_index < b.data.image_index) return -1;
          if (a.data.image_index > b.data.image_index) return 1;

          if (a.data.diagnosis_uid < b.data.diagnosis_uid) return -1;
          if (a.data.diagnosis_uid > b.data.diagnosis_uid) return 1;

          return 0;
        });

        setAnswerDataList(answerList);

        await Promise.all(
          sections.map(async (x) => {
            let question = await CheckExamTemplateQuestionExists(
              x.uid,
              caseData.id
            );

            const exist = question ? true : false;

            switch (x.questions_type) {
              case ExamTemplateQuestionsType.Detection:
                setDetectionQuestion(question);
                setSwitchDetection(exist);
                setDetectionSectionUid(x.uid);
                break;
              case ExamTemplateQuestionsType.Identification:
                answerList.forEach((a) => {
                  const qAnswer = question?.exam_template_answers.find(
                    (y) =>
                      a.data.exam_template_answer_annotation?.case_image_uid ===
                      y.exam_template_answer_annotation?.case_image_uid
                  );
                  if (qAnswer) a.isChecked = true;
                });
                setIdentificationQuestion(question);
                setSwitchIdentification(exist);
                setIdentificationSectionUid(x.uid);
                break;
            }
          })
        );

        setCaseData(caseData);
      } catch (error) {
        console.error(error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const downloadDicomFile = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    console.log("Download dicom file");

    try {
      if (!caseData) throw new Error("Case data is empty");

      setIsLoading(true);
      const result = await GetDicomFileUrl(caseData.id);
      setIsLoading(false);

      const element = document.createElement("a");
      element.href = result;
      element.target = "_blank";
      element.click();
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(
        `DICOMファイルのダウンロードに失敗しました。`
      );
      setIsLoading(false);
    } finally {
      event.stopPropagation();
    }
  };

  const checkAnswer = (
    event: React.ChangeEvent<HTMLInputElement>,
    answerUid: number
  ) => {
    try {
      setAnswerDataList((list) =>
        list.map((x) =>
          x.data.uid === answerUid
            ? {
                isChecked: !x.isChecked,
                data: x.data,
              }
            : x
        )
      );
    } catch (error) {
      console.error(error);
    } finally {
      event.stopPropagation();
    }
  };

  const openVideoDialog = async (questionUid: number) => {
    if (!videoDialogRef.current) return;
    await videoDialogRef.current.openDialog(
      VideoDialogKeyType.ExamTemplateQuestionUid,
      questionUid,
      isEditable
    );
  };

  const deletedVideo = (data: ExamTemplateQuestionData) => {
    console.log("Deleted video");
    try {
      if (detectionQuestion && detectionQuestion.uid === data.uid) {
        setDetectionQuestion(data);
      } else if (
        identificationQuestion &&
        identificationQuestion.uid === data.uid
      ) {
        setIdentificationQuestion(data);
      }
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`AI解析動画削除のセットに失敗しました。`);
    }
  };

  const deleteAiVideoFile = (
    event: React.MouseEvent<HTMLButtonElement>,
    category: string
  ) => {
    console.log("Delete ai video file");
    try {
      switch (category) {
        case "detection":
          setDetectionAiVideoFile(null);
          break;
        case "identification":
          setIdentificationAiVideoFile(null);
          break;
      }
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`AI解析動画の削除に失敗しました。`);
    }
  };

  const confirmQuestions = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    console.log("Add questions");
    try {
      setIsLoading(true);

      if (!templateUid) throw new Error("Template uid is empty");
      if (!caseData) throw new Error("Case data is empty");
      if (
        !switchDetection &&
        !detectionQuestion &&
        !switchIdentification &&
        !identificationQuestion
      )
        throw new Error("Confirm is disabled");
      if (
        switchIdentification &&
        answerDataList.filter((x) => x.isChecked).length < 1
      )
        throw new Error("All answers are not confirmed");

      if (switchDetection) {
        const reqBody = new ExamTemplateQuestionCreate(
          detectionSectionUid,
          caseData.id,
          "",
          1,
          caseData.image_count
        );

        await AddExamTemplateDetectionQuestion(reqBody, detectionAiVideoFile);
      } else {
        if (detectionQuestion) {
          await DeleteExamTemplateQuestion(
            detectionQuestion.uid,
            detectionSectionUid
          );
        }
      }

      if (switchIdentification) {
        const reqBody = new ExamTemplateQuestionCreate(
          identificationSectionUid,
          caseData.id,
          "",
          1,
          caseData.image_count
        );

        const caseImageIdList: number[] = [];
        answerDataList.forEach((x) => {
          if (x.isChecked && x.data.exam_template_answer_annotation !== null) {
            caseImageIdList.push(
              x.data.exam_template_answer_annotation.case_image_uid
            );
          }
        });

        await AddExamTemplateIdentificationQuestion(
          caseImageIdList,
          reqBody,
          identificationAiVideoFile
        );
      } else {
        if (identificationQuestion) {
          await DeleteExamTemplateQuestion(
            identificationQuestion.uid,
            identificationSectionUid
          );
        }
      }

      if (switchDetection || switchIdentification) {
        await CopyExamTemplateCaseFiles(caseData.id);
      }

      navigate(-1);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`問題作成に失敗しました。`);
    } finally {
      setIsLoading(false);
    }
  };

  const selectAnswerRow = (answerUid: number) => {
    console.log(`Select answer -> answerUid: ${answerUid}`);
    try {
      if (!viewerDisplayRef.current)
        throw new Error("Image display ref does not exist");

      const answerData = answerDataList.find((a) => a.data.uid === answerUid);
      if (!answerData)
        throw new Error(
          `Could not find the answerData => answerUid: ${answerUid}`
        );

      const annotationData = answerData.data.exam_template_answer_annotation;
      if (!annotationData)
        throw new Error(
          `Could not find the annotationData => answerUid: ${answerUid}`
        );

      viewerDisplayRef.current.showAnnotationImage(
        annotationData.case_image_uid
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid container className="ExamTemplateViewer-root-grid">
      {!isError ? (
        <>
          <Grid item xs={9}>
            {caseData !== null && (
              <ViewerDisplay
                ref={viewerDisplayRef}
                caseData={caseData}
                enableDrawing={false}
                enableShowDrawingData={false}
              />
            )}
          </Grid>
          <Grid item xs={3} className="ExamTemplateViewer-panel-grid">
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              className="ExamTemplateViewer-list-grid"
            >
              <Grid
                container
                item
                xs={12}
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                style={{ padding: 5 }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <CommonHtmlTooltip
                    placement="left"
                    title={
                      <Fragment>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Grid container item xs={3}>
                            <Typography color="inherit">{`症例番号`}</Typography>
                          </Grid>
                          <Grid container item xs={9}>
                            <Typography color="inherit">{`:${
                              caseData ? caseData.id : "???"
                            }`}</Typography>
                          </Grid>
                          <Grid container item xs={3}>
                            <Typography color="inherit">{`施設`}</Typography>
                          </Grid>
                          <Grid container item xs={9}>
                            <Typography color="inherit">{`:${
                              caseData ? caseData.facility_name : "???"
                            }`}</Typography>
                          </Grid>
                          <Grid container item xs={3}>
                            <Typography color="inherit">{`ベンダー`}</Typography>
                          </Grid>
                          <Grid container item xs={9}>
                            <Typography color="inherit">{`:${
                              caseData ? caseData.vendor_name : "???"
                            }`}</Typography>
                          </Grid>
                          <Grid container item xs={3}>
                            <Typography color="inherit">{`モデル名`}</Typography>
                          </Grid>
                          <Grid container item xs={9}>
                            <Typography color="inherit">{`:${
                              caseData ? caseData.model_name : "???"
                            }`}</Typography>
                          </Grid>
                        </Grid>
                      </Fragment>
                    }
                  >
                    <IconButton edge="end" size="small" color="primary">
                      <Info />
                    </IconButton>
                  </CommonHtmlTooltip>
                  <Button
                    onClick={(event) => downloadDicomFile(event)}
                    disabled={!isEditable || isLoading}
                    fullWidth
                    variant="outlined"
                    endIcon={<Download />}
                  >
                    DICOM
                  </Button>
                  <IconButton
                    onClick={(evt) => navigate(-1)}
                    edge="end"
                    size="small"
                    color="primary"
                  >
                    <KeyboardReturn />
                  </IconButton>
                </Stack>
                <Paper className="ExamTemplateViewer-category-paper">
                  <Stack spacing={1} direction="column" alignItems="left">
                    <FormGroup style={{ paddingLeft: 5 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={switchDetection}
                            onChange={(event) =>
                              setSwitchDetection(switchDetection ? false : true)
                            }
                            disabled={!isEditable}
                            size="small"
                          />
                        }
                        label="検出問題"
                      />
                    </FormGroup>
                    <div
                      {...getDetectionRootProps()}
                      className="ExamTemplateViewer-dropzone"
                    >
                      <input {...getDetectionInputProps()} />
                      {isDetectionDragActive ? (
                        <p>ファイルをドロップしてください…</p>
                      ) : (
                        <p>AI解析動画(検出)</p>
                      )}
                    </div>
                    {detectionAiVideoFile ? (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                      >
                        <div className="ExamTemplateViewer-aivideo-title">
                          {detectionAiVideoFile.name}
                        </div>
                        <IconButton
                          onClick={(event) =>
                            deleteAiVideoFile(event, "detection")
                          }
                          edge="end"
                          size="small"
                          color="error"
                        >
                          <Delete />
                        </IconButton>
                      </Stack>
                    ) : (
                      <Button
                        onClick={async (event) =>
                          await openVideoDialog(
                            detectionQuestion ? detectionQuestion.uid : 0
                          )
                        }
                        disabled={
                          detectionQuestion === null ||
                          !detectionQuestion.ai_video_exists
                        }
                        fullWidth
                      >
                        動画を表示する
                      </Button>
                    )}
                  </Stack>
                </Paper>
                <Paper className="ExamTemplateViewer-category-paper">
                  <Stack spacing={1} direction="column" alignItems="left">
                    <FormGroup style={{ paddingLeft: 5 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={switchIdentification}
                            onChange={(event) =>
                              setSwitchIdentification(
                                switchIdentification ? false : true
                              )
                            }
                            disabled={!isEditable || answerDataList.length < 1}
                            size="small"
                          />
                        }
                        label="鑑別問題"
                      />
                    </FormGroup>
                    <div
                      {...getIdentificationRootProps()}
                      className="ExamTemplateViewer-dropzone"
                    >
                      <input {...getIdentificationInputProps()} />
                      {isIdentificationDragActive ? (
                        <p>ファイルをドロップしてください…</p>
                      ) : (
                        <p>AI解析動画(鑑別)</p>
                      )}
                    </div>
                    {identificationAiVideoFile ? (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                      >
                        <div className="ExamTemplateViewer-aivideo-title">
                          {identificationAiVideoFile.name}
                        </div>
                        <IconButton
                          onClick={(event) =>
                            deleteAiVideoFile(event, "identification")
                          }
                          edge="end"
                          size="small"
                          color="error"
                        >
                          <Delete />
                        </IconButton>
                      </Stack>
                    ) : (
                      <Button
                        onClick={async (event) =>
                          await openVideoDialog(
                            identificationQuestion
                              ? identificationQuestion.uid
                              : 0
                          )
                        }
                        disabled={
                          identificationQuestion === null ||
                          !identificationQuestion.ai_video_exists
                        }
                        fullWidth
                      >
                        動画を表示する
                      </Button>
                    )}
                  </Stack>
                </Paper>
                <TableContainer
                  component={Paper}
                  className="ExamTemplateViewer-exam-table"
                >
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox"></TableCell>
                        <TableCell style={{ width: "100%", minWidth: 100 }}>
                          病名
                        </TableCell>
                        <TableCell style={{ minWidth: 30 }}>枚目</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {answerDataList.map((answer) => (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={answer.data.uid}
                          onClick={(event) => selectAnswerRow(answer.data.uid)}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={answer.isChecked}
                              onChange={(event) =>
                                checkAnswer(event, answer.data.uid)
                              }
                              disabled={!isEditable || !switchIdentification}
                              size="small"
                            />
                          </TableCell>
                          <TableCell
                            style={{ maxWidth: "100px", minWidth: 100 }}
                          >
                            {answer.data.diagnosis.display}
                          </TableCell>
                          <TableCell align="right">
                            {answer.data.image_index}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button
                  onClick={async (event) => await confirmQuestions(event)}
                  variant="contained"
                  fullWidth
                  disabled={
                    !isEditable ||
                    (!detectionQuestion &&
                      !switchDetection &&
                      !identificationQuestion &&
                      !switchIdentification) ||
                    (switchIdentification &&
                      answerDataList.filter((x) => x.isChecked).length < 1)
                  }
                >
                  {isEditable ? "完了する" : "編集不可"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <VideoDialog ref={videoDialogRef} deletedVideo={deletedVideo} />
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      ) : (
        <ViewerErrorCompornent />
      )}
      <CommonAlert ref={commonAlertRef} />
    </Grid>
  );
};

export default ExamTemplateViewer;
