import { Error } from "@mui/icons-material";
import { Grid, Paper, Typography } from "@mui/material";
import { FC } from "react";


const ViewerErrorCompornent: FC = () => {

  return (
    <Grid 
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{height: '100vh', width: '100%', backgroundColor: '#263238'}}
    >
      <Paper elevation={3} style={{width: '30%'}}>
        <Typography
          variant="h6" 
          gutterBottom
          style={{padding: '5px 10px 0 10px', backgroundColor: '#757575', color: 'whitesmoke'}}
        >
          <Error 
            fontSize="small"
            color="error"
            style={{margin: '0 5px 0 0'}}
          />
          Error
        </Typography>
        <Typography
          variant="body1" 
          gutterBottom
          style={{padding: '0 10px 0 10px'}}
        >
          {'エラーが発生しました。'}
        </Typography>
      </Paper>
    </Grid>
  )
};

export default ViewerErrorCompornent;