import { CheckBox } from "@mui/icons-material";
import { Backdrop, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Stack } from "@mui/system";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { ExamTemplateVideoUploaderDialogProps, ExamTemplateVideoUploaderDialogRefs } from "../../interfaces/examTemplate/examTemplateVideoUploaderDialog";
import { ExamTemplateVideoUploaderData, ExamTemplateVideoUploaderStatus, ExamTemplateVideoUploaderStatusDisplay } from "../../objects/examTemplate";
import { UploadExamStudentCsv } from "../../repositories/examStudentRepo";
import { UploadExamTemplateQuestionAiVideo } from "../../repositories/examTemplateQuestionRepo";
import CommonAlert from "../commonAlert";
import './examTemplateVideoUploaderDialog.css'


const ExamTemplateVideoUploaderDialog = forwardRef<ExamTemplateVideoUploaderDialogRefs, ExamTemplateVideoUploaderDialogProps>((props, ref) => {
  const { closedDialog } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [examTemplateSectionUid, setExamTemplateSectionUid] = useState<number | null>(null);
  const [title, setTitle] = useState('');
  const [overWrite, setOverWrite] = useState(false);
  const [videoUploaderList, setVideoUploaderList] = useState<ExamTemplateVideoUploaderData[]>([]);
  const [videoFileList, setVideoFileList] = useState<File[]>([]);
  const [progressValue, setProgressValue] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const cancelUploading = useRef(false);

  const commonAlertRef = useRef<CommonAlertRefs>(null);
  
  const { 
    getRootProps: getUploaderCsvRootProps, 
    getInputProps: getUploaderCsvInputProps, 
    isDragActive: isUploaderCsvDragActive 
  } = useDropzone({ 
    onDrop: async (acceptedFile: File[]) => {
      const csvFile = acceptedFile[0];

      const reader = new FileReader();

      reader.onload = function (e) {
        try {
          if (!e.target) throw new Error('Reader target is empty');

          setIsLoading(true);

          const csv = e.target.result as string;
  
          const csvArray = csv.split(/\r\n|\n/).map((row) => row.split(','));

          const csvHeader = csvArray.shift();
          if (!csvHeader) throw new Error('Could not get the header');
          
          const questionUidIndex = csvHeader.findIndex((x) => x === 'Q ID');
          const caseUidIndex = csvHeader.findIndex((x) => x === 'Case ID');
          const fileNameIndex = csvHeader.findIndex((x) => x === 'File name');
          if (questionUidIndex < 0 || caseUidIndex < 0 || fileNameIndex < 0) {
            commonAlertRef.current?.error('ヘッダーが不正です。');
            return;
          };

          let noError = true;
          const result: ExamTemplateVideoUploaderData[] = [];

          csvArray.forEach((x, index) => {
            if (x.length < 3) return;

            const templateQuestionUid = parseInt(x[questionUidIndex]);
            const caseUid = parseInt(x[caseUidIndex]);
            const fileName = x[fileNameIndex];

            if (isNaN(templateQuestionUid) || isNaN(caseUid) || !fileName) {
              commonAlertRef.current?.error(`${index + 1}行目で不正を検知しました。`);
              noError = false;
            };

            result.push(new ExamTemplateVideoUploaderData(
              templateQuestionUid,
              caseUid,
              fileName,
              ExamTemplateVideoUploaderStatus.Ready,
              ''
            ))
          });

          if (noError) setVideoUploaderList(result);
        } catch (error) {
          console.error(error);
          commonAlertRef.current?.error('CSV読み込みに失敗しました。');
          setVideoUploaderList([]);
        } finally {
          setIsLoading(false);
        };
      };
    
      reader.readAsText(csvFile);
    }, 
    accept: {
      "text/csv": []
    },
    disabled: isUploading || isLoading,
    multiple: false 
  });

  const { 
    getRootProps: getVideoListRootProps, 
    getInputProps: getVideoListInputProps, 
    isDragActive: isVideoListDragActive 
  } = useDropzone({ 
    onDrop: async (acceptedFile: File[]) => {
      setVideoFileList(acceptedFile);
    }, 
    accept: {
      "video/*": []
    },
    disabled: isUploading || isLoading || videoUploaderList.length < 1, 
    multiple: true
  });

  useImperativeHandle (ref, () => ({
    async openDialog (examTemplateSectionUid: number): Promise<void> {
      setExamTemplateSectionUid(examTemplateSectionUid);
      setTitle(`AI解析動画 アップロード`);
      
      setOpenDialog(true);
    }
  }));

  useEffect(() => {
    if (!isUploading) return;
    else {
      const finishedCount = videoUploaderList.filter((data) => data.status === ExamTemplateVideoUploaderStatus.Done || data.status === ExamTemplateVideoUploaderStatus.Error).length;
      if (videoUploaderList.length <= finishedCount) setProgressValue(100);
      else setProgressValue(finishedCount / videoUploaderList.length * 100);
    };
  }, [videoUploaderList]);

  useEffect(() => {
    if (videoFileList.length < 1) {
      return;
    };
    let keep = true;
    cancelUploading.current = false;
    const doneStatus: ExamTemplateVideoUploaderStatus[]  = [
      ExamTemplateVideoUploaderStatus.Done,
      ExamTemplateVideoUploaderStatus.Progressing
    ];

    if (!examTemplateSectionUid) {
      commonAlertRef.current?.error('S IDが空です。');
      return;
    };
    if (videoUploaderList.filter((x) => !doneStatus.includes(x.status)).length < 1) {
      commonAlertRef.current?.error('アップロード可能なレコードがありません。');
      return;
    };

    (async () => {
      setIsUploading(true);

      setVideoUploaderList((prev) => prev.map((value) => 
        !doneStatus.includes(value.status)
        ? new ExamTemplateVideoUploaderData(
          value.templateQuestionUid,
          value.caseUid,
          value.fileName,
          ExamTemplateVideoUploaderStatus.Ready,
          ''
        )
        : value
      ));

      commonAlertRef.current?.info('アップロードを開始しました。');

      for (const x of videoUploaderList) {
        if (!keep) break;
        if (cancelUploading.current) {
          setVideoUploaderList((prev) => prev.map((value) => 
            value.templateQuestionUid === x.templateQuestionUid
            ? new ExamTemplateVideoUploaderData(
              value.templateQuestionUid,
              value.caseUid,
              value.fileName,
              ExamTemplateVideoUploaderStatus.Canceled,
              ''
            )
            : value
          ));
          continue;
        }
        
        if (doneStatus.includes(x.status)) continue;

        try {
          setVideoUploaderList((prev) => prev.map((value) => 
            value.templateQuestionUid === x.templateQuestionUid
            ? new ExamTemplateVideoUploaderData(
              value.templateQuestionUid,
              value.caseUid,
              value.fileName,
              ExamTemplateVideoUploaderStatus.Progressing,
              ''
            )
            : value
          ));

          const videoUploaderData = videoFileList.find((file) => file.name === x.fileName);

          if (!videoUploaderData) {
            setVideoUploaderList((prev) => prev.map((value) => 
              value.templateQuestionUid === x.templateQuestionUid
              ? new ExamTemplateVideoUploaderData(
                value.templateQuestionUid,
                value.caseUid,
                value.fileName,
                ExamTemplateVideoUploaderStatus.Error,
                'Could not find file'
              )
              : value
            ));

            continue;
          };

          const result = await UploadExamTemplateQuestionAiVideo(x.templateQuestionUid, overWrite, examTemplateSectionUid, videoUploaderData);

          setVideoUploaderList((prev) => prev.map((value) => 
            value.templateQuestionUid === x.templateQuestionUid
            ? new ExamTemplateVideoUploaderData(
              value.templateQuestionUid,
              value.caseUid,
              value.fileName,
              result.result ? ExamTemplateVideoUploaderStatus.Done : ExamTemplateVideoUploaderStatus.Error,
              result.reason
            )
            : value
          ));
          
        } catch (error) {
          console.error(error);
          setVideoUploaderList((prev) => prev.map((value) => 
            value.templateQuestionUid === x.templateQuestionUid
            ? new ExamTemplateVideoUploaderData(
              value.templateQuestionUid,
              value.caseUid,
              value.fileName,
              ExamTemplateVideoUploaderStatus.Error,
              'Unkown error'
            )
            : value
          ));
        };
      };

      setIsUploading(false);
      commonAlertRef.current?.info('アップロード処理が終了しました。');
    })()

    return () => {
      keep = false;
    };
  }, [videoFileList]);

  useEffect(() => {
    if (!openDialog) {
      setOpenConfirmDialog(false);
      setExamTemplateSectionUid(null);
      setTitle('');
      setOverWrite(false);
      setIsUploading(false);
      setVideoUploaderList([]);
      setVideoFileList([]);
      if (closedDialog) closedDialog();
    };
  }, [openDialog]);

  const handleChipColor = (status: ExamTemplateVideoUploaderStatus) => {
    switch (status) {
      case ExamTemplateVideoUploaderStatus.Ready:
        return 'default';
      case ExamTemplateVideoUploaderStatus.Progressing:
        return 'primary';
      case ExamTemplateVideoUploaderStatus.Done:
        return 'primary';
      case ExamTemplateVideoUploaderStatus.Error:
        return 'error';
      default:
        return 'default';
    };
  };

  const handleChipVariant = (status: ExamTemplateVideoUploaderStatus) => {
    switch (status) {
      case ExamTemplateVideoUploaderStatus.Done:
        return 'filled';
      case ExamTemplateVideoUploaderStatus.Canceled:
        return 'filled';
      case ExamTemplateVideoUploaderStatus.Error:
        return 'filled';
      default:
        return 'outlined';
    };
  };

  const handleMainDialog = () => {
    if (!isUploading) setOpenDialog(false);
    else setOpenConfirmDialog(true);
  };

  const suspendUploading = () => {
    cancelUploading.current = true;
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='lg'
        open={openDialog} 
        onClose={() => handleMainDialog()}
      >
        <DialogTitle>{`${title} (S ID: ${examTemplateSectionUid})`}</DialogTitle>
        <DialogContent>
          <Grid 
            container 
            direction="row" 
            justifyContent="center" 
            alignItems="center"
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={1}
              style={{width: '100%', marginBottom: '5px'}}
            >
              <div></div>
              <div {...getUploaderCsvRootProps()} className='ExamTemplateVideoUploaderDialog-csv-dropzone'>
                <input {...getUploaderCsvInputProps()} />
                {
                  isUploaderCsvDragActive ?
                    <p className=".ExamTemplateVideoUploaderDialog-csv-dropzone-text">ファイルをドロップしてください…</p> :
                    <p className=".ExamTemplateVideoUploaderDialog-csv-dropzone-text">CSV読み込み</p>
                }
              </div>
              <FormControlLabel 
                control={
                  <Checkbox 
                    checked={overWrite}
                    onChange={(evt) => setOverWrite(!overWrite)} 
                    disabled={isLoading || isUploading}
                    size="small" 
                  /> 
                } 
                label="上書きする" 
              />
            </Stack>
            <TableContainer component={Paper} className='ExamTemplateVideoUploaderDialog-csv-table'>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: 100 }}>Q ID</TableCell>
                    <TableCell style={{ minWidth: 100 }}>症例番号</TableCell>
                    <TableCell style={{ width: '100%', minWidth: 300 }}>ファイル名</TableCell>
                    <TableCell style={{ minWidth: 100 }}>ステータス</TableCell>
                    <TableCell style={{ minWidth: 300 }} padding='none'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {videoUploaderList.map((x, index) => (
                    <TableRow key={index}>
                      <TableCell>{x.templateQuestionUid}</TableCell>
                      <TableCell>{x.caseUid}</TableCell>
                      <TableCell>{x.fileName}</TableCell>
                      <TableCell>
                        <Chip 
                          label={ExamTemplateVideoUploaderStatusDisplay(x.status)} 
                          color={handleChipColor(x.status)}
                          variant={handleChipVariant(x.status)}
                          size='small' 
                        />
                      </TableCell>
                      <TableCell padding='none'>{x.result}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              style={{width: '100%', height: '80px'}}
            >
              {!isUploading
                ? <div {...getVideoListRootProps()} className='ExamTemplateVideoUploaderDialog-videos-dropzone'>
                    <input {...getVideoListInputProps()} />
                    {
                      videoUploaderList.length < 1
                        ? <p className="ExamTemplateVideoUploaderDialog-videos-dropzone-text">CSVを読み込んでください…</p>
                        : <>
                            {
                              isVideoListDragActive 
                                ? <p className="ExamTemplateVideoUploaderDialog-videos-dropzone-text">ファイルをドロップしてください、アップロードが開始されます…</p> 
                                : <p className="ExamTemplateVideoUploaderDialog-videos-dropzone-text">動画ファイルを選択してアップロード開始</p>
                            }
                          </>
                    }
                  </div>
                : <>
                    <LinearProgress variant="determinate" value={progressValue} style={{width: '90%'}} />
                    <Button
                      onClick={(evt) => setOpenConfirmDialog(true)}
                      color='error' variant="outlined"
                    >
                      中断する
                    </Button>
                  </>
              }
            </Stack>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
        <DialogTitle>
          {"アップロードを中止してよろしいですか?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{whiteSpace: 'pre-wrap'}}>
            {
              'アップロードを中断します。'
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button onClick={(event) => setOpenConfirmDialog(false)} variant='outlined'>いいえ</Button>
            <Button onClick={(event) => suspendUploading()} variant="contained" color='error'>はい</Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <CommonAlert
        ref={commonAlertRef}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
});

export default ExamTemplateVideoUploaderDialog;