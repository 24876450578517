import { Delete } from "@mui/icons-material";
import { Checkbox, FormControlLabel, FormGroup, IconButton, Stack, TextField } from "@mui/material";
import { forwardRef } from "react";
import { ExamStudentTagFormatData } from "../../objects/exam";

export interface ExamStudentTagFormatRowRefs {
};

export type ExamStudentTagFormatRowProps = {
  index: number;
  row: ExamStudentTagFormatData;
  edit: (index: number, newData: ExamStudentTagFormatData) => void;
  deleteRow: (index: number) => void;
};

export const ExamStudentTagFormatRow = forwardRef<ExamStudentTagFormatRowRefs, ExamStudentTagFormatRowProps>((props, refs) => {
  const { index, row, edit, deleteRow } = props;

  const handleEditName = (newValue: string | null) => {
    const value = newValue !== null ? newValue : "";
    edit(index, new ExamStudentTagFormatData(value, row.display, row.output));
  };

  const handleEditDisplay = (newValue: string | null) => {
    const value = newValue !== null ? newValue : "";
    edit(index, new ExamStudentTagFormatData(row.name, value, row.output));
  };

  const handleChangeOutput = () => {
    edit(index, new ExamStudentTagFormatData(row.name, row.display, !row.output));
  };

  const handleClickDeleteButton = () => {
    deleteRow(index);
  };

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <TextField 
        value={row.name} 
        onChange={(event) => handleEditName(event.target.value)} 
        label="タイトル" 
        variant="outlined" 
        size="small"
        fullWidth
      />
      <TextField 
        value={row.display} 
        onChange={(event) => handleEditDisplay(event.target.value)} 
        label="表示名" 
        variant="outlined" 
        size="small"
        fullWidth
      />
      <FormGroup>
        <FormControlLabel 
          control={
            <Checkbox 
              checked={row.output}
              onChange={() => handleChangeOutput()}
            />
          } 
          label="出力対象"
          style={{width: "150px"}} 
        />
      </FormGroup>
      <IconButton
        onClick={handleClickDeleteButton}
        size="small"
        color="error"
      >
        <Delete />
      </IconButton>
    </Stack>
  )
});