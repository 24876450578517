import { ExamTemplateSectionAiVideoCountData, ExamTemplateSectionData, ExamTemplateSectionDiagnosisCountData } from "../objects/examTemplate";
import { axiosClient } from "../contexts/axiosClientProvider";


export const AddExamTemplateSection = async (sectionData: ExamTemplateSectionData): Promise<ExamTemplateSectionData> => {
  const response = await axiosClient.post<ExamTemplateSectionData>(`exams/templates/sections`, sectionData);
  return response.data;
};

export const GetExamTemplateSectionList = async (templateUid: number): Promise<ExamTemplateSectionData[]> => {
  const response = await axiosClient.get<ExamTemplateSectionData[]>(`exams/templates/sections?template_uid=${templateUid}`);
  return response.data;
};

export const GetExamTemplateSection = async (sectionUid: number): Promise<ExamTemplateSectionData> => {
  const response = await axiosClient.get<ExamTemplateSectionData>(`exams/templates/sections/${sectionUid}`);
  return response.data;
};

export const GetExamTemplateSectionTotalQuestionCount = async (sectionUid: number): Promise<number> => {
  const response = await axiosClient.get<number>(`exams/templates/sections/${sectionUid}/question`);
  return response.data;
};

export const GetExamTemplateSectionDiagnosisCount = async (sectionUid: number): Promise<ExamTemplateSectionDiagnosisCountData[]> => {
  const response = await axiosClient.get<ExamTemplateSectionDiagnosisCountData[]>(`exams/templates/sections/${sectionUid}/diagnosis`);
  return response.data;
};

export const GetExamTemplateSectionAiVideoCount = async (sectionUid: number): Promise<ExamTemplateSectionAiVideoCountData> => {
  const response = await axiosClient.get<ExamTemplateSectionAiVideoCountData>(`exams/templates/sections/${sectionUid}/video`);
  return response.data;
};

export const UpdateExamTemplateSection = async (sectionUid: number, sectionData: ExamTemplateSectionData): Promise<ExamTemplateSectionData> => {
  const response = await axiosClient.put<ExamTemplateSectionData>(`exams/templates/sections/${sectionUid}`, sectionData);
  return response.data;
};

export const DeleteExamTemplateSection = async (sectionUid: number): Promise<void> => {
  await axiosClient.delete(`exams/templates/sections/${sectionUid}`);
};