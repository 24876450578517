import { ExamTemplateQuestionData } from "../../objects/examTemplate";

export interface VideoDialogRefs {
  openDialog: (type: VideoDialogKeyType, questionUid: number, isEditable: boolean) => Promise<void>;
};

export type VideoDialogProps = {
  errorDetected?: (message: string) => void;
  deletedVideo?: (questionData: ExamTemplateQuestionData) => void;
  closeDialog?: () => void;
};

export const VideoDialogKeyType = {
  ExamTemplateQuestionUid: 'exam-template-question-uid',
  ExamQuestionUid: 'exam-question-uid'
} as const;

export type VideoDialogKeyType = typeof VideoDialogKeyType[keyof typeof VideoDialogKeyType];