import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { UpdateExamTemplateCaseDetectionManualCount } from "../../repositories/ExamTemplateCasesRepo";
import CommonAlert from "../commonAlert";

export interface ChangeManualAnnotationCountButtonRefs {
};

export type ChangeManualAnnotationCountButtonProps = {
  caseId: number;
  manualCount: number | null;
  finishedChange: (caseId: number, value: number | null) => void;
};

export const ChangeManualAnnotationCountButton = forwardRef<ChangeManualAnnotationCountButtonRefs, ChangeManualAnnotationCountButtonProps>((props, refs) => {
  const { caseId, manualCount, finishedChange } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [newCount, setNewCount] = useState<null | number>(null);
  const [isLoading, setIsLoading] = useState(false);

  const commonAlertRef = useRef<CommonAlertRefs>(null);

  useEffect(() => {
    if (openDialog) {
      setNewCount(manualCount);
    };
  }, [openDialog]);

  const handleClickOpenDialogButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpenDialog(true);
  };

  const handleSetNewCount = (newValue: string) => {
    if (!newValue) {
      setNewCount(null);
      return;
    };

    const newValueNumber = Number(newValue);
    if (isNaN(newValueNumber)) {
      return;
    };

    if (newValueNumber < 0) {
      setNewCount(0);
      return;
    };
    if (100 <= newValueNumber) {
      setNewCount(100);
      return;
    }

    setNewCount(newValueNumber);
  };

  const handleUpdateCountValue = async () => {
    try {
      setIsLoading(true);
      await UpdateExamTemplateCaseDetectionManualCount(caseId, newCount);

      finishedChange(caseId, newCount);
      setOpenDialog(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    };
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Button 
        onClick={(evt) => handleClickOpenDialogButton(evt)}
        variant='outlined'
        color={manualCount !== null ? "warning" : "primary"}
      >
        {`ラベル数手動設定 : ${manualCount !== null ? manualCount : "-"}`}
      </Button>
      <Dialog
        maxWidth='md'
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>{`ラベル数手動設定`}</DialogTitle>
        <DialogContent style={{padding: 10}}>
          <DialogContentText>
            症例のラベル数を手動設定します。
            <br></br>
            設定されたラベル数で評価情報が計算されます。
            <br></br>
            値を入れずに設定すると、症例のラベル数で計算するデフォルトの状態に戻ります。
            <br></br>
            設定値はシステムに保存され、本症例が使用されている全試験の結果に影響します。
          </DialogContentText>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={1}
            style={{margin: "10px 0"}}
          >
            <Typography>症例番号 : {`${caseId}`}</Typography>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={4}
            >
              <Typography>現在の設定値 : {manualCount !== null ? manualCount : "-"}</Typography>
              <TextField 
                value={newCount !== null ? newCount : ""}
                onChange={(evt) => handleSetNewCount(evt.target.value)}
                InputProps={{
                  inputMode: 'numeric',
                  inputProps: { min: 0, max: 100 }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                type="number"
                label="新しい設定値"
                size="small"
                style={{width: "150px"}}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            width="100%"
          >
            <Button 
              onClick={handleCloseDialog}
              disabled={isLoading}
              variant='outlined'
            >
              {'閉じる'}
            </Button>
            <Button 
              onClick={(evt) => handleUpdateCountValue()}
              disabled={isLoading}
              variant='contained'
            >
              {'設定する'}
            </Button>
          </Stack>
        </DialogActions>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      <CommonAlert
        ref={commonAlertRef}
      />
    </>
  )
});