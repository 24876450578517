import { Monitor } from "@mui/icons-material";
import { IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { CaseDataRowProps, CaseDataRowRefs } from "../interfaces/caseDataRowInterface";
import { CaseImageData } from "../objects/case";
import './caseDataRow.css';


const CaseDataRow = forwardRef<CaseDataRowRefs, CaseDataRowProps>((props, ref) => {
  const navigate = useNavigate();

  const { row, templateUid } = props;

  const handleDiagnosisList = (data: CaseImageData[]): string [] => {
    const result: string[] = [];

    data.forEach((x) => {
      if (!result.includes(x.diagnosis)) result.push(x.diagnosis);
    });

    return result;
  };

  const openViewer = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('Open viewer');
    try {
      navigate(`/exams/templates/viewer/${templateUid}/${row.id}`);
    } catch (error) {
      console.error(error);
    } finally {
      event.stopPropagation();
    };
  };

  return (
    <TableRow hover tabIndex={-1} key={row.id}>
      <TableCell>{ row.id }</TableCell>
      <TableCell>
        <div className='CaseDataRow-facility-cell'>
          { row.facility_name }
        </div>
      </TableCell>
      <TableCell>
        <div className='CaseDataRow-vendor-cell'>
          { row.vendor_name }
        </div>
      </TableCell>
      <TableCell>
        <div className='CaseDataRow-vendor-cell'>
          { row.model_name }
        </div>
      </TableCell>
      <TableCell>
        { row.case_image_list && handleDiagnosisList(row.case_image_list).map((x, index) => (
          <Typography key={index}>{ x }</Typography>
        ))}
      </TableCell>
      <TableCell align="right">{ row.image_count }</TableCell>
      <TableCell align="right">
        <IconButton 
          onClick={(event) => openViewer(event)} 
          size="small" color="primary"
        >
          <Monitor />
        </IconButton>
      </TableCell>
    </TableRow>
  )
});

export default CaseDataRow;