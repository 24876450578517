import { FC } from 'react';
import { useLocation, Navigate, Outlet, useNavigate } from 'react-router-dom';
import UseStudentAuth from '../hooks/useStudentAuth';


type RequireStudentAuthProps = {
};

const RequireStudentAuth: FC<RequireStudentAuthProps> = (props: RequireStudentAuthProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { studentAuth } = UseStudentAuth();

  return (
    studentAuth
      ? <Outlet/>
      : <Navigate to='/signin/exam' state={{ from: location }} replace />
  );
};

export default RequireStudentAuth;