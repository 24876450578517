import { Backdrop, Button, CircularProgress, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import './ExamSigninPage.css';
import { CommonAlertRefs } from "../interfaces/commonAlertInterface";
import UseStudentAuth from "../hooks/useStudentAuth";
import CommonAlert from "../compornents/commonAlert";
import { axiosPublicClient } from "../contexts/axiosClientProvider";
import UseImageAuth from "../hooks/useImageAuth";


const ExamSignInPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { studentAuth, setStudentAuth } = UseStudentAuth();
  const { setImageAuth } = UseImageAuth();

  const commonAlertRef = useRef<CommonAlertRefs>(null);

  const [examUid, setExamUid] = useState('');
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // initialize.
  useEffect( () => {
    setStudentAuth(null);
    setImageAuth(null);

    (async () => {
      const query = new URLSearchParams(location.search).get("examUid");
      if (query) setExamUid(query);
    })()
  }, []);

  useEffect( () => {
    if (studentAuth) navigate(`/students/${examUid}`);
  }, [studentAuth]);

  const signIn = async () => {
    try {
      setIsLoading(true);

      if (!examUid) {
        commonAlertRef.current?.error('試験番号を入力してください。');
        return;
      }
      if (!id) {
        commonAlertRef.current?.error('ユーザーIDを入力してください。');
        return;
      };
      if (!password) {
        commonAlertRef.current?.error('パスワードを入力してください。');
        return;
      };

      let formData = new FormData();

      formData.append('username', id);
      formData.append('password', password);

      await axiosPublicClient.post(`signin/students/${examUid}`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        }}).then((res) => {
          setStudentAuth(res.data.access_token);
          setImageAuth(res.data.image_token);
        }).catch((error) => {
          console.error(error);
          if (error.response.status === 401) {
            console.error('GET HTTP error 401');
          } else if (error.response.status === 404) {
            console.error('GET HTTP error 404');
          };
    
          throw new Error("HTTP POST failed.");
        }
      );
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('サインインに失敗しました。');
    } finally {
      setIsLoading(false);
    };
  };

  return (
    <>
      <Grid 
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="ExamSigninPage-root-grid"
      >
        <Paper elevation={3} className='ExamSigninPage-main-paper'>
          <Box className="ExamSigninPage-title-box">
            <Typography variant="h6" color={'InfoBackground'}>
              試験ログイン
            </Typography>
          </Box>
          <Grid 
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            className="ExamSigninPage-form-grid"
          >
            <TextField 
              value={examUid} 
              onChange={(event) => setExamUid(event.target.value)} 
              inputProps={{
                maxLength: 20,
              }}
              fullWidth 
              label="試験番号" 
              margin="dense"
              size="small"
              autoComplete='off'
            />
            <TextField 
              value={id} 
              onChange={(event) => setId(event.target.value)} 
              inputProps={{
                maxLength: 100,
              }}
              fullWidth 
              label="ユーザーID" 
              margin="dense"
              size="small"
              autoComplete='off'
            />
            <TextField 
              value={password} 
              onChange={(evt) => setPassword(evt.target.value)} 
              onKeyDown={async (evt) => {
                if (evt.key === 'Enter' && 0 < password.length) {
                  await signIn();
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
              fullWidth 
              label="パスワード" 
              margin="dense" 
              size="small" 
              type="password"
              autoComplete="current-password"
            />
            <Divider style={{margin: '10px 0'}}/>
            <Button 
              onClick={async (event) => await signIn()}
              fullWidth 
              variant="contained"
            >
              ログイン
            </Button>
          </Grid>
        </Paper>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
      <CommonAlert
        ref={commonAlertRef}
      />
    </>
  );
};

export default ExamSignInPage;