import { ExamTemplateQuestionCreate, ExamTemplateQuestionData } from "../objects/examTemplate";
import { axiosClient } from "../contexts/axiosClientProvider";


export const AddExamTemplateDetectionQuestion = async ( 
  data: ExamTemplateQuestionCreate, 
  file: File | null): Promise<ExamTemplateQuestionData> => {
    let formData = new FormData();

    const jsonData = JSON.stringify(data);
    formData.append('question', jsonData);
    if (file) formData.append('file', file);

    const url = `exams/templates/questions/detection`;

    const response = await axiosClient.post<ExamTemplateQuestionData>(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });

    return response.data;
};

export const AddExamTemplateIdentificationQuestion = async ( 
  caseImageIdList: number[],
  data: ExamTemplateQuestionCreate, 
  file: File | null): Promise<ExamTemplateQuestionData> => {
    let formData = new FormData();

    const jsonData = JSON.stringify(data);
    formData.append('question', jsonData);
    if (file) formData.append('file', file);

    let url = `exams/templates/questions/identification?${caseImageIdList.map((x) => `case_image_id_list=${x}`).join('&')}`;

    const response = await axiosClient.post<ExamTemplateQuestionData>(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });

    return response.data;
};


// export const AddExamTemplateQuestion = async ( 
//   caseUid: number, 
//   data: ExamTemplateQuestionCreate, 
//   file: File | null): Promise<ExamTemplateQuestionData> => {
//     let formData = new FormData();
//       const jsonData = JSON.stringify(data);
//       formData.append('question', jsonData);
//       if (file) formData.append('file', file);

//       const url = `exams/templates/questions?case_uid=${caseUid}`;

//     const response = await axiosClient.post<ExamTemplateQuestionData>(url, formData, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       }
//     });

//     return response.data;
// };

export const GetExamTemplateQuestionList = async (sectionUid: number, onlyNonAiVideo: boolean, diagnosisUidList: number[]): Promise<ExamTemplateQuestionData[]> => {
  let diagnosisQuery = '';
  diagnosisUidList.forEach((x) => {
    diagnosisQuery = diagnosisQuery + `&diagnosis_uid_list=${x}`
  });

  let url = `exams/templates/questions?section_uid=${sectionUid}&only_non_ai_video=${onlyNonAiVideo}${diagnosisQuery ? `&${diagnosisQuery}` : ''}`;

  const response = await axiosClient.get<ExamTemplateQuestionData[]>(url);
  return response.data;
};

export const CheckExamTemplateQuestionExists = async (sectionUid: number, caseUid: number): Promise<ExamTemplateQuestionData | null> => {
  const url = `exams/templates/questions/cases?section_uid=${sectionUid}&case_uid=${caseUid}`
  const response = await axiosClient.get<ExamTemplateQuestionData | null>(url);
  return response.data;
};

export const UploadExamTemplateQuestionAiVideo = async (uid: number, overWrite: boolean, sectionUid: number | null, file: File): Promise<{result: boolean, reason: string}> => {
  let formData = new FormData();

  formData.append('file', file);

  let url = `exams/templates/questions/${uid}/video?overwrite=${overWrite}`;

  if (sectionUid) url = url + `&section_uid=${sectionUid}`

  const response = await axiosClient.post<{result: boolean, reason: string}>(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });

  return response.data;
};

export const DeleteExamTemplateQuestionAiVideo = async (uid: number): Promise<ExamTemplateQuestionData> => {
  const response = await axiosClient.delete(`exams/templates/questions/${uid}/video`);
  return response.data;
};

export const DeleteExamTemplateQuestion = async (uid: number, sectionUid: number): Promise<void> => {
  await axiosClient.delete(`exams/templates/questions/${uid}?section_uid=${sectionUid}`);
};

export const DeleteCaseNonExistExamTemplateQuestions = async (examTemplateUid: number, templateQuestionUidList: number[]): Promise<void> => {
  if (templateQuestionUidList.length < 1) throw new Error("Template question uid list's length is lesser than 1");
  
  let url = `exams/templates/questions/case/nonexist/${examTemplateUid}`;

  const queryList: string[] = [];

  templateQuestionUidList.forEach((x) => {
    queryList.push(`template_question_uid_list=${x}`);
  });

  url = url + "?" + queryList.join("&");

  await axiosClient.delete(url);
};