import { Close } from "@mui/icons-material";
import { Alert, IconButton, Snackbar, Stack } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { CommonAlertProps, CommonAlertRefs } from "../interfaces/commonAlertInterface";
import './commonAlert.css';


const autoHiddenMilliSeconds = 3000;

const CommonAlert = forwardRef<CommonAlertRefs, CommonAlertProps>((props, ref) => {
  const [success, setSuccess] = useState(false);
  const [info, setInfo] = useState(false);
  const [warn, setWarn] = useState(false);
  const [error, setError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [warnMessage, setWarnMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useImperativeHandle (ref, () => ({
    success (message: string): void {
      setSuccess(true);
      setSuccessMessage(message);
    },
    info (message: string): void {
      setInfo(true);
      setInfoMessage(message);
    },
    warn (message: string): void {
      setWarn(true);
      setWarnMessage(message);
    },
    error (message: string): void {
      setError(true);
      setErrorMessage(message);
    }
  }));

  useEffect(() => {
    if (!success) setSuccessMessage('');
  }, [success]);

  useEffect(() => {
    if (!info) setInfoMessage('');
  }, [info]);

  useEffect(() => {
    if (!warn) setWarnMessage('');
  }, [warn]);

  useEffect(() => {
    if (!error) setErrorMessage('');
  }, [error]);

  const handleCloseSuccess = () => {
    setSuccess(false);
  };

  const handleCloseInfo = () => {
    setInfo(false);
  };

  const handleCloseWarn = () => {
    setWarn(false);
  };

  const handleCloseError = () => {
    setError(false);
  };

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Snackbar 
        open={success} 
        autoHideDuration={autoHiddenMilliSeconds} 
        onClose={handleCloseSuccess}
      >
        <Alert 
          severity="success" 
          action={
            <IconButton
              onClick={handleCloseSuccess}
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <Close />
            </IconButton>
          }
          style={{ whiteSpace: 'pre-wrap' }}
          >
          <strong>{ successMessage }</strong>
        </Alert>
      </Snackbar>
      <Snackbar 
        open={info} 
        autoHideDuration={autoHiddenMilliSeconds} 
        onClose={handleCloseInfo}
      >
        <Alert 
          severity="info" 
          action={
            <IconButton
              onClick={handleCloseInfo}
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <Close />
            </IconButton>
          }
          style={{ whiteSpace: 'pre-wrap' }}
        >
          <strong>{ infoMessage }</strong>
        </Alert>
      </Snackbar>
      <Snackbar 
        open={warn} 
        autoHideDuration={autoHiddenMilliSeconds} 
        onClose={handleCloseWarn}
      >
        <Alert 
          severity="warning" 
          action={
            <IconButton
              onClick={handleCloseWarn}
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <Close />
            </IconButton>
          }
          style={{ whiteSpace: 'pre-wrap' }}
        >
          <strong>{ warnMessage }</strong>
        </Alert>
      </Snackbar>
      <Snackbar 
        open={error} 
        autoHideDuration={autoHiddenMilliSeconds} 
        onClose={handleCloseError}
      >
        <Alert 
          severity="error" 
          action={
            <IconButton
              onClick={handleCloseError}
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <Close />
            </IconButton>
          } 
          style={{ whiteSpace: 'pre-wrap' }}
        >
          <strong>{ errorMessage }</strong>
        </Alert>
      </Snackbar>
    </Stack>
  )
});

export default CommonAlert;