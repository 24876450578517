import { createContext, useState } from "react";
import { AuthedUser } from "../objects/auth";


type AuthContextProps = {
  auth: AuthedUser | null;
  setAuth: (user: AuthedUser | null) => void;
};

const AuthContext = createContext<AuthContextProps>({
  auth: null,
  setAuth: (user) => {}
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [auth, setAuth] = useState<AuthedUser | null>(null);

  return (
    <AuthContext.Provider value={{auth, setAuth}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;