import { Download, Edit, Info, KeyboardArrowDown, KeyboardArrowUp, Refresh } from "@mui/icons-material";
import { Backdrop, Button, Checkbox, CircularProgress, Collapse, Divider, FormControlLabel, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { ExamTemplateSectionDialogRefs } from "../../interfaces/examTemplate/examTemplateSectionDialogInterface";
import { ExamTemplateSectionRowProps } from "../../interfaces/examTemplate/examTemplateSectionRowInterface";
import { ExamTemplateVideoUploaderDialogRefs } from "../../interfaces/examTemplate/examTemplateVideoUploaderDialog";
import { ExamTemplateQuestionData, ExamTemplateSectionDiagnosisCountData } from "../../objects/examTemplate";
import { GetExamTemplateQuestionList } from "../../repositories/examTemplateQuestionRepo";
import { GetExamTemplateSectionAiVideoCount, GetExamTemplateSectionDiagnosisCount, GetExamTemplateSectionTotalQuestionCount } from "../../repositories/examTemplateSectionRepo";
import CommonAlert from "../commonAlert";
import CommonHtmlTooltip from "../commonHtmlTooltip";
import ExamTemplateQuestionRow from "./examTemplateQuestionRow";
import './examTemplateSectionRow.css';
import ExamTemplateVideoUploaderDialog from "./examTemplateVideoUploaderDialog";


const ExamTemplateSectionRow: FC<ExamTemplateSectionRowProps> = (props: ExamTemplateSectionRowProps) => {
  const navigate = useNavigate();

  const { examTemplateUid, isEditable, row } = props;

  const [collapseOpen, setCollapseOpen] = useState(false);
  const [totalQuestionCount, setTotalQuestionCount] = useState<number | null>(null);
  const [diagnosisCountList, setDiagnosisCountList] = useState<{checked: boolean, data: ExamTemplateSectionDiagnosisCountData}[]>([]);
  const [aiVideoExistsCount, setAiVideoExistsCount] = useState<number | null>(null);
  const [aiVideoNonExistsCount, setAiVideoNonExistsCount] = useState<number | null>(null);
  const [onlyNonAiVideo, setOnlyNonAiVideo] = useState(false);
  const [templateQuestionList, setTemplateQuestionList] = useState<ExamTemplateQuestionData[]>([]);
  const [isMainProgress, setIsMainProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sectionDialogRef = useRef<ExamTemplateSectionDialogRefs>(null);
  const videoUploaderDialogRef = useRef<ExamTemplateVideoUploaderDialogRefs>(null);
  const commonAlertRef = useRef<CommonAlertRefs>(null);

  useEffect( () => {
    (async () => {
      try {
        setIsLoading(true);
        const storageCollapseOpen = sessionStorage.getItem(`template-section-row-${row.uid}-collapseOpen`);
        if (storageCollapseOpen) {
          const open = storageCollapseOpen === 'true' ? true : false;
          setCollapseOpen(open);
        };

        let onlyNonAiVideo = false;
        const storageOnlyNonAiVideo = sessionStorage.getItem(`template-section-row-${row.uid}-onlyNonAiVideo`);
        if (storageOnlyNonAiVideo) {
          onlyNonAiVideo = storageOnlyNonAiVideo === 'true' && true;
          setOnlyNonAiVideo(onlyNonAiVideo);
        };

        let uidList: number[] = [];
        const storageDiagnosisCountList = sessionStorage.getItem(`template-section-row-${row.uid}-diagnosis-uid-list`);
        if (storageDiagnosisCountList) {
          uidList = storageDiagnosisCountList.split(',').map(Number);
        };

        const diagnosisList = await getSectionDiagnosisCount(false);
        
        const diagnosisCountList: {checked: boolean, data: ExamTemplateSectionDiagnosisCountData}[] = [];

        diagnosisList.forEach((x) => {
          diagnosisCountList.push({checked: uidList.includes(x.diagnosis_uid), data: x});
        });

        setDiagnosisCountList(diagnosisCountList);

        const diagnosisUidList = diagnosisCountList.filter((x) => x.checked).map((x) => x.data.diagnosis_uid);
        await getSectionAiVideoCount();
        await getExamTemplateQuestionList(onlyNonAiVideo, diagnosisUidList);
        await getSectionTotalQuestionCount();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      };      
    })()
  }, []);

  useEffect(() => {
    sessionStorage.setItem(`template-section-row-${row.uid}-collapseOpen`, collapseOpen ? 'true' : 'false');
  }, [collapseOpen]);

  useEffect(() => {
    const uidList = diagnosisCountList.filter((x) => x.checked).map((x) => x.data.diagnosis_uid);
    sessionStorage.setItem(`template-section-row-${row.uid}-diagnosis-uid-list`, uidList.join(','));
  }, [diagnosisCountList]);

  useEffect(() => {
    sessionStorage.setItem(`template-section-row-${row.uid}-onlyNonAiVideo`, onlyNonAiVideo ? 'true' : 'false');
  }, [onlyNonAiVideo]);

  const initializeData = async () => {
    console.log('Initialize data');
    try {
      setIsLoading(true);

      await getSectionDiagnosisCount(true);
      await getSectionAiVideoCount();
      const diagnosisUidList = diagnosisCountList.filter((x) => x.checked).map((x) => x.data.diagnosis_uid);
      await getExamTemplateQuestionList(onlyNonAiVideo, diagnosisUidList);
      await getSectionTotalQuestionCount();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    };
  };

  const refreshRow = async () => {
    setTotalQuestionCount(null);
    setDiagnosisCountList([]);
    setAiVideoExistsCount(null);
    setAiVideoNonExistsCount(null); 
    setTemplateQuestionList([]);

    await initializeData();
  }

  const getSectionTotalQuestionCount = async () => {
    try {
      const result = await GetExamTemplateSectionTotalQuestionCount(row.uid);
      setTotalQuestionCount(result);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('問題数カウントの取得に失敗しました。');
      setTotalQuestionCount(null);
    };
  };

  const getSectionDiagnosisCount = async (setEnabled: boolean) => {
    try {
      const result = await GetExamTemplateSectionDiagnosisCount(row.uid);

      if (setEnabled) {
        const checkedUidList = diagnosisCountList.filter((x) => x.checked).map((x) => x.data.diagnosis_uid);
        const setResultList: {checked: boolean, data: ExamTemplateSectionDiagnosisCountData}[] = [];

        result.forEach((x) => {
          setResultList.push({checked: checkedUidList.includes(x.diagnosis_uid), data: x});
        });

        setDiagnosisCountList(setResultList);
      };

      return result;
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('病名カウントの取得に失敗しました。');
      setDiagnosisCountList([]);
      throw new Error('Failed to get setction diagnosis count');
    };
  };

  const handleDiagnosisChecked = async (diagnosisUid: number) => {
    console.log('handle')
    try {
      setIsLoading(true);

      const newList = diagnosisCountList.map((x, index) => (
        x.data.diagnosis_uid === diagnosisUid 
        ? {checked: x.checked ? false : true, data: x.data} 
        : x
      ));
      let diagnosisUidList: number[] = [];
      diagnosisUidList = newList.filter((x) => x.checked).map((x) => x.data.diagnosis_uid);

      await getExamTemplateQuestionList(onlyNonAiVideo, diagnosisUidList);

      setDiagnosisCountList(newList);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    };
  };

  const getSectionAiVideoCount = async () => {
    try {
      const result = await GetExamTemplateSectionAiVideoCount(row.uid);
      setAiVideoExistsCount(result.exists);
      setAiVideoNonExistsCount(result.non_exists);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('AI解析動画カウントの習得に失敗しました。');
      setAiVideoExistsCount(null);
      setAiVideoNonExistsCount(null);
    };
  };

  const handleOnlyNonAiVideo = async () => {
    try {
      setIsLoading(true);

      const newValue = onlyNonAiVideo ? false : true;
      
      let diagnosisUidList: number[] = [];
      diagnosisUidList = diagnosisCountList.filter((x) => x.checked).map((x) => x.data.diagnosis_uid);

      await getExamTemplateQuestionList(newValue, diagnosisUidList);

      setOnlyNonAiVideo(newValue);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    };
  };

  const getExamTemplateQuestionList = async (onlyNonAiVideo: boolean, diagnosisUidList: number[]) => {
    try {
      const questionList = await GetExamTemplateQuestionList(row.uid, onlyNonAiVideo, diagnosisUidList);
      setTemplateQuestionList(questionList);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('問題リストの取得に失敗しました。');
      setTemplateQuestionList([]);
    };
  };

  const downloadTemplateCsv = (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setIsLoading(true);
      
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent = csvContent + 'Q ID,Case ID,File name\n';
      templateQuestionList.forEach((x) => {
        const values = [];
        values.push(x.uid);
        values.push(x.case_uid);
        values.push('');
        csvContent = csvContent + values.join(',') + '\n';
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${row.uid}_question_list.csv`);
      document.body.appendChild(link);

      link.click();
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('テンプレートCSVのダウンロードに失敗しました。');
    } finally {
      setIsLoading(false);
    };
  };

  const openSectionDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('Open section dialog');
    try {
      sectionDialogRef.current?.openDialog('update', row.uid);
    } catch (error) {
      console.error(error);
    };
  };

  const updatedSectionData = async (): Promise<void> => {
    console.log('Confirm section data');
    const diagnosisUidList = diagnosisCountList.filter((x) => x.checked).map((x) => x.data.diagnosis_uid);
    await getExamTemplateQuestionList(onlyNonAiVideo, diagnosisUidList);
  };

  const openVideoUploaderDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('Open video uploader dialog');
    try {
      videoUploaderDialogRef.current?.openDialog(row.uid);
    } catch (error) {
      console.error(error);
    };
  };

  const changedQuestion = async (): Promise<void> => {
    console.log('Changed question');
    await initializeData();
  };

  return (
    <>
      <TableRow onClick={() => setCollapseOpen(!collapseOpen)} hover tabIndex={-1} key={row.uid}>
        <TableCell padding='none'>
          <CommonHtmlTooltip placement="bottom-start" title={
            <Fragment>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                width={150}
              >
                <Grid container item xs={4}>
                  <Typography color="inherit">{`S ID`}</Typography>
                </Grid>
                <Grid container item xs={8}>
                  <Typography color="inherit">{`:${row.uid}`}</Typography>
                </Grid>
              </Grid>
            </Fragment>
          }>
            <IconButton edge="end" size="small" color="primary">
              <Info />
            </IconButton>
          </CommonHtmlTooltip>
        </TableCell>
        <TableCell>{ row.title }</TableCell>
        <TableCell align="right">{ totalQuestionCount ? totalQuestionCount : '-' }</TableCell>
        <TableCell align="right">
          <IconButton
            size="small"
            onClick={() => setCollapseOpen(!collapseOpen)}
            disabled={isLoading}
          >
            {collapseOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow  style={{backgroundColor: '#f5f5f5'}}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Paper style={{padding: 5, margin: '5px 0'}}>
                <Typography variant="body1" gutterBottom className='ExamTemplateSectionRow-section-descrption'>{row.description}</Typography>
                <Divider textAlign="left">病名</Divider>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {diagnosisCountList.length < 1
                    ? <Typography>{'No Data'}</Typography>
                    : <>
                        {diagnosisCountList.map((x, index) => (
                          <Grid container item xs={6} key={index}>
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={1}
                              width='100%'
                            >
                              <Checkbox 
                                checked={x.checked}
                                onChange={(evt) => handleDiagnosisChecked(x.data.diagnosis_uid)} 
                                disabled={isLoading}
                                size="small"
                              />
                              <Typography variant="body1">
                                {`${x.data.display}:`}
                              </Typography>
                              <Typography variant="body1">
                                { x.data.count }
                              </Typography>
                            </Stack>
                          </Grid>
                        ))}
                      </>
                  }
                </Grid>
                <Divider textAlign="left">AI解析動画</Divider>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                  width='100%'
                  marginBottom={0.5}
                >
                  <Typography variant="body1">
                    { `あり: ${aiVideoExistsCount !== null ? aiVideoExistsCount : '-'}` }
                  </Typography>
                  <Typography variant="body1">
                    { `なし: ${aiVideoNonExistsCount !== null ? aiVideoNonExistsCount : '-'}` }
                  </Typography>
                  <FormControlLabel 
                    control={
                      <Checkbox 
                        checked={onlyNonAiVideo}
                        onChange={(evt) => handleOnlyNonAiVideo()} 
                        disabled={(isLoading || (aiVideoNonExistsCount === 0 && aiVideoExistsCount === 0))}
                        size="small" 
                      /> 
                    } 
                    label="なしのみ" 
                  />
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                  width='100%'
                >
                  <Button 
                    onClick={(evt) => downloadTemplateCsv(evt)}
                    endIcon={<Download/>}
                    variant="contained"
                    disabled={isLoading}
                  >
                    テンプレートCSV
                  </Button>
                  <Button 
                    onClick={(evt) => openVideoUploaderDialog(evt)}
                    variant="outlined"
                    disabled={(isLoading || !isEditable)}
                  >
                    まとめてアップロードする
                  </Button>
                </Stack>
              </Paper>
              <TableContainer component={Paper} className='ExamTemplateSectionRow-question-table'>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ minWidth: 40 }} padding='none'></TableCell>
                      <TableCell style={{ minWidth: 70 }}>症例番号</TableCell>
                      <TableCell style={{ minWidth: 170 }}>施設</TableCell>
                      <TableCell style={{ minWidth: 150 }}>{`ベンダー`}</TableCell>
                      <TableCell style={{ width: '100%', minWidth: 100 }}>病名</TableCell>
                      <TableCell style={{ minWidth: 30 }}>枚数</TableCell>
                      <TableCell style={{ minWidth: 40 }}>AI</TableCell>
                      <TableCell style={{ minWidth: 120 }} padding='none'></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {templateQuestionList.map((row, index) => (
                      <ExamTemplateQuestionRow
                        key={index}
                        examTemplateUid={examTemplateUid}
                        isEditable={isEditable}
                        row={row}
                        diagnosisList={diagnosisCountList.map((x) => (
                          {diagnosisUid: x.data.diagnosis_uid, display: x.data.display}
                        ))}
                        changedQuestion={changedQuestion}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ExamTemplateVideoUploaderDialog
        ref={videoUploaderDialogRef}
        closedDialog={changedQuestion}
      />
      <CommonAlert
        ref={commonAlertRef}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
};

export default ExamTemplateSectionRow;