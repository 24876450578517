export class ExamBaseData {
  title: string;
  description: string;
  format: string;
  detection_answer_maximum_limit: number;
  time_limit_minutes: number;
  students_count: number;
  students_tag_format: string;
  publish_start: Date;
  publish_end: Date;
  template_uid: number;

  constructor (
    title: string, 
    description: string, 
    format: string, 
    detection_answer_maximum_limit: number,
    time_limit_minutes: number, 
    students_count: number, 
    students_tag_format: string,
    publish_start: Date, 
    publish_end: Date, 
    template_uid: number) {
      this.title = title;
      this.description = description;
      this.format = format;
      this.detection_answer_maximum_limit = detection_answer_maximum_limit;
      this.time_limit_minutes = time_limit_minutes;
      this.students_count = students_count;
      this.students_tag_format = students_tag_format;
      this.publish_start = publish_start;
      this.publish_end = publish_end;
      this.template_uid = template_uid;
  };
};

export class ExamCreateData extends ExamBaseData {
  constructor (
    title: string, 
    description: string, 
    format: string, 
    detection_answer_maximum_limit: number,
    time_limit_minutes: number, 
    students_count: number, 
    students_tag_format: string,
    publish_start: Date, 
    publish_end: Date, 
    template_uid: number) {
      super(
        title,
        description,
        format,
        detection_answer_maximum_limit,
        time_limit_minutes,
        students_count,
        students_tag_format,
        publish_start,
        publish_end,
        template_uid
      );
  };
};

export class ExamData extends ExamBaseData {
  uid: number;
  template_title: string;
  questions_count: number;
  is_active: boolean;
  student_total: number;
  student_done_count: number;
  
  constructor (
    uid: number,
    is_active: boolean,
    template_title: string,
    questions_count: number,
    student_total: number,
    student_done_count: number,
    title: string, 
    description: string, 
    format: string, 
    detection_answer_maximum_limit: number,
    time_limit_minutes: number, 
    students_count: number, 
    students_tag_format: string,
    publish_start: Date, 
    publish_end: Date, 
    template_uid: number) {
      super(
        title,
        description,
        format,
        detection_answer_maximum_limit,
        time_limit_minutes,
        students_count,
        students_tag_format,
        publish_start,
        publish_end,
        template_uid
      );

      this.uid = uid;
      this.template_title = template_title;
      this.questions_count = questions_count;
      this.is_active = is_active;
      this.student_total = student_total;
      this.student_done_count = student_done_count;
  };
};