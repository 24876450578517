import { ExamTemplateSectionData } from "./examTemplateSectionData";

export class ExamTemplateData {
  uid: number;
  title: string;
  description: string;
  status: ExamTemplateStatus;
  exam_template_sections: ExamTemplateSectionData[];

  constructor (uid: number, title: string, description: string, status: ExamTemplateStatus, exam_template_sections: ExamTemplateSectionData[]) {
    this.uid = uid;
    this.title = title;
    this.description = description;
    this.status = status;
    this.exam_template_sections = exam_template_sections;
  };
};

export const ExamTemplateStatus = {
  Editing: 'editing',
  Ready: 'ready',
  Deleted: 'deleted'
} as const;

export type ExamTemplateStatus = typeof ExamTemplateStatus[keyof typeof ExamTemplateStatus];

export const ExamTemplateStatusDisplay = (name: ExamTemplateStatus): string => {
  switch (name) {
    case 'editing':
      return 'Editing';
    case 'ready':
      return 'Ready';
    case 'deleted':
      return 'Deleted';
  };
};