import React, { createContext, useEffect } from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import UseStudentAuth from '../hooks/useStudentAuth';
import UseRefreshStudentToken from '../hooks/useRefreshStudentToken';

const BaseUrl = process.env.REACT_APP_ORIGIN_API_URL;

export const axiosStudentClient = axios.create({
  baseURL: BaseUrl,
  withCredentials: true,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  }
});

type AxiosStudentClientProps = {
};

const AxiosStudentClientContext = createContext<AxiosStudentClientProps>({});

export const AxiosStudentClientProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const { studentAuth } = UseStudentAuth();
  const refresh = UseRefreshStudentToken();
  
  useEffect(() => {
    const requestInterceptors = axiosStudentClient.interceptors.request.use((config: AxiosRequestConfig) => {
      if (config.headers) {
        const token = studentAuth;
        config.headers.Authorization = `Bearer ${token}`;
      };

      return config;
    });

    const responseInterceptor = axiosStudentClient.interceptors.response.use((response: AxiosResponse) => {
      return response;
    },
    async (error: AxiosError) => {
      switch (error.response?.status) {
        case 401:
          console.error('accecptor got 401');
          navigate('/signin/exam', {replace: true});
          break;
        case 403:
          console.error('accecptor got 403');
          const newAccessToken = await refresh(axiosStudentClient);

          if (!newAccessToken) {
            console.error('Could not get new access token');
            navigate('/signin/exam', {replace: true});
          } else {
            const prevRequest = error.config;
            if (prevRequest.headers) {
              prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
              return axiosStudentClient(prevRequest);
            };
          };
          break;
        default:
          console.error('accecptor got default');
          break;
      };
      
      return Promise.reject(error);
    });

    return () => {
      axiosStudentClient.interceptors.request.eject(requestInterceptors);
      axiosStudentClient.interceptors.response.eject(responseInterceptor);
    };
  }, [studentAuth]);

  return (
    <AxiosStudentClientContext.Provider value={{}}>
      { children }
    </AxiosStudentClientContext.Provider>
  );
};