export class ExamTemplateVideoUploaderData {
  templateQuestionUid: number;
  caseUid: number;
  fileName: string;
  status: ExamTemplateVideoUploaderStatus;
  result: string;

  constructor (
    templateQuestionUid: number,
    caseUid: number,
    fileName: string,
    status: ExamTemplateVideoUploaderStatus,
    result: string
  ) {
    this.templateQuestionUid = templateQuestionUid;
    this.caseUid = caseUid;
    this.fileName = fileName;
    this.status = status;
    this.result = result;
  };
};

export const ExamTemplateVideoUploaderStatus = {
  Ready: 0,
  Progressing: 1,
  Done: 2,
  Canceled: 50,
  Error: 99,
} as const;

export type ExamTemplateVideoUploaderStatus = typeof ExamTemplateVideoUploaderStatus[keyof typeof ExamTemplateVideoUploaderStatus];

export const ExamTemplateVideoUploaderStatusDisplay = (name: ExamTemplateVideoUploaderStatus): string => {
  switch (name) {
    case 0:
      return 'Ready';
    case 1:
      return 'Progressing';
    case 2:
      return 'Done';
    case 50:
      return 'Canceled';
    case 99:
      return 'Error';
  };
};