import { Task } from "@mui/icons-material";
import { IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { GradingDetectionAnswerPanelProps } from "../../interfaces/gradingExam/gradingDetectionAnswerPanelInterface";
import './gradingDetectionAnswerPanel.css';


const GradingDetectionAnswerPanel: FC<GradingDetectionAnswerPanelProps> = (props: GradingDetectionAnswerPanelProps) => {
  const { examUid, questionUid, answer, second, beforeMoveToViewer } = props;

  const navigate = useNavigate();

  const moveToGradingAnswerViewer = () => {
    if (beforeMoveToViewer) beforeMoveToViewer();
    navigate(`/exams/grading/${examUid}/viewer/${questionUid}`);
  };

  const moveToGradingAnswerViewerWithScoreUid = (scoreUid: number) => {
    if (beforeMoveToViewer) beforeMoveToViewer();
    navigate(`/exams/grading/${examUid}/viewer/${questionUid}?initial_score=${scoreUid}`);
  };

  const getUncheckedColor = (): string => {
    return `#bdbdbd`;
  };

  const getCorrectColor = (): string => {
    return '#4caf50';
  };

  const getUncorrectColor = (): string => {
    return '#f44336';
  };

  const getIgnoredColor = (): string => {
    return `#bdbdbd`;
  };

  return (
    <>
      <Paper elevation={2} className='GradingDetectionAnswerPanel-main-paper'>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          className='GradingDetectionAnswerPanel-action-stack'
        >
          <Typography variant="subtitle1">{'病名評価'}</Typography>
          <IconButton 
            onClick={(evt) => moveToGradingAnswerViewer()}
            edge="end" size="small" color="primary"
          >
            <Task />
          </IconButton>
        </Stack>
        <TableContainer className="GradingDetectionAnswerPanel-answer-table">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{''}</TableCell>
                <TableCell colSpan={3}>{'AI解析動画視聴前'}</TableCell>
                <TableCell colSpan={3}>{'AI解析動画視聴後'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{'病名(枚目)'}</TableCell>
                <TableCell align="right">{'未チェック数'}</TableCell>
                <TableCell align="right">{'正解数'}</TableCell>
                <TableCell align="right">{'不正解数'}</TableCell>
                <TableCell align="right">{'未チェック数'}</TableCell>
                <TableCell align="right">{'正解数'}</TableCell>
                <TableCell align="right">{'不正解数'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {answer.diagnosis_list.map((x, index) => (
                <TableRow key={index}>
                  <TableCell>{`${x.display}(${0 < x.image_index ? x.image_index : '-'})`}</TableCell>
                  <TableCell align="right" style={{color: getUncheckedColor(), fontWeight: 'bold'}}>{x.first_ungraded_count}</TableCell>
                  <TableCell align="right" style={{color: getCorrectColor(), fontWeight: 'bold'}}>{x.first_corrected_count}</TableCell>
                  <TableCell align="right" style={{color: getUncorrectColor(), fontWeight: 'bold'}}>{x.first_uncorrected_count}</TableCell>
                  <TableCell align="right" style={{color: getUncheckedColor(), fontWeight: 'bold'}}>{x.second_ungraded_count !== null ? x.second_ungraded_count : '-'}</TableCell>
                  <TableCell align="right" style={{color: getCorrectColor(), fontWeight: 'bold'}}>{x.second_corrected_count !== null ? x.second_corrected_count : '-'}</TableCell>
                  <TableCell align="right" style={{color: getUncorrectColor(), fontWeight: 'bold'}}>{x.second_uncorrected_count !== null ? x.second_uncorrected_count : '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="subtitle1" marginTop={5}>{'画像評価'}</Typography>
        <TableContainer className='GradingDetectionAnswerPanel-image-table'>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="right"></TableCell>
                <TableCell align="right">総画像数</TableCell>
                <TableCell align="right">未チェック数</TableCell>
                <TableCell align="right">正解数</TableCell>
                <TableCell align="right">不正解数</TableCell>
                <TableCell align="right">無効数</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">{'AI解析動画視聴前'}</TableCell>
                <TableCell align="right" style={{fontWeight: 'bold'}}>{answer.first_stat.total_answer_image_count}</TableCell>
                <TableCell align="right" style={{color: getUncheckedColor(), fontWeight: 'bold'}}>{answer.first_stat.unchecked_image_count}</TableCell>
                <TableCell align="right" style={{color: getCorrectColor(), fontWeight: 'bold'}}>{answer.first_stat.corrected_image_count}</TableCell>
                <TableCell align="right" style={{color: getUncorrectColor(), fontWeight: 'bold'}}>{answer.first_stat.incorrected_image_count}</TableCell>
                <TableCell align="right" style={{color: getIgnoredColor(), fontWeight: 'bold'}}>{answer.first_stat.ignored_imaeg_count}</TableCell>
              </TableRow>
              {(second && answer.second_stat) &&
                <TableRow>
                  <TableCell align="left">{'AI解析動画視聴後'}</TableCell>
                  <TableCell align="right" style={{fontWeight: 'bold'}}>{answer.second_stat.total_answer_image_count}</TableCell>
                  <TableCell align="right" style={{color: getUncheckedColor(), fontWeight: 'bold'}}>{answer.second_stat.unchecked_image_count}</TableCell>
                  <TableCell align="right" style={{color: getCorrectColor(), fontWeight: 'bold'}}>{answer.second_stat.corrected_image_count}</TableCell>
                  <TableCell align="right" style={{color: getUncorrectColor(), fontWeight: 'bold'}}>{answer.second_stat.incorrected_image_count}</TableCell>
                  <TableCell align="right" style={{color: getIgnoredColor(), fontWeight: 'bold'}}>{answer.second_stat.ignored_imaeg_count}</TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="subtitle1" marginTop={5}>{'コメント'}</Typography>
        <TableContainer className='GradingDetectionAnswerPanel-score-table'>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{minWidth: 100}}>{`被験者ID`}</TableCell>
                <TableCell style={{minWidth: 100, width: "100%"}}>{`コメント`}</TableCell>
                <TableCell style={{minWidth: 50}}>{``}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.score.map((x, index) => (
                <TableRow key={index}>
                  <TableCell>{x.student_id + `(視聴${x.is_second === false ? `前` : `後`})`}</TableCell>
                  <TableCell>{x.comment}</TableCell>
                  <TableCell>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={1}
                    >
                      <IconButton
                        onClick={(evt) => moveToGradingAnswerViewerWithScoreUid(x.uid)}
                        edge="end" size="small" color="primary"
                      >
                        <Task/>
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
};

export default GradingDetectionAnswerPanel;