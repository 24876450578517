import { Check, Close, KeyboardArrowDown, KeyboardArrowUp, Remove } from "@mui/icons-material";
import { Collapse, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useState } from "react";
import { ExamStudentResultRowProps } from "../../interfaces/exam/examStudentResultRowInterface";
import { ExamStudentQuestionScoreData, ExamStudentResultLogData } from "../../objects/exam";
import { ExamTemplateQuestionsType, ExamTemplateQuestionsTypeDisplay } from "../../objects/examTemplate";
import './examStudentResultRow.css';


const ExamStudentResultRow: FC<ExamStudentResultRowProps> = (props: ExamStudentResultRowProps) => {
  const { examUid, row } = props;

  const [collapseOpen, setCollapseOpen] = useState(false);

  const getLogMaximumLengthList = (): number[] => {
    const firstLength = row.first_logs.length;
    const secondLength = row.second_logs.length;

    if (firstLength < secondLength) return [...Array(secondLength)].map((_, i) => (i));
    else return [...Array(firstLength)].map((_, i) => (i));
  };

  const getTargetLogData = (isSecond: boolean, index: number): ExamStudentResultLogData | null => {
    if (!isSecond) {
      if (index in row.first_logs) return row.first_logs[index];
    } else {
      if (index in row.second_logs) return row.second_logs[index];
    };
    
    return null;
  };

  const handleQuestionScoreDisplay = (questionScore: ExamStudentQuestionScoreData | null): string => {
    if (!questionScore) return 'データなし';
    else if (questionScore.score === null) return '-';
    else return questionScore.score.toString();
  };

  const handleAnswerColor = (isCorrect: boolean | null): string => {
    if (isCorrect === null) return `#bdbdbd`;

    if (isCorrect) {
      return '#4caf50';
    } else {
      return '#f44336';
    };
  };

  const handleCorrectIcon = (isCorrect: boolean | null) => {
    if (isCorrect === null) return <Remove fontSize="small"/>;
    else if (isCorrect === false) return <Close fontSize="small"/>;
    else return <Check fontSize="small"/>;
  };

  return (
    <>
      <TableRow hover tabIndex={-1} onClick={(evt) => setCollapseOpen(!collapseOpen)} key={row.question_uid}>
        <TableCell>{ row.order_by }</TableCell>
        <TableCell>{ row.question_number }</TableCell>
        <TableCell>{ row.case_uid }</TableCell>
        <TableCell>{ ExamTemplateQuestionsTypeDisplay(row.question_type) }</TableCell>
        <TableCell>{ handleQuestionScoreDisplay(row.first_question_score) }</TableCell>
        <TableCell>{ handleQuestionScoreDisplay(row.second_question_score) }</TableCell>
        <TableCell align="right">
          <IconButton
            size="small"
            onClick={(evt) => setCollapseOpen(!collapseOpen)}
          >
            {collapseOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow  style={{backgroundColor: '#f5f5f5'}}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Paper elevation={2} className='ExamStudentResultRow-main-paper'>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '10%' }}>正解</TableCell>
                        <TableCell style={{ width: '45%' }} colSpan={4}>AI解析動画視聴前</TableCell>
                        <TableCell style={{ width: '45%' }} colSpan={4}>AI解析動画視聴後</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.answers.map((x, index) => (
                        <TableRow key={index}>
                          <TableCell>{ `${x.correct_display}(${x.correct_image_index})` }</TableCell>
                          <TableCell colSpan={4} style={{color: handleAnswerColor(x.first_is_correct)}}>
                            {row.question_type === ExamTemplateQuestionsType.Detection &&
                              <>
                                {handleCorrectIcon(x.first_is_correct)}
                              </>
                            }
                            {row.question_type === ExamTemplateQuestionsType.Identification &&
                              `${x.first_answer_display}`
                            }
                          </TableCell>
                          <TableCell colSpan={4} style={{color: handleAnswerColor(x.second_is_correct)}}>
                          {row.question_type === ExamTemplateQuestionsType.Detection &&
                              <>
                                {handleCorrectIcon(x.second_is_correct)}
                              </>
                            }
                            {row.question_type === ExamTemplateQuestionsType.Identification &&
                              `${x.second_answer_display}`
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer component={Paper} className='ExamStudentResultRow-main-table'>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '10%' }}>{`合計(秒)`}</TableCell>
                        <TableCell style={{ width: '45%' }} colSpan={4} align='right'>{ row.first_log_total_seconds ? row.first_log_total_seconds : '-' }</TableCell>
                        <TableCell style={{ width: '45%' }} colSpan={4} align='right'>{ row.second_log_total_seconds ? row.second_log_total_seconds : '-' }</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getLogMaximumLengthList().map((x, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ width: '10%' }}></TableCell>
                          {getTargetLogData(false, x)
                            ? <>
                                <TableCell style={{ width: '20%' }}>{ getTargetLogData(false, x)?.action_display }</TableCell>
                                <TableCell style={{ width: '10%' }}>{ getTargetLogData(false, x)?.description }</TableCell>
                                <TableCell style={{ width: '10%' }} align="right">{ getTargetLogData(false, x)?.created_display }</TableCell>
                                <TableCell style={{ width: '5%' }} align="right">{ 0 < index ? getTargetLogData(false, x)?.diff : '-' }</TableCell>
                              </>
                            : <>
                                <TableCell colSpan={4}></TableCell>
                              </>
                          }
                          {getTargetLogData(true, x)
                            ? <>
                                <TableCell style={{ width: '20%' }}>{ getTargetLogData(true, x)?.action_display }</TableCell>
                                <TableCell style={{ width: '10%' }}>{ getTargetLogData(true, x)?.description }</TableCell>
                                <TableCell style={{ width: '10%' }} align="right">{ getTargetLogData(true, x)?.created_display }</TableCell>
                                <TableCell style={{ width: '5%' }} align="right">{ 0 < index ? getTargetLogData(true, x)?.diff : '-' }</TableCell>
                              </>
                            : <>
                                <TableCell colSpan={4}></TableCell>
                              </>
                          }
                        </TableRow>
                      ))}
                      {row.question_type === ExamTemplateQuestionsType.Detection
                        ? <TableRow>
                            <TableCell style={{ width: '10%', verticalAlign: 'top'  }}>{`コメント`}</TableCell>
                            <TableCell style={{ width: '45%', verticalAlign: 'top' }} colSpan={4}>
                              <div style={{whiteSpace: "pre"}}>
                                { row.first_question_score ? row.first_question_score.description : '-' }
                              </div>
                            </TableCell>
                            <TableCell style={{ width: '45%', verticalAlign: 'top' }} colSpan={4}>
                              <div style={{whiteSpace: "pre"}}>
                                { row.second_question_score ? row.second_question_score.description : '-' }
                              </div>
                            </TableCell>
                          </TableRow>
                        : null
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
};

export default ExamStudentResultRow;