import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import './App.css';
import ExamTemplateListPage from './pages/examTemplate/ExamTemplateListPage';
import ExamTemplateEditorPage from './pages/examTemplate/ExamTemplateEditorPage';
import ExamTemplateViewer from './pages/viewer/ExamTemplateViewer';
import SigninPage from './pages/SigninPage';
import ExamSignInPage from './pages/ExamSignInPage';
import ExamAnswerViewer from './pages/viewer/StudentViewer';
import ExamFinishedPage from './pages/ExamFinishedPage';
import { useEffect } from 'react';
import ExamListPage from './pages/exam/ExamListPage';
import { AuthProvider } from './contexts/authProvider';
import RequireAuth from './compornents/RequireAuth';
import { AxiosClientProvider } from './contexts/axiosClientProvider';
import { StudentAuthProvider } from './contexts/studentAuthProvider';
import RequireStudentAuth from './compornents/requireStudentAuth';
import { AxiosStudentClientProvider } from './contexts/axiosStudentClientProvider';
import UserListPage from './pages/UserListPage';
import UnautholizedPage from './pages/error/UnauthorizedPage';
import NotFoundPage from './pages/error/NotFoundPage';
import PersistStudentSignin from './compornents/persistStudentSignin';
import GradingExamPage from './pages/exam/GradingExamPage';
import GradingAnswerViewer from './pages/viewer/GradingAnswerViewer';
import ExamStudentResultPage from './pages/exam/ExamStudentResultPage';
import { ImageAuthProvider } from './contexts/imageAuthProvider';
import { AxiosImageClientProvider } from './contexts/axiosImageClientProvider';


function App() {
  useEffect(() => {
    document.title = '革新がん出題システム'
  }, []);

  return (
    <div style={{ width: '100%', height: '100%'}}>
      <Router>
        <ImageAuthProvider>
          <AxiosImageClientProvider>
            <AuthProvider>
              <AxiosClientProvider>
                <StudentAuthProvider>
                  <AxiosStudentClientProvider>                
                    <Routes>
                      {/* public routes */}
                      <Route path="/signin" element={<SigninPage />} />
                      <Route path="/signin/exam" element={<ExamSignInPage />} />
                      {/* protected routes */}
                      <Route element={<RequireAuth allowedRoles={[20, 90, 100]}/>}>
                        <Route path="/exams" element={<ExamListPage />} />
                      </Route>
                      <Route element={<RequireAuth allowedRoles={[10, 90, 100]}/>}>
                        <Route path="/users" element={<UserListPage />} />
                      </Route>
                      <Route element={<RequireAuth allowedRoles={[30, 90, 100]}/>}>
                        <Route path="/exams/students/results/:examUid/:examStudentUid" element={<ExamStudentResultPage />} />
                        <Route path="/exams/grading/:examUid" element={<GradingExamPage />} />
                        <Route path="/exams/grading/:examUid/viewer/:examQuestionUid" element={<GradingAnswerViewer />} />
                        <Route path="/exams/templates/:templateUid" element={<ExamTemplateEditorPage />} />
                        <Route path="/exams/templates/viewer/:templateUid/:caseUid" element={<ExamTemplateViewer />} />
                      </Route>
                      <Route element={<RequireAuth allowedRoles={[10, 20, 30, 90, 100]}/>}>
                        <Route path="/" element={<Navigate replace to="/exams/templates" />} />
                        <Route path="/exams/templates" element={<ExamTemplateListPage />} />
                        <Route path="/unautholized" element={<UnautholizedPage />} />
                        <Route path="/notfound" element={<NotFoundPage />} />
                        <Route path="/*" element={<Navigate replace to="/notfound" />} />
                      </Route>
                      {/* protected student routes */}
                      <Route element={<PersistStudentSignin/>}>
                        <Route element={<RequireStudentAuth/>}>
                          <Route path="/students/:examUid" element={<ExamAnswerViewer />} />
                          <Route path="/students/:examUid/finished" element={<ExamFinishedPage />} />
                        </Route>
                      </Route>
                    </Routes>
                  </AxiosStudentClientProvider>
                </StudentAuthProvider>
              </AxiosClientProvider>
            </AuthProvider>
          </AxiosImageClientProvider>
        </ImageAuthProvider>
      </Router>
    </div>
  );
}

export default App;
