import { CancelTokenSource } from "axios";
import { axiosClient } from "../contexts/axiosClientProvider";
import { ExamAnswerDrawingData } from "../objects/exam";

export const GetExamAnswerDrawingImage = async (drawingUid: number, cancelToken: CancelTokenSource): Promise<Blob> => {
  const response = await axiosClient.get(`exams/answers/drawings/${drawingUid}/image`, {
    cancelToken: cancelToken.token,
    responseType : 'arraybuffer'
  });
  
  const dataArray = new Uint8Array(response.data);
  return new Blob([dataArray.buffer], { type: 'image/jpg' });
};

export const GetExamAnswerDrawingList = async (
  useIsSecond: boolean,
  examStudentUid: number | null,
  examQuestionUid: number | null,
  onlyUnchecked: boolean | null,
  isSecond: boolean | null): Promise<ExamAnswerDrawingData[]> => {
  let url = `exams/answers/drawings?use_is_second=${useIsSecond}`;

  if (examStudentUid !== null) {
    url = url + `&exam_student_uid=${examStudentUid}`;
  };

  if (examQuestionUid !== null) {
    url = url + `&exam_question_uid=${examQuestionUid}`;
  };

  if (onlyUnchecked !== null) {
    url = url + `&only_unchecked=${onlyUnchecked}`;
  };

  if (useIsSecond && isSecond !== null) {
    url = url + `&is_second=${isSecond}`;
  };

  const response = await axiosClient.get<ExamAnswerDrawingData[]>(url);
  return response.data;
};

export const UpdateExamAnswerDrawingMarupeke = async (drawingUid: number, newValue: boolean | null): Promise<void> => {
  let url = `exams/answers/drawings/${drawingUid}/marupeke`;

  const body = {
    new_value: newValue
  };

  await axiosClient.put(url, body);
};

export const UpdateExamAnswerDrawingIsIgnored = async (drawingUid: number, newValue: boolean): Promise<void> => {
  let url = `exams/answers/drawings/${drawingUid}/ignored`;

  const body = {
    new_value: newValue
  };

  await axiosClient.put(url, body);
};