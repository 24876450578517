import { Close, Check, Remove } from "@mui/icons-material";
import { TableRow, TableCell, Stack, IconButton, Backdrop, CircularProgress } from "@mui/material";
import { forwardRef, useRef, useState } from "react";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { ExamAnswerDrawingRowProps, ExamAnswerDrawingRowRefs } from "../../interfaces/exam/examAnswerDrawingRowInterface";
import { UpdateExamAnswerDrawingIsIgnored, UpdateExamAnswerDrawingMarupeke } from "../../repositories/examAnswerDrawingRepo";
import CommonAlert from "../commonAlert";

const ExamAnswerDrawingRow = forwardRef<ExamAnswerDrawingRowRefs, ExamAnswerDrawingRowProps>((props, refs) => {
  const { row, selectedExamAnswerDrawingUid, handleSelectDrawingData, handleUpdatedData } = props;

  const [isLoading, setIsLoading] = useState(false);

  const commonAlertRef = useRef<CommonAlertRefs>(null);

  const handleDrawingRowClick = async () => {
    console.log('Handle drawing row click');
    if (handleSelectDrawingData) handleSelectDrawingData(row);
  };

  const updateDrawingMarupeke = async (
    event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement, MouseEvent>,  
    newValue: boolean | null) => {
      console.log(`Update drawing correct`);
      try {
        setIsLoading(true);

        event.stopPropagation();
        
        if (selectedExamAnswerDrawingUid === null || selectedExamAnswerDrawingUid !== row.uid) {
          if (handleSelectDrawingData) handleSelectDrawingData(row);
          return;
        };
        
        await UpdateExamAnswerDrawingMarupeke(row.uid, newValue !== row.is_correct ? newValue : null);

        setIsLoading(false);
        if (handleUpdatedData) await handleUpdatedData();
      } catch (error) {
        console.error(error);
        commonAlertRef.current?.error('被験者解答画像評価の更新に失敗しました。');
      } finally {
        setIsLoading(false);
      };
  };

  const updateDrawingIsIgnored = async (
    event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    try {
      setIsLoading(true);

      event.stopPropagation();
        
      if (selectedExamAnswerDrawingUid === null || selectedExamAnswerDrawingUid !== row.uid) {
        if (handleSelectDrawingData) handleSelectDrawingData(row);
        return;
      };

      await UpdateExamAnswerDrawingIsIgnored(row.uid, !row.is_ignored);
      
      setIsLoading(false);
      if (handleUpdatedData) await handleUpdatedData();
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('被験者解答画像評価の更新に失敗しました。');
    } finally {
      setIsLoading(false);
    };
  };

  return (
    <>
      <TableRow 
        onClick={(evt) => handleDrawingRowClick()}
        hover 
        style={{backgroundColor: selectedExamAnswerDrawingUid !== null && selectedExamAnswerDrawingUid === row.uid ? '#e0e0e0' : ''}}
      >
        <TableCell>{row.uid}</TableCell>
        <TableCell align="right">{row.image_index}</TableCell>
        <TableCell align="right" padding='none'>
          <Stack 
            direction="row"
            justifyContent="flex-end"
            alignItems="center" 
            spacing={0.5}
          >
            <IconButton  
              onClick={async (evt) => await updateDrawingMarupeke(evt, false)}
              size="small" 
              color={row.is_correct === false ? 'error' : "default"}
            >
              <Close fontSize="small" />
            </IconButton>
            <IconButton  
              onClick={async (evt) => await updateDrawingIsIgnored(evt)}
              size="small" 
              style={{color: row.is_ignored ? '#0091ea' : "#757575"}}
            >
              <Remove fontSize="small" />
            </IconButton>
            <IconButton 
              onClick={async (evt) => await updateDrawingMarupeke(evt, true)}
              size="small"
              style={{color: row.is_correct === true ? '#00c853' : "#757575"}}
            >
              <Check fontSize="small" />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <CommonAlert
        ref={commonAlertRef}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
});

export default ExamAnswerDrawingRow;