import { GradingExamQuestionData } from "../objects/gradingExam";
import { axiosClient } from "../contexts/axiosClientProvider";
import { ExamTemplateQuestionsType } from "../objects/examTemplate";

export const GetGradingExamQuestionList = async (
  examUid: number, 
  isNext: boolean,
  lastQuestionNumber: number,
  questionType: ExamTemplateQuestionsType | null,
  limit: number): Promise<{data: GradingExamQuestionData[], can_prev: boolean, can_next: boolean, total_count: number}> => {
    let url = `marupeke`;
    const queryList: string[] = [];

    queryList.push(`exam_uid=${examUid}`);
    queryList.push(`is_next=${isNext}`);
    queryList.push(`last_question_number=${lastQuestionNumber}`);
    if (questionType) queryList.push(`questions_type=${questionType}`);
    queryList.push(`limit=${limit}`);

    if (0 < queryList.length) url = url + `?${queryList.join('&')}`;

    const response = await axiosClient.get<{data: GradingExamQuestionData[], can_prev: boolean, can_next: boolean, total_count: number}>(url);
    return response.data;
};

export const GetGradingExamQuestion = async (questionUid: number): Promise<GradingExamQuestionData> => {
  const response = await axiosClient.get<GradingExamQuestionData>(`marupeke/${questionUid}`);
  return response.data;
};