import { axiosClient } from "../contexts/axiosClientProvider";

export const DownloadExamResult = async (
  examUid: number,
  includeAnswerData: boolean,
  includeActionLog: boolean,
  fileName: string): Promise<void> => {
    let url = `exams/download/results/${examUid}`;

    url = url + `?include_answer_data=${includeAnswerData}&include_action_log=${includeActionLog}`;
    
    const response = await axiosClient.get(url, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/zip' }
    });
    
    const blob = new Blob([response.data], { type: 'application/zip' });
    const downloadUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.download = `${fileName}.zip`;
    link.href = downloadUrl;
    
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(downloadUrl);
    link.parentNode?.removeChild(link);
};