import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { UserDialogProps, UserDialogRefs } from "../../interfaces/user/userDialogInterface";
import { CreateUserRoleList, UserData, UserRoleData } from "../../objects/user";
import { AddUser, DeleteUser, EnableUser, GetUser, InitializeUserPassword, UpdateUser } from "../../repositories/userRepo";
import CommonAlert from "../commonAlert";


const UserDialog = forwardRef<UserDialogRefs, UserDialogProps>((props, ref) => {
  const { updatedUser } = props;

  const commonAlertRef = useRef<CommonAlertRefs>(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [userId, setUserId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState('');
  const [role, setRole] = useState<UserRoleData | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [activationDialog, setDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userRoleList = CreateUserRoleList();

  useImperativeHandle (ref, () => ({
    async openDialog (mode: string, userId: string): Promise<void> {
      try {
        setIsLoading(true);

        switch (mode) {
          case 'add':
            setDialogTitle('追加');
            setUserId('');
            initializeParam();
            break;
          case 'update':
            if (!userId) throw new Error(`Exam template uid is empty`);
  
            const userForUpdate = await GetUser(userId);

            const role = userRoleList.find((x) => x.value === userForUpdate.role);
            if (!role) throw new Error('Could not find role data');

            setDialogTitle('編集');
            setUserId(userId);
            setFirstName(userForUpdate.first_name);
            setLastName(userForUpdate.last_name);
            setEmail(userForUpdate.email);
            setOrganization(userForUpdate.organization);
            setRole(role);
            setIsActive(userForUpdate.is_active);
            break;
          default:
            throw new Error(`Mode is not allowed. mode: ${mode}`);
        };
  
        setMode(mode);
      } catch (error) {
        console.error(error);
        commonAlertRef.current?.error(`ユーザーダイアログが開けませんでした。`);
      } finally {
        setIsLoading(false);
      };
    }
  }));

  useEffect(() => {
    if (mode) setOpenDialog(true);
  }, [mode]);

  useEffect(() => {
    (async () => {
      if (!openDialog) {
        setMode('');
        setUserId('');
        initializeParam();
        if (updatedUser) await updatedUser();
      };
    })()
  }, [openDialog]);

  const initializeParam = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setOrganization('');
    setRole(null);
  };

  const handleUserId = (newValue: string) => {
    setUserId(newValue);
  };

  const confirm = async () => {
    console.log('Confirm user data action');

    try {
      setIsLoading(true);
      if (userId.length < 8) {
        commonAlertRef.current?.error('IDは8文字以上必要です。');
        return;
      } else {
        const regex = new RegExp(/^[a-zA-Z0-9@.\-_]{8,}$/);
        if (!regex.test(userId)) {
          commonAlertRef.current?.error('IDに不正な文字が含まれています。');
          return;
        };
      };
      if (!firstName) { 
        commonAlertRef.current?.error('名は必須項目です。');
        return;
      };
      if (!lastName) {
        commonAlertRef.current?.error('姓は必須項目です。');
        return;
      };
      if (!role) {
        commonAlertRef.current?.error('権限は必須項目です。');
        return;
      };

      const req = new UserData(
        userId,
        firstName,
        lastName,
        email,
        organization,
        role.value,
        isActive
      );

      switch (mode) {
        case 'add':
          const password = await AddUser(req);
          await navigator.clipboard.writeText(password);
          commonAlertRef.current?.success(`ユーザーを追加しました。パスワードはコピーされています。`);
          break;
        case 'update':
          await UpdateUser(userId, req);
          commonAlertRef.current?.success(`ユーザーを更新しました。`);
          break;
        default:
          throw new Error(`Mode is not allowed. mode: ${mode}`);
      };

      setOpenDialog(false);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`ユーザー${dialogTitle}に失敗しました。`);
    } finally {
      setIsLoading(false);
    };
  };

  const initializePassword = async () => {
    console.log('Initialize password');
    
    try {
      if (!isActive) throw new Error('User is not active');

      setPasswordDialog(false);
      setIsLoading(true);

      const newPassword = await InitializeUserPassword(userId);
      await navigator.clipboard.writeText(newPassword);

      commonAlertRef.current?.success(`パスワードを再発行しました。パスワードはコピーされています。`);
      setOpenDialog(false);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`パスワード再発行に失敗しました。`);
    } finally {
      setIsLoading(false);
    };
  };

  const confirmActivation = async () => {
    console.log('Confirm user activation');
    
    try {
      setDeleteDialog(false);
      setIsLoading(true);

      if (isActive) {
        await DeleteUser(userId);
        commonAlertRef.current?.warn(`ユーザーを${getActivationTitle()}しました。`);
      } else {
        const newPassword = await EnableUser(userId);
        await navigator.clipboard.writeText(newPassword);

        commonAlertRef.current?.success(`ユーザーを${getActivationTitle()}しました。初期化パスワードはコピーされています。`);
      };

      setOpenDialog(false);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`ユーザー${getActivationTitle()}に失敗しました。`);
    } finally {
      setIsLoading(false);
    };
  };

  const getActivationTitle = (): string => {
    return isActive ? '無効化' : '有効化';
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='md'
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>{`ユーザー ${dialogTitle}`}</DialogTitle>
        <DialogContent style={{padding: 20}}>
          <Grid 
            container 
            direction="row" 
            justifyContent="space-between" 
            alignItems="center" 
            spacing={2}
          >
            <Grid container item>
              <TextField 
                value={userId} 
                onChange={(event) => handleUserId(event.target.value)} 
                disabled={isLoading || mode !== 'add'}
                label="ID"  
                fullWidth
                required
                InputProps={{
                  inputProps: { max: 150 }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <TextField
                  value={lastName}
                  onChange={(evt) => setLastName(evt.target.value)}
                  label="姓"
                  fullWidth
                  required
                  InputProps={{
                    inputProps: { max: 30 }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={firstName}
                  onChange={(evt) => setFirstName(evt.target.value)}
                  label="名"
                  fullWidth
                  required
                  InputProps={{
                    inputProps: { max: 30 }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item>
              <TextField 
                value={email} 
                onChange={(event) => setEmail(event.target.value)} 
                label="メールアドレス"  
                fullWidth
                InputProps={{
                  inputProps: { max: 150 }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={8}>
                <TextField
                  value={organization}
                  onChange={(evt) => setOrganization(evt.target.value)}
                  label="所属"
                  fullWidth
                  InputProps={{
                    inputProps: { max: 30 }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  value={role}
                  onChange={(evt, newValue) => {
                    setRole(newValue)
                  }}
                  disablePortal
                  options={userRoleList}
                  getOptionLabel={option => option.display}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => 
                    <TextField 
                      {...params} 
                      label="権限"
                      required
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button 
              onClick={(event) => setDeleteDialog(true)} 
              disabled={(mode !== 'update' || isLoading)}
              variant="outlined" 
              color='error'
            >
              {`${getActivationTitle()}する`}
            </Button>
            <Button 
              onClick={(event) => setPasswordDialog(true)} 
              disabled={(mode !== 'update' || !isActive || isLoading)}
              variant="outlined" 
              color='warning'
            >
              パスワード再発行
            </Button>
            <Button 
              onClick={ async (event) => await confirm()} 
              disabled={isLoading}
              variant="contained"
            >
              実行する
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog open={passwordDialog} onClose={() => setPasswordDialog(false)}>
        <DialogTitle>
          {"パスワードを再発行してよろしいですか？"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            パスワードを再発行します。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button onClick={async (event) => setDeleteDialog(false)} variant='outlined'>いいえ</Button>
            <Button onClick={async (event) => await initializePassword()} variant="contained">はい</Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog open={activationDialog} onClose={() => setDeleteDialog(false)}>
        <DialogTitle>
          {`ユーザーを${getActivationTitle()}してよろしいですか?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`ユーザーを${getActivationTitle()}します。`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button onClick={async (event) => setDeleteDialog(false)} variant='outlined'>いいえ</Button>
            <Button onClick={async (event) => await confirmActivation()} variant="contained" color='error'>はい</Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <CommonAlert
        ref={commonAlertRef}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
});

export default UserDialog;