import React, { createContext, useEffect } from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import UseAuth from '../hooks/useAuth';
import UseRefreshToken from '../hooks/useRefreshToken';

const BaseUrl = process.env.REACT_APP_ORIGIN_API_URL;


export const axiosPublicClient = axios.create({
  baseURL: BaseUrl,
  withCredentials: true,
  timeout: 12000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const axiosClient = axios.create({
  baseURL: BaseUrl,
  withCredentials: true,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  }
});

type AxiosClientProps = {
};

const AxiosClientContext = createContext<AxiosClientProps>({});

export const AxiosClientProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const { auth } = UseAuth();
  const refresh = UseRefreshToken();
  
  useEffect(() => {
    const requestInterceptors = axiosClient.interceptors.request.use((config: AxiosRequestConfig) => {
      if (config.headers) {
        const token = auth ? auth.access_token : 'aaa';
        config.headers.Authorization = `Bearer ${token}`;
      };

      return config;
    });

    const responseInterceptor = axiosClient.interceptors.response.use((response: AxiosResponse) => {
      return response;
    },
    async (error: AxiosError) => {
      switch (error.response?.status) {
        case 401:
          console.error('accecptor got 401');
          navigate('/signin', {replace: true});
          break;
        case 403:
          console.error('accecptor got 403');
          const newAccessToken = await refresh(axiosClient);

          if (!newAccessToken) {
            console.error('Could not get new access token');
            navigate('/signin', {replace: true});
          } else {
            const prevRequest = error.config;
            if (prevRequest.headers) {
              prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
              return axiosClient(prevRequest);
            };
          };
          break;
        default:
          console.error('accecptor got default');
          break;
      };
      
      return Promise.reject(error);
    });

    return () => {
      axiosClient.interceptors.request.eject(requestInterceptors);
      axiosClient.interceptors.response.eject(responseInterceptor);
    };
  }, [auth]);

  return (
    <AxiosClientContext.Provider value={{}}>
      { children }
    </AxiosClientContext.Provider>
  );
};