import { Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { forwardRef } from "react";
import { ExamAnswerDrawingData } from "../../objects/exam";
import "./ExamDetectionAnswerAnalysisPanel.css";

export interface ExamDetectionAnswerAnalysisPanelRefs {
};

export type ExamDetectionAnswerAnalysisPanelProps = {
  studentDrawingList: ExamAnswerDrawingData[];
  answerCount: number;
};

export const ExamDetectionAnswerAnalysisPanel = forwardRef<ExamDetectionAnswerAnalysisPanelRefs, ExamDetectionAnswerAnalysisPanelProps>((props, refs) => {
  const {  studentDrawingList, answerCount } = props;

  const handleShowResult = () => {
    if (0 < studentDrawingList.filter((x) => x.is_correct === null && !x.is_ignored).length) return false;
    else return true;
  };

  const handlePrecisitonResult = () => {
    const noIgnoredDrawingList = studentDrawingList.filter((x) => !x.is_ignored);

    const top = noIgnoredDrawingList.filter((x) => x.is_correct === true).length;
    const bottom = noIgnoredDrawingList.length;

    if (top === 0 && 0 < bottom) return 0;
    if (bottom === 0) return null;
    
    const result = top / bottom;
    return result;
  };

  const handleRecallResult = () => {
    const noIgnoredDrawingList = studentDrawingList.filter((x) => !x.is_ignored);

    const top = noIgnoredDrawingList.filter((x) => x.is_correct === true).length;
    const bottom = answerCount;

    if (top === 0 && 0 < bottom) return 0;
    if (bottom === 0) return null;

    const result = top / bottom;
    return result;
  };

  const handleFMeasureResult = () => {
    const precision = handlePrecisitonResult();
    const recall = handleRecallResult();

    if (precision === null) return null;
    if (recall === null) return null;

    const top = 2 * precision * recall;
    const bottom = (precision + recall);

    if (top === 0) return 0;
    if (bottom === 0) return null;
    
    const result =  top / bottom;
    return result;
  };

  const handlePrecisionDisplay = () => {
    if (!handleShowResult()) return "-";

    const result = handlePrecisitonResult();
    if (result === null) return "NaN";
    return result.toFixed(2);
  };

  const handleRecallDisplay = () => {
    if (!handleShowResult()) return "-";

    const result = handleRecallResult();
    if (result === null) return "NaN";
    return result.toFixed(2);
  };

  const handleFmeasureDisplay = () => {
    if (!handleShowResult()) return "-";

    const result = handleFMeasureResult();
    if (result === null) return "NaN";
    return result.toFixed(2);
  };

  return (
    <Paper className="ExamDetectionAnswerAnalysisPanel-paper">
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={1}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Typography className="ExamDetectionAnswerAnalysisPanel-title">{"適合率"}</Typography>
          <Typography>
            {`${handlePrecisionDisplay()}`}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Typography className="ExamDetectionAnswerAnalysisPanel-title">{"感度・再現率"}</Typography>
          <Typography>
            {`${handleRecallDisplay()}`}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Typography className="ExamDetectionAnswerAnalysisPanel-title">{"F値"}</Typography>
          <Typography>
            {`${handleFmeasureDisplay()}`}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  )
});