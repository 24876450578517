import { axiosClient } from "../contexts/axiosClientProvider";
import { ExamAnswerData } from "../objects/exam";

export const GetExamAnswerList = async (
  useIsSecond: boolean,
  examStudentUid: number | null,
  examQuestionUid: number | null,
  onlyUnchecked: boolean | null,
  isSecond: boolean | null): Promise<ExamAnswerData[]> => {
  let url = `/exam/answers?use_is_second=${useIsSecond}`;

  if (examStudentUid !== null) {
    url = url + `&exam_student_uid=${examStudentUid}`;
  };

  if (examQuestionUid !== null) {
    url = url + `&exam_question_uid=${examQuestionUid}`;
  };

  if (onlyUnchecked !== null) {
    url = url + `&only_unchecked=${onlyUnchecked}`;
  };

  if (useIsSecond && isSecond !== null) {
    url = url + `&is_second=${isSecond}`;
  };

  const response = await axiosClient.get<ExamAnswerData[]>(url);
  return response.data;
};

export const UpdateExamAnswerMarupeke = async (examAnswerUid: number, newValue: boolean | null): Promise<void> => {
  let url = `/exam/answers/${examAnswerUid}/marupeke`;

  const body = {
    new_value: newValue
  };

  const response = await axiosClient.put(url, body);
};