import { Info, KeyboardReturn, NavigateBefore, NavigateNext, VideoFile } from "@mui/icons-material";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { forwardRef, Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GradingAnswerViewerHeaderPanelRefs, GradingAnswerViewerHeaderPanelProps } from "../../interfaces/viewer/gradingAnswerViewerHeaderPanelInterface";
import { VideoDialogKeyType, VideoDialogRefs } from "../../interfaces/viewer/videoDialogInterface";
import { ExamQuestionData } from "../../objects/exam";
import { GetExamQuestion } from "../../repositories/examQuestionRepo";
import CommonHtmlTooltip from "../commonHtmlTooltip";
import './gradingAnswerViewerHeaderPanel.css';
import VideoDialog from "./videoDialog";


const GradingAnswerViewerHeaderPanel = forwardRef<GradingAnswerViewerHeaderPanelRefs, GradingAnswerViewerHeaderPanelProps>((props, ref) => {
  const navigate = useNavigate();
  
  const { examUid, examQuestionUid, caseData, clickedMovingQuestion } = props;

  const [examQuestionData, setExamQuestionData] = useState<ExamQuestionData | null>(null);
  const [prevExamQuestionUid, setPrevExamQuestonUid] = useState<number | null>(null);
  const [nextExamQuestionUid, setNextExamQuestionUid] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const videoDialogRef = useRef<VideoDialogRefs>(null);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        await getExamQuestion();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      };
    })()
  }, []);

  const getExamQuestion = async () => {
    try {
      const result = await GetExamQuestion(examQuestionUid);
      setExamQuestionData(result.data);
      setPrevExamQuestonUid(result.prev_uid);
      setNextExamQuestionUid(result.next_uid);
    } catch (error) {
      console.error(error);
    };
  };

  const handleClickedPrevQuestion = () => {
    if (!prevExamQuestionUid) return;
    if (clickedMovingQuestion) clickedMovingQuestion(prevExamQuestionUid);
  };

  const handleClickedNextQuestion = () => {
    if (!nextExamQuestionUid) return;
    if (clickedMovingQuestion) clickedMovingQuestion(nextExamQuestionUid);
  };

  const openVideoDialog = async () => {
    if (!videoDialogRef.current) return;
    if (!examQuestionData) return;
    await videoDialogRef.current.openDialog(VideoDialogKeyType.ExamTemplateQuestionUid, examQuestionData.exam_template_question_uid, false);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0.5}
        >
          <CommonHtmlTooltip placement="left" title={
            <Fragment>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid container item xs={3}>
                  <Typography color="inherit">{`症例番号`}</Typography>
                </Grid>
                <Grid container item xs={9}>
                  <Typography color="inherit">{`:${caseData ? caseData.id : '???'}`}</Typography>
                </Grid>
                <Grid container item xs={3}>
                  <Typography color="inherit">{`施設`}</Typography>
                </Grid>
                <Grid container item xs={9}>
                  <Typography color="inherit">{`:${caseData ? caseData.facility_name : '???'}`}</Typography>
                </Grid>
                <Grid container item xs={3}>
                  <Typography color="inherit">{`ベンダー`}</Typography>
                </Grid>
                <Grid container item xs={9}>
                  <Typography color="inherit">{`:${caseData ? caseData.vendor_name : '???'}`}</Typography>
                </Grid>
                <Grid container item xs={3}>
                  <Typography color="inherit">{`Q UID`}</Typography>
                </Grid>
                <Grid container item xs={9}>
                  <Typography color="inherit">{`:${examQuestionData ? examQuestionData.uid : '???'}`}</Typography>
                </Grid>
              </Grid>
            </Fragment>
          }>
            <IconButton edge="end" size="small" color="primary">
              <Info />
            </IconButton>
          </CommonHtmlTooltip>
          <IconButton 
            onClick={(evt) => openVideoDialog()} 
            disabled={isLoading}
            edge='end' size="small" color="primary"
          >
            <VideoFile />
          </IconButton>
        </Stack>
        <IconButton 
          onClick={(evt) => handleClickedPrevQuestion()}
          disabled={isLoading || !prevExamQuestionUid}
          edge="end" size="small" color="primary"
        >
          <NavigateBefore />
        </IconButton>
        <div>{examQuestionData ? examQuestionData.question_number : '-'}</div>
        <IconButton 
          onClick={(evt) => handleClickedNextQuestion()}
          disabled={isLoading || !nextExamQuestionUid}
          edge="end" size="small" color="primary"
        >
          <NavigateNext />
        </IconButton>
        <IconButton 
          onClick={(evt) => navigate(`/exams/grading/${examUid}`)}
          edge="end" size="small" color="primary"
        >
          <KeyboardReturn />
        </IconButton>
      </Stack>
      <VideoDialog
        ref={videoDialogRef}
      />
    </>
  )
});

export default GradingAnswerViewerHeaderPanel;