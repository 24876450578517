import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack, TextField } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { ExamDialogProps, ExamDialogRefs } from "../../interfaces/exam/examDialogInterface";
import { CreateExamFormatDataList, ExamCreateData, ExamFormatData, ExamStudentTagFormatData } from "../../objects/exam";
import { AddExam, DeleteExam, GetExam, UpdateExam } from "../../repositories/examRepo";
import CommonAlert from "../commonAlert";
import { ExamStudentTagFormatRow } from "./ExamStudentTagFormatRow";


const ExamDialog = forwardRef<ExamDialogRefs, ExamDialogProps>((props, ref) => {
  const { examUid, examTemplateUid, updatedExamData } = props;

  const commonAlertRef = useRef<CommonAlertRefs>(null);

  const defaultStudentCount = 30;
  const defaultTimeLimitMinutes = 120;
  const defaultExamFormat = new ExamFormatData('全体でランダム', 'random_per_exam');

  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [examFormat, setExamFormat] = useState<ExamFormatData>(defaultExamFormat);
  const [detectionAnswerMaximumLimit, setDetectionAnswerMaximumLimit] = useState(5);
  const [timeLimitMinutes, setTimeLimitMinutes] = useState(defaultTimeLimitMinutes);
  const [studentsCount, setStudentsCount] = useState(defaultStudentCount);
  const [studentsTagFormatList, setStudentsTagFormatList] = useState<ExamStudentTagFormatData[]>([]);
  const [studentCountMin, setStudentCountMin] = useState(1);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const examFormatList = CreateExamFormatDataList();

  useImperativeHandle (ref, () => ({
    async openDialog (mode: string): Promise<void> {
      try {
        switch (mode) {
          case 'add':
            setDialogTitle('追加');
            initializeParam();
            break;
          case 'update':
            if (!examUid) throw new Error('Exam uid is empty');

            const examData = await GetExam(examUid);
            
            setDialogTitle('編集');
            setTitle(examData.title);
            const examFormat = examFormatList.find((x) => x.value === examData.format);
            setExamFormat(examFormat ? examFormat : defaultExamFormat);
            setDetectionAnswerMaximumLimit(examData.detection_answer_maximum_limit);
            setDescription(examData.description);
            setTimeLimitMinutes(examData.time_limit_minutes);
            setStudentsCount(examData.students_count);
            setStudentCountMin(examData.students_count);

            const tagList: ExamStudentTagFormatData[] = [];

            if (examData.students_tag_format) {
              const studentsTagFormatJson = JSON.parse(examData.students_tag_format);

              studentsTagFormatJson.forEach((x: any) => {
                tagList.push(new ExamStudentTagFormatData(
                  x.name,
                  x.display,
                  x.output
                ));
              });
            };

            setStudentsTagFormatList(tagList);
            break;
          default:
            throw new Error(`Mode is not allowed. mode: ${mode}`);
        };

        setMode(mode);
      } catch (error) {
        console.error(error);
        commonAlertRef.current?.error(`試験ダイアログが開けませんでした。`);
      };
    }
  }));

  useEffect(() => {
    if (mode) setOpenDialog(true);
  }, [mode]);

  useEffect(() => {
    if (!openDialog) {
      setMode('');
      initializeParam();
    };
  }, [openDialog]);

  const handleClickAddTagButton = () => {
    setStudentsTagFormatList([...studentsTagFormatList, new ExamStudentTagFormatData("", "", true)]);
  };

  const handleEditTag = (targetIndex: number, newData: ExamStudentTagFormatData) => {
    setStudentsTagFormatList(studentsTagFormatList.map((x, index) => (
      index === targetIndex
      ? newData
      : x
    )));
  };

  const handleDeleteTag = (targetIndex: number) => {
    setStudentsTagFormatList(studentsTagFormatList.filter((x, index) => index !== targetIndex));
  };

  const initializeParam = () => {
    setTitle('');
    setDescription('');
    setTimeLimitMinutes(defaultTimeLimitMinutes);
    setStudentsCount(defaultStudentCount);
    setExamFormat(defaultExamFormat);
  };

  const confirm = async () => {
    console.log('Confirm exam data action');
    
    try {
      setIsLoading(true);

      const req = new ExamCreateData(
        title,
        description,
        examFormat.value,
        detectionAnswerMaximumLimit,
        timeLimitMinutes,
        studentsCount,
        JSON.stringify(studentsTagFormatList.filter((x) => x.name && x.display)),
        new Date(),
        new Date(),
        examTemplateUid
      );
      
      switch (mode) {
        case 'add':
          await AddExam(req);
          break;
        case 'update':
          if (!examUid) throw new Error('Exam uid is empty');
          await UpdateExam(examUid, req);
          break;
        default:
          throw new Error(`Mode is not allowed. mode: ${mode}`);
      };

      commonAlertRef.current?.success(`試験を${dialogTitle}しました。\n試験管理画面よりご確認ください。`);
      setOpenDialog(false);
      if (updatedExamData) await updatedExamData();
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`試験${dialogTitle}に失敗しました。`);
    } finally {
      setIsLoading(false);
    };
  };

  const deleteExamData = async () => {
    console.log('Delete section data');
    try {
      if (!examUid) throw new Error('Exam uid is empty');
      setIsLoading(true);

      await DeleteExam(examUid);
      
      commonAlertRef.current?.warn(`試験を削除しました。`);
      setOpenDeleteDialog(false);
      setOpenDialog(false);
      if (updatedExamData) await updatedExamData();
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`試験削除に失敗しました。`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='lg'
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>{`試験 ${dialogTitle}`}</DialogTitle>
        <DialogContent style={{padding: 20}}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={2}
          >
            <TextField 
              value={title} 
              onChange={(event) => setTitle(event.target.value)} 
              label="タイトル" 
              variant="outlined" 
              size="small"
              fullWidth
            />
            <TextField 
              value={description} 
              onChange={(event) => setDescription(event.target.value)} 
              multiline 
              rows={5} 
              inputProps={{
                maxLength: 250,
              }}
              label='概要'
              fullWidth
            />
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
              spacing={2}
              style={{width: '100%'}}
            >
              <TextField
                value={studentsCount}
                onChange={(evt) => setStudentsCount(Number(evt.target.value))}
                label="被験者数"
                type="number"
                InputProps={{
                  inputProps: { min: studentCountMin, max: 100 }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                value={detectionAnswerMaximumLimit}
                onChange={(evt) => setDetectionAnswerMaximumLimit(Number(evt.target.value))}
                label="検出問題解答上限数"
                type="number"
                InputProps={{
                  inputProps: { min: 1, max: 20 }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={mode !== 'add'}
                style={{width: '150px'}}
              />
            </Stack>
            {studentsTagFormatList.map((x, index) => (
              <ExamStudentTagFormatRow
                key={index}
                index={index}
                row={x}
                edit={handleEditTag}
                deleteRow={handleDeleteTag}
              />
            ))}
            <Button
              onClick={(evt) => handleClickAddTagButton()}
              variant="outlined"
            >
              {"被験者タグを追加"}
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button 
              onClick={(event) => setOpenDeleteDialog(true)} 
              disabled={(mode !== 'update')}
              variant="outlined" 
              color='error'
            >
              削除する
            </Button>
            <Button 
              onClick={ async (event) => await confirm()} 
              disabled={title === '' || examFormat.value === '' || timeLimitMinutes < 1 || studentsCount < 1}
              variant="contained"
            >
              実行する
            </Button>
          </Grid>
        </DialogActions>
        <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
          <DialogTitle>
            {"試験を削除してよろしいですか?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              試験を削除します、削除された試験は復元できません。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button onClick={async (event) => setOpenDeleteDialog(false)} variant='outlined'>いいえ</Button>
              <Button onClick={async (event) => await deleteExamData()} variant="contained" color='error'>はい</Button>
            </Grid>
          </DialogActions>
        </Dialog>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      <CommonAlert
        ref={commonAlertRef}
      />
    </>
  )
});

export default ExamDialog;