import { Tooltip } from "@mui/material";
import { ReactElement } from "react";

export type CommonTooltipProps = {
  title: string;
  children: ReactElement;
};

export const CommonTooltip: React.FC<CommonTooltipProps> = (props) => {
  const { title, children } = props;

  return (
    <Tooltip title={title} placement="bottom-end" arrow>
      <span>{children}</span>
    </Tooltip>
  );
};
