import { Dialog, DialogTitle, DialogContent, DialogContentText, FormControlLabel, DialogActions, Button, FormGroup, Checkbox, Backdrop, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { DownloadExamResult } from "../../repositories/ExamsDownloadRepo";
import CommonAlert from "../commonAlert";

export interface ExamResultDownloadDialogRefs {
  openDialog: () => void;
};

export type ExamResultDownloadDialogProps = {
  examUid: number;
  examTitle: string;
};

export const ExamResultDownloadDialog = forwardRef<ExamResultDownloadDialogRefs, ExamResultDownloadDialogProps>((props, refs) => {
  const { examUid, examTitle } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [answerIsChecked, setAnswerIsChecked] = useState(true);
  const [actionLogIsChecked, setActionLogIsChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const commonAlertRef = useRef<CommonAlertRefs>(null);

  useImperativeHandle(refs, () => ({
    openDialog: () => {
      if (examUid < 1) return;
      handleClickDownloadButton();
    }
  }));

  useEffect(() => {

  }, [openDialog]);

  const handleDownloadButtonDisabled = (): boolean => {
    if (!answerIsChecked && !actionLogIsChecked) return true;
    else return false;
  };

  const handleClickDownloadButton = async () => {
    try {
      setIsLoading(true);
      
      await DownloadExamResult(
        examUid,
        answerIsChecked,
        actionLogIsChecked,
        `${examUid}_${examTitle}_試験結果データ`
      );

      setIsLoading(false);
      setOpenDialog(false);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('試験結果データのダウンロードに失敗しました。');
    } finally {
      setIsLoading(false);
    };
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>{'試験結果ダウンロード'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`試験番号: ${examUid}`}
          </DialogContentText>
          <Stack
            direction='column'
            justifyContent='stretch'
            alignItems='flex-start'
            spacing={1}
            width='100%'
          >
            <FormGroup>
              <FormControlLabel 
                control={
                  <Checkbox 
                    checked={answerIsChecked}
                    onChange={() => setAnswerIsChecked(!answerIsChecked)}
                  />
                } 
                label="解答データ" 
              />
              <FormControlLabel 
                control={
                  <Checkbox 
                    checked={actionLogIsChecked}
                    onChange={() => setActionLogIsChecked(!actionLogIsChecked)}
                  />
                } 
                label="操作ログ" 
              />
            </FormGroup>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            spacing={1}
            width='100%'
          >
            <Button 
            onClick={handleCloseDialog}
            variant='outlined'
          >
            {'閉じる'}
          </Button>
          <Button 
            onClick={handleClickDownloadButton}
            disabled={handleDownloadButtonDisabled()}
            variant='contained'
          >
            {'ダウンロード'}
          </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CommonAlert
        ref={commonAlertRef}
      />
    </>
  )
});