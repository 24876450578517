import { Axios } from "axios";
import { AuthedUser } from "../objects/auth";
import UseAuth from "./useAuth";
import UseImageAuth from "./useImageAuth";


const UseRefreshToken = () => {
  const { setAuth } = UseAuth();
  const { setImageAuth } = UseImageAuth();

  const refresh = async (client: Axios): Promise<string> => {
    let token = '';
    
    await client.get('/signin/refresh', {
      withCredentials: true
    }).then((res) => {
      setAuth(new AuthedUser(
        res.data.id,
        res.data.first_name,
        res.data.last_name,
        res.data.email,
        res.data.role,
        res.data.access_token
      ));
      setImageAuth(res.data.image_token);
      
      token = res.data.access_token;
    }).catch((error) => {
      console.error(error);
    });

    return token;
  };

  return refresh;
};

export default UseRefreshToken;