import { axiosClient } from "../contexts/axiosClientProvider";
import { HealthCheckExamData, HealthCheckExamQuestionData, HealthCheckExamStudentData } from "../objects/healthcheck";


export const HealthCheckExam = async (examUid: number): Promise<HealthCheckExamData> => {
  const url = `/healthcheck/exams/${examUid}`;
  const response = await axiosClient.get<HealthCheckExamData>(url);
  return response.data;
};

export const HealthCheckExamQuestion = async (examQuestionUid: number): Promise<HealthCheckExamQuestionData> => {
  const url = `/healthcheck/exams/questions/${examQuestionUid}`;
  const response = await axiosClient.get<HealthCheckExamQuestionData>(url);
  return response.data;
};

export const HealthCheckExamStudent = async (studentUid: number): Promise<HealthCheckExamStudentData> => {
  const url = `/healthcheck/exams/students/${studentUid}`;
  const response = await axiosClient.get<HealthCheckExamStudentData>(url);
  return response.data;
};