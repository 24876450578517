import { Delete, DeleteOutline, Edit, Person, PersonOff, PersonOffOutlined } from "@mui/icons-material";
import { Backdrop, Button, Checkbox, CircularProgress, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import CommonHeader from "../compornents/commonHeader";
import './UserListPage.css';
import { Stack } from "@mui/system";
import { CreateUserRoleList, UserData } from "../objects/user";
import { GetUserList } from "../repositories/userRepo";
import UserDialog from "../compornents/user/userDIalog";
import { UserDialogRefs } from "../interfaces/user/userDialogInterface";


const UserListPage: FC = () => {
  const userDialogRef = useRef<UserDialogRefs>(null);

  const [userList, setUserList] = useState<UserData[]>([]);
  const [filterKeyword, setFilterKeyword] = useState('');
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userRoleList = CreateUserRoleList();

  // initialize.
  useEffect( () => {
    (async () => {
      await getUserList();
    })()
  }, []);

  const getUserList = async () => {
    try {
      setIsLoading(true);
      const list = await GetUserList(filterKeyword, includeDeleted);
      setUserList(list);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterKeyword = (newValue: string) => {
    setFilterKeyword(newValue);
  };

  const clickedAddUserButton = () => {
    userDialogRef.current?.openDialog('add', '');
  };

  const clickedEditUserButton = (userId: string) => {
    userDialogRef.current?.openDialog('update', userId);
  };

  const updatedUser = async () => {
    await getUserList();
  };

  return (
    <Grid container>
      <CommonHeader headerTitle="ユーザー管理"/>
      <div className="UserListPage-main-div">
        <Paper className="UserListPage-filter-paper">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid 
              container 
              item 
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              xs={6}
            >
              <Stack direction="row" spacing={1} style={{width: '100%'}}>
                <TextField 
                  value={filterKeyword} 
                  onChange={(event) => handleFilterKeyword(event.target.value)} 
                  label="キーワード" variant="outlined" size="small" style={{width: '70%'}}
                />
                <Checkbox 
                  checked={includeDeleted} 
                  onChange={(evt) => setIncludeDeleted(includeDeleted ? false : true)}
                  icon={<PersonOffOutlined />} 
                  checkedIcon={<PersonOff color="error" />}
                  size='small' 
                />
                <Button 
                  onClick={async () => await getUserList()} 
                  color="primary" 
                  variant="contained"
                >
                  検索する
                </Button>
              </Stack>
            </Grid>
            <Grid 
              container 
              item 
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              xs={6}
            >
              <Button 
                onClick={() => clickedAddUserButton()}
                variant="outlined" 
                color="primary"
              >
                追加する
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <TableContainer component={Paper} className='UserListPage-exams-table'>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
              <TableCell style={{ minWidth: 20 }}></TableCell>
                <TableCell style={{ minWidth: 300 }}>名前</TableCell>
                <TableCell style={{ width: '100%', minWidth: 100 }}>ID</TableCell>
                <TableCell style={{ minWidth: 450 }}>所属</TableCell>
                <TableCell style={{ minWidth: 200 }}>権限</TableCell>
                <TableCell style={{ minWidth: 30 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList.map((user) => (
                <TableRow hover tabIndex={-1} key={user.id}>
                  <TableCell>
                    {user.is_active
                      ? <Person fontSize="small"></Person>
                      : <PersonOff fontSize="small" color="error"></PersonOff>
                    }
                  </TableCell>
                  <TableCell>{ `${user.last_name} ${user.first_name}` }</TableCell>
                  <TableCell>{ user.id }</TableCell>
                  <TableCell>{ user.organization }</TableCell>
                  <TableCell>{ userRoleList.find((x) => x.value === user.role) ? userRoleList.find((x) => x.value === user.role)?.display : '???' }</TableCell>
                  <TableCell align="right">
                    <Stack direction='row' spacing={1}>
                      <IconButton 
                        onClick={(event) => clickedEditUserButton(user.id)} 
                        edge="end" size="small" color="primary"
                      >
                        <Edit />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <UserDialog
        ref={userDialogRef}
        updatedUser={async () => await updatedUser()}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default UserListPage;