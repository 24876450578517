import { ShapeData } from "./shapeData";

export class DrawingData {
  uid: string;
  imageIndex: number;
  data: Blob;
  shapeData: ShapeData;
  fileName: string;

  constructor (uid: string, imageIndex: number, data: Blob, shapeData: ShapeData, fileName: string) {
    this.uid = uid;
    this.imageIndex = imageIndex;
    this.data = data;
    this.shapeData = shapeData;
    this.fileName = fileName;
  }
}