import { ContentCopy, ContentPaste, Edit } from "@mui/icons-material";
import { Backdrop, Chip, CircularProgress, IconButton, Stack, TableCell, TableRow } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { ExamDialogRefs } from "../../interfaces/exam/examDialogInterface";
import { ExamTemplateRowProps } from "../../interfaces/examTemplate/examTemplateRowInterface";
import CommonAlert from "../commonAlert";
import './examTemplateRow.css';
import ExamDialog from "../exam/examDialog";
import ExamTemplateDialog from "./examTemplateDialog";
import { ExamTemplateDialogRefs } from "../../interfaces/examTemplate/examTemplateDialogInterface";
import { ExamTemplateStatus } from "../../objects/examTemplate";


const ExamTemplateRow: FC<ExamTemplateRowProps> = (props: ExamTemplateRowProps) => {
  const navigate = useNavigate();

  const { row, reloadExamTemplateData } = props;

  const templateDialogRef = useRef<ExamTemplateDialogRefs>(null);
  const examDialogRef = useRef<ExamDialogRefs>(null);
  const commonAlertRef = useRef<CommonAlertRefs>(null);

  const [isLoading, setIsLoading] = useState(false);

  const openExamTemplateDialog = async (mode: string) => {
    try {
      setIsLoading(true);
      await templateDialogRef.current?.openDialog(mode, row.uid);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const moveToEditorPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('Move to editer page');
    try {
      navigate(`/exams/templates/${row.uid}`, {state: { useSession: false }});
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`試験テンプレート編集画面への移動に失敗しました。`);
    } finally {
      event.stopPropagation();
    };
  };

  const openExamDialog = () => {
    examDialogRef.current?.openDialog('add', -1, row.uid);
  };

  const updatedExamTemplateData = async () => {
    await reloadExamTemplateData();
  };

  const getStatusColor = (status: ExamTemplateStatus): "default" | "warning" | "primary" | "error" | "info" | "secondary" | "success" | undefined => {
    switch (status) {
      case ExamTemplateStatus.Editing:
        return 'warning';
      case ExamTemplateStatus.Ready:
        return 'primary';
      case ExamTemplateStatus.Deleted:
        return 'error';
      default:
        return 'info';
    }
  };

  return (
    <>
      <TableRow hover tabIndex={-1} key={row.uid}>
        <TableCell align="right" padding='none'>
          <Stack direction='row' spacing={1}>
            <IconButton 
              onClick={async (event) => openExamTemplateDialog('update')} 
              edge="end" size="small" color="primary"
            >
              <Edit />
            </IconButton>
            <IconButton 
              onClick={async (event) => openExamTemplateDialog('copy')}
              edge="end" size="small" color="primary"
            >
              <ContentCopy />
            </IconButton>
          </Stack>
        </TableCell>
        <TableCell>{ row.title }</TableCell>
        <TableCell>{ row.description }</TableCell>
        <TableCell>
          <Chip label={row.status} variant="outlined" color={getStatusColor(row.status)} />
        </TableCell>
        <TableCell align="right" padding='none'>
          <Stack direction='row' spacing={1}>
            <IconButton 
              onClick={async (event) => moveToEditorPage(event)} 
              edge="end" size="small" color="primary"
            >
              <MenuIcon />
            </IconButton>
            <IconButton 
              onClick={async (event) => openExamDialog()}
              disabled={row.status !== 'ready'} 
              edge="end" size="small" color="primary"
            >
              <ContentPaste />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <ExamTemplateDialog
        ref={templateDialogRef}
        updatedTemplateData={async () => await updatedExamTemplateData()}
      />
      <ExamDialog
        ref={examDialogRef}
        examUid={null}
        examTemplateUid={row.uid}
        updatedExamData={async () => await updatedExamTemplateData()}
      />
      <CommonAlert
        ref={commonAlertRef}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
};

export default ExamTemplateRow;