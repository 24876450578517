import { Image } from "@mui/icons-material";
import { CircularProgress, Grid, IconButton, Popover, Typography } from "@mui/material";
import { FC, useState } from "react";
import { CaseImageButtonProps } from "../../interfaces/viewer/caseImageButtonInterface";
import { GetAnnotationImageData } from "../../repositories/caseRepo";
import './caseImageButton.css';


const CaseImageButton: FC<CaseImageButtonProps> = (props: CaseImageButtonProps) => {
  const { caseImageUid } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);

  const handlePopoverOpen = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (!imageBlob && caseImageUid) {
      const imageData = await GetAnnotationImageData(caseImageUid);
      setImageBlob(imageData);
    };
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton 
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        disabled={caseImageUid === null}
        edge="end" size="small" color={caseImageUid !== null ? "primary" : 'default'}
      >
        <Image />
      </IconButton>
      <Popover
        id={`mouse-pop-over-${caseImageUid}`}
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}      
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div>
          {imageBlob
            ? <img 
                src={imageBlob ? URL.createObjectURL(imageBlob) : undefined} 
                width={600} 
                height={'auto'}
              />
            : <Grid 
                container
                direction="column"
                justifyContent="center"
                alignItems="center" 
                style={{width: '100px', height: '100px'}}
              >
                <CircularProgress color="primary" />
              </Grid>
          }
        </div>
      </Popover>
    </>
  )
};

export default CaseImageButton;