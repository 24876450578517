import { CloudDownload, CloudUpload, Grading, PersonOff } from "@mui/icons-material";
import { Backdrop, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, Stack, TableCell, TableRow } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { ExamStudentRowProps } from "../../interfaces/exam/examStudentRowInterface";
import { UploadExamStudentAnswerData } from "../../repositories/examStudentRepo";
import CommonAlert from "../commonAlert";
import { ExamStudentResultDownloadDialog, ExamStudentResultDownloadDialogRefs } from "./ExamStudentResultDownloadDialog";
import './examStudentResultRow.css';


const ExamStudentRow: FC<ExamStudentRowProps> = (props: ExamStudentRowProps) => {
  const { examUid, row, checkedRow, updatedData } = props;

  const navigate = useNavigate();

  const [studentResultFile, setStudentResultFile] = useState<File | null>(null);
  const [confirmUploadResultDialog, setConfirmUploadResultDialog] = useState(false);
  const [overwriteResult, setOverwriteResult] = useState(false);
  const [reScoreQuestion, setReScoreQuestion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const examStudentResultDownloadDialogRef = useRef<ExamStudentResultDownloadDialogRefs>(null);
  const commonAlertRef = useRef<CommonAlertRefs>(null);

  useEffect(() => {
    if (studentResultFile) setConfirmUploadResultDialog(true);
  }, [studentResultFile]);

  useEffect(() => {
    if (!confirmUploadResultDialog) {
      setStudentResultFile(null);
      setOverwriteResult(false);
    }
  }, [confirmUploadResultDialog]);

  const handleRowChecked = (uid: number) => {
    if (checkedRow) checkedRow(uid);
  };

  const handleTagList = (tagList: string) => {
    if (!tagList) return [];

    const tagJson = JSON.parse(tagList);

    const result: string[] = [];
    tagJson.forEach((x: any) => {
      if (x.value) result.push(x.value);
    });
    return result;
  };

  const moveToStudentResultPage = (event: React.MouseEvent<HTMLButtonElement>, studentUid: number) => {
    try {
      if (!examUid) throw new Error('Exam uid is empty');

      navigate(`/exams/students/results/${examUid}/${studentUid}`);
    } catch (error) {
      console.error(error);
    };
  };

  const handleClickOpenExamDownloadDialogButton = () => {
    examStudentResultDownloadDialogRef.current?.openDialog();
  };

  const handleUploadResultFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(`Handle upload result file change -> studentUid: ${row.data.uid}`);
    try {
      if (!event.target.files) throw new Error('Files are empty');
      setIsLoading(true);
      
      setStudentResultFile(event.target.files[0]);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('解答情報ファイルのセットに失敗しました。');
    } finally {
      setIsLoading(false);
    };
  };

  const uploadExamStudentAnswerData = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    console.log(`Upload student answer data -> studentUid: ${row.data.uid}`);
    try {
      setIsLoading(true);
      setConfirmUploadResultDialog(false);

      if (!studentResultFile) {
        commonAlertRef.current?.error('被験者解答データファイルの読み込みに失敗しました。');
        return;
      };

      const fileName = `${examUid}_${row.data.uid}_${row.data.sign_in_id}_解答データアップロード`;
      await UploadExamStudentAnswerData(row.data.uid, overwriteResult, reScoreQuestion, studentResultFile, fileName);

      if (updatedData) updatedData();
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('被験者解答データのアップロードに失敗しました。');
    } finally {
      setIsLoading(false);
    };
  };

  return (
    <>
      <TableRow hover>
        <TableCell padding="checkbox">
          <Checkbox 
            checked={row.isChecked}
            onChange={(evt) => handleRowChecked(row.data.uid)} 
            disabled={isLoading}
            size="small"
          />
        </TableCell>
        <TableCell>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            width="100%"
          >
            { row.data.sign_in_id }
            { !row.data.is_active
              ? <PersonOff color="error"/>
              : null
            }
          </Stack>
        </TableCell>
        <TableCell>{ row.data.name }</TableCell>
        <TableCell>
        <Stack 
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={0.5}
        >
          {row.data.tag_list && 
            handleTagList(row.data.tag_list).map((tag, index) => (
              <Chip key={index} label={tag} color="primary" variant="outlined" size="small" style={{marginRight: '3px'}}/>
            ))
          }
        </Stack>
        </TableCell>
        <TableCell>{ row.data.ended !== null ? 'YES' : 'NO' }</TableCell>
        <TableCell>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <IconButton 
            onClick={(evt) => handleClickOpenExamDownloadDialogButton()}
            disabled={isLoading || row.data.ended === null}
            edge="end" size="small" color="primary"
          >
            <CloudDownload />
          </IconButton>
          <IconButton 
            disabled={isLoading}
            edge='end' size="small" color="primary" component="label"
          >
            <input 
              onChange={(evt) => {
                handleUploadResultFileChange(evt);
                evt.target.value = '';
              }}
              hidden 
              accept="application/zip" 
              type="file"
            />
            <CloudUpload />
          </IconButton>
          <IconButton 
            onClick={(evt) => moveToStudentResultPage(evt, row.data.uid)}
            disabled={isLoading}
            edge="end" size="small" color="primary"
          >
            <Grading />
          </IconButton>
        </Stack>
        </TableCell>
      </TableRow>
      <ExamStudentResultDownloadDialog
        ref={examStudentResultDownloadDialogRef}
        examUid={examUid}
        examStudent={row.data}
      />
      {studentResultFile &&
        <Dialog open={confirmUploadResultDialog} onClose={() => setConfirmUploadResultDialog(false)}>
          <DialogTitle>
            {"解答情報をアップロードしてよろしいですか?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{whiteSpace: 'pre-wrap'}}>
              {
                `解答情報をアップロードします。\n被験者ID: ${row.data.sign_in_id}\nファイル名: ${studentResultFile.name}`
              }
            </DialogContentText>
            <FormControlLabel 
              disabled={isLoading} 
              control={
                <Checkbox 
                  checked={overwriteResult}
                  onChange={(evt) => setOverwriteResult(!overwriteResult)} 
                  disabled={isLoading}
                  size="small"
                />
              } 
              label="上書きする"
            />
            <FormControlLabel 
              disabled={isLoading} 
              control={
                <Checkbox 
                  checked={reScoreQuestion}
                  onChange={(evt) => setReScoreQuestion(!reScoreQuestion)} 
                  disabled={isLoading}
                  size="small"
                />
              } 
              label="自動で再評価する"
            />
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button onClick={(event) => setConfirmUploadResultDialog(false)} variant='outlined'>いいえ</Button>
              <Button onClick={async (event) => await uploadExamStudentAnswerData(event)} variant="contained" color='primary'>はい</Button>
            </Grid>
          </DialogActions>
        </Dialog>
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CommonAlert
        ref={commonAlertRef}
      />
    </>
  )
};

export default ExamStudentRow;