export class UserData {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  organization: string;
  role: number;
  is_active: boolean;

  constructor (
    id: string, 
    first_name: string, 
    last_name: string, 
    email: string, 
    organization: string,
    role: number,
    is_active: boolean) {
    this.id = id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.organization = organization;
    this.role = role;
    this.is_active = is_active;
  };
};

export class UserCreate extends UserData {
  password: string;

  constructor(
    id: string, 
    first_name: string, 
    last_name: string, 
    email: string, 
    organization: string,
    role: number,
    is_active: boolean,
    password: string) {
      super(
        id,
        first_name,
        last_name,
        email,
        organization,
        role,
        is_active
      );

      this.password = password;
  };
};