import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { FC, useState } from "react";
import { GradingIdentificationAnswerPanelProps } from "../../interfaces/gradingExam/gradingIdentificationAnswerPanelInterface";
import { GradingIdentificationDiagnosisData } from "../../objects/gradingExam";
import CaseImageButton from "../viewer/caseImageButton";
import './gradingIdentificationAnswerPanel.css';


const GradingIdentificationAnswerPanel: FC<GradingIdentificationAnswerPanelProps> = (props: GradingIdentificationAnswerPanelProps) => {
  const { answerList, second } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getGrowthRateDisplay = (data: GradingIdentificationDiagnosisData): string => {
    if (!second) return '';

    if (data.growth_rate === null) return `null%`;
    
    switch (data.growth_fluctuation) {
      case 'equal':
        return '--';
      case 'increase':
        return `+${data.growth_rate}%`;
      case 'decrease':
        return `-${data.growth_rate}%`;
      default:
        return '???';
    };
  };

  const getGrowthRateColor = (data: GradingIdentificationDiagnosisData): string => {
    if (!second) return '#212121';

    const growthRate = data.growth_rate;
    
    switch (data.growth_fluctuation) {
      case 'equal':
        return '#9e9e9e';
      case 'increase':
        if (data.is_correct) {
          if (!growthRate) return '#4caf50';
          else if (growthRate <= 20) return '#c8e6c9';
          else if (growthRate <= 40) return '#a5d6a7';
          else if (growthRate <= 60) return '#81c784';
          else if (growthRate <= 60) return '#66bb6a';
          else return '#4caf50';
        } else {
          if (!growthRate) return '#f44336';
          else if (growthRate <= 20) return '#ffcdd2';
          else if (growthRate <= 40) return '#ef9a9a';
          else if (growthRate <= 60) return '#e57373';
          else if (growthRate <= 60) return '#ef5350';
          else return '#f44336';
        };
      case 'decrease':
        if (data.is_correct) {
          if (!growthRate) return '#f44336';
          else if (growthRate <= 20) return '#ffcdd2';
          else if (growthRate <= 40) return '#ef9a9a';
          else if (growthRate <= 60) return '#e57373';
          else if (growthRate <= 60) return '#ef5350';
          else return '#f44336';
        } else {
          if (!growthRate) return '#4caf50';
          else if (growthRate <= 20) return '#c8e6c9';
          else if (growthRate <= 40) return '#a5d6a7';
          else if (growthRate <= 60) return '#81c784';
          else if (growthRate <= 60) return '#66bb6a';
          else return '#4caf50';
        };
      default:
        if (data.is_correct) return '#4caf50';
        else return '#f44336';
    };
  };

  const getCorrectedColor = (isCorrect: boolean, rate: number | null): string => {
    if (rate === null) return `#bdbdbd`;

    if (isCorrect) {
      return '#4caf50';
    } else {
      return '#f44336';
    };
  };

  return (
    <>
      {answerList.map((answer, index) => (
        <Paper key={index} elevation={2} className='GradingIdentificationAnswerPanel-main-paper'>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <div className="GradingIdentificationAnswerPanel-answer-title-div">
              <Typography variant="subtitle1">{ `${index + 1}. ${answer.answer_diagnosis_display}(${answer.answer_image_index}枚目)` }</Typography>
            </div>
            <CaseImageButton
              caseImageUid={answer.answer_case_image_uid}
            />
          </Stack>
          <TableContainer key={index}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {answer.diagnosis_list.map((d, index) => (
                    <TableCell 
                      key={index}
                      align='right'
                      style={{ minWidth: 150}} 
                    >
                      <div>{ d.display }</div>
                    </TableCell>
                  ))}
                  <TableCell
                    align='right'
                    style={{ minWidth: 50}} 
                  >
                    {`良性`}
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{ minWidth: 50}} 
                  >
                    {`悪性`}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{'AI解析動画視聴前'}</TableCell>
                  {answer.diagnosis_list.map((d, index) => (
                    <TableCell 
                      key={index} 
                      align='right'
                      style={{ 
                        color: getCorrectedColor(d.is_correct, d.first_selected_rate), 
                        fontWeight: 'bold' 
                      }}
                    >
                      { `${d.first_selected_count}` }
                      {/* { `${d.first_selected_count} (${d.first_selected_rate}%)` } */}
                    </TableCell>
                  ))}
                  <TableCell
                    align='right'
                    style={{color: !answer.answer_diagnosis_is_malignant ? "#4caf50" : "#f44336", fontWeight: "bold"}}
                  >
                      {answer.first_not_malignant_count}
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{color: answer.answer_diagnosis_is_malignant ? "#4caf50" : "#f44336", fontWeight: "bold"}}
                  >
                      {answer.first_malignant_count}
                  </TableCell>
                </TableRow>
                {second &&
                  <>
                    <TableRow>
                      <TableCell>{'AI解析動画視聴後'}</TableCell>
                      {answer.diagnosis_list.map((d, index) => (
                        <TableCell 
                          key={index} 
                          align='right'
                          style={{ 
                            color: getCorrectedColor(d.is_correct, d.second_selected_rate), 
                            fontWeight: 'bold' 
                          }}
                        >
                          { `${d.second_selected_count}` }
                          {/* { `${d.second_selected_count} (${d.second_selected_rate}%)` } */}
                        </TableCell>
                      ))}
                      <TableCell
                        align='right'
                        style={{color: !answer.answer_diagnosis_is_malignant ? "#4caf50" : "#f44336", fontWeight: "bold"}}
                      >
                          {answer.second_not_malignant_count}
                      </TableCell>
                      <TableCell
                        align='right'
                        style={{color: answer.answer_diagnosis_is_malignant ? "#4caf50" : "#f44336", fontWeight: "bold"}}
                      >
                          {answer.second_malignant_count}
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                      <TableCell>{'増減率'}</TableCell>
                      {answer.diagnosis_list.map((d, index) => (
                        <TableCell 
                          style={{ minWidth: 150}} 
                          align='right'
                          key={index}
                        >
                          <div>
                            <span style={{color: getGrowthRateColor(d)}}>
                              {`(${getGrowthRateDisplay(d)})`}
                            </span>
                          </div>
                        </TableCell>
                      ))}
                    </TableRow> */}
                  </>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ))}
    </>
  )
};

export default GradingIdentificationAnswerPanel;