import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { ExamTemplateQuestionData } from "../../objects/examTemplate";
import { DeleteCaseNonExistExamTemplateQuestions } from "../../repositories/examTemplateQuestionRepo";
import CommonAlert from "../commonAlert";
import "./CaseNotExistDialog.css";

export interface CaseNotExistDialogRefs {
  openDialog: (record: ExamTemplateQuestionData[]) => void;
};

export type CaseNotExistDialogProps = {
  examTemplateUid: number;
  closeDialog: () => void;
};

export const CaseNotExistDialog = forwardRef<CaseNotExistDialogRefs, CaseNotExistDialogProps>((props, refs) => {
  const { examTemplateUid, closeDialog } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [records, setRecords] = useState<ExamTemplateQuestionData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const commonAlertRef = useRef<CommonAlertRefs>(null);

  useImperativeHandle(refs, () => ({
    openDialog (targetRecords: ExamTemplateQuestionData[]): void {
      setRecords(targetRecords);
    }
  }));

  useEffect(() => {
    if (0 < records.length) {
      setOpenDialog(true);
    };
  }, [records]);

  useEffect(() => {
    if (!openDialog) {
      setRecords([]);
      closeDialog();
    };
  }, [openDialog]);

  const handleClickExecuteButton = async () => {
    if (records.length < 1) return;
    try {
      setIsLoading(true);
      const templateQuestionUidList = records.map((x) => x.uid);

      await DeleteCaseNonExistExamTemplateQuestions(examTemplateUid, templateQuestionUidList);

      setOpenDialog(false);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error("問題データの削除に失敗しました。");
    } finally {
      setIsLoading(false);
    };
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='lg'
        open={openDialog} 
        disableEscapeKeyDown
      >
        <DialogTitle>{`削除済症例データを検出しました。`}</DialogTitle>
        <DialogContent style={{padding: 10}}>
          <DialogContentText>
            <div>下記問題に紐づく症例がセンター側で削除されたため、テンプレート内から問題を削除いたします。</div>
          </DialogContentText>
          <TableContainer component={Paper} className='CaseNotExistDialog-table'>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 70 }}>症例番号</TableCell>
                  <TableCell style={{ minWidth: 180 }}>施設</TableCell>
                  <TableCell style={{ minWidth: 100 }}>ベンダー</TableCell>
                  <TableCell style={{ minWidth: 100 }}>モデル</TableCell>
                  <TableCell style={{ width: '100%', minWidth: 140 }}>病名</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records.map((question, index) => (
                  <TableRow
                    key={index}
                  >
                    <TableCell>{question.case_uid}</TableCell>
                    <TableCell>{question.exam_template_case.institution_name}</TableCell>
                    <TableCell>{question.exam_template_case.vendor_name}</TableCell>
                    <TableCell>{question.exam_template_case.model_name}</TableCell>
                    <TableCell>
                      {question.exam_template_answers.map((answer, index) => (
                        <div key={index}>
                          {`${answer.diagnosis.display}(${answer.image_index}目)`}
                        </div>
                      ))}
                    </TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Button
            onClick={(evt) => handleClickExecuteButton()}
            disabled={isLoading}
            color="error"
            variant="contained"
          >
            実行する
          </Button>
        </Stack>
        </DialogActions>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      <CommonAlert
        ref={commonAlertRef}
      />
    </>
  )
});