export class ExamFormatData {
  display: string;
  value: string;

  constructor (display: string, value: string) {
    this.display = display;
    this.value = value;
  };
};

export const CreateExamFormatDataList = () => {
  return [
    new ExamFormatData('', ""),
    new ExamFormatData('全体でランダム', "random_per_exam"), 
    new ExamFormatData('セクション毎でランダム', "random_per_section"), 
    new ExamFormatData('順番どおり', "in_order"),
  ]
};