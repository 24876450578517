export class ImageData {
  index: number;
  data: Blob | null;
  isError: boolean;

  constructor (index: number, data: Blob | null, isError: boolean) {
    this.index = index;
    this.data = data;
    this.isError = isError;
  };
};