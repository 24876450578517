export class StudentDetectionAnswerData {
  image_index: number;
  start_x: number;
  start_y: number;
  end_x: number;
  end_y: number;
  image_width: number;
  image_height: number;
  shape_type: string;
  border_color: string;
  border_width: number;
  file_name: string;

  constructor (
    image_index: number,
    start_x: number,
    start_y: number,
    end_x: number,
    end_y: number,
    image_width: number,
    image_height: number,
    shape_type: string,
    border_color: string,
    border_width: number,
    file_name: string
  ) {
    this.image_index = image_index;
    this.start_x = start_x;
    this.start_y = start_y;
    this.end_x = end_x;
    this.end_y = end_y;
    this.image_width = image_width;
    this.image_height = image_height;
    this.shape_type = shape_type;
    this.border_color = border_color;
    this.border_width = border_width;
    this.file_name = file_name;
  };
};