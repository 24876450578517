export class ExamStudentTagFormatData {
  name: string;
  display: string;
  output: boolean;

  constructor (
    name: string, 
    display: string, 
    output: boolean
  ) {
    this.name = name;
    this.display = display;
    this.output = output;
  };
};