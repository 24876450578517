import { axiosClient } from "../contexts/axiosClientProvider";
import { ExamStudentData, ExamStudentResultQuestionData } from "../objects/exam";
import { ExamTemplateQuestionsType } from "../objects/examTemplate";


export const GetExamStudentTagList = async (examUid: number) => {
  let url = `/exams/students/tags/${examUid}`;

  const response = await axiosClient.get<{name: string, value: string}[]>(url);

  return response.data;
};

export const GetExamStudent = async (studentUid: number): Promise<ExamStudentData> => {
  const response = await axiosClient.get<ExamStudentData>(`exams/students/${studentUid}`);
  return response.data;
};

export const GetExamStudentList = async (examUid: number, ended: boolean | null, onlyNotGraded: number | null): Promise<ExamStudentData[]> => {
  let url = `exams/students?exam_uid=${examUid}`;
  
  if (ended !== null) {
    url = url + `&ended=${ended}`;
  };
  if (onlyNotGraded !== null) {
    url = url + `&only_not_graded=${onlyNotGraded}`;
  };

  const response = await axiosClient.get<ExamStudentData[]>(url);
  return response.data;
};

export const GetExamStudentResultList = async (studentUid: number, questions_type: ExamTemplateQuestionsType | null): Promise<ExamStudentResultQuestionData[]> => {
  let url = `exams/students/results/${studentUid}`;

  if (questions_type !== null) {
    url = url + `?questions_type=${questions_type}`;
  };

  const response = await axiosClient.get<ExamStudentResultQuestionData[]>(url);
  return response.data;
};

export const DownloadExamStudentAnswerData = async (
  studentUid: number,
  includeAnswerData: boolean,
  includeImageData: boolean,
  includeActionLog: boolean,
  fileName: string): Promise<void> => {
    let url = `exams/students/results/${studentUid}/download`;

    url = url + `?include_answer_data=${includeAnswerData}&include_image_data=${includeImageData}&include_action_log=${includeActionLog}`;
    
    const response = await axiosClient.get(url, {
      responseType: 'arraybuffer',
      headers: { Accept: 'application/zip' }
    });
    
    const blob = new Blob([response.data], { type: 'application/zip' });
    const downloadUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.download = `${fileName}.zip`;
    link.href = downloadUrl;
    
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(downloadUrl);
    link.parentNode?.removeChild(link);
};

export const UploadExamStudentAnswerData = async (studentUid: number, overWrite: boolean, reScore: boolean, file: File, fileName: string): Promise<void> => {
  const url = `exams/students/results/${studentUid}/upload?overwrite=${overWrite}?re_score=${reScore}`;
  
  let formData = new FormData();
  
  formData.append('file', file);

  const response = await axiosClient.post(url, formData, {
    timeout: 120000,
    responseType: 'arraybuffer',
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: 'application/zip'
    }
  });

  const blob = new Blob([response.data], { type: 'application/zip' });
  const downloadUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.download = `${fileName}.zip`;
  link.href = downloadUrl;
  
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(downloadUrl);
  link.parentNode?.removeChild(link);
};

export const DownloadExamStudentCsv = async (examUid: number, fileName: string): Promise<void> => {
  const response = await axiosClient.get(`exams/students/csv?exam_uid=${examUid}`);
  
  const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);

  const downloadUrl = URL.createObjectURL( new Blob([bom, response.data], { type: "text/csv" }) );
  const link = document.createElement("a");
  if (fileName) {
    link.download = `${fileName}.csv`;
  };
  link.href = downloadUrl;
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(downloadUrl);
  link.parentNode?.removeChild(link);
};

export const UploadExamStudentCsv = async (examUid: number, file: File): Promise<void> => {
  let formData = new FormData();
  
  formData.append('file', file);

  await axiosClient.post(`exams/students/csv?exam_uid=${examUid}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
};

export const EnableExamStudentList = async (studentUidList: number[]): Promise<void> => {
  let url = `exams/students/enable`;

  await axiosClient.put(url, studentUidList);
};

export const DisableExamStudentList = async (studentUidList: number[]): Promise<void> => {
  let url = `exams/students/disable`;

  await axiosClient.put(url, studentUidList);
};