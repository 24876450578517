import { axiosClient } from "../contexts/axiosClientProvider";
import { ExamCreateData, ExamData } from "../objects/exam";


export const AddExam = async (data: ExamCreateData): Promise<ExamData> => {
  const response = await axiosClient.post<ExamData>(`exams`, data);
  return response.data;
};

export const GetExam = async (examUid: number): Promise<ExamData> => {
  const response = await axiosClient.get<ExamData>(`exams/${examUid}`);
  return response.data;
};

export const GetExamList = async (templateUid: number | null, title: string, deleted: boolean): Promise<ExamData[]> => {
  const url = `exams?${templateUid ? `template_uid=${templateUid}&` : ''}title=${title}&deleted=${deleted}`
  const response = await axiosClient.get<ExamData[]>(url);
  return response.data;
};

export const DownloadStandaloneZip = async (examUid: number): Promise<void> => {
  const url = `exams/${examUid}/standalone/records`;
  const response = await axiosClient.get(url, {
    responseType: 'arraybuffer',
    headers: { Accept: 'application/zip' }
  });
  
  const blob = new Blob([response.data], { type: 'application/zip' });
  const downloadUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.download = `${examUid}_records.zip`;
  link.href = downloadUrl;
  
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(downloadUrl);
  link.parentNode?.removeChild(link);
}

export const UpdateExam = async (examUid: number, data: ExamCreateData): Promise<ExamData> => {
  const response = await axiosClient.put<ExamData>(`exams/${examUid}`, data);
  return response.data;
};

export const DeleteExam = async (examUid: number): Promise<void> => {
  await axiosClient.delete<ExamData>(`exams/${examUid}`);
};