import { axiosClient } from "../contexts/axiosClientProvider";
import { axiosStudentClient } from "../contexts/axiosStudentClientProvider";


export const GetVideoInfo = async (templateQuestionUid: number): Promise<{type: string, url: string}> => {
  const response = await axiosClient.get(`videos?template_question_uid=${templateQuestionUid}`);
  return response.data;
};

export const GetVideo = async (templateQuestionUid: number): Promise<Blob> => {
  const response = await axiosClient.get(`videos/stream?template_question_uid=${templateQuestionUid}`, {
    responseType : 'arraybuffer'
  });
  
  const dataArray = new Uint8Array(response.data);
  return new Blob([dataArray.buffer], { type: 'vide/mp4' });
};

export const GetVideoInfoForStudent = async (examQuestionUid: number): Promise<{type: string, url: string}> => {
  const response = await axiosStudentClient.get(`videos/student?exam_question_uid=${examQuestionUid}`);
  return response.data;
};

export const GetVideoForStudent = async (examQuestionUid: number): Promise<Blob> => {
  const response = await axiosStudentClient.get(`videos/stream/student?exam_question_uid=${examQuestionUid}`, {
    responseType : 'arraybuffer'
  });
  
  const dataArray = new Uint8Array(response.data);
  return new Blob([dataArray.buffer], { type: 'vide/mp4' });
};