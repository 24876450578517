import { TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { HealthCheckExamQuestionRowProps } from "../../interfaces/exam/healthCheckExamQuestionRowInterface";


const HealthCheckExamQuestionRow: FC<HealthCheckExamQuestionRowProps> = (props: HealthCheckExamQuestionRowProps) => {
  const { row } = props;
  
  const handleCellColor = (): string => {
    if (!row.healthData) return '';
    if (row.healthData.checkResult()) return '#2196f3';
    else return '#f44336';
  };

  const handleBooleanDisplay = (value: boolean): string => {
    return value ? 'YES' : 'NO';
  };

  const handleNumberListDisplay = (value: number[]): string => {
    if (value.length < 1) return '-';
    else return value.join(',');
  };

  const handleAiVideoDisplay = (value: boolean | null): string => {
    if (value === null) return '-';
    else if (!value) return 'NO';
    else return 'YES';
  };

  return (
    <>
      <TableRow hover tabIndex={-1} key={row.base.uid}>
        <TableCell style={{color: handleCellColor()}}>{ row.base.question_number }</TableCell>
        <TableCell style={{color: handleCellColor()}}>{ row.healthData ? row.healthData.messages.join(',') : '-' }</TableCell>
        <TableCell style={{color: handleCellColor()}}>{ row.healthData ? handleBooleanDisplay(row.healthData.question_exists) : '-' }</TableCell>
        <TableCell style={{color: handleCellColor()}}>{ row.healthData ? handleBooleanDisplay(row.healthData.template_question_exists) : '-' }</TableCell>
        <TableCell style={{color: handleCellColor()}}>{ row.healthData ? handleBooleanDisplay(row.healthData.template_answers_exists) : '-' }</TableCell>
        <TableCell style={{color: handleCellColor()}}>{ row.healthData ? handleNumberListDisplay(row.healthData.forbidden_template_answer_uid_list) : '-' }</TableCell>
        <TableCell style={{color: handleCellColor()}}>{ row.healthData ? handleBooleanDisplay(row.healthData.case_exists) : '-' }</TableCell>
        <TableCell style={{color: handleCellColor()}}>{ row.healthData ? handleNumberListDisplay(row.healthData.unknown_case_image_uid_list) : '-' }</TableCell>
        <TableCell style={{color: handleCellColor()}}>{ row.healthData ? handleNumberListDisplay(row.healthData.unknown_image_index_list) : '-' }</TableCell>
        <TableCell style={{color: handleCellColor()}}>
          { row.healthData 
            ? handleAiVideoDisplay(row.healthData.ai_video_exists) 
            : '-' 
          }
        </TableCell>
      </TableRow>
    </>
  )
};

export default HealthCheckExamQuestionRow;