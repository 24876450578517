import {
  Backdrop,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { DiagnosisData } from "../../objects/diagnosis";
import { ExamAnswerIdentificationAnalysisData } from "../../objects/exam";
import { GetDiagnosisList } from "../../repositories/diagnosisRepo";
import { GetExamAnswerIdentificationAnalysis } from "../../repositories/ExamAnswerIdentificationRepo";
import {
  GetExamStudentList,
  GetExamStudentTagList,
} from "../../repositories/examStudentRepo";
import { GetExamTemplateCaseVendorAndModelList } from "../../repositories/filterRepo";
import CommonAlert from "../commonAlert";
import "./ExamIdentificationAnswerPanel.css";

export interface ExamIdentificationAnswerPanelRefs {}

export type ExamIdentificationAnswerPanelProps = {
  examUid: number;
  examTemplateUid: number;
};

export const ExamIdentificationAnswerPanel = forwardRef<
  ExamIdentificationAnswerPanelRefs,
  ExamIdentificationAnswerPanelProps
>((props, refs) => {
  const { examUid, examTemplateUid } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ExamAnswerIdentificationAnalysisData | null>(
    null
  );
  const [studentFilterList, setStudentFilterList] = useState<
    { type: string; name: string; value: string }[]
  >([]);
  const [selectedStudentTagValue, setSelectedStudentTagValue] =
    useState<string>("全て");
  const [diagnosisRadio, setDiagnosisRadio] = useState("malignant");
  const [diagnosisList, setDiagnosisList] = useState<DiagnosisData[]>([]);
  const [selectedDiagnosisUid, setSelectedDiagnosisUid] = useState<number>(0);
  const [vendorList, setVendorList] = useState<string[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<string>("全て");

  const commonAlertRef = useRef<CommonAlertRefs>(null);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        await getExamTemplateCaseVendorAndModelList();
        await getExamStudentTagList();
        await getDiagnosisList();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (diagnosisRadio === "diagnosis") {
      if (selectedDiagnosisUid < 1) return;
    }

    (async () => {
      try {
        setIsLoading(true);

        await getIdentificationAnswerAnalysis();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    selectedStudentTagValue,
    diagnosisRadio,
    selectedDiagnosisUid,
    selectedVendor,
  ]);

  const getExamTemplateCaseVendorAndModelList = async () => {
    try {
      const result = await GetExamTemplateCaseVendorAndModelList(
        examTemplateUid
      );
      setVendorList(result);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(
        "ベンダー/モデル名リストの取得に失敗しました。"
      );
    }
  };

  const getExamStudentTagList = async () => {
    try {
      const result: { type: string; name: string; value: string }[] = [];

      const tagList = await GetExamStudentTagList(examUid);
      tagList.forEach((x) => {
        result.push({ type: "tag", name: x.name, value: x.value });
      });

      const studentsList = await GetExamStudentList(examUid, null, null);
      studentsList.forEach((x) => {
        if (x.is_active)
          result.push({
            type: "student",
            name: x.uid.toString(),
            value: x.sign_in_id,
          });
      });

      setStudentFilterList(result);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(
        "鑑別回答解析用被験者タグデータの取得に失敗しました。"
      );
      setStudentFilterList([]);
    }
  };

  const getDiagnosisList = async () => {
    try {
      const result = await GetDiagnosisList(null, true, true);

      if (0 < result.length) {
        setSelectedDiagnosisUid(result[0].uid);
      }

      setDiagnosisList(result);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(
        "鑑別回答解析用病名データの取得に失敗しました。"
      );
      setDiagnosisList([]);
    }
  };

  const getIdentificationAnswerAnalysis = async () => {
    try {
      let filterTag = "";
      let filterStudent: number | undefined;

      if (selectedStudentTagValue) {
        const studentFilter = studentFilterList.find(
          (x) => x.value === selectedStudentTagValue
        );

        if (studentFilter) {
          switch (studentFilter.type) {
            case "tag":
              filterTag = `{"name": "${studentFilter.name}", "value": "${studentFilter.value}"}`;
              break;
            case "student":
              filterStudent = Number(studentFilter.name);
          }
        }
      }

      const useMalignant = diagnosisRadio === "malignant" ? true : false;

      let filterVendor: string | null = null;

      if (selectedVendor && selectedVendor !== "全て") {
        filterVendor = selectedVendor;
      }

      const result = await GetExamAnswerIdentificationAnalysis(
        examUid,
        useMalignant,
        selectedDiagnosisUid,
        filterTag,
        filterVendor,
        filterStudent
      );
      setData(result);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error("鑑別回答解析データの取得に失敗しました。");
      setData(null);
    }
  };

  return (
    <>
      <Paper className="ExamIdentificationAnswerPanel-paper">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
          className="ExamIdentificationAnswerPanel-main-stack"
        >
          <Divider textAlign="left">{`鑑別問題 解析情報`}</Divider>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <FormControl
              size="small"
              className="ExamIdentificationAnswerPanel-select"
            >
              <InputLabel>{"被験者"}</InputLabel>
              <Select
                value={
                  selectedStudentTagValue ? selectedStudentTagValue : "全て"
                }
                onChange={(evt) => setSelectedStudentTagValue(evt.target.value)}
                disabled={isLoading}
                label={"被験者"}
              >
                <MenuItem value={"全て"}>{"全て"}</MenuItem>
                {studentFilterList.map((x, index) => (
                  <MenuItem value={x.value} key={index}>
                    {x.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <RadioGroup
                value={diagnosisRadio}
                onChange={(evt) => setDiagnosisRadio(evt.target.value)}
                defaultValue={"malignant"}
                row
              >
                <FormControlLabel
                  value="malignant"
                  control={<Radio />}
                  label="悪性"
                />
                <FormControlLabel
                  value="diagnosis"
                  control={<Radio />}
                  label="病名選択"
                />
              </RadioGroup>
            </FormControl>
            <FormControl
              size="small"
              className="ExamIdentificationAnswerPanel-select"
            >
              <InputLabel>{"病名"}</InputLabel>
              <Select
                value={0 < selectedDiagnosisUid ? selectedDiagnosisUid : "-"}
                onChange={(evt) =>
                  setSelectedDiagnosisUid(Number(evt.target.value))
                }
                disabled={diagnosisRadio !== "diagnosis"}
                label={"病名"}
              >
                {diagnosisList.map((x, index) => (
                  <MenuItem value={x.uid} key={index}>
                    {x.display}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              size="small"
              className="ExamIdentificationAnswerPanel-select"
            >
              <InputLabel>{"ベンダー/モデル"}</InputLabel>
              <Select
                value={selectedVendor ? selectedVendor : "全て"}
                onChange={(evt) => setSelectedVendor(evt.target.value)}
                disabled={isLoading}
                label={"ベンダー/モデル"}
              >
                <MenuItem value={"全て"}>{"全て"}</MenuItem>
                {vendorList.map((x, index) => (
                  <MenuItem value={x} key={index}>
                    {x}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "100%" }}></TableCell>
                  <TableCell
                    align="right"
                    style={{ minWidth: 100 }}
                  >{`正解率`}</TableCell>
                  <TableCell
                    align="right"
                    style={{ minWidth: 100 }}
                  >{`感度`}</TableCell>
                  <TableCell
                    align="right"
                    style={{ minWidth: 100 }}
                  >{`特異度`}</TableCell>
                  <TableCell
                    align="right"
                    style={{ minWidth: 100 }}
                  >{`MCC`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{`AI解析動画視聴前`}</TableCell>
                  <TableCell align="right">
                    {data ? data.first_answer_rate.toFixed(2) : "-"}
                  </TableCell>
                  <TableCell align="right">
                    {data ? data.first_recall.toFixed(2) : "-"}
                  </TableCell>
                  <TableCell align="right">
                    {data ? data.first_specificity.toFixed(2) : "-"}
                  </TableCell>
                  <TableCell align="right">
                    {data ? data.first_mcc.toFixed(2) : "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`AI解析動画視聴後`}</TableCell>
                  <TableCell align="right">
                    {data ? data.second_answer_rate.toFixed(2) : "-"}
                  </TableCell>
                  <TableCell align="right">
                    {data ? data.second_recall.toFixed(2) : "-"}
                  </TableCell>
                  <TableCell align="right">
                    {data ? data.second_specificity.toFixed(2) : "-"}
                  </TableCell>
                  <TableCell align="right">
                    {data ? data.second_mcc.toFixed(2) : "-"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Paper>
      <CommonAlert ref={commonAlertRef} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
});
