import { FC } from 'react';
import { useLocation, Navigate, Outlet, useNavigate } from 'react-router-dom';
import UseAuth from '../hooks/useAuth';


type RequireAuthProps = {
  allowedRoles: number[];
};

const RequireAuth: FC<RequireAuthProps> = (props: RequireAuthProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { auth } = UseAuth();

  if (auth && !props.allowedRoles.includes(auth?.role)) navigate('/unautholized');

  return (
    auth
      ? <Outlet/>
      : <Navigate to='/signin' state={{ from: location }} replace />
  );
};

export default RequireAuth;