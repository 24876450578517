import { ExamStudentData } from "./examStudentData";

export class ExamStudentQuestionScoreBaseData {
  student_uid: number;
  question_uid: number;
  is_second: boolean;
  score: number | null;
  description: string;

  constructor (
    student_uid: number,
    question_uid: number,
    is_second: boolean,
    score: number | null,
    description: string) {
      this.student_uid = student_uid;
      this.question_uid = question_uid;
      this.is_second = is_second;
      this.score = score;
      this.description = description;
  };
};

export class ExamStudentQuestionScoreCreateData extends ExamStudentQuestionScoreBaseData {
  constructor (
    student_uid: number,
    question_uid: number,
    is_second: boolean,
    score: number | null,
    description: string) {
      super(
        student_uid,
        question_uid,
        is_second,
        score,
        description
      );
  };
};

export class ExamStudentQuestionScoreData extends ExamStudentQuestionScoreBaseData {
  uid: number;
  exam_student: ExamStudentData;
  
  constructor (
    uid: number,
    student_uid: number,
    question_uid: number,
    is_second: boolean,
    score: number | null,
    description: string,
    exam_student: ExamStudentData) {
      super(
        student_uid,
        question_uid,
        is_second,
        score,
        description
      );

      this.uid = uid;
      this.exam_student = exam_student;
  };
};