import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Backdrop, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import CommonHeader from "../../compornents/commonHeader";
import './GradingExamPage.css';
import { Stack } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { GetExam } from "../../repositories/examRepo";
import { ExamData } from "../../objects/exam";
import { GradingExamQuestionData } from "../../objects/gradingExam";
import { GetGradingExamQuestionList } from "../../repositories/gradingExamRepo";
import GradingExamQuestionRow from "../../compornents/gradingExam/gradingExamQuestionRow";
import { ExamTemplateQuestionsType, ExamTemplateQuestionsTypeDisplay, GetExamTemplateQuestionTypeList, GetExamTemplateQuestionTypeValue } from "../../objects/examTemplate";
import { ExamResultDownloadDialog, ExamResultDownloadDialogRefs } from "../../compornents/exam/ExamResultDownloadDialog";
import { ExamIdentificationAnswerPanel } from "../../compornents/exam/ExamIdentificationAnswerPanel";


const GradingExamPage: FC = () => {
  const navigate = useNavigate();

  const { examUid } = useParams();

  const paginationStorageName = `GradingExamPage-${examUid}-pagination`;
  const questionTypeStorageName = `GradingExamPage-${examUid}-question-type`;
  const lastQuestionNumberStorageName = `GradingExamPage-${examUid}-last-question-number`;
  const lastIsNextStorageName = `GradingExamPage-${examUid}-last-is-next`;

  let initialPagination = 1;
  const paginationStorageValue = sessionStorage.getItem(paginationStorageName);
  if (paginationStorageValue && !isNaN(Number(paginationStorageValue))) {
    initialPagination = Number(paginationStorageValue);
  };

  let initialQuestionType: ExamTemplateQuestionsType | null = null;
  const questionTypeStorageValue = sessionStorage.getItem(questionTypeStorageName);
  if (questionTypeStorageValue) {
    initialQuestionType = GetExamTemplateQuestionTypeValue(questionTypeStorageValue);
  };
  
  const [headerTitle, setHeaderTitle] = useState('試験評価');
  const [filterQuestionType, setFilterQuestionType] = useState<ExamTemplateQuestionsType | null>(initialQuestionType);
  const [canPrev, setCanPrev] = useState(false);
  const [canNext, setCanNext] = useState(false);
  const [count, setCount] = useState<number | null>(null);
  const [pagination, setPagination] = useState(initialPagination);
  const [exam, setExam] = useState<ExamData | null>(null);
  const [gradingQuestionList, setGradingQuestionList] = useState<GradingExamQuestionData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const examResultDownloadDialogRef = useRef<ExamResultDownloadDialogRefs>(null);

  const gradingQuestionLimit = 50;

  const questionTypeList = GetExamTemplateQuestionTypeList();

  // initialize.
  useEffect( () => {
    if (!examUid) navigate('/notfound');
    (async () => {
      try {
        setIsLoading(true);

        const targetExamUid = Number(examUid);

        const exam = await GetExam(targetExamUid);
        setHeaderTitle(`試験評価: ${exam.title}`);
        setExam(exam);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      };
    })()
  }, []);

  useEffect(() => {
    if (!exam) return;

    (async () => {
      try {
        setIsLoading(true);

        let initialLastQuestionNumber = 0;
        const lastQuestionNumber = Number(sessionStorage.getItem(lastQuestionNumberStorageName));
        if (!isNaN(lastQuestionNumber)) {
          initialLastQuestionNumber = lastQuestionNumber;
        };

        let initialIsNext = true;
        const lastIsNext = sessionStorage.getItem(lastIsNextStorageName);
        if (lastIsNext) {
          initialIsNext = lastIsNext.toLowerCase() === "true" ? true : false;
        };

        await getGradingExamQeustionList(initialIsNext, initialLastQuestionNumber);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      };
    })()
  }, [exam]);

  useEffect(() => {
    if (!exam) return;

    (async () => {
      try {
        setIsLoading(true);

        sessionStorage.setItem(questionTypeStorageName, filterQuestionType ? filterQuestionType.toString() : "");
        
        await getGradingExamQeustionList(true, 0);
        setPagination(1);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      };
    })()
  }, [filterQuestionType]);

  useEffect(() => {
    sessionStorage.setItem(paginationStorageName, pagination.toString());
  }, [pagination]);

  const clickPrevButton = async () => {
    try {
      setIsLoading(true);
      
      let lastQuestionNumber = 0;
      if (0 < gradingQuestionList.length) {
        lastQuestionNumber = gradingQuestionList.map((x) => x.question_number).reduce((a, b) => {return Math.min(a, b)});
      };

      await getGradingExamQeustionList(0 < lastQuestionNumber ? false : true, lastQuestionNumber);
      
      setPagination(pagination - 1);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    };
  };

  const clickNextButton = async () => {
    try {
      setIsLoading(true);
      
      let lastQuestionNumber = 0;
      if (0 < gradingQuestionList.length) {
        lastQuestionNumber = gradingQuestionList.map((x) => x.question_number).reduce((a, b) => {return Math.max(a, b)});
      };
      
      await getGradingExamQeustionList(true, lastQuestionNumber);

      setPagination(pagination + 1);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    };
  };

  const getGradingExamQeustionList = async (isNext: boolean, lastQuestionNumber: number) => {
    if (!exam) return;
    console.log('Get grading exam question list');
    try {
      const result = await GetGradingExamQuestionList(exam.uid, isNext, lastQuestionNumber, filterQuestionType, gradingQuestionLimit);
      
      setGradingQuestionList(result.data);
      setCanPrev(result.can_prev);
      setCanNext(result.can_next);
      setCount(result.total_count);

      sessionStorage.setItem(lastQuestionNumberStorageName, lastQuestionNumber.toString());
      sessionStorage.setItem(lastIsNextStorageName, isNext.toString());
    } catch (error) {
      console.error(error);
      setGradingQuestionList([]);
      setCanPrev(false);
      setCanNext(false);
      setCount(null);
      setPagination(1);
    };
  };

  const handleSetQuestionType = (value: string | null) => {
    switch (value) {
      case ExamTemplateQuestionsType.Detection:
        setFilterQuestionType(value);
        break;
      case ExamTemplateQuestionsType.Identification:
        setFilterQuestionType(value);
        break;
      default:
        setFilterQuestionType(null);
        break;
    };
  };

  const handleBeforeMoveToOtherPage = (examQuestionUid: number) => {
    console.log('Detected move to other page');
  };

  const createRecordCountStart = (): number => {
    return (gradingQuestionLimit * pagination) - (gradingQuestionLimit - 1);
  };

  const createRecordCountEnd = (): number => {
    return ((pagination - 1) * gradingQuestionLimit) + gradingQuestionList.length;
  };

  const handleClickOpenResultDownloadDialogButton = () => {
    examResultDownloadDialogRef.current?.openDialog();
  };

  return (
    <Grid container>
      <CommonHeader headerTitle={headerTitle}/>
      <div className="GradingExamPage-main-div">
        <Paper className="GradingExamPage-filter-paper">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            style={{margin: '0 10px'}}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Typography>{`総問題数:`}</Typography>
              <Typography width={'40px'}>{`${exam ? exam.questions_count : '-'}`}</Typography>
              <Typography>{`完了者数/被験者数:`}</Typography>
              <Typography width={'60px'}>{`${exam ? exam.student_done_count : '-'}/${exam ? exam.student_total : '-'}`}</Typography>
              <FormControl style={{width: '150px'}} size="small">
                <InputLabel>{'出題形式'}</InputLabel>
                <Select
                  value={filterQuestionType}
                  onChange={(evt) => handleSetQuestionType(evt.target.value)}
                  label={'出題形式'}
                >
                  <MenuItem value={''}></MenuItem>
                  {questionTypeList.map((x, index) => (
                    <MenuItem value={x} key={index}>{ExamTemplateQuestionsTypeDisplay(x)}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Button
                onClick={(evt) => handleClickOpenResultDownloadDialogButton()}
                disabled={isLoading}
                variant="outlined"
              >
                {'ダウンロード'}
              </Button>
            </Stack>
          </Stack>
        </Paper>
        <TableContainer 
          component={Paper} 
          id='GradingExamPage-exams-table' 
          className='GradingExamPage-exams-table'
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 100 }}>問題No.</TableCell>
                <TableCell style={{ minWidth: 100 }}>症例番号</TableCell>
                <TableCell style={{ minWidth: 70 }}>出題形式</TableCell>
                <TableCell style={{ minWidth: 70 }}>{`未評価(前)`}</TableCell>
                <TableCell style={{ minWidth: 70 }}>{`未評価(後)`}</TableCell>
                <TableCell style={{ minWidth: 100 }}>画像未チェック</TableCell>
                <TableCell style={{ minWidth: 60 }}>{`コメント`}</TableCell>
                <TableCell style={{ width: '100%', minWidth: 100 }}>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                    style={{width: '100%'}}
                  >
                    <IconButton 
                      onClick={async (env) => await clickPrevButton()}
                      disabled={(isLoading || !canPrev)}
                      edge="end" size="small" color="primary"
                    >
                      <NavigateBefore />
                    </IconButton>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      style={{width: '120px'}}
                    >
                      {count !== null &&
                        <>
                          <Typography>{`${count}`}</Typography>
                          <Typography>{`(${createRecordCountStart()}-${createRecordCountEnd()})`}</Typography>
                        </>
                      }
                    </Stack>
                    <IconButton 
                      onClick={async (env) => await clickNextButton()}
                      disabled={(isLoading || !canNext)}
                      edge="end" size="small" color="primary"
                    >
                      <NavigateNext />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {examUid &&
                gradingQuestionList.map((q) => (
                  <GradingExamQuestionRow 
                    key={q.uid}
                    examUid={Number(examUid)}
                    row={q} 
                    beforeMoveToOtherPage={handleBeforeMoveToOtherPage}
                  />
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        {examUid && exam !== null
          ? <ExamIdentificationAnswerPanel
              examUid={Number(examUid)}
              examTemplateUid={exam?.template_uid}
            />
          : null
        }
      </div>
      {examUid && exam !== null
        ? <ExamResultDownloadDialog
            ref={examResultDownloadDialogRef}
            examUid={Number(examUid)}
            examTitle={exam.title}
          />
        : null
      }
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default GradingExamPage;