import { ExamTemplateQuestionsType } from "../examTemplate";
import { GradingExamAnswerUnityData } from "./gradingExamAnswerUnityData";
import { GradingExamQuestionScoreData } from "./gradingExamQuestionScoreData";

export class GradingExamQuestionData {
  uid: number;
  template_question_uid: number;
  question_number: number;
  question_type: ExamTemplateQuestionsType;
  case_uid: number;
  detection_manual_count: number | null;
  second: boolean;
  answered_rate: number;
  first_not_scored_count: number;
  second_not_scored_count: number;
  question_score: GradingExamQuestionScoreData[];
  answer_unity: GradingExamAnswerUnityData;

  constructor (
    uid: number, 
    template_question_uid: number, 
    question_number: number,
    question_type: ExamTemplateQuestionsType,
    case_uid: number,
    detection_manual_count: number | null,
    second: boolean,
    answered_rate: number,
    first_not_scored_count: number,
    second_not_scored_count: number,
    question_score: GradingExamQuestionScoreData[],
    answer_unity: GradingExamAnswerUnityData) {
      this.uid = uid;
      this.template_question_uid = template_question_uid;
      this.question_number = question_number;
      this.question_type = question_type;
      this.case_uid = case_uid;
      this.detection_manual_count = detection_manual_count;
      this.second = second;
      this.answered_rate = answered_rate;
      this.first_not_scored_count = first_not_scored_count;
      this.second_not_scored_count = second_not_scored_count;
      this.question_score = question_score;
      this.answer_unity = answer_unity;
    };
};