export class UserRoleData {
  value: number;
  display: string;

  constructor (
    value: number,
    display: string
  ) {
    this.value = value;
    this.display = display;
  };
};

export const CreateUserRoleList = () => {
  return [
    new UserRoleData(10, 'ユーザー管理者'),
    new UserRoleData(20, '試験管理者'),
    new UserRoleData(30, '試験テンプレート管理者'),
    new UserRoleData(90, 'システム管理者')
  ]
};