import { Dialog, DialogContent } from "@mui/material";
import { CommonDialogAction } from "./CommonDialogAction";
import { CommonDialogTitle } from "./CommonDialogHeader";
import { ReactNode } from "react";

interface CommonDialogProps {
  openDialog: boolean;
  closeDialog: () => void;
  dialogTitle: string;
  disabled: boolean;
  contentChildren: ReactNode;
  actionChildren: ReactNode;
  maxWidth?: "sm" | "md" | "lg";
  disableFullWidth?: boolean;
  isProgressing?: boolean;
}

export const CommonDialog: React.FC<CommonDialogProps> = (props) => {
  const {
    openDialog,
    closeDialog,
    dialogTitle,
    disabled,
    contentChildren,
    actionChildren,
    isProgressing,
    maxWidth,
    disableFullWidth,
  } = props;

  const handleCloseDialog = () => {
    if (isProgressing === true) return;
    closeDialog();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      fullWidth={disableFullWidth === true ? false : true}
      maxWidth={maxWidth || "md"}
    >
      <CommonDialogTitle
        title={dialogTitle}
        disabled={disabled || isProgressing === true}
        clickedClose={handleCloseDialog}
      />
      <DialogContent>{contentChildren}</DialogContent>
      <CommonDialogAction>{actionChildren}</CommonDialogAction>
    </Dialog>
  );
};
