import { Delete, DeleteOutline } from "@mui/icons-material";
import { Backdrop, Button, Checkbox, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import CommonHeader from "../../compornents/commonHeader";
import { GetExamTemplateList } from "../../repositories/examTemplateRepo";
import './ExamTemplateListPage.css';
import { ExamTemplateData } from "../../objects/examTemplate";
import { Stack } from "@mui/system";
import ExamTemplateDialog from "../../compornents/examTemplate/examTemplateDialog";
import { ExamTemplateDialogRefs } from "../../interfaces/examTemplate/examTemplateDialogInterface";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import CommonAlert from "../../compornents/commonAlert";
import ExamTemplateRow from "../../compornents/examTemplate/examTemplateRow";
import { CopyExamTemplateCases } from "../../repositories/ExamTemplateCasesRepo";


const ExamTemplateListPage: FC = () => {
  const templateDialogRef = useRef<ExamTemplateDialogRefs>(null);
  const commonAlertRef = useRef<CommonAlertRefs>(null);

  const [examsList, setExamsList] = useState<ExamTemplateData[]>([]);
  const [filterExamTitle, setFilterExamTitle] = useState('');
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // initialize.
  useEffect( () => {
    (async () => {
      sessionStorage.clear();
      await getExamTemplateList();
    })()
  }, []);

  const getExamTemplateList = async () => {
    try {
      setIsLoading(true);

      const list = await GetExamTemplateList(filterExamTitle, includeDeleted);
      setExamsList(list);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`試験テンプレートリストの取得に失敗しました。`);
      setExamsList([]);
    } finally {
      setIsLoading(false);
    }
  };

  const inputFilterExamTitle = (newValue: string) => {
    setFilterExamTitle(newValue);
  };

  const openExamTemplateDialog = async (mode: string, srcUid: number) => {
    try {
      setIsLoading(true);
      await templateDialogRef.current?.openDialog(mode, srcUid);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const copyExamTemplateCases = async () => {
    try {
      setIsLoading(true);

      await CopyExamTemplateCases();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatedTemplateData = async () => {
    await getExamTemplateList();
  };

  const updatedExamTemplateData = async () => {
    await getExamTemplateList();
  };

  return (
    <Grid container>
      <CommonHeader headerTitle="試験テンプレート管理"/>
      <div className="ExamTemplateListPage-main-div">
        <Paper className="ExamTemplateListPage-filter-paper">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            style={{margin: "0 10px"}}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              width="50%"
            >
              <TextField 
                value={filterExamTitle} 
                onChange={(event) => inputFilterExamTitle(event.target.value)} 
                label="タイトル" variant="outlined" size="small" fullWidth
              />
              <Checkbox 
                checked={includeDeleted} 
                onChange={(evt) => setIncludeDeleted(includeDeleted ? false : true)}
                icon={<DeleteOutline />} 
                checkedIcon={<Delete color="error" />}
                size='small' 
              />
              <Button 
                onClick={async () => await getExamTemplateList()} 
                color="primary" 
                variant="contained"
                style={{minWidth: "90px"}}
              >
                検索する
              </Button>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Button 
                onClick={() => openExamTemplateDialog('add', -1)}
                variant="outlined" 
                color="primary"
              >
                追加する
              </Button>
              <Button 
                onClick={() => copyExamTemplateCases()}
                variant="outlined" 
                color="error"
              >
                症例コピー
              </Button>
            </Stack>
          </Stack>
        </Paper>
        <TableContainer component={Paper} className='ExamTemplateListPage-exams-table'>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 90 }} padding='none'></TableCell>
                <TableCell style={{ minWidth: 500 }}>タイトル</TableCell>
                <TableCell style={{ width: '100%', minWidth: 100 }}>概要</TableCell>
                <TableCell style={{ minWidth: 100 }}>ステータス</TableCell>
                <TableCell style={{ minWidth: 90 }} padding='none'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {examsList.map((x, index) => (
                <ExamTemplateRow
                  key={index}
                  row={x}
                  reloadExamTemplateData={updatedExamTemplateData}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <ExamTemplateDialog
        ref={templateDialogRef}
        updatedTemplateData={async () => await updatedTemplateData()}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CommonAlert
        ref={commonAlertRef}
      />
    </Grid>
  );
};

export default ExamTemplateListPage;