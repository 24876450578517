import { CaseData } from "../objects/case";
import { axiosClient } from "../contexts/axiosClientProvider";
import { CancelTokenSource } from "axios";
import { axiosImageClient } from "../contexts/axiosImageClientProvider";

export const GetCaseData = async (caseUid: number): Promise<CaseData> => {
  const response = await axiosClient.get<CaseData>(`cases?case_uid=${caseUid}`);
  return response.data;
};

export const GetDicomFileUrl = async (caseUid: number): Promise<string> => {
  const response = await axiosClient.get<string>(`cases/download/dicom/${caseUid}`);
  return response.data;
};

export const GetCaseDataWithQuestionUid = async (questionUid: number): Promise<CaseData> => {
  const response = await axiosClient.get<CaseData>(`cases/question/${questionUid}`);
  return response.data;
};

export const GetCaseDataList = async (
  lastCaseId: number,
  facilityId: number | null, 
  vendorId: number | null, 
  keyword: string,
  isNext: boolean,
  limit: number): Promise<{data: CaseData[], can_prev: boolean, can_next: boolean}> => {
    let query = `cases/list?last_case_id=${lastCaseId}&keyword=${keyword}&is_next=${isNext}&limit=${limit}`;
    if (facilityId) query = query + `&facility_id=${facilityId}`;
    if (vendorId) query = query + `&vendor_id=${vendorId}`;
    const response = await axiosClient.get<{data: CaseData[], can_prev: boolean, can_next: boolean}>(query);
    return response.data;
};

export const GetCaseImageData = async (caseUid: number, imageIndex: number, cancelToken: CancelTokenSource): Promise<Blob> => {
  const url = `cases/image?case_uid=${caseUid}&image_index=${imageIndex}`;
  const response = await axiosImageClient.get(url, {
    cancelToken: cancelToken.token,
    responseType : 'arraybuffer'
  });
  
  const dataArray = new Uint8Array(response.data);
  return new Blob([dataArray.buffer], { type: 'image/jpg' });
};


export const GetAnnotationImageData = async (caseImageUid: number): Promise<Blob> => {
  const url = `cases/image/annotation?case_image_uid=${caseImageUid}`;
  const response = await axiosImageClient.get(url, {
    responseType : 'arraybuffer'
  });
  
  const dataArray = new Uint8Array(response.data);
  return new Blob([dataArray.buffer], { type: 'image/jpg' });
};