import { TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { HealthCheckExamStudentRowProps } from "../../interfaces/exam/healthCheckExamStudentRowInterface";


const HealthCheckExamStudentRow: FC<HealthCheckExamStudentRowProps> = (props: HealthCheckExamStudentRowProps) => {
  const { row } = props;
  
  const handleCellColor = (): string => {
    if (!row.healthData) return '';
    if (row.healthData.checkResult()) return '#2196f3';
    else return '#f44336';
  };

  const handleBooleanDisplay = (value: boolean): string => {
    return value ? 'YES' : 'NO';
  };

  const handleNumberListDisplay = (value: number[]): string => {
    if (value.length < 1) return '-';
    else return value.join(',');
  };

  return (
    <>
      <TableRow hover tabIndex={-1} key={row.base.uid}>
        <TableCell style={{color: handleCellColor()}}>{ row.base.sign_in_id }</TableCell>
        <TableCell style={{color: handleCellColor()}}>{ row.healthData ? row.healthData.messages.join(',') : '-' }</TableCell>
        <TableCell style={{color: handleCellColor()}}>{ row.healthData ? handleBooleanDisplay(row.healthData.is_active) : '-' }</TableCell>
        <TableCell style={{color: handleCellColor()}}>{ row.healthData ? handleBooleanDisplay(row.healthData.q_order_by_count_is_correct) : '-' }</TableCell>
        <TableCell style={{color: handleCellColor()}}>{ row.healthData ? handleNumberListDisplay(row.healthData.diff_q_uid_list) : '-' }</TableCell>
        <TableCell style={{color: handleCellColor()}}>{ row.healthData ? handleNumberListDisplay(row.healthData.duplicate_q_order_by_uid_list) : '-' }</TableCell>
        <TableCell style={{color: handleCellColor()}}>{ row.healthData ? handleNumberListDisplay(row.healthData.forbidden_q_order_by_uid_list) : '-' }</TableCell>
      </TableRow>
    </>
  )
};

export default HealthCheckExamStudentRow;