import { NavigateBefore, NavigateNext, Refresh, Search } from "@mui/icons-material";
import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import CommonHeader from "../../compornents/commonHeader";
import './ExamTemplateEditorPage.css';
import { ExamTemplateData, ExamTemplateQuestionData, ExamTemplateSectionData } from "../../objects/examTemplate";
import { Stack } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { CaseData, FacilityData, VendorData } from "../../objects/case";
import { GetCaseDataList } from "../../repositories/caseRepo";
import { GetFacilityList, GetVendorList } from "../../repositories/filterRepo";
import ExamTemplateSectionRow from "../../compornents/examTemplate/examTemplateSectionRow";
import { ExamTemplateSectionDialogRefs } from "../../interfaces/examTemplate/examTemplateSectionDialogInterface";
import CaseDataRow from "../../compornents/caseDataRow";
import { CaseDataRowRefs } from "../../interfaces/caseDataRowInterface";
import { GetExamTemplateSectionList } from "../../repositories/examTemplateSectionRepo";
import { CheckExamTemplateCasesExist, GetExamTemplate, UpdateExamTemplateStatusReady } from "../../repositories/examTemplateRepo";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import CommonAlert from "../../compornents/commonAlert";
import { CaseNotExistDialog, CaseNotExistDialogRefs } from "../../compornents/examTemplate/CaseNotExistDialog";


const ExamTemplateEditorPage: FC = () => {
  const navigate = useNavigate();

  const { templateUid } = useParams();
  
  const [examTemplate, setExamTemplate] = useState<ExamTemplateData | null>(null);
  const [isEditable, setIsEditable] = useState(false);
  const [headerTitle, setHeaderTitle] = useState('試験テンプレート編集');
  const [examTemplateSectionslist, setExamTemplateSectionsList] = useState<ExamTemplateSectionData[]>([]);
  const [casesList, setCasesList] = useState<CaseData[]>([]);
  const [facilityList, setFacilityList] = useState<FacilityData[]>([]);
  const [vendorList, setVendorList] = useState<VendorData[]>([]);
  const [filterFacility, setFilterFacility] = useState<FacilityData | null>(null);
  const [filterVendor, setFilterVendor] = useState<VendorData | null>(null);
  const [filterKeyword, setFilterKeyword] = useState('');
  const [canPrev, setCanPrev] = useState(false);
  const [canNext, setCanNext] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [caseNotExistQuestionList, setCaseNotExistQuestionList] = useState<ExamTemplateQuestionData[]>([]);

  const caseDataRowRef = useRef<CaseDataRowRefs>(null);
  const commonAlertRef = useRef<CommonAlertRefs>(null);
  const caseNotExistDialogRef = useRef<CaseNotExistDialogRefs>(null);

  const caseDataLimit = 50;

  // initialize.
  useEffect( () => {
    (async () => {
      try {
        setIsLoading(true);

        const template = await GetExamTemplate(Number(templateUid));

        setExamTemplate(template);
        
        setHeaderTitle(`試験テンプレート編集: ${template.title}`);
        const editable = template.status === 'editing' ? true : false;
        setIsEditable(editable);

        if (editable) {
          const facilityList = await getFacilityFliter('');
          const vendorList = await getVendorFliter('');

          let lastCaseId = 0;
          const storageLastCaseId = sessionStorage.getItem('template-viewer-filter-lastCaseId');
          if (storageLastCaseId && parseInt(storageLastCaseId)) {
            lastCaseId = Number(storageLastCaseId);
          };

          let facilityId = null;
          const storageFacilityId = sessionStorage.getItem('template-viewer-filter-facilityId');
          if (storageFacilityId && parseInt(storageFacilityId)) {
            const filterFacility = facilityList.find((x) => x.id === Number(storageFacilityId));

            if (filterFacility) {
              setFilterFacility(filterFacility);
              facilityId = Number(storageFacilityId);
            };
          };
          
          let vendorId = null;
          const storageVendorId = sessionStorage.getItem('template-viewer-filter-vendorId');
          if (storageVendorId && parseInt(storageVendorId)) {
            const filterVendor = vendorList.find((x) => x.id === Number(storageVendorId));

            if (filterVendor) {
              setFilterVendor(filterVendor);
              vendorId = Number(storageVendorId);
            };
          };
          
          let keyword = '';
          const storagekeyword = sessionStorage.getItem('template-viewer-filter-keyword');
          if (storagekeyword) {
            keyword = storagekeyword;
            setFilterKeyword(keyword);
          };

          let isNext = true;
          const storageIsNext = sessionStorage.getItem('template-viewer-filter-isNext');
          if (storageIsNext) {
            isNext = storageIsNext === 'false' ? false : true;
          };
          
          await getCaseDataList(
            lastCaseId, 
            facilityId, 
            vendorId, 
            keyword, 
            isNext);
        };

        await getExamTemplateSectionList();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })()
  }, []);

  useEffect(() => {
    if (examTemplate === null) return;

    if (examTemplate.status === "editing") {
      checkExamTemplateCasesExist();
    };
  }, [examTemplate]);

  useEffect(() => {
    if (caseNotExistQuestionList.length < 1) return;

    caseNotExistDialogRef.current?.openDialog(caseNotExistQuestionList);
  }, [caseNotExistQuestionList]);

  const checkExamTemplateCasesExist = async () => {
    try {
      if (!templateUid) return;

      const result = await CheckExamTemplateCasesExist(Number(templateUid));
      setCaseNotExistQuestionList(result);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error("症例の存在チェックに失敗しました。");
    };
  }

  const getFacilityFliter = async (inputValue: string) => {
    console.log('Get facility filter list');

    try {
      const result = await GetFacilityList(inputValue);
      setFacilityList(result);
      return result;
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`施設リストの取得に失敗しました。`);
      setFacilityList([]);
      return [];
    };
  };

  const getVendorFliter = async (inputValue: string) => {
    console.log('Get vendor filter list');

    try {
      const result = await GetVendorList(inputValue);
      setVendorList(result);
      return result;
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`ベンダーリストの取得に失敗しました。`);
      setVendorList([]);
      return [];
    };
  };

  const clickedGetCaseDataList = async () => {
    await getCaseDataList(
      0, 
      filterFacility ? filterFacility.id : null, 
      filterVendor ? filterVendor.id : null, 
      filterKeyword, 
      true);
  };

  const clickedPrevCaseDataList = async () => {
    let lastCaseId = 0;
    if (0 < casesList.length) {
      lastCaseId = casesList.map((x) => x.id).reduce((a, b) => {return Math.min(a, b)});
    };
    await getCaseDataList(
      lastCaseId, 
      filterFacility ? filterFacility.id : null, 
      filterVendor ? filterVendor.id : null, 
      filterKeyword, 
      0 < lastCaseId ? false : true);
  };

  const clickedNextCaseDataList = async () => {
    let lastCaseId = 0;
    if (0 < casesList.length) {
      lastCaseId = casesList.map((x) => x.id).reduce((a, b) => {return Math.max(a, b)});
    };
    await getCaseDataList(
      lastCaseId, 
      filterFacility ? filterFacility.id : null, 
      filterVendor ? filterVendor.id : null, 
      filterKeyword, 
      true);
  };

  const getCaseDataList = async (lastCaseId: number, facilityId: number | null, vendorId: number | null, keyword: string, isNext: boolean) => {
    console.log(`Get case data list -> lastCaseId: ${lastCaseId}`);

    try {
      setIsLoading(true);

      const result = await GetCaseDataList(lastCaseId, facilityId, vendorId, keyword, isNext, caseDataLimit);

      setCanPrev(result.can_prev);
      setCanNext(result.can_next);
      setCasesList(result.data);

      sessionStorage.setItem('template-viewer-filter-lastCaseId', lastCaseId.toString());
      sessionStorage.setItem('template-viewer-filter-facilityId', facilityId ? facilityId.toString() : '');
      sessionStorage.setItem('template-viewer-filter-vendorId', vendorId ? vendorId.toString() : '');
      sessionStorage.setItem('template-viewer-filter-keyword', keyword);
      sessionStorage.setItem('template-viewer-filter-isNext', isNext ? 'true' : 'false');
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`症例リストの取得に失敗しました。`);
      setCanPrev(false);
      setCanNext(false);
      setCasesList([]);
    } finally {
      setIsLoading(false);
    };
  };

  const getExamTemplateSectionList = async () => {
    console.log('Get exam template section list');

    try {
      setIsLoading(true);
      setExamTemplateSectionsList([]);

      const examTemplateUid = getExamTemplateUid();

      const result = await GetExamTemplateSectionList(examTemplateUid);
      setExamTemplateSectionsList(result);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`セクションリストの取得に失敗しました。`);
      setExamTemplateSectionsList([]);
    } finally {
      setIsLoading(false);
    };
  };

  const handleFinishEditingButton = async () => {
    try {
      if (examTemplate === null) return;
      if (examTemplate.status !== "editing") return;
      
      setIsLoading(true);

      const caseNonExistList = await CheckExamTemplateCasesExist(Number(templateUid));

      if (0 < caseNonExistList.length) {
        setCaseNotExistQuestionList(caseNonExistList);
        return;
      };

      setConfirmDialog(true);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error("編集完了前チェックに失敗しました。");
    } finally {
      setIsLoading(false);
    }
  };

  const updateExamTemplateReady = async (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('Update exam template ready');
    try {
      setConfirmDialog(false);
      setIsLoading(true);
      
      const uid = getExamTemplateUid();
      await UpdateExamTemplateStatusReady(uid);

      commonAlertRef.current?.success(`試験テンプレートの編集を完了しました。`);
      navigate('/exams/templates');
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error(`試験テンプレートの編集完了に失敗しました。`);
    } finally {
      setIsLoading(false);
      event.stopPropagation();
    };
  };

  const getExamTemplateUid = (): number => {
    const examTemplateUid = parseInt(templateUid ? templateUid.toString() : '');
    if (!examTemplateUid) throw new Error('Could not parse examtemplate template uid');
    return examTemplateUid;
  };

  const handleClosedCaseNonExistDialog = async () => {
    setCaseNotExistQuestionList([]);
    await getExamTemplateSectionList();
  };

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="center" style={{ height: '100vh' }}>
      <Grid container>
        <CommonHeader headerTitle={headerTitle}/>
      </Grid>
      <Grid container className='ExamTemplateEditorPage-main-div'>
        <Grid container item spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid container item xs={6}>
            <Paper className="ExamTemplateEditorPage-filter-paper">
              <Grid
                container 
                item
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Stack direction="row" spacing={1} style={{width: '100%'}}>
                  <Autocomplete
                    value={filterFacility}
                    onChange={(evt, newValue) => {
                      setFilterFacility(newValue)
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disabled={(!isEditable || isLoading)}
                    disablePortal
                    options={facilityList}
                    getOptionLabel={option => option.name}
                    sx={{width: '30%'}}
                    size='small'
                    renderInput={(params) => 
                      <TextField 
                        {...params} 
                        onChange={async (evt) => await getFacilityFliter(evt.target.value)}
                        disabled={(!isEditable || isLoading)}
                        label="施設"
                      />
                    }
                  />
                  <Autocomplete
                    value={filterVendor}
                    onChange={(evt, newValue) => {
                      setFilterVendor(newValue)
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disabled={(!isEditable || isLoading)}
                    disablePortal
                    options={vendorList}
                    getOptionLabel={option => option.displayName}
                    sx={{width: '20%'}}
                    size='small'
                    renderInput={(params) => 
                      <TextField 
                        {...params} 
                        onChange={async (evt) => await getVendorFliter(evt.target.value)}
                        disabled={(!isEditable || isLoading)}
                        label="ベンダー"
                      />
                    }
                  />
                <TextField 
                  value={filterKeyword} 
                  onChange={(event) => setFilterKeyword(event.target.value)} 
                  disabled={(!isEditable || isLoading)}
                  label="キーワード" 
                  variant="outlined" size="small" style={{width: '40%'}}
                />
                <IconButton 
                  onClick={async (evt) => await clickedGetCaseDataList()}
                  disabled={(!isEditable || isLoading)}
                  edge="end" size="small" color="primary"
                >
                  <Search />
                </IconButton>
              </Stack>
              </Grid>
            </Paper>
            <TableContainer component={Paper} className='ExamTemplateEditorPage-case-table'>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: 70 }}>症例番号</TableCell>
                    <TableCell style={{ minWidth: 140 }}>施設</TableCell>
                    <TableCell style={{ minWidth: 100 }}>ベンダー</TableCell>
                    <TableCell style={{ minWidth: 100 }}>モデル</TableCell>
                    <TableCell style={{ width: '100%', minWidth: 140 }}>病名</TableCell>
                    <TableCell style={{ minWidth: 30 }} padding='none'>枚数</TableCell>
                    <TableCell style={{ minWidth: 40 }} padding='none'>
                      <Stack direction="row">
                        <IconButton 
                          onClick={async (evt) => await clickedPrevCaseDataList()}
                          disabled={(!isEditable || isLoading || !canPrev)}
                          edge="end" size="small" color="primary"
                        >
                          <NavigateBefore />
                        </IconButton>
                        <IconButton 
                          onClick={async (evt) => await clickedNextCaseDataList()}
                          disabled={(!isEditable || isLoading || !canNext)}
                          edge="end" size="small" color="primary"
                        >
                          <NavigateNext />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {casesList.map((c, index) => (
                    <CaseDataRow 
                      key={index}
                      ref={caseDataRowRef}
                      templateUid={getExamTemplateUid()}
                      row={c} 
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid 
            container 
            item
            xs={6}
          >
            <TableContainer component={Paper} className='ExamTemplateEditorPage-exam-table'>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: 40 }} padding='none'>
                    </TableCell>
                    <TableCell style={{ width: '100%', minWidth: 300 }}>タイトル</TableCell>
                    <TableCell style={{ minWidth: 50 }}>問題数</TableCell>
                    <TableCell style={{ minWidth: 40 }} align='right'>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={0.5}
                      >
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {examTemplateSectionslist.map((section) => (
                    <ExamTemplateSectionRow 
                      key={section.uid}
                      examTemplateUid={section.template_uid}
                      isEditable={isEditable}
                      row={section} 
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button 
              onClick={(evt) => handleFinishEditingButton()}
              disabled={isEditable === false}
              fullWidth 
              variant="contained" 
              color="primary"
            >
              {isEditable ? '編集完了' : '編集不可'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
        <DialogTitle>
          {"編集を完了してよろしいですか?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{whiteSpace: 'pre-wrap'}}>
            {
              '試験テンプレートの編集を完了します。'
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button onClick={async (event) => setConfirmDialog(false)} variant='outlined'>いいえ</Button>
            <Button onClick={async (event) => await updateExamTemplateReady(event)} variant="contained" color='primary'>はい</Button>
          </Grid>
        </DialogActions>
      </Dialog>
      {templateUid
        ? <CaseNotExistDialog
            ref={caseNotExistDialogRef}
            examTemplateUid={Number(templateUid)}
            closeDialog={handleClosedCaseNonExistDialog}
          />
        : null
      }
      <CommonAlert
        ref={commonAlertRef}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default ExamTemplateEditorPage;