import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Collapse, IconButton, Stack, TableCell, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { GradingExamQuestionRowProps } from "../../interfaces/gradingExam/gradingExamQuestionRowInterface";
import { ExamTemplateQuestionsType, ExamTemplateQuestionsTypeDisplay } from "../../objects/examTemplate";
import GradingDetectionAnswerPanel from "./gradingDetectionAnswerPanel";
import './gradingExamQuestionRow.css';
import GradingIdentificationAnswerPanel from "./gradingIdentificationAnswerPanel";


const GradingExamQuestionRow: FC<GradingExamQuestionRowProps> = (props: GradingExamQuestionRowProps) => {
  const { examUid, row, beforeMoveToOtherPage } = props;

  const [collapseOpen, setCollapseOpen] = useState(false);

  useEffect(() => {
    const rowCollapseUid = Number(sessionStorage.getItem(handleRowCollapseStorageItemName()));
    if (rowCollapseUid && !isNaN(rowCollapseUid)) {
      setCollapseOpen(rowCollapseUid === row.uid ? true : false);
    };
  }, []);

  const handleRowCollapseStorageItemName = (): string => {
    return `GradingExamQuestionRow-${examUid}-collapse`;
  };

  const handleCountUncheckedDetectionAnswer = (): string => {
    if (!row.answer_unity.detection) return '-';

    let count = 0;

    count = row.answer_unity.detection.first_stat.unchecked_image_count;

    if (row.answer_unity.detection.second_stat) count = count + row.answer_unity.detection.second_stat.unchecked_image_count;

    return 0 < count ? count.toString() : '-';
  };

  const handleBeforeMoveToOtherPage = () => {
    sessionStorage.setItem(handleRowCollapseStorageItemName(), row.uid.toString());
    if (beforeMoveToOtherPage) beforeMoveToOtherPage(row.uid);
  };

  return (
    <>
      <TableRow 
        id={`GradingExamQuestionRow-exam-table-row-${row.uid}`}
        onClick={() => setCollapseOpen(!collapseOpen)} 
        hover tabIndex={-1} key={row.uid}
      >
        <TableCell>{ row.question_number }</TableCell>
        <TableCell>{ row.case_uid }</TableCell>
        <TableCell>{ ExamTemplateQuestionsTypeDisplay(row.question_type) }</TableCell>
        <TableCell align="right">{ 0 < row.first_not_scored_count ? row.first_not_scored_count : '-' }</TableCell>
        <TableCell align="right">{ 0 < row.second_not_scored_count ? row.second_not_scored_count : '-' }</TableCell>
        <TableCell align="right">
          {row.question_type === ExamTemplateQuestionsType.Detection &&
            <>
              {handleCountUncheckedDetectionAnswer()}
            </>
          }
          {row.question_type === ExamTemplateQuestionsType.Identification &&
            <>
              {`-`}
            </>
          }
        </TableCell>
        <TableCell align="right">{ 0 < row.question_score.length ? row.question_score.length : '-' }</TableCell>
        <TableCell>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            style={{width: '100%'}}
          >
            <IconButton
              size="small"
              onClick={() => setCollapseOpen(!collapseOpen)}
            >
              {collapseOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow  style={{backgroundColor: '#f5f5f5'}}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {(row.question_type === 'detection' && row.answer_unity.detection) &&
                <GradingDetectionAnswerPanel
                  key={row.uid}
                  examUid={examUid}
                  questionUid={row.uid}
                  answer={row.answer_unity.detection}
                  second={row.second}
                  score={row.question_score}
                  beforeMoveToViewer={handleBeforeMoveToOtherPage}
                />
              }
              {row.question_type === 'identification' && 
                <GradingIdentificationAnswerPanel
                  answerList={row.answer_unity.identification}
                  second={row.second}
                />
              }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
};

export default GradingExamQuestionRow;