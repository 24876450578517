import { DialogActions, Stack } from "@mui/material";
import { ReactNode } from "react";

type CommonDialogActionProps = {
  children: ReactNode;
};

export const CommonDialogAction: React.FC<CommonDialogActionProps> = (
  props
) => {
  const { children } = props;

  return (
    <DialogActions>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        width="100%"
      >
        {children}
      </Stack>
    </DialogActions>
  );
};
