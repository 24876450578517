import { Axios } from "axios";
import UseStudentAuth from "./useStudentAuth";
import UseImageAuth from "./useImageAuth";


const UseRefreshStudentToken = () => {
  const { setStudentAuth } = UseStudentAuth();
  const { setImageAuth } = UseImageAuth();

  const refresh = async (client: Axios): Promise<string> => {
    let token = '';

    await client.get('/signin/students/refresh', {
      withCredentials: true
    }).then((res) => {
      token = res.data.access_token;

      setStudentAuth(token);
      setImageAuth(res.data.image_token);
    }).catch((error) => {
      console.error(error);
    });
    
    return token;
  };

  return refresh;
};

export default UseRefreshStudentToken;