import { axiosStudentClient } from "../contexts/axiosStudentClientProvider";
import { ExamStudentActionLogCreateData } from "../objects/exam";
import { StudentQuestionData, StudentIdentificationAnswerData, StudentDetectionAnswerData } from "../objects/student";


export const GetExamQuestion = async (examUid: number): Promise<StudentQuestionData | null> => {
  const response = await axiosStudentClient.get<StudentQuestionData | null>(`students/${examUid}`);
  return response.data;
};

export const CreateFirstDetectionAnswer = async (
  questionUid: number, 
  data: StudentDetectionAnswerData[], 
  imageBlobList: {fileName: string, data: Blob}[]): Promise<{needSecond: boolean}> => {
  let formData = new FormData();

  // Need dummy because api could not get request if formdata is empty.
  if (data.length < 1 && imageBlobList.length < 1) {
    formData.append('dummy', 'dummy');
  } else {
    imageBlobList.forEach((x) => {
      formData.append('files', x.data, x.fileName);
    });
  
    data.forEach((x) => {
      const jsonData = JSON.stringify(x);
      formData.append('answers', jsonData);
    });
  };
  
  const response = await axiosStudentClient.post<{needSecond: boolean}>(
    `students/answers/detection/first/${questionUid}`, 
    formData, 
    {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }
  );

  return response.data;
};

export const CreateSecondDetectionAnswer = async (
  questionUid: number, 
  data: StudentDetectionAnswerData[], 
  imageBlobList: {fileName: string, data: Blob}[]): Promise<void> => {
  let formData = new FormData();

  // Need dummy because api could not get request if formdata is empty.
  if (data.length < 1 && imageBlobList.length < 1) {
    formData.append('dummy', 'dummy');
  } else {
    imageBlobList.forEach((x) => {
      formData.append('files', x.data, x.fileName);
    });
  
    data.forEach((x) => {
      const jsonData = JSON.stringify(x);
      formData.append('answers', jsonData);
    });
  };

  await axiosStudentClient.post(
    `students/answers/detection/second/${questionUid}`, 
    formData, 
    {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }
  );
};

export const CreateFirstIdentificationAnswer = async (
  questionUid: number,
  data: StudentIdentificationAnswerData[]): Promise<{needSecond: boolean}> => {
    const response = await axiosStudentClient.post<{needSecond: boolean}>(`students/answers/identification/first/${questionUid}`, data);
    return response.data;
};

export const CreateSecondIdentificationAnswer = async (
  questionUid: number,
  data: StudentIdentificationAnswerData[]): Promise<void> => {
    await axiosStudentClient.post(`students/answers/identification/second/${questionUid}`, data);
};

export const AddExamStudentActionLog = async (questionUid: number, isSecond: boolean, data: ExamStudentActionLogCreateData): Promise<void> => {
  const url = `students/actionlogs/${questionUid}?is_second=${isSecond}`;
  await axiosStudentClient.post(url, data);
};

export const CheckExamFinished = async (examUid: number): Promise<{finished: boolean, title: string}> => {
  const response = await axiosStudentClient.get<{finished: boolean, title: string}>(`students/check/${examUid}`);
  return response.data;
};