import { Cancel, Check, Edit } from "@mui/icons-material";
import { Stack, Typography, Slider, IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Backdrop, CircularProgress, Paper, Divider, Tooltip } from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { ExamStudentQuestionScorePanelProps, ExamStudentQuestionScorePanelRefs } from "../../interfaces/exam/examStudentQuestionScorePanelInterface";
import { ExamStudentQuestionScoreCreateData } from "../../objects/exam";
import { UpdateExamStudentQuestionScore } from "../../repositories/examStudentQuestionScoreRepo";
import CommonAlert from "../commonAlert";
import "./examStudentQuestionScorePanel.css";


const ExamStudentQuestionScorePanel = forwardRef<ExamStudentQuestionScorePanelRefs, ExamStudentQuestionScorePanelProps>((props, ref) => {
  const { score, updatedScore } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [scoreValue, setScoreValue] = useState(50);
  const [description, setDescription] = useState('');
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const commonAlertRef = useRef<CommonAlertRefs>(null);

  useEffect(() => {
    setScoreValue(score.score === null ? 50 : score.score);
    setDescription(score.description);
    setIsEditing(score.score === null ? true : false);
  }, [score]);

  const handleChangeQuestionScore = async (event: Event | React.SyntheticEvent, newValue: number | number[]) => {
    setScoreValue(newValue as number);
  };

  const updateStudentQuestionScore = async (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('Update student question score');
    try {
      setisLoading(true);

      const data = new ExamStudentQuestionScoreCreateData(
        score.student_uid,
        score.question_uid,
        score.is_second,
        scoreValue,
        description
      );

      await UpdateExamStudentQuestionScore(score.uid, data);
      
      setisLoading(false);
      setOpenUpdateDialog(false);
      setIsEditing(false);
      if (updatedScore) await updatedScore();
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error('総合評価の更新に失敗しました。');
    } finally {
      setisLoading(false);
    };
  };

  const handleQuestionScoreValue = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = Number(evt.target.value);
    console.log(newValue);
    if (newValue < 0) setScoreValue(0);
    else if (100 < newValue) setScoreValue(100);
    else setScoreValue(newValue);
  };

  const handleClickedCancelButton = () => {
    if (score.score === null) return;

    setIsEditing(false);
    setScoreValue(score.score);
  };
  
  return (
    <>
      <Paper
        className="examStudentQuestionScorePanel-paper"
      >
        <Divider textAlign="left" style={{marginTop: '5px'}}>{`問題評価${score.score !== null ? '' : '(未)'}`}</Divider>
        {score.score
          ? <Stack 
              direction="row"
              justifyContent="space-between"
              alignItems="center" 
              spacing={2}
              className="examStudentQuestionScorePanel-main-stack"
            >
              <Tooltip title={description} placement="top-end">
                <Typography noWrap>{`${description}`}</Typography>
              </Tooltip>
              <Button 
                onClick={(evt) => setOpenUpdateDialog(true)}
                disabled={isLoading}
                variant="outlined"
                style={{minWidth: "120px"}}
              >
                {`コメント編集`}
              </Button>
            </Stack>
          : <Stack 
              direction="row"
              justifyContent="space-between"
              alignItems="center" 
              spacing={2}
              className="examStudentQuestionScorePanel-main-stack"
            >
              <Button
                onClick={(evt) => setOpenUpdateDialog(true)}
                disabled={isLoading}
                fullWidth
                variant="outlined"
              >
                {`評価済にする`}
              </Button>
            </Stack>
        }
        {/* {isEditing
          ? <Stack 
              direction="row"
              justifyContent="space-between"
              alignItems="center" 
              spacing={2}
              className="examStudentQuestionScorePanel-main-stack"
            >
              <TextField
                value={scoreValue}
                onChange={(evt) => handleQuestionScoreValue(evt)}
                InputProps={{ 
                  inputProps: { min: 0, max: 100 } 
                }}
                type="number"
                size="small"
                style={{width: '150px'}}
              />
              <Slider 
                value={scoreValue} 
                onChange={handleChangeQuestionScore}
                disabled={isLoading || !isEditing}
                min={0}
                max={100}
              />
              <Stack
                direction="row"
                justifyContent='flex-end'
                alignItems='center'
                spacing={1}
              >
                {score.score !== null &&
                  <IconButton 
                    onClick={() => handleClickedCancelButton()}
                    disabled={isLoading || score.score === null}
                    edge='end' color="error"
                  >
                    <Cancel/>
                  </IconButton>
                }
                <IconButton 
                  onClick={() => setOpenUpdateDialog(true)}
                  disabled={isLoading}
                  edge='end' color="success"
                >
                  <Check/>
                </IconButton>
              </Stack>
            </Stack>
          : <Stack 
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              className="examStudentQuestionScorePanel-main-stack"
            >
              <Typography style={{color: '#2196f3', fontWeight: 'bold', fontSize: '20px'}}>{`${scoreValue}`}</Typography>
              <Tooltip title={description} placement="top-end">
                <Typography noWrap>{`${description}`}</Typography>
              </Tooltip>
              <IconButton 
                onClick={(evt) => setIsEditing(true)}
                disabled={isLoading}
                edge='end' color="primary"
              >
                <Edit/>
              </IconButton>
            </Stack>
        } */}
        <Stack 
          direction="row"
          justifyContent="space-between"
          alignItems="center" 
          spacing={2}
        >
        </Stack>
      </Paper>
      <Dialog 
        open={openUpdateDialog} 
        onClose={() => setOpenUpdateDialog(false)}
        maxWidth='sm'
        fullWidth>
        <DialogTitle>
          {"問題評価を更新してよろしいですか?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div>{`被験者ID: ${score.exam_student.sign_in_id}(${!score.is_second ? '視聴前' : '視聴後'})`}</div>
            {/* <div>{`評価点: ${score.score !== null ? score.score : '未評価'} → ${scoreValue}`}</div> */}
          </DialogContentText>
          <TextField 
            value={description} 
            onChange={(event) => setDescription(event.target.value)} 
            multiline 
            rows={5} 
            inputProps={{
              maxLength: 250,
            }}
            label='コメント'
            fullWidth
            style={{marginTop: '10px'}}
          />
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button onClick={async (event) => setOpenUpdateDialog(false)} variant='outlined'>いいえ</Button>
            <Button onClick={async (evt) => await updateStudentQuestionScore(evt)} variant="contained" color='primary'>はい</Button>
          </Grid>
        </DialogActions>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
      <CommonAlert
        ref={commonAlertRef}
      />
    </>
  );
});

export default ExamStudentQuestionScorePanel;