export class DiagnosisData {
  uid: number;
  is_malignant: boolean;
  display: string;

  constructor (uid: number, is_malignant: boolean, display: string) {
    this.uid = uid;
    this.is_malignant = is_malignant;
    this.display = display;
  };
};
