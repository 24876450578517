import { ExamTemplateData, ExamTemplateQuestionData } from "../objects/examTemplate";
import { axiosClient } from "../contexts/axiosClientProvider";


export const AddExamTemplate = async (data: ExamTemplateData): Promise<ExamTemplateData> => {
  const response = await axiosClient.post<ExamTemplateData>(`exams/templates`, data);
  return response.data;
};

export const GetExamTemplate = async (examUid: number): Promise<ExamTemplateData> => {
  const response = await axiosClient.get<ExamTemplateData>(`exams/templates/${examUid}`);
  return response.data;
};

export const GetExamTemplateList = async (examTitle: string, includeDeleted: boolean): Promise<ExamTemplateData[]> => {
  const response = await axiosClient.get<ExamTemplateData[]>(`exams/templates?title=${examTitle}&deleted=${includeDeleted}`);
  return response.data;
};

export const CheckExamTemplateCasesExist = async (examTemplateUid: number): Promise<ExamTemplateQuestionData[]> => {
  let url = `exams/templates/check/cases/${examTemplateUid}`;

  const response = await axiosClient.get(url);
  return response.data;
};

export const UpdateExamTemplate = async (uid: number, data: ExamTemplateData): Promise<ExamTemplateData> => {
  const response = await axiosClient.put<ExamTemplateData>(`exams/templates/${uid}`, data);
  return response.data;
};

export const UpdateExamTemplateStatusReady = async (uid: number): Promise<ExamTemplateData> => {
  const response = await axiosClient.put<ExamTemplateData>(`exams/templates/${uid}/ready`);
  return response.data;
};

export const CopyExamTemplate = async (uid: number, newExam: ExamTemplateData): Promise<ExamTemplateData> => {
  const response = await axiosClient.put<ExamTemplateData>(`exams/templates/${uid}/copy`, newExam);
  return response.data;
};

export const DeleteExamTemplate = async (uid: number): Promise<void> => {
  await axiosClient.delete<ExamTemplateData>(`exams/templates/${uid}`);
};

// export class ExamTemplateRepo {
//   private client = new AuthenticatedHttpClient();

//   public async Add(data: ExamTemplateData): Promise<ExamTemplateData> {
//     return await (await this.client.post(`exams/templates`, data)).data;
//   };

//   public async GetList(examTitle: string, includeDeleted: boolean): Promise<ExamTemplateData[]> {
//     return await (await this.client.get(`exams/templates?title=${examTitle}&deleted=${includeDeleted}`)).data;
//   };

//   public async Update(uid: number, data: ExamTemplateData): Promise<ExamTemplateData> {
//     return await (await this.client.put(`exams/templates/${uid}`, data)).data;
//   };

//   public async UpdateStatusReady(uid: number): Promise<ExamTemplateData> {
//     return await (await this.client.put(`exams/templates/${uid}/ready`)).data;
//   };

//   public async Copy(uid: number, newExam: ExamTemplateData): Promise<ExamTemplateData> {
//     return await (await this.client.put(`exams/templates/${uid}/copy`, newExam)).data;
//   };

//   public async Delete(uid: number): Promise<ExamTemplateData> {
//     return await (await this.client.delete(`exams/templates/${uid}`)).data;
//   };
// };