import { DiagnosisData } from "../diagnosis";
import { ExamTemplateAnswerAnnotationData } from "./examTemplateAnswerAnnotationData";
import { ExamTemplateAnswerDrawingData } from "./examTemplateAnswerDrawingData";
import { ExamTemplateCaseAnnotationData } from "./examTemplateCaseAnnotationData";

export class ExamTemplateAnswerData {
  uid: number;
  image_index: number;
  diagnosis_uid: number;
  answer_type: ExamTemplateAnswerType;
  diagnosis: DiagnosisData;
  exam_template_answer_annotation: ExamTemplateAnswerAnnotationData | null;
  exam_template_answer_drawing: ExamTemplateAnswerDrawingData | null;

  constructor(
    uid: number,
    image_index: number,
    diagnosis_uid: number,
    answer_type: ExamTemplateAnswerType,
    diagnosis: DiagnosisData,
    exam_template_answer_annotation: ExamTemplateAnswerAnnotationData | null,
    exam_template_answer_drawing: ExamTemplateAnswerDrawingData | null
  ) {
    this.uid = uid;
    this.image_index = image_index;
    this.diagnosis_uid = diagnosis_uid;
    this.answer_type = answer_type;
    this.diagnosis = diagnosis;
    this.exam_template_answer_annotation = exam_template_answer_annotation;
    this.exam_template_answer_drawing = exam_template_answer_drawing;
  }
}

export const ExamTemplateAnswerType = {
  Annotation: "annotation",
  Drawing: "drawing",
} as const;

export type ExamTemplateAnswerType =
  (typeof ExamTemplateAnswerType)[keyof typeof ExamTemplateAnswerType];

export const ExamTemplateAnswerTypeDisplay = (
  name: ExamTemplateAnswerType
): string => {
  switch (name) {
    case "annotation":
      return "Annotation";
    case "drawing":
      return "Drawing";
  }
};
