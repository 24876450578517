import { FC, Fragment, useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, Grid, List, ListItem, ListItemButton, ListItemText, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CommonHeaderProps } from "../interfaces/commonHeaderInterface";
import { SignOut } from "../repositories/authRepo";
import UseAuth from "../hooks/useAuth";
import { AccountCircle } from "@mui/icons-material";
import CommonHtmlTooltip from "./commonHtmlTooltip";


const CommonHeader: FC<CommonHeaderProps> = (props: CommonHeaderProps) => {
  const navigate = useNavigate();

  const { headerTitle } = props;
  
  const [openDrawer, setOpenDrawer] = useState(false);

  const { auth, setAuth } = UseAuth();

  const routing = [
    {
      display: '試験テンプレート管理',
      route: '/exams/templates'
    },
    {
      display: '試験管理',
      route: '/exams'
    },
    {
      display: 'ユーザー管理',
      route: '/users'
    }
  ];

  const moveTo = (route: string) => {
    navigate(`${route}`);
  };

  const signOut = async () => {
    console.log('Signout');

    try {
      await SignOut();
      setAuth(null);
      sessionStorage.clear();
      navigate('/signin', {replace: true});
    } catch (error) {
      console.error(error);
    } finally {

    };
  }

  return (
    <AppBar position="static">
      <Toolbar variant="dense">
        <Stack direction="row" alignItems="center" sx={{ width: '100%' }} spacing={2}>
        <IconButton
          onClick={(event) => setOpenDrawer(true)}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {headerTitle ? headerTitle : '革新がん出題システム'}
        </Typography>
        <CommonHtmlTooltip placement="bottom-start" title={
          <Fragment>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid container item xs={3}>
                <Typography color="inherit">{`ID`}</Typography>
              </Grid>
              <Grid container item xs={9}>
                <Typography color="inherit">{`:${auth ? auth.id : '???'}`}</Typography>
              </Grid>
              <Grid container item xs={3}>
                <Typography color="inherit">{`名前`}</Typography>
              </Grid>
              <Grid container item xs={9}>
                <Typography color="inherit">{`:${auth ? `${auth.last_name} ${auth.first_name}` : '???'}`}</Typography>
              </Grid>
            </Grid>
          </Fragment>
        }>
          <IconButton size="large" edge="end" color="inherit">
            <AccountCircle />
          </IconButton>
        </CommonHtmlTooltip>
        </Stack>
      </Toolbar>
      <Drawer
        anchor='left'
        open={openDrawer}
        onClose={(event) => setOpenDrawer(false)}
      >
        <Box
          sx={{ width: 300, height: '100vh' }}
          role="presentation"
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            sx={{height: '100vh'}}
          >
            <List>
            {routing.map((route) => (
              <ListItem key={route.route} disablePadding>
                <ListItemButton
                  onClick={(event) => moveTo(route.route)}
                >
                  <ListItemText primary={route.display} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={async (evt) => await signOut()}
            >
              <ListItemText primary={'ログアウト'} />
            </ListItemButton>
          </ListItem>
          </Grid>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default CommonHeader;