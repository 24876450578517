import {
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import ViewerDisplay from "../../compornents/viewer/viewerDIsplay";
import { ViewerDisplayRefs } from "../../interfaces/viewer/viewerDIsplayInterface";
import { CaseData } from "../../objects/case";
import "./GradingAnswerViewer.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ExamTemplateAnswerData } from "../../objects/examTemplate";
import ViewerErrorCompornent from "../../compornents/viewer/viewerErrorCompornent";
import { GetCaseData } from "../../repositories/caseRepo";
import CommonAlert from "../../compornents/commonAlert";
import { CommonAlertRefs } from "../../interfaces/commonAlertInterface";
import { GetGradingExamQuestion } from "../../repositories/gradingExamRepo";
import { GradingExamQuestionData } from "../../objects/gradingExam";
import GradingAnswerViewerHeaderPanel from "../../compornents/viewer/gradingAnswerViewerHeaderPanel";
import { GradingAnswerViewerHeaderPanelRefs } from "../../interfaces/viewer/gradingAnswerViewerHeaderPanelInterface";
import GradingAnswerViewerSearchPanel from "../../compornents/viewer/gradingAnswerViewerSearchPanel";
import GradingAnswerDrawingDisplay from "../../compornents/viewer/gradingAnswerDrawingDisplay";
import ExamStudentQuestionScorePanel from "../../compornents/exam/examStudentQuestionScorePanel";
import { GradingAnswerDrawingDisplayRefs } from "../../interfaces/viewer/gradingAnswerDrawingDisplayInterface";
import { GetExamAnswerDrawingList } from "../../repositories/examAnswerDrawingRepo";
import { GradingAnswerViewerSearchPanelRefs } from "../../interfaces/viewer/gradingAnswerViewerSearchPanelInterface";
import { GetExamTemplateAnswerList } from "../../repositories/examTemplateAnswerRepo";
import {
  ExamAnswerData,
  ExamAnswerDrawingData,
  ExamStudentQuestionScoreData,
} from "../../objects/exam";
import { GetExamAnswerList } from "../../repositories/examAnswerRepo";
import { GetExamStudentQuestionScore } from "../../repositories/examStudentQuestionScoreRepo";
import ExamAnswerDrawingRow from "../../compornents/exam/examAnswerDrawingRow";
import ExamAnswerRow from "../../compornents/exam/examAnswerRow";
import { ExamDetectionAnswerAnalysisPanel } from "../../compornents/exam/ExamDetectionAnswerAnalysisPanel";
import { ChangeManualAnnotationCountButton } from "../../compornents/case/ChangeManualAnnotationCountButton";

const GradingAnswerViewer: FC = () => {
  const navigate = useNavigate();

  const { examUid, examQuestionUid } = useParams();
  const [searchParams] = useSearchParams();

  let initialScoreUid: number | null = null;
  const queryScoreUid = searchParams.get("initial_score");
  if (queryScoreUid) {
    const parseScoreUid = Number(queryScoreUid);
    if (parseScoreUid) initialScoreUid = parseScoreUid;
    console.log(initialScoreUid);
  }

  const [gradingQuestionData, setGradingQuestionData] =
    useState<GradingExamQuestionData | null>(null);
  const [caseData, setCaseData] = useState<CaseData | null>(null);
  const [selectedQuestionScoreData, setSelectedQuestionScoreData] =
    useState<ExamStudentQuestionScoreData | null>(null);
  const [templateAnswerList, setTemplateAnswerList] = useState<
    ExamTemplateAnswerData[]
  >([]);
  const [studentAnswerList, setStudentAnswerList] = useState<ExamAnswerData[]>(
    []
  );
  const [selectedStudentAnswerData, setSelectedStudentAnswerData] =
    useState<ExamAnswerData | null>(null);
  const [studentDrawingList, setStudentDrawingList] = useState<
    ExamAnswerDrawingData[]
  >([]);
  const [selectedStudentDrawingData, setSelectedStudentDrawingData] =
    useState<ExamAnswerDrawingData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const headerPanelRef = useRef<GradingAnswerViewerHeaderPanelRefs>(null);
  const searchPanelRef = useRef<GradingAnswerViewerSearchPanelRefs>(null);
  const viewerDisplayRef = useRef<ViewerDisplayRefs>(null);
  const drawingDisplayRef = useRef<GradingAnswerDrawingDisplayRefs>(null);
  const commonAlertRef = useRef<CommonAlertRefs>(null);

  // initialize.
  useEffect(() => {
    if (!examUid || !examQuestionUid) navigate("/notfound");
    (async () => {
      try {
        setIsLoading(true);

        const gradingQuestionData = await GetGradingExamQuestion(
          Number(examQuestionUid)
        );
        setGradingQuestionData(gradingQuestionData);
      } catch (error) {
        console.error(error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [examQuestionUid]);

  useEffect(() => {
    if (!gradingQuestionData) return;

    (async () => {
      try {
        setIsLoading(true);
        await getCaseData(gradingQuestionData.case_uid);
        await getExamTemplateAnswerList(
          gradingQuestionData.template_question_uid
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [gradingQuestionData]);

  useEffect(() => {
    if (!selectedStudentAnswerData) return;
    try {
      const templateAnswerUid = selectedStudentAnswerData.template_answer_uid;

      const templateAnswerData = templateAnswerList.find(
        (a) => a.uid === templateAnswerUid
      );
      if (!templateAnswerData)
        throw new Error(`Could not find the template answer Data`);

      const annotationData = templateAnswerData.exam_template_answer_annotation;
      if (!annotationData) throw new Error(`Could not find the annotationData`);

      showAnnotationImage(annotationData.case_image_uid);
    } catch (error) {
      console.error(error);
    }
  }, [selectedStudentAnswerData]);

  useEffect(() => {
    const drawingUidList = studentDrawingList.map((x) => x.uid);
    drawingDisplayRef.current?.updateDrawingUidList(drawingUidList);
  }, [studentDrawingList]);

  useEffect(() => {
    if (!selectedStudentDrawingData) {
      return;
    }

    drawingDisplayRef.current?.showDrawingImage(selectedStudentDrawingData);

    const imageIndex = selectedStudentDrawingData.image_index;

    let isFound = false;
    for (let i = 0; i <= 5; i++) {
      const plusAnswerData = studentAnswerList.find(
        (a) => a.image_index === imageIndex + i
      );
      if (plusAnswerData) {
        setSelectedStudentAnswerData(plusAnswerData);
        isFound = true;
        break;
      }

      const minusAnswerData = studentAnswerList.find(
        (a) => a.image_index === imageIndex - i
      );
      if (minusAnswerData) {
        setSelectedStudentAnswerData(minusAnswerData);
        isFound = true;
        break;
      }
    }

    if (!isFound) {
      viewerDisplayRef.current?.changeCurrentIndex(imageIndex);
    }
  }, [selectedStudentDrawingData]);

  const initializePage = () => {
    setSelectedStudentAnswerData(null);
    setSelectedStudentDrawingData(null);
    setStudentAnswerList([]);
    setStudentDrawingList([]);
    setTemplateAnswerList([]);
    setCaseData(null);
    setGradingQuestionData(null);
  };

  const clickedMovingQuestion = async (examQuestionUid: number) => {
    console.log("Clicked moving question");
    initializePage();
    navigate(`/exams/grading/${examUid}/viewer/${examQuestionUid}`);
  };

  const getCaseData = async (templateQuestionUid: number) => {
    console.log("Get case data");
    try {
      const result = await GetCaseData(templateQuestionUid);
      setCaseData(result);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error("症例データの取得に失敗しました。");
      setCaseData(null);
    }
  };

  const getExamTemplateAnswerList = async (templateQuestionUid: number) => {
    console.log("Get exam template answer list");
    try {
      const result = await GetExamTemplateAnswerList(templateQuestionUid);
      setTemplateAnswerList(result);
    } catch (error) {
      console.error(error);
      commonAlertRef.current?.error("試験正解リストの取得に失敗しました。");
      setTemplateAnswerList([]);
    }
  };

  const handleChangedExamStudentQuestionScore = async (
    data: ExamStudentQuestionScoreData | null
  ): Promise<void> => {
    console.log("Handle changed exam student question score");
    try {
      setIsLoading(true);
      if (!data) {
        setSelectedQuestionScoreData(null);
        setStudentAnswerList([]);
        setStudentDrawingList([]);
        return;
      }

      await getExamStudentQuestionScore(data.uid);
      await getExamAnswerList(
        data.question_uid,
        data.student_uid,
        data.is_second
      );
      await getExamAnswerDrawingList(
        data.question_uid,
        data.student_uid,
        data.is_second
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getExamStudentQuestionScore = async (
    examStudentQuestionScoreUid: number
  ) => {
    console.log("Get exam student question score");
    try {
      const result = await GetExamStudentQuestionScore(
        examStudentQuestionScoreUid
      );
      setSelectedQuestionScoreData(result);
    } catch (error) {
      console.error(error);
      setSelectedQuestionScoreData(null);
      commonAlertRef.current?.error("被験者評価情報の取得に失敗しました。");
    }
  };

  const getExamAnswerDrawingList = async (
    examQuestionUid: number,
    examStudentUid: number,
    isSecond: boolean
  ) => {
    console.log("Get exam answer drawing list");
    try {
      const result = await GetExamAnswerDrawingList(
        true,
        examStudentUid,
        examQuestionUid,
        null,
        isSecond
      );

      result.sort((a, b) => {
        if (a.image_index < b.image_index) return -1;
        if (a.image_index > b.image_index) return 1;

        return 0;
      });

      setStudentDrawingList(
        result.filter(
          (x) =>
            x.question_uid === examQuestionUid &&
            x.student_uid === examStudentUid
        )
      );
    } catch (error) {
      console.error(error);
      setStudentDrawingList([]);
      commonAlertRef.current?.error(
        "被験者解答画像リストの取得に失敗しました。"
      );
    }
  };

  const getExamAnswerList = async (
    examQuestionUid: number,
    examStudentUid: number,
    isSecond: boolean
  ) => {
    console.log("Get exam answer list");
    try {
      const result = await GetExamAnswerList(
        true,
        examStudentUid,
        examQuestionUid,
        null,
        isSecond
      );

      result.sort((a, b) => {
        if (a.image_index < b.image_index) return -1;
        if (a.image_index > b.image_index) return 1;

        if (a.diagnosis_uid < b.diagnosis_uid) return -1;
        if (a.diagnosis_uid > b.diagnosis_uid) return 1;

        return 0;
      });

      setStudentAnswerList(result);
    } catch (error) {
      console.error(error);
      setStudentAnswerList([]);
      commonAlertRef.current?.error(
        "被験者病名評価リストの取得に失敗しました。"
      );
    }
  };

  const handleGetTemplateAnswer = (uid: number) => {
    const templateAnswerData = templateAnswerList.find((a) => a.uid === uid);
    if (!templateAnswerData)
      throw new Error(`Could not find the template answer Data`);

    return templateAnswerData;
  };

  const handleGetCaseImageUid = (targetTemplateAnswerUid: number) => {
    const templateAnswerData = templateAnswerList.find(
      (a) => a.uid === targetTemplateAnswerUid
    );
    if (!templateAnswerData)
      throw new Error(`Could not find the template answer Data`);

    const annotationData = templateAnswerData.exam_template_answer_annotation;
    if (!annotationData) throw new Error(`Could not find the annotationData`);

    return annotationData.case_image_uid;
  };

  const changeImageIndex = (imageIndex: number) => {
    if (!selectedStudentAnswerData) return;
    if (selectedStudentAnswerData.image_index !== imageIndex)
      setSelectedStudentAnswerData(null);
  };

  const showAnnotationImage = (caseImageUid: number) => {
    if (!viewerDisplayRef.current)
      throw new Error("Image display ref does not exist");
    viewerDisplayRef.current.showAnnotationImage(caseImageUid);
  };

  const handleSelectExamAnswerData = (data: ExamAnswerData) => {
    setSelectedStudentAnswerData(data);
  };

  const handleChangeDetectionManualCount = (
    caseId: number,
    value: number | null
  ) => {
    if (!gradingQuestionData || gradingQuestionData.case_uid !== caseId) return;

    setGradingQuestionData(
      new GradingExamQuestionData(
        gradingQuestionData.uid,
        gradingQuestionData.template_question_uid,
        gradingQuestionData.question_number,
        gradingQuestionData.question_type,
        gradingQuestionData.case_uid,
        value,
        gradingQuestionData.second,
        gradingQuestionData.answered_rate,
        gradingQuestionData.first_not_scored_count,
        gradingQuestionData.second_not_scored_count,
        gradingQuestionData.question_score,
        gradingQuestionData.answer_unity
      )
    );
  };

  const handleUpdatedStudentAnswerData = async () => {
    if (!selectedQuestionScoreData) return;
    console.log("Handle updated student answer data");
    try {
      setIsLoading(true);
      await getExamAnswerList(
        selectedQuestionScoreData.question_uid,
        selectedQuestionScoreData.student_uid,
        selectedQuestionScoreData.is_second
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateExamTemplateAnswer = async () => {
    if (!gradingQuestionData) return;
    console.log("Handle updated student answer data");
    try {
      setIsLoading(true);

      await getExamTemplateAnswerList(
        gradingQuestionData.template_question_uid
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectDrawingData = async (data: ExamAnswerDrawingData) => {
    setSelectedStudentDrawingData(data);
  };

  const handleUpdatedStudentDrawingData = async () => {
    if (!selectedQuestionScoreData) return;
    console.log("Handle updated student drawing data");
    try {
      setIsLoading(true);
      await getExamAnswerDrawingList(
        selectedQuestionScoreData.question_uid,
        selectedQuestionScoreData.student_uid,
        selectedQuestionScoreData.is_second
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatedExamStudentQuestionScore = async () => {
    console.log("Handle updated exam student question score");
    try {
      searchPanelRef.current?.moveToNextScore();
    } catch (error) {
      console.error(error);
    }
  };

  const handleExamDetectionAnswerAnalysisPanelAsnwerCount = (): number => {
    if (
      gradingQuestionData &&
      gradingQuestionData.detection_manual_count !== null
    ) {
      const manualCount = gradingQuestionData.detection_manual_count;

      if (manualCount <= 5) return gradingQuestionData.detection_manual_count;
      else return 5;
    }

    const answerList = studentAnswerList.filter(
      (x) => x.diagnosis_uid !== 99999
    );

    if (answerList.length <= 5) return answerList.length;
    else return 5;
  };

  return (
    <Grid container className="GradingAnswerViewer-root-grid">
      {!isError ? (
        <>
          <Grid item xs={4.5} style={{ borderRight: "1px solid #2196f3" }}>
            {caseData !== null && (
              <ViewerDisplay
                ref={viewerDisplayRef}
                caseData={caseData}
                enableDrawing={false}
                enableShowDrawingData={false}
                changeImageIndex={changeImageIndex}
              />
            )}
          </Grid>
          <Grid item xs={4.5}>
            {caseData !== null && 0 < studentDrawingList.length && (
              <GradingAnswerDrawingDisplay
                ref={drawingDisplayRef}
                caseData={caseData}
              />
            )}
          </Grid>
          <Grid item xs={3} className="GradingAnswerViewer-panel-grid">
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              className="GradingAnswerViewer-list-grid"
            >
              <Grid
                container
                item
                xs={12}
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                style={{ padding: 5 }}
              >
                {caseData && examUid && examQuestionUid && (
                  <GradingAnswerViewerHeaderPanel
                    ref={headerPanelRef}
                    examUid={Number(examUid)}
                    examQuestionUid={Number(examQuestionUid)}
                    caseData={caseData}
                    clickedMovingQuestion={clickedMovingQuestion}
                  />
                )}
                <TableContainer
                  component={Paper}
                  className="GradingAnswerViewer-answer-table"
                >
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: "100%", minWidth: 100 }}>
                          病名
                        </TableCell>
                        <TableCell style={{ minWidth: 30 }}>枚目</TableCell>
                        <TableCell style={{ minWidth: 50 }} padding="none">
                          サイズ
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 40 }}
                          padding="none"
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {studentAnswerList.map((x, index) => (
                        <ExamAnswerRow
                          key={index}
                          row={x}
                          selectedExamAnswerUid={
                            selectedStudentAnswerData !== null
                              ? selectedStudentAnswerData.uid
                              : null
                          }
                          handleSelectExamAnswerData={
                            handleSelectExamAnswerData
                          }
                          getCaseImageUid={handleGetCaseImageUid}
                          getTemplateAnswer={handleGetTemplateAnswer}
                          handleUpdatedData={handleUpdatedStudentAnswerData}
                          handleUpdateTemplateAnswer={
                            handleUpdateExamTemplateAnswer
                          }
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {gradingQuestionData ? (
                  <ChangeManualAnnotationCountButton
                    caseId={gradingQuestionData.case_uid}
                    manualCount={gradingQuestionData.detection_manual_count}
                    finishedChange={handleChangeDetectionManualCount}
                  />
                ) : null}
                {caseData && (
                  <GradingAnswerViewerSearchPanel
                    ref={searchPanelRef}
                    examUid={Number(examUid)}
                    examQuestionUid={Number(examQuestionUid)}
                    initialScoreUid={initialScoreUid}
                    changedExamStudentQuestionScore={
                      handleChangedExamStudentQuestionScore
                    }
                  />
                )}
                <TableContainer
                  component={Paper}
                  className="GradingAnswerViewer-drawing-table"
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: "100%", minWidth: 70 }}>
                          No.
                        </TableCell>
                        <TableCell style={{ minWidth: 30 }}>枚目</TableCell>
                        <TableCell
                          style={{ minWidth: 100 }}
                          padding="none"
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {studentDrawingList.map((x, index) => (
                        <ExamAnswerDrawingRow
                          key={index}
                          row={x}
                          selectedExamAnswerDrawingUid={
                            selectedStudentDrawingData !== null
                              ? selectedStudentDrawingData.uid
                              : null
                          }
                          handleSelectDrawingData={handleSelectDrawingData}
                          handleUpdatedData={handleUpdatedStudentDrawingData}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {caseData ? (
                  <ExamDetectionAnswerAnalysisPanel
                    studentDrawingList={studentDrawingList}
                    answerCount={handleExamDetectionAnswerAnalysisPanelAsnwerCount()}
                  />
                ) : null}
                {selectedQuestionScoreData && (
                  <ExamStudentQuestionScorePanel
                    score={selectedQuestionScoreData}
                    updatedScore={handleUpdatedExamStudentQuestionScore}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      ) : (
        <ViewerErrorCompornent />
      )}
      <CommonAlert ref={commonAlertRef} />
    </Grid>
  );
};

export default GradingAnswerViewer;
